<template>
  <div class="age-verification-dialog" id="age-verification-dialog" :class="{ 'fade-in': ageVerification.shown, 'fade-out': !ageVerification.shown }">
    <div class="common-dialog-container flex-ends">
      <div class="common-dialog-header">
        <div class="common-dialog-icon">
          <BigIcon class="animate__bounceIn" :loading="true" icon="age-verification" />
        </div>

        <div class="common-dialog-title">{{ $t("receipts.verifyAgeDialogTitle") }}</div>
        <div class="common-dialog-description">{{ $t("receipts.verifyAgeDialogDescription") }}</div>
      </div>

      <div class="common-dialog-footer">
        <div class="common-dialog-button fill">
          <f7-button id="age-verification-confirm-button" @tap="ageVerification.resolver(true)" large round fill>{{$t("receipts.ageVerified")}}</f7-button>
        </div>
        <div class="common-dialog-button">
          <f7-button id="age-verification-cancel-button" @tap="ageVerification.resolver(false)" large round>{{$t("receipts.verifyAgeBack")}}</f7-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from "vue"
  import BigIcon from "../BigIcon"

  export default {
    components: { BigIcon },
    setup() {
      const ageVerification = ref(gui.ageVerificationData)

      watch(gui.ageVerificationData, value => {
        ageVerification.value = value
      })

      return { ageVerification }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .age-verification-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateY(100vh);
    background-color: $main-bg-color;
    z-index: 13499;
    transition: .3s;

    &.fade-out {
      .animate__bounceIn {
        animation-name: none !important;
      }
    }

    &.fade-in {
      visibility: visible;
      transform: translateY(0);
    }

    .common-dialog-icon {
      .big-icon-container {
        .big-icon-inner {
          position: relative;
          .big-icon {
            position: absolute;
            right: 8px;
            bottom: 14px;
            img {
              height: 140px;
            }
          }
        }
      }
    }
  }
</style>
