import { date } from "@/js/filters"
import * as moment from "moment"

export async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function clearSelection() {
  if (document.selection && document.selection.empty ) {
    document.selection.empty()
  } else if (window.getSelection) {
    window.getSelection().removeAllRanges()
  }
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  })
}

export function displayExpired(expired) {
  document.body.innerHTML = `
    <style>
      #app-expired {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    </style>
    
    <div id="app-expired">
      <h1>Version expired.</h1>
      <p>${ expired.localeTime }</p>
    </div>
  `
}

export function getApplicationExpired() {
  const expired = String(process.env.VUE_APP_EXPIRED_AT || "").trim()

  if(expired !== "") {
    const expiredTime = new Date(Number(expired)).getTime()
    if(!isNaN(expiredTime) && Date.now() >= expiredTime) {
      return {
        expiredTime,
        localeTime: date(expiredTime, { dateShort: true })
      }
    }
  }

  return null
}

export function isActiveTimeRange(from, to, forTime = null) {
  if(typeof from === "string" && typeof to === "string") {
    const currentTime = forTime || Date.now()
    const currentDay = moment(new Date(currentTime)).format("YYYY-MM-DD")

    if(/^\d{2}:\d{2}$/.test(from) && /^\d{2}:\d{2}$/.test(to)) {
      let beginCheckTime = new Date(currentDay + " " + from + ":00").getTime()
      let endCheckTime = new Date(currentDay + " " + to + ":00").getTime()

      if(!isNaN(beginCheckTime) && !isNaN(endCheckTime)) {
        if(beginCheckTime > endCheckTime) {
          endCheckTime += 86400 * 1000
        }
        if(currentTime >= beginCheckTime && currentTime <= endCheckTime) {
          return true
        }
      }
    }
  }

  return false
}
