<template>
  <div class="sheet-details-container">
    <div v-for="item of refunds.items" :key="item.id" class="sheet-detail-item">
      <div class="sheet-detail-item-left bold">#{{ item.id }}</div>
      <div class="sheet-detail-item-right">${{ $filters.sum(item.sum) }}</div>
    </div>
    <div class="sheet-detail-item sheet-detail-item-padding-top">
      <div class="sheet-detail-item-left">{{ $t("receipts.previousRefunds") }}</div>
      <div class="sheet-detail-item-right bold">${{ $filters.sum(refunds.total) }}</div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from "vue"

  export default {
    props: {
      refunds: { type: Array, default: () => [] }
    },
    setup(props) {
      const refunds = ref(props.refunds)

      watch(() => props.refunds, value => {
        refunds.value = value
      })

      return { refunds }
    }
  }
</script>