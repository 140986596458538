<template>
  <teleport to="#app">
    <div class="sheet" :class="{ 'fade-in': shown }">
      <div @click="close" class="sheet-backdrop"></div>
      <div class="sheet-container" :class="className">
        <slot />
      </div>
    </div>
  </teleport>
</template>

<script>
  import { ref, watch } from "vue"

  export default {
    emits: ["sheet:closed"],
    props: {
      class: { type: String, default: "" },
      opened: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
      const shown = ref(props.opened)
      const className = ref(props.class)

      watch(() => props.opened, value => {
        shown.value = value
      })

      const close = () => {
        shown.value = false
        emit("sheet:closed", shown.value)
      }

      return { shown, className, close }
    }
  }
</script>