import gui from "../gui"
import state from "../state"
import dicts from "../dicts"

import { navigate } from "./router"

const ITEMS_PER_PAGE = 30
const FAVOURITES_QUERY = () => ({ favourite: true, storeId: state.user.storeId, enabled: true })
const CATEGORY_QUERY = (id, filter = "") => {
  const query = { categoryId: id, storeId: state.user.storeId, enabled: true }
  if(filter.trim() !== "") {
    query["name LIKE"] = `%${ filter }%`
  }

  return query
}

export async function selectGoods(options = {}) {
  // Cache favourites
  if(state.cache.favourites && state.cache.favourites.lastUpdate) {
    if(Date.now() - state.cache.favourites.lastUpdate < 60 * 10 * 1000) {
      state.cache.favourites.goods = state.cache.favourites.goods.slice(0, ITEMS_PER_PAGE)
      gui.favourites = state.favourites = state.cache.favourites
      await navigate("/receipt/add-item")
      return true
    }
  }

  gui.preloader(true)

  state.favourites = gui.favourites = state.cache.favourites = {
    lastUpdate: Date.now(),
    activeTab: options.activeTab || "favourites",
    uploading: false,
    goods: await getFavourites(0),
    total: await dicts.goods.count({ where: FAVOURITES_QUERY() }),
    category: await getCategory({ id: 0 }),
    searching: {
      loading: false,
      filter: "",
      goods: []
    }
  }

  gui.preloader(false)

  await navigate("/receipt/add-item")
}

export async function getFavourites(skip = 0) {
  return await dicts.goods.get({ where: FAVOURITES_QUERY(), limit: ITEMS_PER_PAGE, offset: skip })
}

export async function openCategory({ id, filter = "" }) {
  gui.preloader(true)

  if(id === 0) {
    gui.preloader(false)
    await selectGoods({ activeTab: "categories" })
  } else {
    const category = await getCategory({ id, filter })
    if(category) {
      state.category = gui.category = category
      await navigate("/receipt/category")
    } else {
      gui.toast(gui.translate("receipts.categoryNotFound"))
    }

    gui.preloader(false)
  }
}

export async function searchGoods({ filter }) {
  state.favourites.searching.filter = filter
  state.favourites.searching.loading = true

  gui.favourites = state.favourites

  const goods = await dicts.goods.get({
    where: {
      or: {
        "name LIKE": `%${ filter }%`,
        "barcode": filter
      },
      storeId: state.user.storeId
    },
    order: "name ASC",
    limit: 30
  })

  state.favourites.searching.loading = false
  state.favourites.searching.goods = goods

  gui.favourites = state.favourites
}

export async function filterCategory({ filter }) {
  state.category.searching = true
  state.category.filter = filter
  gui.category = state.category

  const { total, goods } = await getFilteredCategoryGoods({
    id: state.category._id,
    filter,
    children: state.category.children
  })

  gui.category = state.category = {
    ...state.category,
    filter,
    total,
    goods,
    searching: false
  }
}

export async function uploadFavourites() {
  const goodsLength = state.favourites.goods.length

  if(state.favourites.total > goodsLength) {
    state.favourites.uploading = true
    gui.favourites = state.favourites

    state.favourites.goods.push(...await getFavourites(goodsLength))
    state.favourites.uploading = false

    gui.favourites = state.favourites
  }
}

export async function uploadCategory() {
  const goodsLength = state.category.goods.length

  if(state.category.total > goodsLength) {
    state.category.uploading = true
    gui.category = state.category

    const { goods } = await getFilteredCategoryGoods({
      id: state.category._id,
      skip: goodsLength,
      filter: state.category.filter,
      children: state.category.children
    })

    state.category.goods.push(...goods)
    state.category.uploading = false

    gui.category = state.category
  }
}

export async function changeTab(tab) {
  state.favourites.activeTab = tab
  gui.favourites = state.favourites
}

async function getCategory({ id, filter = "" }) {
  const children = await dicts.goods_categories.get({ parentId: id, companyId: state.user.companyId, enabled: true })
  const category = id === 0 ? { _id: 0, name: "All categories" } : await dicts.goods_categories.getOne({
    _id: id,
    companyId: state.user.companyId,
    enabled: true
  })

  if(category) {
    const { total, goods } = await getFilteredCategoryGoods({ id, filter, children })

    return {
      ...category,
      total,
      goods,
      filter,
      children,
      uploading: false,
      searching: false
    }
  }

  return null
}

export async function getFilteredCategoryGoods({ id, filter = "", children, skip = 0 }) {
  const query = CATEGORY_QUERY(id, filter)
  const goods = children.length === 0 ? await dicts.goods.get({
    where: query,
    limit: ITEMS_PER_PAGE,
    offset: skip
  }) : []
  const total = children.length === 0 ? await dicts.goods.count({ where: query }) : 0

  return { total, goods }
}