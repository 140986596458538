import * as moment from "moment"

export function date(date, options = { dateShort: false }) {
  return options.dateShort ? moment(new Date(date)).format("MMM DD, YYYY") : moment(new Date(date)).format("MMMM DD, YYYY h:mmA")
}

export function time(date) {
  return moment(new Date(date)).format("h:mmA")
}

export function sum(sum) {
  sum = +sum
  return Math.round((sum + (sum > 0 ? 0.0000001 : -0.0000001)) * 100) / 100
}

export function currency(sum) {
  return `$${sum}`
}

export function sumFixed(sum) {
  return (+sum).toFixed(2)
}

export function getInitials(name) {
  if(!name) {
    return "X"
  }

  return name.split(" ").map(part => part.slice(0, 1).toUpperCase()).slice(0, 2).join("") || "X"
}

export function displayGroups(groups) {
  if(Array.isArray(groups?.names) && groups?.names?.length > 0) {
    return groups.names.join(", ")
  }

  return "..."
}