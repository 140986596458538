import * as native from './native'
import state from "./state"
import gui from './gui'

import { getWorldnetKey } from "./settings"
import { round2 } from "./libs/util"

native.rpc['terminal'] = async function(method, ...params) {
  console.log(`RPC ${method} ${params.join(',')}`)
  if (method === 'terminal.error') {
    await error.apply(this, params)
  } else if (method === 'terminal.settingsRetrieved') {
    await settingsRetrieved.apply(this, params)
  } else if (method === 'terminal.deviceConnected') {
    await deviceConnected.apply(this, params)
  } else if (method === 'terminal.message') {
    await message.apply(this, params)
  } else if (method === 'terminal.saleResponse') {
    await saleResponse.apply(this, params)
  } else if (method === 'terminal.refundResponse') {
    await refundResponse.apply(this, params)
  } else {
    // await gui.error(`Unknown rpc signal ${method}`)
  }
}

export const hooks = {
  sale: null,
  message: null,
  error: null,
  refund: null,
  init: null,
  initDevice: null,
}

export async function init(terminalId = '4307001') {
  if (state.isInit) {
    return false
  }
  terminalId = '4412001'
  console.log('init', terminalId)
  await native.call('jni.initSdk', terminalId)
}

export async function initDevice() {
  if (state.isInitDevice) {
    return false
  }
  await native.call('jni.initDevice')
}

export async function sdkReady(callback) {
  if(state.isInit && state.isInitDevice) {
    callback()
  } else {
    hooks.init = async () => {
      await initDevice()
    }

    hooks.initDevice = async () => {
      callback()
    }

    if(state.isInit) {
      await initDevice()
    } else {
      await init(await getWorldnetKey())
    }
  }
}

export async function setOfflineMode(isOffline) {
  return new Promise(async resolve => {
    await sdkReady(async (error = false) => {
      if(error) {
        resolve(null)
      } else {
        resolve(await native.call('jni.setOfflineMode', isOffline))
      }
    })
  })
}

export async function saleTerminal(sum, sumTips = 0) {
  await native.call('jni.sale', sum, sumTips)
}

export async function refundTerminal(sum, reason, uniqueRef = null) {
  if(typeof uniqueRef === "string" && uniqueRef != "") {
    await native.call('jni.refund', sum, (reason || "Refund"), uniqueRef)
  } else {
    await unreferencedRefundTerminal(sum, reason)
  }
}

export async function unreferencedRefundTerminal(sum, reason) {
  await native.call('jni.unreferencedRefund', sum, (reason || "unreferencedRefund"))
}

export async function error(message) {
  if (typeof hooks.error === 'function') {
    await hooks.error(message)
  } else {
    await gui.message(`Error hook on error: ${message}`)
  }
}

export async function message(message) {
  if (typeof hooks.message === 'function') {
    await hooks.message(message)
  } else {
    await gui.message(`Error hook on message: ${message}`)
  }
}

export async function settingsRetrieved(message) {
  state.isInit = true
  if (typeof hooks.init === 'function') {
    await hooks.init(message)
  }
}

export async function deviceConnected(message) {
  state.isInitDevice = true
  if (typeof hooks.initDevice === 'function') {
    await hooks.initDevice(message)
  }
}

export async function saleResponse(message, data = {}) {
  if (typeof hooks.sale === 'function') {
    await hooks.sale(message, data)
  } else {
    await gui.message('Error hook on saleResponse')
  }
}

export async function refundResponse(message, data = {}) {
  if (typeof hooks.refund === 'function') {
    await hooks.refund(message, data)
  } else {
    await gui.message('Error hook on refundResponse')
  }
}

export async function cancel() {
  if (typeof hooks.cancel === 'function') {
    await hooks.cancel()
  }

  if(state.paymentData.state !== "error") {
    await native.call('jni.cancel')
  }
}

export async function tryAgain() {
  if(state.paymentData.sum && state.paymentData.state !== "active") {
    state.paymentData.state = "active"
    state.paymentData.error = ""
    state.paymentData.message = gui.translate("terminalInitTransaction")
    state.paymentData.canCancel = false

    gui.payment(state.paymentData)

    if (state.isInit && state.isInitDevice) {
      await state.paymentData.process.handler()
    } else {
      if(!state.isInit) {
        await init(await getWorldnetKey())
      } else {
        await initDevice()
      }
    }
  }
}

export async function refund(sum, uniqueRef, options = {}) {
  return await executeOperation(sum, {
    process: {
      hookName: "refund",
      handler: async () => {
        await refundTerminal(sum, null, uniqueRef)
      }
    },
    title: gui.translate("checkout.refund"),
    type: "refund",
    total: sum,
    tips: 0,
    ...options
  })
}

export async function payment(sum, tips = 0, options = {}) {
  return await executeOperation(sum, {
    process: {
      hookName: "sale",
      handler: async () => {
        await saleTerminal(sum, tips)
      }
    },
    title: gui.translate("checkout.payment"),
    type: "payment",
    total: round2(sum + tips),
    tips,
    ...options
  })
}

export async function executeOperation(sum, options = {}) {
  state.paymentData = {
    ...options, sum,
    error: "",
    state: "active",
    canCancel: false,
    message: gui.translate("terminalInitTransaction")
  }

  gui.payment(state.paymentData)

  return new Promise(async resolve => {
    hooks.cancel = () => {
      gui.paymentClose()
      resolve({ error: state.paymentData.error, cancel: true, payData: null })
    }

    hooks.message = async message => {
      state.paymentData.canCancel = message === "INSERT_OR_SWIPE_OR_TAP_CARD"
      state.paymentData.message = gui.translate(`terminal.${message}`)

      gui.payment(state.paymentData)
    }

    hooks.error = message => {
      state.paymentData.state = "error"
      state.paymentData.error = message
      state.paymentData.canCancel = true

      gui.payment(state.paymentData)
    }

    hooks[options.process.hookName] = (message, payData) => {
      state.paymentData.state = "completed"
      gui.payment(state.paymentData)

      if(options.closeManually !== true) {
        gui.paymentClose()
      }

      resolve({ error: null, cancel: false, payData })
    }

    await setOfflineMode(state.settings.offlinePayments || false)
    await options.process.handler()
  })
}
