<template>
  <f7-page-content
    infinite
    :infinite-distance="50"
    @infinite="loadMore"
    class="tab-content"
  >
    <template v-if="docs?.length > 0">
      <f7-list class="shifts-list-wrap" no-hairlines no-chevron>
        <ul class="shifts-list">
          <template v-for="report in docs" :key="report._id">
            <ListItem :report="report" @click="open(report)"></ListItem>
          </template>
        </ul>
      </f7-list>
    </template>
    <template v-else>
      <div class="category-empty">
        <div class="category-empty-text">
          {{ $t("shifts.shiftsIsMissing") }}
        </div>
      </div>
    </template>
  </f7-page-content>
</template>

<script>
  import ListItem from "./ListItem";
  import { ref, watch } from "vue";

  export default {
    emits: ["report-click"],
    components: { ListItem },
    props: {
      docs: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
      },
    },
    setup() {
      const shifts = ref(gui.shifts);
      const order = ref(shifts.value.order[shifts.value.activeTab]);
      const activeTab = ref(shifts.value.activeTab);
      const allowInfinite = ref(shifts.value.loadMoreEnable[activeTab.value]);

      watch(gui.shifts, (value) => {
        shifts.value = value;
        activeTab.value = value.activeTab;
        allowInfinite.value = value.loadMoreEnable[value.activeTab];
        order.value = value.order[value.activeTab];
      });


      const loadMore = async () => {
        if (!shifts.value.loadMoreEnable[activeTab.value]) return;
        if (shifts.value.searching.loading) return;

        if (
          shifts.value[activeTab.value].length <
          shifts.value.offset[activeTab.value]
        ) {
          await gui.dispatch("docs.disableLoadMore", "shifts");
          return;
        }

        allowInfinite.value = false;
        await gui.dispatch("docs.loadMoreDocs", "shifts");
        allowInfinite.value = true;
      };

      return {
        order,
        loadMore,
        updateOrder: () => {
          gui.dispatch("docs.updateOrder", "shifts", order.value);
        },
        open: (value) => {
          gui.dispatch("shift.viewTheClosedShift", value);
        },
      };
    }
  };
</script>

<style lang="scss">
  @import "@/css/variables";
  .shifts-list-wrap {
    margin: 0;
    .shifts-list {
      background: none;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0);
      padding: $minimal-padding;
    }
  }
</style>