import { computed } from "vue"
import { isCafe, orderOrReceipt } from "../helpers"

export default [
  {
    name: orderOrReceipt("widgets.newReceipt", "widgets.newOrder"),
    icon: "new_receipt",
    key: "new-receipt",
    do: () => gui.dispatch("receipt.create")
  },
  {
    name: orderOrReceipt("widgets.receipts", "widgets.orders"),
    icon: "receipts",
    key: "receipts",
    do: () => gui.dispatch('docs.openDocs', { activeTab: "postponed", page: "receipts" }),
  },
  {
    name: computed(() => gui.shift.value.state === 'active' ? "widgets.closeShift" : "widgets.openShift"),
    icon: computed(() => gui.shift.value.state === 'active' ? "close_shift" : "open_shift"),
    key: "toggle-shift",
    do: () => gui.dispatch("shift.toggle")
  },
  {
    name: "widgets.currentShift",
    icon: "current_shift",
    key: "current-shift",
    do: () => gui.dispatch("shift.openPage"),
  },
  {
    name: "widgets.shiftsList",
    icon: "shifts_list",
    key: "shifts-list",
    do: () => gui.dispatch("docs.openDocs", { activeTab: "shifts", page: "shifts" }),
  },
  {
    name: "widgets.depositing",
    icon: "depositing",
    key: "depositing",
    do: () => gui.dispatch("shift.openCashIn"),
  },
  {
    name: "widgets.withdrawal",
    icon: "withdrawal",
    key: "withdrawal",
    do: () => gui.dispatch("shift.openCashOut"),
  },
  {
    name: "widgets.printXReport",
    icon: "x_report",
    key: "print-x-report",
    do: () => gui.dispatch("shift.printXReport")
  },
  {
    name: "widgets.settings",
    icon: "settings",
    key: "settings",
    do: () => gui.dispatch("settings.openSettings")
  },
  {
    name: "widgets.calculator",
    icon: "calculator",
    key: "calculator",
    do: () => gui.calculator.value = true
  },
  {
    name: "widgets.contactSupport",
    icon: "help",
    key: "support",
    do: () => gui.dispatch("support.openSupportEmail")
  }
]