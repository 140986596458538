<template>
  <div class="new-payment-container payment">
    <div class="new-payment-inner">
      <div class="new-payment-item">
        <div class="new-payment-item-label">{{ $t("checkout.toPay") }}</div>
        <div class="new-payment-item-value">${{ $filters.sum(receipt.sumToPay) }}</div>
      </div>

      <div class="new-payment-item">
        <div class="new-payment-item-label">{{ $t("checkout.changeDue") }}</div>
        <div class="new-payment-item-value green">
          <span v-if="change > 0">${{ $filters.sum(change) }}</span>
        </div>
      </div>

      <div class="new-payment-item cash-field">
        <div class="new-payment-item-label">{{ $t("checkout.cash") }}</div>
        <PriceField id="cash-method-sum" :rewrite-first="true" @input="updateChange" v-model="sumUserCash" ref="sum" input-mode="none" />
      </div>
    </div>
    <div class="new-payment-keyboard">
      <Keyboard @confirm="confirm" :disabled="!isValidSum" ref="keyboard" id="cash-method-keyboard" />
    </div>
  </div>

  <CustomBottomSheet class="payment-info-sheet" ref="sheet" :mount-root="true" :default-close-all="true">
    <div class="payment-info-body">
      <template v-for="detail of paymentsList">
        <div class="checkout-details-item" :class="{ 'bold': detail.key === 'toPay', 'change': detail.key === 'change' }">
          <div class="checkout-details-item-inner">
            <div class="checkout-details-title">{{ $t(detail.title) }}</div>
            <div class="checkout-details-value">{{ detail.value }}</div>
          </div>
        </div>
      </template>
    </div>
  </CustomBottomSheet>
</template>

<script>
  import { ref, watch, computed } from "vue"
  import { getPaymentsList } from "../../js/helpers"
  import { currency } from "../../js/filters"

  import Keyboard from "../Keyboard"
  import PriceField from "../PriceField"
  import CustomBottomSheet from "../CustomBottomSheet"

  export default {
    components: { Keyboard, PriceField, CustomBottomSheet },
    props: {
      receipt: { type: Object, default: () => ({}) }
    },
    setup(props) {
      const receipt = ref(props.receipt)
      const sumUserCash = ref(receipt.value.sumToPay)
      const isOpenedDetails = ref(false)
      const visibleDetails = ref(false)
      const change = ref(0)

      watch(() => props.receipt, value => {
        receipt.value = value
        change.value = receipt.value.sumChange
      })

      const isValidSum = computed(() => {
        return Number(change.value) >= 0
      })

      const isChangeVisible = computed(() => {
        return Number(change.value) > 0
      })

      const isChangeOverSize = computed(() => {
        if(Number(change.value) < 0) {
          return false
        }

        return String(Number(change.value).toFixed(2)).length > 7
      })

      const paymentsList = computed(() => {
        const list = [
          { title: "checkout.cash", key: "cash", value: currency(sumUserCash.value || 0) },
          ...getPaymentsList(receipt.value),
          { title: "checkout.toPay", key: "toPay", value: currency(receipt.value.sumToPay || 0) }
        ]

        return list
      })

      return {
        change,
        receipt,
        sumUserCash,
        isValidSum,
        paymentsList,
        visibleDetails,
        isOpenedDetails,
        isChangeVisible,
        isChangeOverSize,
        updateChange: () => gui.dispatch("receipt.updateChange", sumUserCash.value),
        confirm: () => gui.dispatch("receipt.fixPayCash", sumUserCash.value),
        toggle: () => visibleDetails.value = !visibleDetails.value
      }
    },
    methods: {
      openInfo() {
        this.$refs.sheet.showAll()
      }
    },
    mounted() {
      this.$refs?.keyboard?.setRelation(this.$refs?.sum)
    }
  }
</script>
