<template>
  <f7-sheet
    class="keyboard-sheet"
    @sheet:closed="hideKeyboard"
  >
    <f7-page-content>
      <p class="keyboard-sheet-title">{{ title }}</p>
      <DisplayedValue
        v-model:value="keyboardSheetValue"
        v-model:rewrited="rewrited"
        ref="sum"
        :id="`${id}-display`"
        :errorText="errorText"
        :error="error"
        :integer="integer"
      />
      <Keyboard
        @confirm="onConfirm"
        :disabled="error"
        ref="keyboard"
        :id="`${id}-keyboard`"
      />
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import DisplayedValue from "./DisplayedValue";
import Keyboard from "../Keyboard";
import { ref, watch } from "vue";

export default {
  components: { DisplayedValue, Keyboard },
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    value: { type: [String, Number], required: true },
    min: { type: Number, default: -Infinity },
    max: { type: Number, default: +Infinity },
    integer: { type: Boolean, default: false },
  },
  emits: ['onConfirm', 'update:value'],
  setup(props, { emit }) {
    const shownSheet = ref(false)
    const keyboardSheetValue = ref(props.value);
    const rewrited = ref(false);
    const error = ref(false);
    const errorText = ref("");

    watch(props, (value) => {
      keyboardSheetValue.value = value.value;
    });

    watch(keyboardSheetValue, (value) => {
      const val = Number(value);
       if (val < props.min) {
        error.value = true;
        errorText.value = gui.translate("receipts.valueMinError", {value: props.min});
        return;
      }
      if (val > props.max) {
        error.value = true;
        errorText.value = gui.translate("receipts.valueMaxError", {value: props.max});
        return;
      }
      if (error.value) {
        error.value = false;
      }
    });


    return {
      error,
      errorText,
      rewrited,
      shownSheet,
      keyboardSheetValue,
      onConfirm: () => {
        emit('update:value', Number(keyboardSheetValue.value));
        emit('onConfirm');
        rewrited.value = false;
      },
      hideKeyboard: () => {
        keyboardSheetValue.value = props.value;
        rewrited.value = false;
      },
    }
  },
  mounted() {
    this.$refs?.keyboard?.setRelation(this.$refs?.sum);
  },
}
</script>

<style lang="scss">
@import '@/css/variables';

.keyboard-sheet {
  height: auto;
  background-color: $input-color;
  border-radius: 5px 5px 0 0;

  .keyboard-right {
    .keyboard-button:first-child {
      .button {
        border-radius: $border-radius $border-radius 0 0;
      }
    }
  }
  
  .keyboard-displayed-value {
    background-color: $input-color;
  }

  .keyboard-sheet-title {
    font-size: 18px;
    text-align: center;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 5px;
    color: rgba(#000, .56);
  }
}
</style>