import { createApp, ref } from "vue"
import { createRouter, createMemoryHistory } from "vue-router"
import { createI18n } from "vue-i18n"

console.log(process.env)

import App from "@/App.vue"
import routes from "@/js/routes"
import * as filters from "@/js/filters"
import * as helpers from "@/js/helpers"

import gui from "@/js/gui"
import * as pos from "mplus-pos"
import { en, ru } from "@/locales/index"
import { handleTouches } from "@/js/touches"
import { getApplicationExpired, displayExpired } from "@/js/utils"

// Page components
import Page from "@/components/Page/Page";
import Inner from "@/components/Page/Inner";
import Header from "@/components/Page/Header";
import Footer from "@/components/Page/Footer";
import Subnavbar from "@/components/Page/Subnavbar";
import HeaderMessage from "@/components/Page/HeaderMessage";

// Import f7 components
import f7fab from "@/components/f7/FAB";
import f7tab from "@/components/f7/Tab";
import f7tabs from "@/components/f7/Tabs";
import f7input from "@/components/f7/Input";
import f7panel from "@/components/f7/Panel";
import f7sheet from "@/components/f7/Sheet";
import f7link from "@/components/f7/Link";
import f7button from "@/components/f7/Button";
import f7ripple from "@/components/f7/Ripple";
import f7toggle from "@/components/f7/Toggle"
import f7list from "@/components/f7/List";
import f7listItem from "@/components/f7/ListItem";
import f7pageContent from "@/components/f7/PageContent";
import f7accordionContent from "@/components/f7/AccordionContent"
import f7actions from "@/components/f7/Actions"
import f7actionsGroup from "@/components/f7/ActionsGroup"
import f7actionsButton from "@/components/f7/ActionsButton"

// Import Icons and App Custom Styles
import "@/css/sfpro.css";
import "@/css/icons.css";
import "@/css/mpi.css";
import "@/css/app.sass";
import "@/css/f7.sass";

// Import components styles
import "@vueform/slider/themes/default.css";

window.pos = pos
window.gui = gui
window.f7 = {}

// Init i18n
const i18n = createI18n({
  locale: "en",
  messages: { ru, en, }
});

const router = createRouter({
  history: createMemoryHistory(),
  routes: routes.map(route => ({ path: route.path, component: route.component }))
})

const app = createApp(App)

app.use(router)
app.use(i18n)
app.use({
  install: (app) => {
    gui.i18n = i18n.global
    gui.router = router

    app.config.globalProperties.$gui = gui;
    app.config.globalProperties.$filters = filters;
    app.config.globalProperties.$helpers = helpers;
    app.config.globalProperties.$realTime = ref(new Date());

    setInterval(() => {
      app.config.globalProperties.$realTime.value = new Date();
    }, 1000);
  },
})

// Define components
app
  .component("Page", Page)
  .component("Header", Header)
  .component("Footer", Footer)
  .component("Inner", Inner)
  .component("Subnavbar", Subnavbar)
  .component("HeaderMessage", HeaderMessage)

// Define f7 components
app
  .component("f7-fab", f7fab)
  .component("f7-tab", f7tab)
  .component("f7-tabs", f7tabs)
  .component("f7-input", f7input)
  .component("f7-panel", f7panel)
  .component("f7-sheet", f7sheet)
  .component("f7-link", f7link)
  .component("f7-button", f7button)
  .component("f7-ripple", f7ripple)
  .component("f7-toggle", f7toggle)
  .component("f7-list", f7list)
  .component("f7-list-item", f7listItem)
  .component("f7-page-content", f7pageContent)
  .component("f7-accordion-content", f7accordionContent)
  .component("f7-actions", f7actions)
  .component("f7-actions-group", f7actionsGroup)
  .component("f7-actions-button", f7actionsButton)

const expired = getApplicationExpired()
if(expired) {
  displayExpired(expired)
} else {
  // Mount app
  app.mount("#app")

  // Handle touches
  handleTouches()

  const initPos = async () => {
    await pos.setGui(gui);
    await pos.router.bindRoutes(routes.map(route => ({ path: route.path, history: route.history })));
    await pos.init();
  }

  initPos().then(() => {
    console.log("POS Init")
  })
}
