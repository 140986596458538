import rpc from "../libs/rpc"
import gui from "../gui"
import user from "../user"
import state from "../state"
import dicts from "../dicts"
import * as settings from "../settings"

import { v4 as uuid } from "uuid"
import { refund } from "../terminal"
import { isOnline } from "../online"
import { navigate } from "./router"
import { printBill } from "../libs/bills"
import { openShiftProcess } from "./shift"
import { calculateDocTaxes } from "../handlers/taxes"
import { calculateGoodsTotals, getPaymentMethods, isCompletedReceipt, setError } from "./receipt"
import { complete, calculate, resetReceipt, calculateGood } from "./receipt"

export async function createRefund(receipt = null) {
  if(isOnline) {
    receipt = receipt === null ? state.receiptData : receipt

    if(isCompletedReceipt(receipt)) {
      const refundType = await settings.getStoreRefundType()

      if(refundType === "particular_store" && receipt.storeId != state.user.storeId) {
        await gui.error(gui.translate("receipts.returnsParticularStore"))
        return false
      } else if(refundType === "business_store") {
        const storeBusinessType = await settings.getBusinessType()
        const receiptBusinessType = receipt?.store?.business?.key

        if(storeBusinessType != receiptBusinessType) {
          await gui.error(gui.translate("receipts.returnsBusinessType"))
          return false
        }
      }

      if(!await openShiftProcess()) {
        return false
      }

      if(!await user.verifyPermissionRequest("all.cashier.receiptSpecials.approveRefund", {
        description: gui.translate("receipts.approveRefundDescription"),
        title: gui.translate("receipts.approveRefundTitle")
      })) {
        return false
      }

      gui.preloader(true)
      const { refundReceipt, error } = await createRefundDocument(receipt)
      gui.preloader(false)

      if(error) {
        gui.toast(error.message)
      } else {
        await calculate(refundReceipt)
        await openRefund(refundReceipt)
      }
    }
  } else {
    await gui.message(gui.translate("receipts.refundsAvailableOnline"))
  }
}

export async function openRefund(refundData) {
  refundData.availablePayments = await getPaymentMethods(refundData)

  gui.receiptData = state.receiptData = refundData
  await navigate("/receipt")
}

export async function refundCash() {
  if(isNewRefund(state.receiptData) && state.receiptData.availablePayments.includes("cash")) {
    if(state.shift.availableCash >= state.receiptData.sumTotal) {
      state.receiptData.paymentType = "cash"

      await calculate(state.receiptData, { from: "checkout" })
      await complete(state.receiptData)

      gui.preloader(false)
      await print(state.receiptData)

      await navigate("/checkout/result")
    } else {
      gui.toast(gui.translate("checkout.notEnoughCash"))
    }
  }

  gui.preloader(false)
}

export async function refundCard() {
  if(isNewRefund(state.receiptData) && state.receiptData.availablePayments.includes("card")) {
    const sum = state.receiptData.sumTotal
    const uniqueRef = state.receiptData.payData ? state.receiptData.payData.UniqueRef : ""
    const response = await refund(sum, uniqueRef)

    if(response.cancel === false && response.error === null) {
      state.receiptData.paymentType = "card"
      state.receiptData.refundData = response.refundData

      await setError(state.receiptData, null)
      await calculate(state.receiptData, { from: "checkout" })
      await complete(state.receiptData)

      gui.preloader(false)
      await print(state.receiptData)

      await navigate("/checkout/result")
    }
  }
}

export async function checkoutRefund(refund) {
  if(state.receiptData.sumTotal > 0) {
    if(refund.availablePayments.includes("cash")) {
      await navigate("/checkout")
    } else if(refund.availablePayments.includes("card")) {
      await refundCard()
    } else {
      await gui.message(gui.translate("checkout.refundMethodUnavailable"))
    }
  } else {
    await complete(state.receiptData)
    await print(state.receiptData)
    await navigate("/checkout/result")
  }
}

export async function changeRefundCount({ uuid, count }) {
  if(isNewRefund(state.receiptData)) {
    const good = state.receiptData.goods.find(good => good.receiptGoodUuid === uuid)

    if(good && count <= good.countToRefund) {
      good.count = count

      await calculate(state.receiptData)
      gui.receiptData = state.receiptData
    } else {
      gui.toast(gui.translate("receipts.productNotFound"))
    }
  }
}

export async function unselectAll() {
  if(isNewRefund(state.receiptData)) {
    for(let good of state.receiptData.goods) {
      good.count = 0
    }

    await calculate(state.receiptData)
    gui.receiptData = state.receiptData
  }
}

export async function selectAll() {
  if(isNewRefund(state.receiptData)) {
    for(let good of state.receiptData.goods) {
      good.count = good.countToRefund
    }

    await calculate(state.receiptData)
    gui.receiptData = state.receiptData
  }
}

async function createRefundDocument(receiptForConvert) {
  const receipt = JSON.parse(JSON.stringify(receiptForConvert))
  const previousRefunds = await getPreviousRefundDocuments(receipt)
  const response = { error: null, refundReceipt: null }

  await resetReceipt(receipt)

  receipt._id = null
  receipt._calculating = false
  receipt.originalReceipt = receiptForConvert

  receipt.type = "refund"
  receipt.state = "active"
  receipt.reopen = false
  receipt.createdOn = Date.now()
  receipt.completedOn = null
  receipt.mainDocUuid = receipt.uuid
  receipt.displayedId = null
  receipt.openUserId = state.user._id
  receipt.uuid = uuid()
  receipt.refundData = null

  receipt.certificates = []
  receipt.sumCertificates = 0
  receipt.taxes = {}
  receipt.sumOriginalTotal = receipt.sumTotal
  receipt.sumTaxes = 0
  receipt.sumTotal = 0
  receipt.sumSubTotal = 0
  receipt.sumDiscount = 0
  receipt.sumChange = 0
  receipt.sumUserCash = 0
  receipt.countToRefund = 0

  receipt.goodsCount = 0
  receipt.originalGoodsCount = 0
  receipt.previousRefunds = {
    items: [],
    total: 0
  }

  for(let item of previousRefunds) {
    receipt.previousRefunds.items.push({
      id: item.displayedId,
      sum: item.sumTotal
    })
    receipt.previousRefunds.total += item.sumTotal
  }

  for (let index = 0; index < receipt.goods.length; index++) {
    const good = receipt.goods[index]
    const refundedCount = good.count - previousRefunds.reduce((acc, cur) => {
      return acc + cur.goods[index].count
    }, 0)

    good.originalCount = good.count
    good.countToRefund = refundedCount < 0 ? 0 : refundedCount
    good.count = 0
    good.absDiscountFull = good.absDiscount || 0

    await calculateGood(receipt, good)

    receipt.goodsCount += good.count
    receipt.countToRefund += good.countToRefund
    receipt.originalGoodsCount += good.originalCount
  }

  await calculateRefund(receipt)
  response.refundReceipt = receipt

  return response
}

export async function calculateRefund(refund) {
  await calculateDocTaxes(refund, refund.originalReceipt.taxes)
  await calculateGoodsTotals(refund, { refund: true })

  refund.sumTotal = refund.sumTotal > refund.sumOriginalTotal ? refund.sumOriginalTotal : refund.sumTotal
}

async function getPreviousRefundDocuments(receipt) {
  const request = { type: "refund", "state in": ["completed", "received"], mainDocUuid: receipt.uuid }
  const refunds = await dicts.docs.get(request) || []

  if(isOnline) {
    const remoteRefunds = await rpc("pos.receipt.getRefundDocuments", receipt.uuid)

    if(Array.isArray(remoteRefunds)) {
      refunds.push(...remoteRefunds)
    }
  }

  return [...new Map(refunds.map(doc => [doc.uuid, doc])).values()] || []
}

export async function print(refund = state.receiptData) {
  const count = state.user.store?.printRefundReceipt || 0

  if(count > 0) {
    return await printBill("refund", refund, {
      count,
      queue: [settings.PRINTERS.MASTER],
      processMessage: gui.translate("checkout.printingRefund")
    })
  }

  return true
}

export async function printCopy(refund = state.receiptData) {
  return await printBill("refund", refund, {
    copy: true,
    queue: [settings.PRINTERS.MASTER],
    processMessage: gui.translate("checkout.printingRefundCopy")
  })
}

function isNewRefund(receipt) {
  return receipt.state === "active" && receipt.type === "refund"
}
