<template>
  <div class="total-sheet-component">
    <CustomBottomSheet ref="sheet" @change="sheetHandler">
      <MainBlock :details-button="detailsButton" :hasHiddenContent="hasHiddenContent" :sheetExpanded="!isMaximumOpened" :receipt="receipt"/>
      <template #hidden>
        <div class="sheet-details-list">
          <Details v-if="details.length > 0" :details="details" />
          <template v-if="$helpers.isRefund(receipt) && Array.isArray(receipt?.previousRefunds?.items) && receipt?.previousRefunds?.items?.length > 0">
            <Refunds :refunds="receipt.previousRefunds" />
          </template>
        </div>
      </template>
    </CustomBottomSheet>
  </div>
</template>

<script>
  import CustomBottomSheet from "../CustomBottomSheet";
  import MainBlock from "./TotalsSheet/MainBlock";
  import Refunds from "./TotalsSheet/Refunds";
  import Details from "./TotalsSheet/Details";


  import { ref, watch, computed, onMounted } from "vue"
  import { isNewReceipt, isReceipt, isSelection } from "../../js/helpers"

  export default {
    components: {
      CustomBottomSheet,
      MainBlock,
      Refunds,
      Details
    },
    props: {
      receipt: { type: Object, default: () => ({}) },
      detailsButton: { type: Boolean, default: false }
    },
    setup(props) {
      const sheet = ref(null)
      const hasHiddenContent = ref(false)
      const isMaximumOpened = ref(false)

      const receipt = ref(props.receipt)

      watch(() => props.receipt, value => {
        receipt.value = value
      })

      onMounted(() => {
        gui.totalSheet.value = {
          stepClose() {
            sheet.value.showToStep()
          },
          stepOpen() {
            sheet.value.showAll()
          },
          show() {
            sheet.value.showToStep()
          },
          hide() {
            sheet.value.closeAll()
          }
        }
      })

      return {
        sheet,
        receipt,
        isMaximumOpened,
        hasHiddenContent,
        minimize: () => {
          gui.totalSheet.value.stepClose()
        },
        sheetHandler: value => {
          isMaximumOpened.value = value.state === "shown-all"
          hasHiddenContent.value = value.hasHiddenContent
        },
        details: computed(() => {
          const details = []
          const taxes = Object.values(receipt.value?.taxes || {})

          if(isReceipt(receipt.value) && Array.isArray(receipt.value.certificates) && receipt.value.certificates.length > 0) {
            for(let certificate of receipt.value.certificates) {
              details.push({
                title: certificate.name,
                value: certificate.amount,
                type: "sum",
                valueBold: true
              })
            }
          }

          // Items count
          if(receipt.value.type === "receipt") {
            if(isSelection(receipt.value)) {
              details.push({
                title: gui.translate("receipts.itemsSelected"),
                value: `${receipt.value?.selectedCount} ${gui.translate("receipts.of")} ${receipt.value?.goodsCount}`,
                type: "text",
                valueBold: false
              })
            } else {
              details.push({
                title: gui.translate("receipts.items"),
                value: receipt.value?.goodsCount,
                type: "number",
                valueBold: false
              })

              if(isNewReceipt(receipt.value) && !isSelection(receipt.value)) {
                details.push({
                  title: gui.translate("receipts.total"),
                  value: receipt.value?.sumTotal,
                  type: "sum",
                  valueBold: true
                })
              }
            }

            details.push({
              title: gui.translate("receipts.subTotal"),
              value: receipt.value?.sumSubTotal,
              type: "sum",
              valueBold: true
            })
          } else {
            // Sub total
            if(Number(receipt.value?.sumSubTotal) > 0) {
              details.push({
                title: gui.translate("receipts.subTotal"),
                value: receipt.value?.sumSubTotal,
                type: "sum",
                valueBold: true
              })
            }
          }

          // Tax exemption
          if(Number(receipt.value?.sumTaxesExempt) > 0) {
            details.push({
              title: gui.translate("receipts.sumTaxesExempt"),
              value: receipt.value?.sumTaxesExempt,
              type: "sum",
              valueBold: false
            })
          }

          // Taxes sum
          if(Number(receipt.value?.sumTaxes) > 0) {
            details.push({
              title: gui.translate("receipts.taxes"),
              value: receipt.value?.sumTaxes,
              type: "sum",
              valueBold: true
            })
          }

          // Taxes list
          if(!receipt.value?.taxExemptionEnabled) {
            for(let tax of taxes) {
              details.push({
                title: tax.name,
                value: tax.sum,
                type: "sum",
                valueBold: true,
                paddingLeft: true
              })
            }
          }

          // Discount
          if(Number(receipt.value?.sumDiscount) > 0) {
            details.push({
              title: gui.translate("receipts.discount"),
              value: receipt.value?.sumDiscount,
              type: "sum",
              valueBold: true
            })
          }

          // Tips
          if(Number(receipt.value?.sumTips) > 0) {
            details.push({
              title: gui.translate("receipts.tips"),
              value: receipt.value?.sumTips,
              type: "sum",
              valueBold: true
            })
          }

          return details
        })
      }
    }
  };
</script>

<style lang="scss">
  @import "@/css/variables";
  .sheet-details-list {
    .sheet-details-container:last-child {
      border-bottom: none;
    }
  }

  .sheet-details-container {
    padding: 13px;
    font-size: 16px;
    border-bottom: 1px solid rgba(#000, .12);
    .sheet-detail-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 2px;
      &:last-child {
        margin-bottom: 0;
      }
      &.padding-left {
        padding-left: 15px !important;
        color: $text-secondary;
      }
      .sheet-detail-item-left {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        padding-right: 20px;
      }
      .bold {
        font-weight: bold;
      }
    }
  }
</style>
