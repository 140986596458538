<template>
  <div class="searching-list">
    <div v-for="good of goods" :key="good._id" class="searching-item-container">
      <f7-button @tap="$emit('good-click', good)" class="searching-item ripple-color-black">
        <div class="searching-item-inner">
          <div class="searching-item-left">
            <div class="searching-item-image">
              <AsyncImage :id="good.mainImage" />
            </div>
          </div>
          <div class="searching-item-right">
            <div class="searching-item-title">{{ good.name }}</div>
            <div class="searching-item-price">${{ $filters.sum(good.price) }}</div>
          </div>
        </div>
      </f7-button>
    </div>
  </div>
</template>

<script>
  import { watch, ref } from "vue"
  import AsyncImage from "../AsyncImage"

  export default {
    components: { AsyncImage },
    props: {
      goods: { type: Array, default: () => [] }
    },
    setup(props) {
      const goods = ref(props.goods)

      watch(() => props.goods, value => {
        goods.value = value
      })

      return { goods }
    }
  }
</script>

<style lang="scss">
@import "@/css/variables";

.searching-list {
  padding: $minimal-padding;
  .searching-item-container {
    margin-bottom: $item-margin;
    &:last-child {
      margin-bottom: 0;
    }

    .searching-item {
      background-color: #fff;
      display: flex;
      justify-content: flex-start;
      border-radius: $border-radius;
      height: 76px;
      text-transform: none;
      font-size: 18px;
      line-height: 18px;
      font-weight: normal;
      color: $text-color;
      padding: 0 $minimal-padding;
      box-shadow: $item-shadow;
      padding-right: 17px;
      overflow: hidden;
      .searching-item-inner {
        display: flex;
        align-items: center;
        width: 100%;
        .searching-item-right {
          flex: 1;
          display: flex;
          align-items: flex-start;
          .searching-item-title {
            flex: 1;
          }
          .searching-item-price {
            min-width: 75px;
            max-width: 80px;
            display: flex;
            justify-content: flex-end;
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
      .searching-item-image {
        min-width: 50px;
        height: 50px;
        border-radius: $border-radius;
        overflow: hidden;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 0;
        margin-right: $minimal-padding;
      }
      .searching-item-title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        white-space: normal;
        text-align: left;
        text-overflow: ellipsis;
        font-weight: normal;
        font-size: 16px;
        max-height: 55px;
        overflow: hidden;
      }
    }
  }
}
</style>
