<template>
  <Page schema="transparent" class="shift-page" >
    <Header>
      <div class="left">
        <f7-link if="view-shift-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t('shifts.shiftId', {id: viewedShift.displayedId}) }}</div>
    </Header>

    <Inner>
      <!--  Opened shift view  -->
      <div class="shift-page-content opening-shift">
        <!-- shift short view  -->
        <ShiftShortDetails :clickable="false" :report="viewedShift" />
        <ShiftDetails :shift="viewedShift"/>
      </div>
    </Inner>

    <Footer>
      <div class="footer-buttons">
        <f7-button @tap="printZReport" id="open-shift-button" round fill large>{{ $t("shifts.printZReportCopy") }}</f7-button>
      </div>
    </Footer>
  </Page>
</template>

<script>
  import ShiftDetails from '../../components/ShiftsPage/ShiftDetails'
  import ShiftShortDetails from '../../components/ShiftsPage/ListItem'

  export default {
    components: { ShiftDetails, ShiftShortDetails },
    setup() {

      return {
        viewedShift: gui.viewedShift,
        printZReport: () => gui.dispatch("shift.printZReport", gui.viewedShift.value, true),
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";
  .shift-page {
    .shift-button {
      position: fixed;
      padding-bottom: 30px;
      box-sizing: border-box;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-image: linear-gradient(rgba($main-bg-color-rgb, 0), rgba($main-bg-color-rgb, .7) 30%, rgba($main-bg-color-rgb, 1));
      }
      .button {
        position: relative;
        z-index: 1;
        min-width: $basic-width;
        max-width: $basic-width;
        justify-content: center;
        display: flex;
        align-items: center;
        text-transform: none;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .shift-page-content {
      position: relative;
      min-height: 100%;
      background-color: #fff;
      padding-bottom: 80px;
      box-sizing: border-box;
    }
    .shift-page-content.opening-shift {
      padding: $padding-arrow;
      .shifts-list-item {
        padding: 0;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 20px;
        .item-link {
          padding: 0 !important;
        }
        .shifts-list-item-subtitle {
          font-size: 16px;
        }
        .shifts-list-item-inner {
          padding: 0;
        }
      }
    }
  }
</style>
