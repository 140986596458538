import rpc from "../libs/rpc"
import state from "../state"

import { isOnline } from "../online"
import { updateSellerPIN } from "./helper"
import * as offline from "./offline"

export async function identifySeller(data) {
  if(isOnline) {
    return await rpc("auth.identifySeller", data)
  } else {
    return await offline.identifySeller(data)
  }
}

export async function identifySellerBarcode(data) {
  if(isOnline) {
    return await rpc("auth.identifySellerBarcode", data)
  } else {
    return await offline.identifySellerBarcode(data)
  }
}

export async function loginSeller(data) {
  if(isOnline) {
    return await rpc("auth.loginSeller", { ...data, controlToken: state.device.controlToken })
  } else {
    return await offline.loginSeller(data)
  }
}

export async function resetSellerPIN({ login, pin }) {
  if(isOnline) {
    const response = await rpc("auth.resetSellerPIN", { login, pin, controlToken: state.device.controlToken })

    if(!response.error) {
      await updateSellerPIN(response.authData)
    }

    return response
  } else {
    return {
      error: { code: 0, message: "rpc.resetSellerPINOnlineOnly" }
    }
  }
}

export async function getUserData() {
  if(isOnline) {
    return await rpc("auth.getUserData")
  } else {
    return await offline.getUserData()
  }
}