import rpc from "../libs/rpc"
import gui from "../gui"
import user from "../user"
import dicts from "../dicts"
import { isOnline } from "../online"
import state, { getCachedGood } from "../state"
import handlers from '../handlers'
import * as settings from "../settings"

import { v4 as uuid } from "uuid"
import { push } from "../sync"
import { isCafe } from "../settings"
import { payment } from "../terminal"
import { openDocs } from "./docs"
import { printBill } from "../libs/bills"
import { selectGoods } from "./favourites"
import { saveDocument } from "../doc"
import { splitRunnerCount } from "./split"
import { openShiftProcess, addTaxes } from "./shift"
import { navigate, navigateBack, popRoute } from "./router"
import { open as openTables, setReceiptTable } from "./tables"
import { applyCertificate, cancelCertificates } from "./certificates"
import { isActiveTimeRange, round2, round2floor } from "../libs/util"
import { calculateRefund, checkoutRefund, openRefund } from "./refund"
import { checkIsAllSent, checkIsGoodSent, printRunner } from "./runner"

const PRICE_MIN = 0

export const SELECTION = {
  SPLITTING: 0
}

export async function create(goods = [], options = {}) {
  if(!await openShiftProcess()) {
    return false
  }

  const receiptData = getReceiptSchema(state)

  receiptData.goods = goods
  receiptData.comments = options.comments || []
  receiptData.parentSplitUuid = options.parentSplitUuid || null
  receiptData.availablePayments = await getPaymentMethods(receiptData)

  await handlers.createReceipt(receiptData)

  if(receiptData.goods.length > 0) {
    if(options.table) {
      await setReceiptTable(receiptData, options.table)
    }

    await calculate(receiptData)
    await save(receiptData)
  }

  if(options.notOpenReceipt === true) {
    return receiptData
  }

  if (options.tableData) {
    await setReceiptTable(receiptData, options.tableData)
  }

  gui.receiptData = state.receiptData = receiptData
  if (await settings.isCafe() && !options.tableData) {
    await openTables()
  } else {
    await openReceipt(state.receiptData)
  }

  return receiptData
}

export async function open(receipt) {
  // Close groups
  await closeGroups(receipt)

  if(receipt.type === "receipt") {
    receipt.reopen = true
    receipt.updatedBy = state.user._id
    state.receiptData = gui.receiptData = receipt

    if(isNewReceipt(receipt)) {
      if(!await openShiftProcess()) {
        return false
      }

      if(+receipt.userId !== +state.user._id) {
        if (!await gui.questionUI({
          message: gui.translate(`${await settings.isCafe() ? 'receipts.changeWaiterTitle' : 'receipts.changeCashierTitle'}`),
          description: gui.translate(`${await settings.isCafe() ? 'receipts.changeWaiterQuestion' : 'receipts.changeCashierQuestion'}`),
          confirmButton: gui.translate("yes"),
          cancelButton: gui.translate("no")
        })) {
          return false
        }

        if(!await user.verifyPermissionRequest("all.cashier.receiptSpecials.changeCashier", {
          description: gui.translate(`${await settings.isCafe() ? 'receipts.changeWaiterAccessDescription' : 'receipts.changeCashierAccessDescription'}`),
          title: gui.translate(`${await settings.isCafe() ? 'receipts.changeWaiter' : 'receipts.changeCashier'}`)
        })) {
          return false
        }
      }

      await resetReceipt(receipt)
      await save(receipt)
    }

    await openReceipt(receipt)
  } else if(receipt.type === "refund") {
    await openRefund(receipt)
  }
}

export async function cancelReceipt() {
  if(isNewReceipt(state.receiptData)) {
    if(isOnline) {
      if(await gui.questionUI({
        message: gui.translate(await isCafe() ? "receipts.cancelOrderQuestion" : "receipts.cancelReceiptQuestion"),
        confirmButton: gui.translate("yes"),
        cancelButton: gui.translate("no")
      })) {
        if(!await user.verifyPermissionRequest("all.cashier.receiptSpecials.cancelReceipt", {
          description: gui.translate(await isCafe() ? "receipts.approveCancelOrderDescription" : "receipts.approveCancelReceiptDescription"),
          title: gui.translate(await isCafe() ? "receipts.approveCancelOrderTitle" : "receipts.approveCancelReceiptTitle")
        })) {
          return false
        }

        const isReopen = state.receiptData.reopen

        gui.preloader(true)

        // Remove local receipt
        if(state.receiptData._id) {
          // 1. Cancel certificates
          if(await cancelCertificates(state.receiptData)) {
            // 2. Delete receipt from service
            const response = await rpc("pos.receipt.cancelReceipt", state.receiptData.uuid)
            if(!response || response.error) {
              gui.preloader(false)
              gui.toast("rpc.unhandledError")
              return false
            }

            // 3. Delete receipt from local db
            await dicts.docs.del({ _id: state.receiptData._id })
          } else {
            gui.error(gui.translate("certificates.cancelError"))
          }
        }

        gui.preloader(false)

        if(isReopen) {
          await popRoute()
          await openDocs({
            activeTab: "postponed",
            page: "receipts"
          })
        } else {
          await navigate("/home")
        }
      }
    } else {
      gui.toast(gui.translate("checkNetworkConnection"))
    }
  }
}

export async function createReceiptWithQuery(query) {
  gui.preloader(true)

  const goods = await dicts.goods.get({ ...query, storeId: state.user.storeId, enabled: true })

  if(goods.length > 0) {
    const prepared = await Promise.all(goods.map(good => prepareGood(good)))

    gui.preloader(false)
    await create(prepared)
  } else {
    gui.preloader(false)
    await gui.message(gui.translate("receipts.productNotFound"))
  }

  gui.preloader(false)
}

export async function fixPayCash(sumUserCash) {
  sumUserCash = Number(sumUserCash)
  gui.preloader(true)

  if(isNewReceipt(state.receiptData) && state.receiptData.availablePayments.includes("cash")) {
    if(sumUserCash >= state.receiptData.sumToPay) {
      state.receiptData.paymentType = "cash"
      state.receiptData.sumUserCash = round2(sumUserCash)
      state.receiptData.sumCash = state.receiptData.sumToPay

      await calculate(state.receiptData, { from: "checkout" })
      await complete(state.receiptData)

      gui.preloader(false)
      await print(state.receiptData)

      await navigate("/checkout/result")
    } else {
      gui.toast(gui.translate("checkout.userSumLessThenTotal", {
        sumUserCash,
        sumTotal: state.receiptData.sumToPay
      }))
    }
  }
  gui.preloader(false)
}

export async function fixPayCard(sumCard, tips = 0) {
  sumCard = Number(sumCard)
  gui.preloader(true)

  if(isNewReceipt(state.receiptData)) {
    state.receiptData.paymentType = "card"
    state.receiptData.sumUserCash = 0
    state.receiptData.sumCard = round2(sumCard + tips)

    await calculate(state.receiptData, { from: "checkout" })
    await complete(state.receiptData)

    gui.preloader(false)
    await print(state.receiptData)

    await navigate("/checkout/result")
  }

  gui.preloader(false)
}

export async function executeCardPayment(receipt = state.receiptData) {
  if(isNewReceipt(receipt) && receipt.availablePayments.includes("card")) {
    const sum = receipt.sumToPayReceipt
    const tips = receipt.sumTips

    const response = await payment(sum, tips)

    if(response.cancel === false && response.error === null) {
      receipt.payData = response.payData

      await setError(receipt, null)
      await fixPayCard(sum, tips)
    }
  }
}

export async function checkout() {
  if(await isSelection(state.receiptData)) {
    return false
  }

  if(state.receiptData.goodsCount > 0) {
    if(isNewReceipt(state.receiptData) && !await settings.isCafe()) {
      if(!await isGoodsAvailable(state.receiptData)) {
        await gui.message(gui.translate("receipts.someItemsReceiptNotAvailable"))
        return false
      }

      if(state.receiptData.goods.find(good => good.verifyRequired && good.count > 0)) {
        if(!await gui.ageVerification()) {
          return false
        }
      }
    }

    await calculate(state.receiptData, { from: "checkout" })
    gui.receiptData = state.receiptData

    if(state.receiptData.type === "receipt") {
      await checkoutReceipt(state.receiptData)
    } else if(state.receiptData.type === "refund") {
      await checkoutRefund(state.receiptData)
    }
  }
}

export async function checkoutReceipt(receipt = state.receiptData) {
  if(state.receiptData.sumCertificates > state.receiptData.sumTotal) {
    return false
  }

  if(state.receiptData.sumToPay > 0) {
    if(receipt.availablePayments.includes("cash")) {
      await navigate("/checkout")
    } else if(receipt.availablePayments.includes("card")) {
      await executeCardPayment()
    } else {
      await gui.message(gui.translate("checkout.paymentMethodUnavailable"))
    }
  } else {
    await complete(state.receiptData)
    await print(state.receiptData)
    await navigate("/checkout/result")
  }
}

export async function updateChange(sumUserCash) {
  if(isNewReceipt(state.receiptData)) {
    state.receiptData.sumUserCash = round2(sumUserCash)
    await calculate(state.receiptData, { from: "checkout" })

    gui.receiptData = state.receiptData
  }
}

export async function deleteGood({ uuid }, options = {}) {
  await changeCount({ uuid, count: 0 }, options)
}

export async function changeSelectedCount({ uuid, count }) {
  if(await isSelection(state.receiptData)) {
    const good = state.receiptData.goods.find(good => good.receiptGoodUuid === uuid)

    if(good) {
      good.selected = count
      await calculate(state.receiptData)

      gui.receiptData = state.receiptData
    } else {
      gui.toast(gui.translate("receipts.productNotFound"))
    }
  }
}

export async function changeCount({ uuid, count }, options = {}) {
  if(await isSelection(state.receiptData)) {
    return false
  }

  if(isNewReceipt(state.receiptData)) {
    const good = state.receiptData.goods.find(good => good.receiptGoodUuid === uuid)

    if(good) {
      good.count = count
      good.sent = await checkIsGoodSent(good)

      if(good.count <= 0) {
        good.orderIndex = Math.abs(good.orderIndex) * -1
      }

      if(await goodsCountById(good._id) === 0) {
        state.receiptData.goodsGroupsOpened[good._id] = false
      }

      if(!options.withoutCalculate) {
        if(isOpenedProduct(state.receiptData)) {
          await calculate(state.receiptData)
        } else {
          // Calculate & save
          await save(state.receiptData)
        }
      }

      gui.receiptData = state.receiptData
    } else {
      gui.toast(gui.translate("receipts.productNotFound"))
    }
  }
}

export async function openGood({ uuid }) {
  if(state.receiptData.goods.find(good => good.receiptGoodUuid === uuid)) {
    state.receiptData = gui.receiptData = {
      ...state.receiptData,
      currentGood: uuid
    }

    state.product = gui.product = { shown: true }
  } else {
    gui.toast(gui.translate("receipts.productNotFound"))
  }
}

export async function closeGood() {
  state.product = gui.product = { shown: false }

  await save(state.receiptData)
  gui.receiptData = state.receiptData
}

export async function goodsCountById(id) {
  return state.receiptData.goods.filter(good => good._id === id).reduce((acc, cur) => acc + cur.count, 0)
}

export async function scannedBarcode({ barcode }) {
  const prefix = await settings.getCertificatePrefix()

  if(prefix && typeof barcode === "string" && barcode.startsWith(prefix)) {
    await applyCertificate(barcode)
  } else {
    // for other barcode cases
    await addGood({ barcode })
  }
}

export async function addGood(query) {
  if(await isSelection(state.receiptData)) {
    return false
  }

  if(isNewReceipt(state.receiptData)) {
    try {
      gui.preloader(true)

      const good = query.id ? await getCachedGood(query.id, "id") : await getCachedGood(query.barcode, "barcode")
      if(good) {
        let newGood;
        const savedGood = state.receiptData.goods.find(item => item._id === good._id && !item.promoApplied)
        const savedGoodItems = state.receiptData.goods.filter(item => item._id === good._id)
        const addByIncreaseCount = !await settings.isCafe() && savedGood && savedGoodItems.length === 1;

        if(addByIncreaseCount) {
          savedGood.orderIndex = Math.abs(savedGood.orderIndex)
          savedGood.count++
        } else {
          newGood = await prepareGood(good)
          newGood.sent = await checkIsGoodSent(newGood)
          newGood.orderIndex = state.receiptData.goods.length + 1

          if(await isGoodLocked(newGood)) {
            gui.preloader(false)
            await gui.message(gui.translate("receipts.timeRestrictionError", {
              from: newGood.timeRestrictionsFrom,
              to: newGood.timeRestrictionsTo
            }))
            return false
          }

          state.receiptData.goods.unshift(newGood)
        }

        await save(state.receiptData)
        gui.receiptData = state.receiptData

        if (query.barcode) {
          if (state.product && state.product.shown) {
            await closeGood()
          }

          await navigate('/receipt')
        } else {
          await openGood({ uuid: addByIncreaseCount ? savedGood.receiptGoodUuid : newGood.receiptGoodUuid });
        }
      } else {
        gui.preloader(false)
        await gui.message(gui.translate("receipts.productNotFound"))
      }
    } catch (e) {
      console.log(e)
    } finally {
      gui.preloader(false)
    }
  }
}

export async function openReceipt(receipt = state.receiptData) {
  receipt.availablePayments = await getPaymentMethods(receipt)

  state.receiptData = gui.receiptData = receipt

  if(state.receiptData.goods.length > 0) {
    await navigate("/receipt")
  } else {
    await selectGoods()
  }
}

export async function print(receipt = state.receiptData, copy = false) {
  return await printBill("receipt", receipt, {
    copy,
    queue: [settings.PRINTERS.MASTER],
    processMessage: gui.translate(copy ? "checkout.printingReceiptCopy" : "checkout.printingReceipt")
  })
}

export async function bill(receipt = state.receiptData) {
  if(await settings.isCafe() && isNewReceipt(receipt)) {
    await calculate(receipt, { from: "bill" })
    return await printBill("bill", receipt, {
      queue: [settings.PRINTERS.MASTER],
      processMessage: gui.translate("checkout.printingBill")
    })
  }
}


export async function openDiscount() {
  await navigate("/receipt/discount")
}

export function isOpenedProduct() {
  if(state.product) {
    return state.product.shown
  }

  return false
}

export function isLoyaltyMemberAvailable(receipt) {
  return receipt.loyaltyMember !== null
}

export function isCompletedReceipt(receipt) {
  return ["completed", "received"].includes(receipt.state) && receipt.type === "receipt"
}

export function isNewReceipt(receipt) {
  return receipt.state === "active" && receipt.type === "receipt"
}

export async function calculateGood(doc, good, options = {}) {
  good.usedMinPrice = false
  good.usedFixPrice = false

  let price = Number(good.price)

  if(good.priceFixed) {
    good.usedFixPrice = true
    price = good.priceFixed
  } else {
    if(good.priceAction !== null && good.priceAction !== undefined) {
      price = good.priceAction
    }
    if(good.pricePercent) {
      price = round2(price * (1 - good.pricePercent / 100.0))
    }
  }

  good.priceMin = good.priceMin || PRICE_MIN
  if(price < good.priceMin) {
    good.usedMinPrice = true
    price = good.priceMin
  }

  good.priceTotal = price
  good.pack = good.pack || 1
  const count = await isSelection(doc) ? good.selected : good.count

  if(options.refund === true && good.absDiscountFull > 0.001) {
    good.absDiscount = round2floor(good.absDiscountFull / good.originalCount * count)
    good.absDiscount = good.absDiscount > 0.001 ? good.absDiscount : 0
  } else {
    good.absDiscount = good.absDiscount || 0
  }

  good.sum = getSumSchema()
  good.sum.subTotal = round2(good.price * count * good.pack)
  good.sum.taxes = good.sumTaxes || 0
  good.sum.discount = round2(good.sum.subTotal - (good.priceTotal) * count * good.pack + good.absDiscount)
  good.sum.total = round2(good.sum.subTotal + good.sum.taxes - good.sum.discount)

  good.sum.totalWithoutTaxes = round2(good.sum.subTotal - good.sum.discount)
}

export async function calculate(receiptData, options = {}) {
  if(receiptData._calculating === true) {
    return false
  }

  receiptData._calculating = true
  if(receiptData.type === "receipt") {
    // calculate for new receipt
    await handlers.calc(receiptData, options)
    await calculateGoodsTotals(receiptData)

    receiptData.sumCertificates = receiptData.certificates.reduce((acc, cur) => round2(acc + cur.amount), 0)
    receiptData.sumUserCash = receiptData.sumUserCash || 0
    receiptData.sumCard = receiptData.sumCard || 0
    receiptData.sumCash = receiptData.sumCash || 0

    receiptData.sumTotalWithTips = round2(receiptData.sumTotal + receiptData.sumTips)
    receiptData.sumPayments = round2(receiptData.sumCard + receiptData.sumUserCash)

    receiptData.sumToPayReceipt = round2(receiptData.sumTotal - receiptData.sumCertificates)
    receiptData.sumToPay = round2(receiptData.sumToPayReceipt + receiptData.sumTips)
    receiptData.sumChange = round2(receiptData.sumPayments - receiptData.sumToPay)

    receiptData.isAllSent = await checkIsAllSent(receiptData)
  } else if(receiptData.type === "refund") {
    // calculate for refund
    await calculateRefund(receiptData)
  }

  receiptData.goods = sortGoods(receiptData.goods)
  receiptData.goodsGroups = await groupGoods(receiptData)
  receiptData._calculating = false
}

export async function groupGoods(receipt) {
  const goods = receipt.goods
  const groupsIds = [...new Set(goods.map(good => String(good._id))).values()]
  const groups = groupsIds.map(id => {
    let count = 0
    let selected = 0
    let countToRefund = 0

    let sumSubTotal = 0
    let sumTotal = 0
    let orderIndex = null
    let copyIndex = null
    let verifyRequired = false
    let sent = true
    let sum = getSumSchema()

    const items = goods.filter(good => String(good._id) === id)
    const good = items[0]

    const name = good.name
    const mainImage = good.mainImage
    const timeRestrictions = good.timeRestrictions
    const timeRestrictionsFrom = good.timeRestrictionsFrom
    const timeRestrictionsTo = good.timeRestrictionsTo

    for(let good of items) {
      count += good.count
      selected += good.selected
      countToRefund += good.countToRefund

      sumTotal = round2(sumTotal + good.sum.total)
      sumSubTotal = round2(sumSubTotal + good.sum.subTotal)
      orderIndex = orderIndex === null || orderIndex < good.orderIndex ? good.orderIndex : orderIndex
      copyIndex = copyIndex === null || copyIndex < good.copyIndex ? good.copyIndex : copyIndex
      verifyRequired = good.verifyRequired
      if(!good.sent) {
        sent = false
      }

      sum.totalWithoutTaxes = round2(sum.totalWithoutTaxes + good.sum.totalWithoutTaxes)
    }

    sum.total = sumTotal
    sum.subTotal = sumSubTotal

    return {
      _id: id,
      sum,
      sent,
      name,
      count,
      selected,
      countToRefund,
      sumSubTotal,
      sumTotal,
      mainImage,
      orderIndex,
      copyIndex,
      verifyRequired,
      goods: items,
      timeRestrictions,
      timeRestrictionsFrom,
      timeRestrictionsTo,
      opened: receipt.goodsGroupsOpened[id]
    }
  })

  return sortGoods(groups)
}

export async function closeGroups(receipt) {
  receipt.goodsGroupsOpened = {}
  if(Array.isArray(receipt.goodsGroups)) {
    for(let group of receipt.goodsGroups) {
      group.opened = false
    }
  }
}

export async function deleteGroup(id) {
  id = String(id)
  for await (let good of state.receiptData.goods) {
    if(String(good._id) === id) {
      await deleteGood({ uuid: good.receiptGoodUuid }, { withoutCalculate: true })
    }
  }

  await save(state.receiptData)
  gui.receiptData = state.receiptData
}

export async function toggleGroup(id) {
  state.receiptData.goodsGroupsOpened = {}

  for(let group of state.receiptData.goodsGroups) {
    if(id === group._id) {
      group.opened = !group.opened
      if(group.opened) {
        state.receiptData.goodsGroupsOpened[id] = true
      }
    } else {
      group.opened = false
    }
  }

  gui.receiptData = state.receiptData
}

export function sortGoods(goods) {
  return goods.sort((a, b) => {
    if(a.orderIndex > b.orderIndex) {
      return -1
    } else if(a.orderIndex == b.orderIndex) {
      if(a.copyIndex < b.copyIndex) {
        return -1
      } else {
        return 1
      }
    }

    return 1
  })
}

export async function calculateGoodsTotals(doc, options = {}) {
  doc.sum = getSumSchema()
  doc.goodsCount = 0
  doc.selectedCount = 0
  doc.absDiscount = 0
  for (let good of doc.goods) {
    await calculateGood(doc, good, options)

    doc.sum.subTotal = round2(doc.sum.subTotal + good.sum.subTotal)
    doc.sum.total = round2(doc.sum.total + good.sum.total)
    doc.sum.discount = round2(doc.sum.discount + good.sum.discount)
    doc.sum.taxes = round2(doc.sum.taxes + good.sum.taxes)

    doc.selectedCount += good.selected
    doc.goodsCount += good.count
    doc.absDiscount = round2(doc.absDiscount + (good.absDiscount || 0))
  }

  if (doc.discountAmount) {
    doc.sum.discount = doc.discountAmount
  }

  doc.sumTaxes = doc.sum.taxes
  doc.sumTotal = doc.sum.total
  doc.sumSubTotal = doc.sum.subTotal
  doc.sumDiscount = doc.sum.discount
  doc.sumTips = round2(doc.tipsPercent ? doc.sumTotal * (doc.tipsPercent / 100) : (doc.tipsAmount ? doc.tipsAmount : 0))
}

export async function toggleTaxExemption(receipt = state.receiptData) {
  receipt.taxExemptionEnabled = !receipt.taxExemptionEnabled

  await save(receipt)

  gui.receiptData = receipt
  gui.toast(gui.translate(receipt.taxExemptionEnabled ? "receipts.taxExemptionEnabled" : "receipts.taxExemptionDisabled"))
}

export async function save(receiptData, options = {}) {
  if(await isSelection(receiptData)) {
    return false
  }

  await calculate(receiptData, options)
  await saveDocument(receiptData)

  if(state.receipts) {
    state.receipts.updated = false
    gui.receipts = state.receipts
  }

  if(state.tables) {
    state.tables.updated = false
    gui.tables = state.tables
  }
}

export async function complete(receipt) {
  setError(receipt, null)

  receipt.completedOn = Date.now()
  receipt.state = "completed"

  // Clear deleted goods
  if(receipt.type === "receipt") {
    receipt.goods = receipt.goods.filter(good => good.count > 0)
  }

  // Save taxes and complete receipt
  await save(receipt, { from: "checkout" })
  await addTaxes(receipt)

  receipt.nextRelatedReceipt = await dicts.docs.getOne(await getRelatedReceiptQuery(receipt))
  gui.receiptData = receipt

  push().then(() => {
    console.log("Completed receipt received")
  })
}

export async function createGoodSplitCopy(receipt, good, mainGoodCount) {
  const newGoodCount = good.count - mainGoodCount
  const newGood = JSON.parse(JSON.stringify(good))

  await splitRunnerCount(good, newGood, newGoodCount)
  // for newGood is no needed to check sent status, because it equal good status before split
  good.sent = await checkIsGoodSent(good)

  good.count = mainGoodCount

  newGood.count = newGoodCount
  newGood.receiptGoodUuid = uuid()
  newGood.copyIndex = receipt.goods.filter(item => item._id == good._id).length + 1

  receipt.goods.unshift(newGood)

  return newGood
}

export async function getRelatedReceiptQuery(receipt) {
  const parentSplitUuid = receipt.parentSplitUuid ? receipt.parentSplitUuid : receipt.uuid

  return {
    type: "receipt",
    state: "active",
    or: { parentSplitUuid, uuid: parentSplitUuid }
  }
}

export async function back() {
  if(await isSelection(state.receiptData)) {
    await endSelection(state.receiptData)
  } else if (await settings.isCafe() && isNewReceipt(state.receiptData) && !state.receiptData.isAllSent && state.receiptData.goodsCount > 0) {
    if(await gui.questionUI({
      message: gui.translate("receipts.notificationNotSentItems"),
      cancelButton: gui.translate("receipts.postpone"),
      confirmButton: gui.translate("receipts.send")
    })) {
      await printRunner(state.receiptData)
    } else {
      await gui.clearHistory();
      await openDocs({ activeTab: "postponed", page: "receipts" })
    }
  } else {
    await navigateBack()
  }
}

export async function startSelection(receipt = state.receiptData, type = null) {
  receipt._selection = true
  receipt._selectionType = type

  if(type === SELECTION.SPLITTING) {
    receipt._splitting = true
  }

  for(let good of receipt.goods) {
    good.selected = 0
  }

  await calculate(receipt)
  gui.receiptData = receipt
}

export async function endSelection(receipt = state.receiptData) {
  receipt._selection = false
  receipt._splitting = false
  receipt._selectionType = false

  for(let good of receipt.goods) {
    good.selected = 0
  }

  await calculate(receipt)
  gui.receiptData = receipt
}

export async function isSelection(receipt = state.receiptData) {
  return receipt._selection === true
}

export async function isSplitting(receipt = state.receiptData) {
  return await isSelection(receipt) && receipt._selectionType === SELECTION.SPLITTING
}

export async function resetReceipt(receipt) {
  setError(receipt, null)

  receipt.userName = state.user.fullName
  receipt.userId = state.user._id

  receipt.storeId = state.user.storeId
  receipt.companyId = state.user.companyId
  receipt.store = state.user.store
  receipt.company = state.user.company

  receipt.deviceId = state.device._id
  receipt.shiftId = state.shift._id
  receipt.shiftUuid = state.shift.uuid

  await calculate(receipt)
  await endSelection(receipt)
}

export async function prepareGood(good) {
  return {
    ...good,
    copyIndex: 0,
    orderIndex: 0,
    count: 1,
    selected: 0,
    receiptGoodUuid: uuid(),
    comments: "",
    promoDetails: [],
    selectedModifiers: [],
    countRunner: {},
    countPrint: {},
    sent: false,
    runner: Array.isArray(good.runner) ? good.runner : []
  }
}

export async function isGoodsAvailable(receipt) {
  for(let good of receipt.goods) {
    if(await isGoodLocked(good)) {
      return false
    }
  }

  return true
}

export async function isGoodLocked(good) {
  if(good.timeRestrictions && good.count > 0) {
    if(!await isActiveTimeRange(good.timeRestrictionsFrom, good.timeRestrictionsTo)) {
      return true
    }
  }

  return false
}

/*
* error = { string message, int code }
* */
export function setError(receipt, error) {
  receipt.resultError = error
}

export function getSumSchema() {
  return {
    total: 0,
    taxes: 0,
    discount: 0,
    tips: 0,
    subTotal: 0,
    totalWithoutTaxes: 0
  }
}

export async function getPaymentMethods(receipt) {
  const paymentType = receipt.paymentType
  let methods = []

  // receipt
  const isCashMethodAvailable = await settings.isCashMethodAvailable()
  const isCardMethodAvailable = await settings.isCardMethodAvailable()

  // refund
  const isCashRefundAvailable = await settings.isCashRefundAvailable()
  const isCardRefundAvailable = await settings.isCardRefundAvailable()
  const isAvailableCardToCash = await settings.isAvailableCardToCash()
  const isAvailableCashToCard = await settings.isAvailableCashToCard()
  const isInsecureRefund = await settings.isInsecureRefund()

  if(receipt.type === "receipt") {
    if(isCashMethodAvailable) {
      methods.push("cash")
    }

    if(isCardMethodAvailable) {
      methods.push("card")
    }
  } else if(receipt.type === "refund") {
    if(isCashRefundAvailable && (paymentType === "cash" || isAvailableCardToCash)) {
      methods.push("cash")
    }

    if(isCardRefundAvailable && (paymentType === "card" || (isAvailableCashToCard && isInsecureRefund))) {
      methods.push("card")
    }
  }

  return methods
}

export function getReceiptSchema(state) {
  return {
    sum: getSumSchema(),
    type: "receipt", // refund
    state: "active", // completed, received
    paymentType: "cash", // card
    certificates: [],
    announcements: [],
    selectedCount: 0,
    goodsCount: 0,
    currentGood: 0,
    goodsGroups: [],
    goodsGroupsOpened: {},
    goods: [],
    reopen: false,
    resultError: null,
    sumCertificates: 0,
    sumToPay: 0,
    sumToPayReceipt: 0,
    sumTaxes: 0,
    sumCard: 0,
    sumCash: 0,
    sumTaxesExempt: 0,
    sumTotalWithTips: 0,
    sumTotal: 0,
    sumSubTotal: 0,
    sumDiscount: 0,
    sumTips: 0,
    sumChange: 0,
    sumUserCash: 0,
    userName: state.user.fullName,
    userId: state.user._id,
    store: state.user.store,
    company: state.user.company,
    storeId: state.user.storeId,
    companyId: state.user.companyId,
    openUserId: state.user._id,
    deviceId: state.device._id,
    shiftId: state.shift._id,
    shiftUuid: state.shift.uuid,
    mainDocUuid: null,
    displayedId: null,
    isAllSent: false,
    comments: [],
    runnerErrors: [],
    parentSplitUuid: null,
    uuid: uuid(),
    payData: null,
    completedOn: null,
    createdOn: Date.now(),
    loyaltyMember: null,
    hall: null,
    tableName: null,
  }
}
