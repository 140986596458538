<template>
  <div class="vue-app">
    <PrintingDialog />
    <GetSumUIDialog />
    <QuestionUIDialog />
    <AgeVerificationDialog />
    <PeriodicAgeVerificationDialog />
    <ProductDialog />
    <ModifiersDialog />
    <CalculatorDialog />
    <LoyaltyDialog />

    <LoginScreen />
    <PaymentDialog />
    <PairingDialog />
    <SyncDialog />

    <Idle />

    <TotalSheet :receipt="receiptData" />

    <!--  Router view  -->
    <div class="page-router-view">
      <drawer-layout ref="drawer">
        <template #drawer>
          <LeftPanel />
        </template>
        <template #content>
          <router-view v-slot="{ Component }">
            <transition :name="animationName">
              <component :is="Component" />
            </transition>
          </router-view>
        </template>
      </drawer-layout>
    </div>

    <Toast />
    <PreloaderDialog />

    <ScannerFlying @scanned="onScanned" />
  </div>
</template>

<script>
  import { computed } from "vue"

  import DrawerLayout from "./components/DrawerLayout"
  import LeftPanel from "./components/LeftPanel";
  import ScannerFlying from "./components/ScannerFlying"

  import LoginScreen from "./components/LoginScreen"
  import PaymentDialog from "./components/Dialogs/PaymentDialog"
  import PairingDialog from "./components/Dialogs/PairingDialog"
  import SyncDialog from "./components/Dialogs/SyncDialog"

  import Idle from "./components/Idle"
  import PrintingDialog from "./components/Dialogs/PrintingDialog"
  import GetSumUIDialog from "./components/Dialogs/GetSumUIDialog"
  import QuestionUIDialog from "./components/Dialogs/QuestionUIDialog"
  import AgeVerificationDialog from "./components/Dialogs/AgeVerificationDialog"
  import PeriodicAgeVerificationDialog from "./components/Dialogs/PeriodicAgeVerificationDialog"
  import ProductDialog from "./components/Dialogs/ProductDialog"
  import ModifiersDialog from "./components/Dialogs/ModifiersDialog"
  import CalculatorDialog from "./components/Dialogs/CalculatorDialog"
  import LoyaltyDialog from "./components/Dialogs/LoyaltyDialog"

  import TotalSheet from "./components/Receipt/TotalSheet"
  import PreloaderDialog from "@/components/f7/PreloaderDialog"
  import Toast from "@/components/f7/Toast"

  export default {
    components: { DrawerLayout, TotalSheet, LeftPanel, ScannerFlying, LoginScreen, PaymentDialog, PairingDialog, SyncDialog, Idle, PrintingDialog, GetSumUIDialog, QuestionUIDialog, AgeVerificationDialog, PeriodicAgeVerificationDialog, ProductDialog, ModifiersDialog, CalculatorDialog, LoyaltyDialog, PreloaderDialog, Toast },
    setup() {
      const receiptData = gui.receiptData

      return {
        receiptData,
        animationName: computed(() => {
          return gui.settingsData.value.disableAnimation ? null : "slide"
        }),
        onScanned: data => {
          if(typeof gui.onLoginScanned.value === "function") {
            gui.onLoginScanned.value(data)
          } else if(typeof gui.onScanned.value === "function") {
            gui.onScanned.value(data)
          }
        }
      };
    },
  };
</script>
