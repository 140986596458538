<template>
  <Page @page:beforein="onPageBeforeIn">
    <div class="welcome-page white"></div>
  </Page>
</template>

<script>
  export default {
    methods: {
      onPageBeforeIn() {
        gui?.disableSwipePanel()
      }
    }
  }
</script>

<style lang="sass">
  @import "../css/pages/welcome"
</style>
