import dicts from "./dicts"
import { createCacheHandler } from "./libs/cache"

const state = defaultState()

export function clearState() {
  for(let [key, value] of Object.entries(defaultState())) {
    state[key] = value
  }
}

export function defaultState() {
  return {
    cache: {},
    taxes: {},
    promotions: []
  }
}

export async function initState() {
  await loadPromotions()
  await loadTaxes()
}

export async function loadPromotions() {
  if(state.user && state.user.storeId) {
    const currentTime = Date.now()

    state.promotions = await dicts.promotions.get({
      "storeId": state.user.storeId,
      "enabled": true,
      "startDate <": currentTime,
      "endDate >": currentTime
    })
  }
}

export async function loadTaxes() {
  const taxes = await dicts.taxes.get({})
  const holidays = await dicts.taxes_holidays.get({})

  state.taxes = Object.fromEntries(new Map((taxes || []).map(tax => [String(tax._id), tax])))
  for(let holiday of holidays) {
    const tax = state.taxes[String(holiday.taxId)]
    if(tax) {
      tax.holiday = {
        start: new Date(holiday.startDate),
        end: new Date(holiday.endDate)
      }
    }
  }
}

// Every 10 minutes
export const getCachedGood = createCacheHandler(60 * 10 * 1000, async (key, type) => {
  const query = type === "id" ? { _id: key } : { barcode: key }

  query.enabled = true
  query.storeId = state.user.storeId

  return await dicts.goods.getOne(query)
})

export default state
