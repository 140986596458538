import axios from 'axios'
import state from "../state"
import user from "../user"

import { error } from "./response.js"
import { isOnline, setOnline } from "../online"

const instance = axios.create({})

instance.interceptors.request.use(function (config) {
  const token = state.user && state.user.token ? state.user.token : localStorage.getItem("JWT_TOKEN")

  if(token) {
    config.headers["JWT-TOKEN"] = token
  }

  return config
})

async function rpc(name) {
  try {
    let apiUrl = null

    if(process.env.VUE_APP_API_URL) {
      apiUrl = process.env.VUE_APP_API_URL
    } else {
      const port = window.location.protocol.indexOf('https') === 0 ? '' : ':3000'
      apiUrl = process.env.NODE_ENV === 'production' ? window.location.origin : `${window.location.protocol}//${window.location.hostname}${port}`
    }

    const res = await instance.post(`${apiUrl}/api/v1/${name}`, Array.from(arguments).slice(1))
    const data = res.data

    setOnline(true)

    if(data?.error?.code === 401 && user.isAuthorized() && isOnline) {
      await user.logout()
    }

    return data
  } catch (e) {
    console.log('Error axios', e)
    setOnline(false)
    return error(`521`, e.message)
  }
}

export default rpc
