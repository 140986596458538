<template>
  <div class="bottom-sheet-component" :class="{ 'max-height': isMaxHeight }">
    <div v-tap="backdropClick" class="bottom-sheet-backdrop" :style="backdropStyles"></div>
    <div @touchstart="dragStart" @touchend="dragStop" @touchleave="dragStop" ref="container" class="bottom-sheet-container" :style="styles">
      <div ref="visible" class="bottom-sheet-visible">
        <slot />
      </div>
      <div ref="hidden" class="bottom-sheet-hidden" :style="{ 'max-height': `${height.maxHiddenHeight}px` }">
        <slot name="hidden"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import { tap } from "@/js/touches"

  export default {
    directives: { tap },
    props: {
      mountRoot:  { type: Boolean, default: false },
      defaultCloseAll: { type: Boolean, default: false }
    },
    data() {
      return {
        backdropStyles: {},
        isMaxHeight: false,
        animate: null,
        frame: null,
        styles: {},
        height: {
          page: 0,
          container: 0,
          visible: 0,
          hidden: 0,
          maxHiddenHeight: 0,
          pixels: 0
        },
        condition: {
          visible: false,
          hidden: false
        },
        drag: {
          draggable: false,
          moveY: 0,
          startY: 0,
          startHeight: 0,
          realStartY: 0,
          stopList: [".item-picker-container"]
        }
      }
    },
    methods: {
      backdropClick() {
        if(this.defaultCloseAll) {
          this.closeAll(300)
        } else {
          this.showToStep(300)
        }
      },
      showToStep(animate = 300) {
        this.condition = { visible: true, hidden: false }
        this.showPixels(this.height.visible, animate)
        this.emit()
      },
      showAll(animate = 300) {
        this.condition = { visible: true, hidden: true }
        this.showPixels(this.height.container, animate)
        this.emit()
      },
      closeAll(animate = 300) {
        this.condition = { visible: false, hidden: false }
        this.showPixels(0, animate)
        this.emit()
      },
      emit() {
        let state = "closed"

        if(this.condition.visible && this.condition.hidden) {
          state = "shown-all"
        } else if(this.condition.visible) {
          state = "step"
        }

        this.$emit("change:state", {
          state,
          hasHiddenContent: this.height.hidden >= 10
        })
      },
      dragMove(event) {
        if(this.drag.draggable) {
          const { y } = this.getEvent(event)
          this.drag.moveY = y

          const pixels = (this.height.page - this.drag.moveY + this.drag.startHeight - this.drag.startY)
          this.showPixels(pixels)
        }
      },
      dragStart(event) {
        if(this.isMaxHeight) {
          return false
        }

        for(let stopItem of this.drag.stopList) {
          if(event.target.closest(stopItem)) {
            this.drag.draggable = false
            return false
          }
        }

        this.drag.draggable = true
        const { targetY, y } = this.getEvent(event)

        this.drag.startHeight = this.height.pixels
        this.drag.realStartY = y
        this.drag.moveY = y
        this.drag.startY = this.height.pixels - targetY
      },
      dragStop() {
        const endPixelsDifference = this.drag.realStartY - this.drag.moveY
        const minPixels = 50

        if(endPixelsDifference > minPixels || (this.condition.hidden && Math.abs(endPixelsDifference) < minPixels)) {
          this.showAll(300)
        } else {
          if(this.defaultCloseAll) {
            this.closeAll(300)
          } else {
            if(this.height.pixels > 0.001) {
              this.showToStep(300)
            }
          }
        }

        this.drag.draggable = false
      },
      getEvent(event) {
        const eventResponse = { y: null, targetY: null }

        if(event.touches && event.touches.length > 0) {
          eventResponse.y = event.touches[0].clientY
          eventResponse.targetY = event.touches[0].clientY - (this.height.page - this.height.pixels)
        }

        return eventResponse
      },
      updateHeight() {
        if(!this.$refs.container) {
          return false
        }

        const startHiddenHeight = this.height.hidden

        if(this.height.container != this.$refs.container.offsetHeight) {
          if(this.animate) {
            clearTimeout(this.animate)
            this.animate = null
            this.styles.transition = "none"
          }
        }

        const pageHeight = window.innerHeight
        const visibleHeight = this.$refs.visible.offsetHeight

        this.height = {
          page: pageHeight,
          container: this.$refs.container.offsetHeight,
          visible: visibleHeight,
          hidden: this.$refs.hidden.offsetHeight,
          pixels: this.height.pixels || 0,
          maxHiddenHeight: pageHeight - visibleHeight
        }

        if(startHiddenHeight != this.height.hidden) {
          this.emit()

          if(this.condition.visible && !this.condition.hidden) {
            this.showToStep(0)
          }
        }
      },
      updateStyles() {
        // hide container
        // this.styles.bottom = `-${this.height.container}px`

        if(!this.drag.draggable && this.animate === null) {
          if(!this.condition.visible && !this.condition.hidden) {
            this.showPixels(0, 0)
          } else if(this.condition.visible && this.condition.hidden) {
            this.showPixels(this.height.container, 0)
          } else if(this.condition.visible) {
            this.showPixels(this.height.visible, 0)
          }
        }
      },
      render() {
        this.updateHeight()
        this.updateStyles()
      },
      showPixels(pixels, animate = 0) {
        pixels = pixels > this.height.container ? this.height.container : pixels
        pixels = pixels < 0 ? 0 : pixels

        if(pixels >= this.height.page) {
          pixels = this.height.page
          this.isMaxHeight = true
        } else {
          this.isMaxHeight = false
        }

        this.height.pixels = pixels

        // this.styles.transform = `translateY(-${pixels}px)`
        this.styles.bottom = `${pixels - this.height.container}px`
        this.styles.opacity = "1"
        this.styles.visibility = "visible"
        this.styles.transition = animate ? `${animate}ms` : "none"

        if(pixels === 0) {
          this.styles.visibility = "hidden"
          this.styles.opacity = "0"
        }

        if(this.animate) {
          clearTimeout(this.animate)
        }

        this.animate = setTimeout(() => {
          this.animate = null
          this.styles.transition = "none"
        }, animate)

        if(pixels <= this.height.visible) {
          this.backdropStyles = {}
        } else {
          let percent = (pixels * 100 / this.height.container) / 100

          percent = percent > 1 ? 1 : percent
          percent = percent < 0 ? 0 : percent

          this.backdropStyles = {
            opacity: percent,
            visibility: "visible"
          }
        }
      }
    },
    unmounted() {
      document.body.removeEventListener("touchmove", this.dragMove, false)

      if(this.mountRoot && this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
    },
    mounted() {
      if(this.mountRoot) {
        this.$nextTick(() => this.$root.$el.append(this.$el))
      }

      this.render()

      const container = this.$refs.container
      if(container) {
        // Observe height changes
        const resizeObserver = new ResizeObserver(() => this.render())
        resizeObserver.observe(container)
      }

      document.body.addEventListener("touchmove", this.dragMove, false)
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";
  .bottom-sheet-backdrop {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10998;
    background-color: rgba(#000, 0.4);
    visibility: hidden;
    opacity: 0;
    transition: .3s;
  }

  .bottom-sheet-component.max-height .bottom-sheet-container {
    border-radius: 0;
  }

  .bottom-sheet-hidden {
    overflow: auto;
  }

  .bottom-sheet-container {
    background-color: $light-bg-color;
    border-radius: $border-radius $border-radius 0 0;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10999;
    box-shadow: $footer-shadow;
    user-drag: none;
    transition: .3s;
    will-change: border-radius;
  }
</style>
