<template>
  <Page
      :hide-header-on-scroll="true"
      :hide-footer-on-scroll="true"
      :hide-fab-on-scroll="true"
      @page:scrollup="pageScrollUp"
      @page:scrolldown="pageScrollDown"
      @page:scrollend="pageScrollEnd"
      @page:beforeout="onPageBeforeOut"
      @page:beforein="onPageBeforeIn">
    <!-- Header -->
    <Header ref="header" v-model="searchValue">
      <div class="left">
        <f7-link @touchstart="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">
        Hello world title long title
        <div class="subtitle">hello world</div>
      </div>
      <div class="right">
        <f7-link class="icon-only">
          <i class="icon mpi-filter color-black"></i>
        </f7-link>
        <f7-link @tap="startSearch" class="icon-only">
          <i class="icon mpi-search color-black"></i>
        </f7-link>
      </div>
      <template #out>
        <Subnavbar>
          <div v-for="tab of [{title: 'Postponed', key: 'test1'}, {title: 'Completed', key: 'test2'}]" :key="tab.key" class="subnavbar-button-item">
            <f7-button @tap="onTap" :id="`add-item-${tab.key}-btn`" :class="{'active': 'test1' === tab.key}">{{ $t(tab.title) }}</f7-button>
          </div>
        </Subnavbar>
        <div v-for="i in testCount">Index:{{i}}</div>
      </template>
    </Header>

    <div class="header-messages">
      <!--  Header message  -->
      <HeaderMessage v-model="shownMessage">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, ut.</HeaderMessage>

      <!--  Header message error  -->
      <HeaderMessage v-model="shownError" color="error">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quisquam, ut.</HeaderMessage>

      <!--  Header message success  -->
      <HeaderMessage v-model="shownSuccess" color="success">Lorem ipsuelit. Quisquam, ut.</HeaderMessage>
    </div>

    <!--  Page content  -->
    <Inner>
      <template #fixed>
        <f7-fab-backdrop></f7-fab-backdrop>
        <f7-fab position="right-bottom">
          <f7-icon md="material:add" class="fab-icon"></f7-icon>
          <f7-icon md="material:close" class="fab-icon"></f7-icon>
          <f7-fab-buttons position="top">
            <f7-fab-button fab-close :label="$t('settings.unpairingDevice')" class="color-red" id="settings-unpairing-device">
              <f7-icon material="leak_remove" />
            </f7-fab-button>
            <f7-fab-button fab-close :label="$t('settings.addPrinter')" id="settings-add-printer">
              <f7-icon material="print" />
            </f7-fab-button>
          </f7-fab-buttons>
        </f7-fab>
      </template>

      <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto debitis eaque et explicabo ipsa iusto,
        molestias numquam provident quas quia quos sint, temporibus? Deleniti ea maiores necessitatibus rerum, sapiente
        sit.
      </div>
      <f7-button @tap="shownMessage = !shownMessage">toggle message {{ shownMessage }}</f7-button>
      <f7-button @tap="shownError = !shownError">toggle error {{ shownError }}</f7-button>
      <f7-button @tap="shownSuccess = !shownSuccess">toggle success {{ shownSuccess }}</f7-button>
      <hr>
      <f7-button @tap="startSearch">Start search</f7-button>
      Request: <b>{{ searchValue }}</b>
      <f7-button @tap="testCount++">Add test counter</f7-button>
      <div>Ad architecto eaque error esse eveniet facilis maxime modi nihil obcaecati omnis quae ratione repellat rerum,
        saepe sint tempora, totam unde vel vero voluptates! Accusamus dignissimos nam soluta tempora veritatis!
      </div>
      <div>Amet aut beatae cupiditate dicta eaque facere, fugiat illum ipsam officiis praesentium quidem quis sequi
        veniam? Ad at cum deleniti enim ipsa labore magnam, quam quis quos recusandae similique unde?
      </div>
      <div>Blanditiis fugit mollitia nihil officiis omnis placeat porro quas, similique suscipit vel. Ad asperiores
        consequatur, debitis earum eius error eum ex fugit iste libero perferendis provident quae ratione ut voluptates.
      </div>
      <div>Amet culpa cum cupiditate debitis, delectus deleniti dicta excepturi inventore nesciunt placeat reiciendis
        rerum sint! Aliquam animi asperiores aut eum expedita hic illum magnam nihil nulla, numquam quam tempora
        voluptatem?
      </div>
      <div>Animi commodi culpa deleniti dignissimos dolore eum excepturi, expedita fugiat iusto laboriosam omnis optio,
        vel veniam. Aliquid cumque debitis libero rerum totam. Assumenda libero minima, optio provident qui repudiandae
        sapiente!
      </div>
      <div>Incidunt ipsa, ipsam minus modi numquam rem. Consequuntur earum hic ipsam iure mollitia reiciendis rem
        repellat sint! Amet corporis, dolore itaque numquam officia quidem quos recusandae sequi vel veritatis,
        voluptatibus?
      </div>
      <div>Dicta dolore laudantium magni minima nihil quisquam quo temporibus unde, vel voluptatem. Alias aliquid amet
        fugit id magni nesciunt sapiente sequi totam voluptatum. Dicta iure molestias provident vitae. Aliquam, aut?
      </div>
      <div>Consectetur consequatur et expedita maxime nostrum omnis quae recusandae totam voluptas voluptate? Accusamus
        autem ea eius enim iusto labore nisi obcaecati officia, quisquam ut veniam voluptas voluptatum? Animi, nisi,
        placeat?
      </div>
      <div>Ad alias aliquid assumenda cupiditate ea expedita sit tenetur. Adipisci consequatur cum deleniti id incidunt
        iusto nostrum officia officiis quae, quo quos rem sequi veritatis. Magni modi quaerat quasi recusandae.
      </div>

      <p style="color: red">page end</p>
    </Inner>

    <!-- Footer -->
    <Footer>
      <div class="footer-buttons">
        <f7-button class="fill">Confirm</f7-button>
        <f7-button>Reset</f7-button>
      </div>
    </Footer>
  </Page>
</template>

<script>
  import { ref } from "vue"

  export default {
    setup() {
      const header = ref(null)
      const searchValue = ref("")
      const testCount = ref(0)

      const shownMessage = ref(false)
      const shownError = ref(false)
      const shownSuccess = ref(false)

      return {
        header,
        searchValue,
        shownMessage,
        shownSuccess,
        shownError,
        testCount,
        onTap: () => console.log('tap'),
        pageScrollUp: () => {
          console.log("scroll up!")
        },
        pageScrollDown: () => {
          console.log("scroll down!")
        },
        pageScrollEnd: () => {
          console.log("scroll end!")
        },
        onPageBeforeIn: () => {
          console.log("IN")
        },
        onPageBeforeOut: () => {
          console.log("OUT")
        },
        startSearch: () => {
          header.value.enableSearching()
        }
      }
    }
  }
</script>
