<template>
  <div class="total-block-header">
    <div class="total-block-header-left">
      <div class="total-block-header-total" :class="{ 'small': needSmall }">
        <div class="total-block-header-total-title">{{ receipt.state === "active" ? $t("receipts.toReturn") : $t("receipts.returned") }}</div>
        <div class="total-block-header-total-value">${{ $filters.sum(receipt.sumTotal) }}</div>
      </div>
      <div class="total-block-header-details">{{ hintText }}</div>
    </div>
    <div class="total-block-header-right">
      <template v-if="detailsButton">
        <f7-button id="refund-block-details" :disabled="receipt.goods.length === 0" fill round @tap="openReceipt">{{ $t("receipts.details") }}</f7-button>
      </template>
      <template v-else>
        <f7-button id="refund-block-checkout" v-if="$helpers.isNewRefund(receipt)" :disabled="receipt.goodsCount <= 0" fill round @tap="refund">{{ $t("receipts.refund") }}</f7-button>
        <div id="refund-block-ready" v-else-if="$helpers.isCompletedRefund(receipt)" class="checkout-payed-like-button">
          <div class="checkout-payed-like-button-icon">
            <i class="mpi-md-done"></i>
          </div>
          <div class="checkout-payed-like-button-text">{{ $t(`receipts.${receipt.paymentType}AlreadyRefunded`) }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      receipt: { type: Object, default: () => ({}) },
      sheetExpanded: { type: Boolean, required: true, default: false },
      detailsButton: { type: Boolean, default: false }
    },
    methods: {
      refund() {
        gui.dispatch("receipt.checkout")
      },
      openReceipt() {
        gui.navigate("/receipt")
      }
    },
    computed: {
      needSmall() {
        return this.receipt.sumTotal > 99999
      },
      itemsCountTitle() {
        const title = this.receipt?.state === "active" ? gui.translate("receipts.itemsSelected") : gui.translate("receipts.itemsReturned")
        const count = `${this.receipt?.goodsCount} ${gui.translate("receipts.of")} ${this.receipt?.state === "active" ? this.receipt?.countToRefund : this.receipt?.originalGoodsCount}`

        return `${title} ${count}`
      },
      hintText() {
        if(Number(this.receipt?.originalGoodsCount) > 0) {
          return this.itemsCountTitle
        }

        return this.sheetExpanded ? gui.translate("receipts.swipeUp") : gui.translate("receipts.swipeDown")
      }
    }
  }
</script>
