<template>
  <f7-list-item class="receipts-list-item" :clickable="clickable" :class="{'sent': receipt.isAllSent }">
    <div class="receipts-list-item-content">
      <div class="receipts-list-item-row receipts-list-item-title">
        <div class="row-cell">{{ `#${receipt.displayedId ?? ""}` }}</div>
        <div class="row-cell">{{ receipt.sumTotal }}</div>
      </div>
      <div class="receipts-list-item-row receipts-list-item-subtitle">
        <div class="row-cell">
          {{ $filters.date(receipt.completedOn ?? receipt.createdOn) }}
        </div>
        <div class="row-cell">
          {{ $t("receipts.totalItems", { qty: receipt.goodsCount }) }}
        </div>
      </div>
      <div class="receipts-list-item-row receipts-list-item-subtitle">
        <div class="row-cell">{{ receipt.userName }}</div>
        <div v-if="$helpers.isCafe()" class="row-cell">
          {{ $t("receipts.table", { name: receipt.tableName ?? $t('filters.null') }) }}
        </div>
      </div>
      <div v-if="receipt.type === 'refund'" class="receipts-list-item-row receipts-list-item-subtitle">
        <div class="row-cell refund">{{ $t('receipts.refund') }}</div>
      </div>
    </div>
  </f7-list-item>
</template>

<script>
export default {
  props: {
    receipt: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<style lang='scss'>
@import "@/css/variables";

.receipts-list-item {
  background: $light-bg-color;
  margin: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  // height: 90px;
  &.sent {
    background: #ffffff;
  }
  .item-link {
    flex-grow: 1;
    .item-content {
      padding: 0;
      .item-inner {
        padding: 0;
      }
    }
  }
}
.receipts-list-item .item-content .item-inner::after {
  content: none;
}
.receipts-list-item .item-content {
  height: 100%;
}
.receipts-list-item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 14px 18px;

  .receipts-list-item-row {
    display: flex;
    justify-content: space-between;
    .row-cell {
      display: flex;
      align-items: center;
      &.refund {
        color: $error-color;
      }
    }
  }
  .receipts-list-item-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 118.75%;
    color: #22272c;
    height: 24px;
  }
  .receipts-list-item-subtitle {
    font-size: 12px;
    line-height: 118.75%;
    color: $text-secondary;
    height: 18px;
  }
}
</style>