<template>
  <div class="shift-item-container">
    <div class="shift-item-row">
      <div class="shift-item-row-header">
        <div class="shift-item-row-name">{{ $t("shifts.sales") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.sumPayments) }}</div>
      </div>
      <div class="shift-item-row-sub-item">
        <div class="shift-item-row-name">{{ $t("shifts.cash") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.sumCash) }}</div>
      </div>
      <div class="shift-item-row-sub-item">
        <div class="shift-item-row-name">{{ $t("shifts.card") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.sumCard) }}</div>
      </div>
      <div class="shift-item-row-sub-item">
        <div class="shift-item-row-name">{{ $t("shifts.certificates") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.sumCertificates) }}</div>
      </div>
    </div>
    <div class="shift-item-row">
      <div class="shift-item-row-header">
        <div class="shift-item-row-name">{{ $t("shifts.refunds") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.sumRefunds) }}</div>
      </div>
      <div class="shift-item-row-sub-item">
        <div class="shift-item-row-name">{{ $t("shifts.cash") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.cashRefund) }}</div>
      </div>
      <div class="shift-item-row-sub-item">
        <div class="shift-item-row-name">{{ $t("shifts.card") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.cardRefund) }}</div>
      </div>
    </div>
    <div class="shift-item-row">
      <div class="shift-item-row-header">
        <div class="shift-item-row-name">{{ $t("shifts.taxes") }}</div>
      </div>
      <div class="shift-item-row-sub-item">
        <div class="shift-item-row-name">{{ $t("shifts.taxesSales") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.sumTaxes) }}</div>
      </div>
      <div class="shift-item-row-sub-item">
        <div class="shift-item-row-name">{{ $t("shifts.taxesRefunds") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.sumTaxesRefund) }}</div>
      </div>
    </div>
    <div v-if="$helpers.isCafe()" class="shift-item-row">
      <div class="shift-item-row-header">
        <div class="shift-item-row-name">{{ $t("shifts.tips") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.sumTips) }}</div>
      </div>
      <div class="shift-item-row-sub-item">
        <div class="shift-item-row-name">{{ $t("shifts.cashTips") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.cashTips) }}</div>
      </div>
      <div class="shift-item-row-sub-item">
        <div class="shift-item-row-name">{{ $t("shifts.cardTips") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.cardTips) }}</div>
      </div>
    </div>
    <div class="shift-item-row">
      <div class="shift-item-row-header">
        <div class="shift-item-row-name">{{ $t("shifts.deposit") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.cashIn) }}</div>
      </div>
    </div>
    <div class="shift-item-row">
      <div class="shift-item-row-header">
        <div class="shift-item-row-name">{{ $t("shifts.withdrawal") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.cashOut) }}</div>
      </div>
    </div>
    <div v-if="shift.state === 'active'" class="shift-item-row">
      <div class="shift-item-row-header">
        <div class="shift-item-row-name">{{ $t("shifts.cashAvailable") }}</div>
        <div class="shift-item-row-value">${{ $filters.sum(shift.availableCash) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shift: { type: Object, required: true },
  },
  setup() {

  },
}
</script>

<style lang="scss">
 @import "@/css/variables";

.shift-item-row-header {
  display: flex;
  align-items: flex-start;
  font-weight: bold;
  font-size: 18px;
  justify-content: space-between;
}
.shift-item-container {
  .shift-item-row {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    .shift-item-row-sub-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 12px;
      color: $text-secondary;
      font-size: 16px;
      line-height: 118.75%;
      padding-left: 15px;
    }
  }
}
</style>
