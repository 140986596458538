<template>
  <div class="certificate-field input-item" :class="{ 'right': isTextHidden }">
    <div ref="inner" class="certificate-field-inner">
      <span ref="text" class="certificate-field-tex">{{ certificate }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      modelValue: { type: String, default: "" }
    },
    data() {
      return {
        isTextHidden: false,
        certificate: this.modelValue
      }
    },
    methods: {
      updateHidden() {
        this.$nextTick(() => {
          this.isTextHidden = this.checkHidden()
        })
      },
      update() {
        this.updateHidden()
        this.emit()
      },
      backspace() {
        this.certificate = this.certificate.slice(0, -1)
        this.update()
      },
      press(char) {
        this.certificate += char
        this.update()
      },
      emit() {
        this.$emit("update:modelValue", this.certificate)
      },
      checkHidden() {
        const inner = this.$refs.inner
        const text = this.$refs.text

        if(inner && text) {
          if(text.offsetWidth > inner.offsetWidth) {
            return true
          }
        }

        return false
      }
    },
    mounted() {
      this.updateHidden()
    },
    watch: {
      modelValue(value) {
        this.certificate = value
        this.updateHidden()
      }
    }
  }
</script>

<style lang="sass" scoped>
  .certificate-field
    box-sizing: border-box
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    overflow: hidden
    letter-spacing: 4px
    &.right
      justify-content: flex-end
      text-align: right
      .certificate-field-inner
        justify-content: flex-end
    .certificate-field-inner
      overflow: hidden
      box-sizing: border-box
      width: 100%
      display: flex
      justify-content: center
</style>
