import rpc from '../libs/rpc';
import gui from '../gui';
import state from '../state';
import dicts from '../dicts';

import * as settings from '../settings';
import { isOnline } from '../online';
import { navigate } from "./router"
import { mergeShifts } from './shift';

const limit = 10;
// const exactMatch = true;

export async function openDocs(options) {
  gui.preloader(true);

  const filter = options.filter ? { ...getDefaultFilter(options.page), ...options.filter } : getDefaultFilter(options.page);

  const docs = await getDocsByPage(
    options.page,
    filter
  );

  docs.updated = true;
  docs.activeTab = options.activeTab;
  docs.searching = getDefaultSearching();
  docs.tableData = options.tableData ?? docs.tableData;
  docs.filter = filter;
  state[options.page] = gui[options.page] = docs;

  gui.preloader(false);

  if (!options.needUpdate) await navigate(`/${options.page}/list`);
}

async function getDocsByPage(page, filter) {
  filter = filter || {};

  const docs = { order: {}, offset: {}, loadMoreEnable: {} };

  for (let key in defaultDocSchemas[page]) {
    if (!defaultDocSchemas[page].hasOwnProperty(key)) {
      continue;
    }
    const type = defaultDocSchemas[page][key].type;

    docs[key] = await getDocs(
      type,
      defaultDocSchemas[page][key].state,
      state[page]?.order?.[key] ?? null,
      0,
      filter
    );
    docs.loadMoreEnable[key] = true;
    docs.order[key] =
      state[page]?.order[key] || defaultDocSchemas[page][key].order;
    docs.offset[key] = limit;
    // docs.offset[key] = state[page]?.offset[key] || limit;
  }
  return docs;
}

async function getDocs(type, states, order, offset, filter, search) {
  order =
    order ||
    (states.includes('active') ? 'createdOn DESC' : 'completedOn DESC');
  filter = filter || {};
  search = search || '';

  return await dicts.docs.get({
    where: {
      'type IN': type,
      ...prepareState(states),
      ...prepareFilter(filter),
      ...prepareSearchValues(search),
      storeId: state.user.store._id,
    },
    order,
    offset,
    limit,
  });
}

export async function updateFilters(page, filter) {
  //without params = reset
  filter = filter || getDefaultFilter(page);

  gui.preloader(true);

  state[page].filter = filter;

  resetOffsetByPage(page);

  const docs = await getDocsByPage(page, filter);

  for (let key in docs) {
    if (!docs.hasOwnProperty(key)) {
      continue;
    }
    state[page][key] = docs[key];
  }
  gui.preloader(false);

  gui[page] = state[page];
}

export async function updateOrder(page, order) {
  gui.preloader(true);
  const activeTab = state[page].activeTab;

  state[page].order[activeTab] = order;
  state[page].offset[activeTab] = 0;
  state[page].remoteOffset[activeTab] = 0;

  state[page][activeTab] = await getDocs(
    defaultDocSchemas[page][activeTab].type,
    defaultDocSchemas[page][activeTab].state,
    order,
    0
  );

  gui[page] = state[page];

  gui.preloader(false);
}

export function resetOffsetByPage(page) {
  for (let key in defaultDocSchemas[page]) {
    if (!defaultDocSchemas[page].hasOwnProperty(key)) {
      continue;
    }
    state[page].offset[key] = 0;
  }
  gui[page] = state[page];
}

export async function changeTab(page, tab) {
  state[page].activeTab = tab;
  gui[page] = state[page];
}

function prepareSearchValues(searchValue) {
  // заменить или удалить ?
  if (searchValue === '') return {};
  return {
    // or: {
    'displayedId LIKE': `%${searchValue}%`,
    // 'userName LIKE': `%${searchValue}%`,
    // 'tableName LIKE': `%${searchValue}%`,
    // },
  };
}

function prepareState(state) {
  return { [Array.isArray(state) ? 'state IN' : 'state']: state };
}

function prepareFilter(obj) {
  obj = obj || {};
  const result = {};

  for (var key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue;
    }

    if (Array.isArray(obj[key])) {
      if (obj[key]?.length === 0) continue;

      if(key === 'tableId' && obj[key].includes(null)) {
        const arrFiltered = obj[key].filter((item => item !== null));

        if (arrFiltered.length) {
          result.or = { [`${key} IN`]: arrFiltered, [key]: null };
        } else {
          result[key] = null;
        }

      } else {
        result[`${key} IN`] = obj[key];
      }

      continue;
    }

    if (key === 'type') {
      result['type IN'] = obj.type;
      continue;
    }

    if (key === 'date') {
      if (obj[key]?.minSec !== '') {
        const time = obj[key].minSec;
        result.or = {
          [`completedOn >=`]: time,
          [`createdOn >=`]: time,
        };
      }

      if (obj[key]?.maxSec !== '') {
        const time = obj[key].maxSec + 24 * 60 * 60 * 1000 - 1;

        if (result.or) {
          result.and = {
            or: {
              [`completedOn <=`]: time,
              [`createdOn <=`]: time,
            },
          };
        } else {
          result.or = {
            [`completedOn <=`]: time,
            [`createdOn <=`]: time,
          };
        }
      }

      continue;
    }
    if (obj[key]?.min !== '' && obj[key]?.min !== undefined) {
      result[`${key} >=`] = obj[key].min;
    }
    if (obj[key]?.max !== '' && obj[key]?.max !== undefined) {
      result[`${key} <=`] = obj[key].max;
    }
  }

  return result;
}

export async function getFiltersOptions(page) {
  const options = {};
  const activeTab = state[page].activeTab;
  const where = {
    'type IN': defaultDocSchemas[page][activeTab].type,
    'state IN': ['completed', 'received', 'active'],
    storeId: state.user.store._id,
  };

  options.userId = {
    current: await dicts.docs.get({
      where,
      fields: ['userName as text', 'userId as value'],
      distinct: true,
    }),
  };

  if (page === 'receipts') {
    if (await settings.isCafe()) {
      options.tableId = {
        current: await dicts.docs.get({
          where,
          fields: ['tableName as text', 'tableId as value'],
          distinct: true,
        }),
      };
    }
  }

  return options;
}

export async function openFilters(page) {
  gui.preloader(true);

  const filterOptions = await getFiltersOptions(page);

  state[page].filterOptions = prepareOptions(filterOptions);

  //add selected table from tables widget to filters
  if (state[page].tableData && !state[page].filterOptions.tableId.opts.find((item)=> item.value === state[page].tableData.table._id)) {
    state[page].filterOptions.tableId.opts.push({text: state[page].tableData.table.tableName, value: state[page].tableData.table._id})
  }

  gui[page] = state[page];

  gui.preloader(false);

  await navigate(`/${page}/filter`);
}

/**
 * mapping and clear empty results
 * @param {*} obj = {
 * paymentType: {current: SQLResultSetRowList, all: Array(2)},
 * tableName: {current: SQLResultSetRowList}, // empty
 * userId: {current: SQLResultSetRowList},
 * }
 * @returns {
 *   paymentType: {
 *     title: "paymentType",
 *     opts: Array(1),
 *     all: Array(2)
 *    },
 *   userId: {
 *     title: "userId",
 *     opts: Array(1),
 *     all: null
 *   }
 * }
 */
function prepareOptions(obj) {
  const result = {};

  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue;
    }

    let arr = Array.from(obj[key].current);

    if (key !== 'tableId') {
      //clear empty results
      arr = arr.filter((value) => value[key] !== null);
    } else {
      //rename null to skipped tables
      arr = arr.map((item) => ({ text: item.text ?? gui.translate("filters.null"), value: item.value  }));
    }

    if (arr.length > 0) {
      result[key] = {title: key, opts: arr};
    }
  }

  return result;
}

function getDefaultFilter(page) {
  const filter = {
    sumTotal: { min: '', max: '' },
    date: { minSec: '', maxSec: '' },
    userId: [],
  };

  if (page === 'shifts') return filter;

  return {
      ...filter,
      tableId: [],
      paymentType: [],
      type: ['receipt', 'refund'],
    };
}
// full filters example
// {
//   sumTotal: { min: '', max: '' },
//   userId: [],
//   tableId: [],
//   paymentType: [],
//   date: { min: '', max: '' },
// };

export function getDefaultSearching() {
  return {
    loading: false,
    docs: [],
  };
}

const defaultDocSchemas = {
  receipts: {
    postponed: {
      type: ['receipt'],
      order: 'createdOn DESC',
      state: ['active'],
    },
    completed: {
      type: ['receipt', 'refund'],
      order: 'completedOn DESC',
      state: ['completed', 'received'],
    },
  },
  shifts: {
    shifts: {
      type: ['shift'],
      order: 'completedOn DESC',
      state: ['completed', 'received'],
    },
  },
};

export async function disableLoadMore(page) {
  const activeTab = state[page].activeTab;

  state[page].loadMoreEnable[activeTab] = false;

  gui[page] = state[page];
}

export async function loadMoreDocs(page) {
  const activeTab = state[page].activeTab;

  if (state[page][activeTab].length < state[page].offset[activeTab]) return;

  gui.preloader(true);

  const docs = await getDocs(
    defaultDocSchemas[page][activeTab].type,
    defaultDocSchemas[page][activeTab].state,
    state[page].order[activeTab],
    state[page].offset[activeTab],
    state[page].filter
  );

  state[page].offset[activeTab] += limit;
  state[page][activeTab] = state[page][activeTab].concat(docs);
  gui[page] = state[page];

  gui.preloader(false);
}

export async function searchDocs(page, searchingValue) {
  state[page].searching.loading = true;
  gui[page] = state[page];

  const activeTab = state[page].activeTab;

  // if (exactMatch) { // для частичного поиска нужен отдельный offset для searching.docs
  const remote =
    isOnline && activeTab !== 'postponed'
      ? await rpc('pos.receipt.findOne', {
        'type IN': defaultDocSchemas[page][activeTab].type,
        ...prepareState(defaultDocSchemas[page][activeTab].state),
        displayedId: searchingValue,
        // companyId: state.user.company._id
      })
      : await dicts.docs.getOne({
          where: {
            'type IN': defaultDocSchemas[page][activeTab].type,
            ...prepareState(defaultDocSchemas[page][activeTab].state),
            displayedId: searchingValue,
            companyId: state.user.company._id,
          },
        });

  // state[page][activeTab] = remote ? [remote] : [];
  state[page].searching.docs = remote ? [remote] : [];
  state[page].searching.loading = false;
  gui[page] = state[page];

  return;
  // }

  // //if partial match enabled
  // const search = await getDocs(
  //   defaultDocSchemas[page][activeTab].type,
  //   defaultDocSchemas[page][activeTab].state,
  //   state[page].order[activeTab],
  //   0,
  //   state[page].filter, // игнорировать фильтры ?
  //   searchingValue
  // );

  // state[page].offset[activeTab] = 0;
  // state[page].searching.loading = false;
  // state[page].searching.docs = search;
  // // state[page][activeTab] = search;
  // gui[page] = state[page];
}

export async function getFullReport(from, to) {
  gui.preloader(true);

  const reports = await dicts.docs.get({
    where: {
      type: 'shift',
      'state IN': ['completed', 'received'],
      'completedOn >=': from,
      'completedOn <=': to + 24 * 60 * 60 * 1000 - 1,
      storeId: state.user.store._id,
    },
  });

  if (reports.length > 0) {
    let fullReport = null;
    for (const report of reports) {
      if (!fullReport) {
        fullReport = report;
        continue;
      }

      fullReport = await mergeShifts(fullReport, report);
    }

    fullReport.periodStart = from;
    fullReport.periodEnd = to + 24 * 60 * 60 * 1000 - 1;

    gui.fullReport = state.fullReport = fullReport;
  } else {
    gui.fullReport = state.fullReport = null;
  }

  gui.preloader(false);
}

export async function clearFullReport() {
  gui.fullReport = state.fullReport = null;
}

export async function openFullReportPage() {
  gui.preloader(true);

  const availableDates = await dicts.docs.get({
    fields: ['MIN(completedOn) as min', 'MAX(completedOn) as max'],
    where: {
      type: 'shift',
      'state IN': ['received', 'completed'],
      storeId: state.user.store._id,
    },
  });

  gui.fullReportDates = state.fullReportDates = {
    from: availableDates[0].min,
    to: availableDates[0].max,
  };

  gui.preloader(false);

  await navigate('/shifts/full-report');
}

export async function update(options) {
  await openDocs(options);
}

/*
export function getDocSchema() {
  return {
    order: {},
    offset: {},
    loadMoreEnable: {},
    updated: true,
    activeTab: '',
    searching: {
      loading: false,
      docs: [],
    },
    tableData: null,
    // filter: options.filter ? getCustomFilter(options.page, options.filter) : getDefaultFilter(options.page, options.filter),

  }
}
*/
