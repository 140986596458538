import * as native from "./native"

import state from "./state"
import gui from "./gui"

let searching = false

native.rpc['bluetooth'] = async function(method, ...params) {
  console.log(`RPC ${method} ${params.join(',')}`)
  if (method === 'bluetooth.scanInfo') {
    await scanInfo.apply(this, params)
  } else if (method === 'bluetooth.scanFinished') {
    await scanFinished.apply(this, params)
  }
}

export async function scanStart() {
  searching = true
  console.log("Bluetooth devices scanning...")
  await native.call('bluetooth.scanStart')
}

export async function scanInfo(device) {
  state.bluetoothDevices = state.bluetoothDevices ? state.bluetoothDevices : {}

  if(device.address) {
    state.bluetoothDevices[device.address] = { ...device, updated: Date.now() }
  }

  gui.bluetoothDevices = state.bluetoothDevices
}

export async function scanFinished() {
  searching = false
}