<template>
  <Page class="filters-page" id="filters-page">
    <Header>
      <div class="left">
        <f7-link
          @tap="$gui.navigateBack()"
          class="icon-only"
          id="filters-back-link">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t("filters.filters") }}</div>
    </Header>

    <Inner>
      <div class="filters-page-content">
        <div class="filter-block filters-group-wrap">
          <div class="filters-group-title">
            {{
              page === "receipts" ? $t("filters.price") : $t("filters.sumtotal")
            }}
          </div>
          <div class="content ">
            <div class="form-group price">
              <div
                  @click="showKeyboard('sumTotal.min')"
                  class="input-item"
                  :class="{ error: errorPrice.min, focus: activeField.key === 'sumTotal.min' }"
                  id="filter-sumtotal-min"
              >
                <span v-if="filter.sumTotal.min !== ''">$</span>{{ filter.sumTotal.min }}
              </div>
              <div
                  @click="showKeyboard('sumTotal.max')"
                  class="input-item"
                  :class="{ error: errorPrice.max, focus: activeField.key === 'sumTotal.max' }"
                  id="filter-sumtotal-max"
              >
                <span v-if="filter.sumTotal.max !== ''">$</span>{{ filter.sumTotal.max }}
              </div>
            </div>
          </div>
        </div>

        <Dates
          v-model:from="filter.date.minSec"
          v-model:to="filter.date.maxSec"
          id="date"
          :title="$t('filters.date')"
        />

        <FilterBlock
            v-if="showPayments"
            :title="$t('filters.paymentType')"
            id="payment"
            :filter="filter"
            property="paymentType"
            :options="paymentFilterOptions"
            @change="onChange"
            twoColumns
        />

        <FilterBlock
            v-if="showTypes"
            :title="$t('filters.type')"
            id="type"
            :filter="filter"
            property="type"
            :options="typesFilterOptions"
            @change="onChangeTypes"
            twoColumns
            exactValueMatch
        />

        <FilterBlock
            v-if="showTables"
            :title="$t('filters.tableId')"
            id="table"
            :filter="filter"
            property="tableId"
            :options="filterOptions.tableId.opts"
            @change="onChange"
        />

        <FilterBlock
            v-if="filterOptions?.userId"
            :title="$t('filters.userName')"
            id="user"
            :filter="filter"
            property="userId"
            :options="filterOptions.userId.opts"
            @change="onChange"
        />
      </div>

      <template #fixed>
        <KeyboardSheet
            :opened="isKeyboardOpened"
            @sheet:closed="hideKeyboard"
            @onConfirm="onConfirm"
            :value="activeField.value"
            @update:value="updateActiveField"
            :title="$t(activeField.title)"
            id='filters'
        />
      </template>
    </Inner>

    <Footer>
      <div class="footer-buttons some-buttons">
        <f7-button
            large
            round
            class="fill"
            @tap="updateFilters"
            :disabled="errorDate || errorPrice.min || errorPrice.max"
            id="filters-confirm-btn">
          {{ $t("filters.apply") }}
        </f7-button>
        <f7-button large round @tap="resetFilters" id="filters-reset-btn">
          {{ $t("filters.reset") }}
        </f7-button>
      </div>
    </Footer>
  </Page>
</template>

<script>
  import { ref, watch, computed } from "vue";
  import { useRoute } from "vue-router";
  import { isCafe } from "../../js/helpers";
  import FilterBlock from "../../components/Filter/FilterBlock";
  import Dates from "../../components/Filter/Dates";
  import KeyboardSheet from "../../components/CustomStepper/KeyboardSheet";

  export default {
    components: { FilterBlock, KeyboardSheet, Dates },
    setup() {
      const route = useRoute()

      const page = route.params.page;
      const filterPage = ref(gui[page]);
      const filter = ref(filterPage.value.filter);
      const filterOptions = ref(filterPage.value.filterOptions);
      const errorPrice = ref({ min: false, max: false });
      const errorDate = ref(false);
      const isKeyboardOpened = ref(false);
      const activeField = ref({value: '', key: '', title: 'filters.insertPriceMin'});

      const typesFilterOptions = [
        { text: gui.translate("filters.refunded"), value: "refund" },
        { text: gui.translate("filters.hideRefunded"), value: "receipt" },
      ];

      const paymentFilterOptions = [
        { text: gui.translate("filters.card"), value: "card" },
        { text: gui.translate("filters.cash"), value: "cash" },
      ];

      const hideKeyboard = () => {
        isKeyboardOpened.value = false;
        activeField.value.key = '';
      };

      watch(gui[page], (value) => {
        filter.value = value.filter;
      });

      watch(filter.value.date, (value) => {
        if (value.minSec !== "" && value.maxSec !== "") {
          if (value.minSec > value.maxSec) {
            errorDate.value = true;
          } else {
            errorDate.value = false;
          }
        }
      });

      const comparePrices = (key) => {
        if (
            filter.value.sumTotal.min !== "" &&
            filter.value.sumTotal.max !== ""
          ) {

            if (filter.value.sumTotal.min > filter.value.sumTotal.max) {
              errorPrice.value[key] = true;
            } else {
              errorPrice.value[key] = false;
            }

            if (filter.value.sumTotal.min < filter.value.sumTotal.max) {
              errorPrice.value.min = false;
              errorPrice.value.max = false;
            }
          }
      };

      return {
        errorPrice,
        errorDate,
        filter,
        filterOptions,
        typesFilterOptions,
        paymentFilterOptions,
        activeField,
        isKeyboardOpened,
        page,
        hideKeyboard,
        updateFilters() {
          gui.dispatch("docs.updateFilters", page, filter.value);
          gui.navigateBack();
        },
        resetFilters() {
          gui.dispatch("docs.updateFilters", page);
        },
        onChange: (option, value) => {
          if (!filter.value[option].includes(value)) {
            filter.value[option].push(value);
          } else {
            filter.value[option].splice(
              filter.value[option].indexOf(value),
              1
            );
          }
        },
        onChangeTypes: (type, value) => {
          if (filter.value.type?.toString() === value) {
            filter.value.type = ["receipt", "refund"];
          } else {
            filter.value.type = [value];
          }
        },
        showKeyboard: (key) => {
          isKeyboardOpened.value = true;

          //set key for update
          activeField.value.key = key;

          //set value and title in keyboard
          if (key === 'sumTotal.min') {
            activeField.value.value = filter.value.sumTotal.min;
            activeField.value.title = page === 'receipts' ? 'filters.insertPriceMin' : 'filters.insertSumtotalMin';
            }
          if (key === 'sumTotal.max') {
            activeField.value.value = filter.value.sumTotal.max;
            activeField.value.title = page === 'receipts' ? 'filters.insertPriceMax' : 'filters.insertSumtotalMax';
            }

        },
        onConfirm: () => {
          hideKeyboard();
        },
        updateActiveField(value) {
          const keys = activeField.value.key.split('.');
          filter.value[keys[0]][keys[1]] = value;
          comparePrices(keys[1]);
        },
        showTypes: computed(() => page === 'receipts' && !isCafe()),
        showTables: computed(() => filterOptions.value.tableId && page === 'receipts'),
        showPayments: computed(() => page === 'receipts'),
      };
    },
  };
</script>
<style lang="scss">
  @import "@/css/variables";

  .filters-page {
    .filters-page-content {
      padding: $padding-arrow;
      .filters-group-title {
        margin: 0;
        margin-bottom: 10px;
      }
      .filter-block {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        .content {
          .form-group {
            display: flex;
            justify-content: space-between;
            .input-item {
              box-sizing: border-box;
              white-space: nowrap;
              overflow: auto;
              text-align: center;
              line-height: $input-height;
            }
          }
        }
      }
    }
  }
  .filters-group-wrap {
    margin-bottom: 15px;
    .form-group {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
  }
  .filters-group-title {
    font-weight: 600;
    font-size: $text-size;
    line-height: 118.75%;
    color: $text-color;
  }
</style>





