<template #header>
  <div class="page-header">
    <div class="page-header-height">
      <div class="page-header-inner">
        <div class="page-header-searching-container" :class="{ 'fade-in': searchShown, 'with-clear': withClear }">
          <div class="page-header-searching-input" :class="{ 'loading': searchLoading }">
            <f7-button @tap="clear" :id="`${searchId}-clear`" class="page-header-searching-input-clear">
              <i class="mpi-md-close"></i>
            </f7-button>
            <div class="page-header-searching-input-icon">
              <i class="mpi-search"></i>
            </div>
            <div class="page-header-searching-input-loading">
              <Preloader />
            </div>
            <input :id="searchId" ref="search" @input="onSearch" :value="search" class="input-item" type="text" :placeholder="searchPlaceholder" />
          </div>
          <div class="page-header-searching-cancel">
            <f7-link @tap="disableSearching" :id="`${searchId}-cancel`">{{ $t("cancel") }}</f7-link>
          </div>
        </div>
        <slot />
      </div>
      <slot name="out" />
    </div>
  </div>
</template>

<script>
  import { delay, uuidv4 } from "../../js/utils"
  import Preloader from "@/components/f7/Preloader"

  export default {
    components: { Preloader },
    props: {
      modelValue: { type: String, default: "" },
      searchId: { type: String, default: null },
      searchLoading: { type: Boolean, default: false },
      searchHideOutside: { type: Boolean, default: true },
      searchPlaceholder: { type: String, default: "Searching..." },
    },
    data() {
      return {
        searchIdValue: this.searchId || "hf-" + uuidv4(),
        search: this.modelValue,
        searchShown: false
      }
    },
    methods: {
      async enableSearching() {
        setTimeout(() => {
          this.searchShown = true
        }, 20)

        while(this.$refs.search !== document.activeElement) {
          await delay(100)
          this.$refs.search?.focus()
        }

        this.$emit("search:enable")
      },
      disableSearching() {
        this.searchShown = false
        this.$emit("search:disable")
      },
      onSearch(event) {
        this.search = event.target.value
        this.emit()
      },
      detectOutside(event) {
        if(this.searchShown && this.searchHideOutside) {
          if(!event.target.closest(".page-header-inner")) {
            this.disableSearching()
          }
        }
      },
      clear() {
        this.search = ""
        this.emit()
        setTimeout(async () => {
          await this.enableSearching()
        }, 5)
      },
      emit() {
        this.$emit("update:modelValue", this.search)
        this.$emit("search:update", this.search)
      }
    },
    computed: {
      withClear() {
        return this.search !== ""
      }
    },
    mounted() {
      document.addEventListener("click", this.detectOutside, false)
    },
    beforeUnmount() {
      document.removeEventListener("click", this.detectOutside, false)
    },
    watch: {
      modelValue(value) {
        this.search = value
      }
    }
  }
</script>
