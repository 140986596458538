import dicts from "./dicts"

import { createDisplayedId } from "./libs/util"
import { pushDocument } from "./sync"
import state from "./state"
import { v4 as uuid } from "uuid"

export async function saveDocument(doc) {
  const [id] = await dicts.docs.put(doc)

  if(typeof id === "number") {
    doc._id = id
  }

  if(!doc.displayedId) {
    doc.displayedId = await createDisplayedId(doc)

    // update display id
    await dicts.docs.put(doc)
  }

  // Sending document to service
  pushDocument(doc).then()
}

// Login or Logout document
export async function createSessionDocument(type, isForce = false) {
  await dicts.docs.put({
    uuid: uuid(),
    type: type === "login" ? "login" : "logout",
    state: "completed",
    userId: state.user._id,
    storeId: state.user.storeId,
    deviceId: state.device._id,
    companyId: state.user.companyId,
    createdOn: Date.now(),
    force: isForce
  })
}
