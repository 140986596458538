import state from "./state"

export const SERVER_DOWN_CODE = 521

export const PRINTERS = {
  MASTER: "master",
  KITCHEN: "kitchen",
  BAR: "bar"
}

export const PRINTERS_NAMES = {
  [PRINTERS.MASTER]: "Master",
  [PRINTERS.KITCHEN]: "Kitchen",
  [PRINTERS.BAR]: "Bar",
}

/* Store settings begin */

export async function getWorldnetKey() {
  if(state.user && state.user.store) {
    return state.user.store.worldnetKey
  }

  return null
}

export async function getCertificatePrefix() {
  if(state.user && state.user.company) {
    return state.user.company.certificateBarcodePrefix
  }

  return null
}

export async function getBusinessType() {
  if(state.user && state.user.store) {
    return state.user?.store?.business?.key
  }

  return null
}

export async function isCafe() {
  return await getBusinessType() === "cafe"
}

export async function isCashMethodAvailable() {
  return Boolean(state.user.store?.cashEnabled)
}

export async function isCardMethodAvailable() {
  return Boolean(state.user.store?.cardEnabled)
}

export async function getStoreRefundType() {
  return state.user.store?.refund?.type
}

export async function isCashRefundAvailable() {
  return Boolean(state.user.store?.refund?.cashEnabled)
}

export async function isCardRefundAvailable() {
  return Boolean(state.user.store.refund?.cardEnabled)
}

export async function isAvailableCashToCard() {
  return Boolean(state.user.store.refund?.availableCashToCard)
}

export async function isAvailableCardToCash() {
  return Boolean(state.user.store.refund?.availableCardToCash)
}

export async function isInsecureRefund() {
  return state.user.store.refund?.refundInsecure === true
}

export async function shiftOpenAmountControl() {
  return ["zero", "previously", "any"].includes(state.user.store.shiftOpenAmountControl) ? state.user.store.shiftOpenAmountControl : "zero"
}

/* Store settings end */

export async function getPrinter(type) {
  if(Array.isArray(state.settings?.printers)) {
    const printer = state.settings.printers.find(printer => printer.type === type)
    return printer ? printer : null
  }

  return null
}
