<template>
  <div class="actions-sheet-component">
    <CustomBottomSheet @change:state="$emit('change:state', $event)" :default-close-all="true" ref="sheet">
      <div class="actions-sheet-header">
        <div class="actions-sheet-header-title">{{ $t("receipts.actionsTitle") }}</div>
        <f7-button id="receipt-actions-close" @tap.prevent="close" class="actions-sheet-header-times"><i class="mpi-del"></i></f7-button>
      </div>
      <template #hidden>
        <div class="actions-sheet-wrapper">
          <div class="actions-sheet-body content" :class="{'with-footer': footerGroup.length > 0}">
            <div v-for="(group, index) of actions" :key="index" class="actions-sheet-group" :class="{ 'many': group.length > 1 }">
              <ActionSheetItem v-for="(item, jindex) of group" @item-click="callItem" :item="item" :key="jindex" />
            </div>
          </div>
          <div v-if="footerGroup.length > 0" class="actions-sheet-body footer">
            <div class="actions-sheet-group" :class="{ 'many': footerGroup.length > 1 }">
              <ActionSheetItem v-for="(item, index) of footerGroup" @item-click="callItem" :item="item" :key="index" />
            </div>
          </div>
        </div>
      </template>
    </CustomBottomSheet>
  </div>
</template>

<script>
  import { computed } from "vue"
  import { orderOrReceipt } from "../../js/helpers"

  import ActionSheetItem from "./ActionSheetItem"
  import CustomBottomSheet from "../CustomBottomSheet"

  export default {
    components: { ActionSheetItem, CustomBottomSheet },
    methods: {
      callItem(item) {
        if(item.closeSheet !== false) {
          this.close()
        }

        if(typeof item.go === "function") {
          item.go()
        }
      },
      open() {
        this.$refs.sheet.showAll()
      },
      close() {
        this.$refs.sheet.closeAll()
      }
    },
    computed: {
      isCafe() {
        return this.$helpers.isCafe()
      },
      isRefund() {
        return this.$helpers.isRefund(gui.receiptData.value)
      },
      isNewReceipt() {
        return this.$helpers.isNewReceipt(gui.receiptData.value)
      },
      isNewRefund() {
        return this.$helpers.isNewRefund(gui.receiptData.value)
      },
      isCompletedRefund() {
        return this.$helpers.isCompletedRefund(gui.receiptData.value)
      },
      isCompletedReceipt() {
        return this.$helpers.isCompletedReceipt(gui.receiptData.value)
      },
      allSelected() {
        for (let good of gui.receiptData.value.goods) {
          if(good.countToRefund !== good.count) {
            return false
          }
        }

        return true
      },
      existsDiscount() {
        if(this.isNewReceipt) {
          if(gui.receiptData.value?.manualDiscount?.discounts?.length > 0 || gui.receiptData.value?.manualDiscount?.isSumEditable === true) {
            return true
          }
        }

        return false
      },
      existsTips() {
        return gui.receiptData.value?.tips && gui.receiptData.value?.tips?.tipsEnabled
      },
      sumGroup() {
        const group = []

        if(this.existsDiscount) {
          group.push({ title: "receipts.discountOption", go: () => gui.dispatch("receipt.openDiscount") })
        }

        if(this.isNewReceipt) {
          group.push({
            title: "receipts.taxExemption",
            go: () => gui.dispatch("receipt.toggleTaxExemption"),
            toggleItem: true,
            toggleValue: computed(() => {
              return gui.receiptData.value?.taxExemptionEnabled === true
            })
          })

          group.push({
            title: "certificates.actionTitle",
            go: () => gui.dispatch("certificates.open")
          })

          group.push({
            title: "loyalty.actionTitle",
            go: () => gui.dispatch("loyalty.open")
          })
        }

        if(this.isCafe && this.isNewReceipt && this.existsTips) {
          group.push({ title: "receipts.tips", go: () => gui.navigate("/receipt/tips") })
        }

        group.push({ title: "calculator.calculator", go: () => gui.calculator.value = true })

        return group
      },
      changeGroup() {
        const group = []

        if(this.isCafe && this.isNewReceipt) {
          group.push({ title: "receipts.splitOrder", go: () => gui.dispatch("split.startSplitting") })
        }

        if(this.isCafe && this.isNewReceipt) {
          group.push({ title: "tables.changeTable", go: () => gui.dispatch("tables.open", { change: true }) })
        }

        if(this.isNewReceipt) {
          group.push({ title: this.isCafe ? "receipts.changeWaiter" : "receipts.changeCashier", go: () => gui.dispatch("waiter.open") })
        }

        return group
      },
      printerGroup() {
        const group = []

        if(this.isCafe && this.isNewReceipt) {
          group.push({ title: "receipts.runner", go: () => gui.dispatch("runner.createOrderTicket", gui.receiptData.value) })
        }

        if(this.isCompletedRefund) {
          group.push({ title: "receipts.printRefund", go: () => gui.dispatch("refund.printCopy", gui.receiptData.value) })
        }

        if(this.isCompletedReceipt) {
          group.push({ title: "receipts.printReceipt", go: () => gui.dispatch("receipt.print", gui.receiptData.value, true) })
        }

        if(this.isCafe && this.isNewReceipt) {
          group.push({ title: "receipts.bill", go: () => gui.dispatch("receipt.bill") })
        }

        return group
      },
      footerGroup() {
        const group = []

        if(this.isNewRefund) {
          if(this.allSelected) {
            group.push({ title: "receipts.unselectAll", color: "green", go: () => gui.dispatch("refund.unselectAll") })
          } else {
            group.push({ title: "receipts.selectAll", color: "green", go: () => gui.dispatch("refund.selectAll") })
          }
        }

        if(!this.isCafe && this.isCompletedReceipt) {
          group.push({ title: "receipts.createReturn", color: "red", go: () => gui.dispatch("refund.createRefund") })
        }

        if(this.isNewReceipt) {
          group.push({ title: orderOrReceipt("receipts.cancelReceipt", "receipts.cancelOrder").value, color: "red", go: () => gui.dispatch("receipt.cancelReceipt") })
        }

        if(this.isRefund) {
          group.push({ title: "receipts.closeRefund", go: () => gui.navigateBack() })
        }

        return group
      },
      actions() {
        return [this.sumGroup, this.printerGroup, this.changeGroup].filter(group => {
          return group.length > 0
        })
      }
    }
  }
</script>
