<template>
  <teleport to="#app">
    <div class="actions-modal" :class="{ 'fade-in': shown, 'backdrop': backdrop }">
      <div v-tap="hide" class="actions-modal-backdrop"></div>
      <div class="actions-model-inner">
        <slot />
      </div>
    </div>
  </teleport>
</template>

<script>
  import { tap } from "@/js/touches"

  export default {
    directives: { tap },
    props: {
      modelValue: { type: Boolean, default: false },
      backdrop: { type: Boolean, default: true }
    },
    data() {
      return {
        shown: this.modelValue
      }
    },
    methods: {
      hide() {
        this.shown = false
        this.$emit("update:modelValue", this.shown)
      }
    },
    watch: {
      modelValue(value) {
        this.shown = value
      }
    }
  }
</script>
