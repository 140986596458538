import rpc from "./libs/rpc"

const UPDATE_INTERVAL = process.env.NODE_ENV === "production" ? 2000 : 30000

let isOnline = true

async function ping() {
  const response = await rpc("ping")
  return Boolean(response?.serverDate)
}

const setOnline = value => {
  isOnline = value
}

const startPingInterval = () => {
  ping().then(online => {
    isOnline = online
  })

  setInterval(async () => {
    isOnline = await ping()
  }, UPDATE_INTERVAL)
}

export {
  isOnline,
  setOnline,
  startPingInterval
}