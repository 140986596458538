<template>
  <Page>
    <Header>
      <div class="left">
        <f7-link @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">Not found</div>
    </Header>
    <Inner>
      <f7-navbar title="" back-link="Back"></f7-navbar>
      <f7-block strong>
        <p>Sorry</p>
        <p>Requested content not found.</p>
      </f7-block>
    </Inner>
  </Page>
</template>
