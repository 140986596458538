<template>
  <div class="printing-dialog" id="printing-dialog" :class="{ 'fade-in': printing.shown, 'fade-out': !printing.shown }">
    <div class="common-dialog-container" :class="{ 'flex-ends': printing.error, 'one-button': printing.error }">
      <div class="common-dialog-header">
        <div class="common-dialog-icon">
          <BigIcon class="animate__bounceIn" :loading="!printing.error" icon="printing" />
        </div>

        <div class="common-dialog-title">{{ printing.error ? $t("printError") : printing.title }}</div>
        <div v-if="printing.error" class="common-dialog-description error">{{ printing.error }}</div>
      </div>

      <div v-if="printing.error && printing.resolver" class="common-dialog-footer">
        <div class="common-dialog-button fill">
          <f7-button @tap="printing.resolver(true)" large round fill>{{ $t("ok") }}</f7-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from "vue"
  import BigIcon from "../BigIcon"

  export default {
    components: { BigIcon },
    setup() {
      const printing = ref(gui.printingProcess)

      watch(gui.printingProcess, value => {
        printing.value = value
      })

      return { printing }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .printing-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateY(100vh);
    background-color: $main-bg-color;
    z-index: 13499;
    transition: .5s;

    .common-dialog-title {
      font-size: 29px;
    }

    &.fade-out {
      .animate__bounceIn {
        animation-name: none !important;
      }
    }

    &.fade-in {
      visibility: visible;
      transform: translateY(0);
    }
  }
</style>
