<template>
  <div ref="container" class="accordion-item-content">
    <div ref="inner" class="accordion-item-content-inner">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      resetHeight() {
        const inner = this.$refs.inner
        const container = this.$refs.container

        if(inner && container) {
          const height = inner.offsetHeight
          container.style.setProperty("--accordion-item-height", height + "px")
        }
      }
    },
    updated() {
      this.resetHeight()
    },
    mounted() {
      this.resetHeight()
    }
  }
</script>