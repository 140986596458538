<template>
  <div @touchstart="dragStart" @touchend="dragStop" class="item-picker-container" :style="{ 'height': `${height}px` }">
    <div :style="{ marginTop: `-${middleHeight / 2}px`, height: `${middleHeight}px` }" class="item-picker-middle">
      <div class="item-picker-middle-inner"></div>
    </div>
    <div class="item-picker-items" :style="{ 'transform': `translateY(${styles.translateY}px)`, transition: this.styles.transition }">
      <div v-for="(item, index) of itemsList" :ref="el => setItemRef(el, item)" :key="item.key" :id="`item-picker-${index}`" class="item-picker-item" :style="{ 'height': `${itemHeight}px` }" :class="{ 'active': selected === item.key }">
        <div class="item-picker-inner">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { click } from "../js/audio"

export default {
  props: {
    modelValue: { type: String, default: null },
    items: { type: Array, default: () => [] },
    height: { type: Number, default: 120 },
    itemHeight: { type: Number, default: 35 },
    middleHeight: { type: Number, default: 40 },
    transition: { type: Number, default: 300 }
  },
  data() {
    return {
      itemsRefs: [],
      previous: this.modelValue,
      selected: this.modelValue,
      itemsList: this.items,
      styles: {
        translateY: 0,
        transition: `${this.transition}ms`
      },
      drag: {
        draggable: false,
        selected: null,
        startY: 0,
        startTranslateY: 0,
        startSelected: null,
        touchedAt: null
      }
    }
  },
  methods: {
    getItemIndex(key) {
      for(let index = 0; index < this.itemsList.length; index++) {
        if(this.itemsList[index].key === key) {
          return index
        }
      }

      return null
    },
    change(emit = true) {
      const index = this.getItemIndex(this.selected)
      if(index !== null) {
        this.styles.translateY = -(index * this.itemHeight) + (this.height / 2) - (this.itemHeight / 2)
      }

      if(emit) {
        this.$emit("update:modelValue", this.selected)
      }
    },
    clickItem(item) {
      this.selected = item.key
      this.change()
    },
    dragStart(event) {
      event.preventDefault()
      const { y } = this.getEvent(event)

      this.drag.draggable = true
      this.styles.transition = `none`

      this.drag.startY = y
      this.drag.startSelected = this.selected
      this.drag.startTranslateY = this.styles.translateY
      this.drag.touchedAt = Date.now()
    },
    dragStop(event) {
      if(Date.now() - this.drag.touchedAt < 100) {
        for(let item of this.itemsRefs) {
          if(event.target.closest(".item-picker-item") === item.el) {
            this.clickItem(item.item)
          }
        }
      }

      this.drag.draggable = false
      this.drag.startY = 0
      this.styles.transition = `${this.transition}ms`

      this.change()
      if(this.drag.startSelected !== this.selected) {
        this.$emit("change", this.selected)
      }
    },
    dragMove(event) {
      if(this.drag.draggable) {
        const { y } = this.getEvent(event)
        const maxTranslateY = this.height / 2 - (this.itemHeight / 2)
        const minTranslateY = ((this.itemsList.length * this.itemHeight) * -1) + (this.height / 2) + (this.itemHeight / 2)
        const difference = this.drag.startY - y

        let translateY = this.drag.startTranslateY - difference

        translateY = translateY > maxTranslateY ? maxTranslateY : translateY
        translateY = translateY < minTranslateY ? minTranslateY : translateY

        const index = Math.round(((this.height / 2) + (this.itemHeight / 2) - translateY) / this.itemHeight) - 1
        if(this.itemsList[index]) {
          this.selected = this.itemsList[index].key
          if(this.selected !== this.previous) {
            click()
          }

          this.previous = this.selected
        }

        this.styles.translateY = translateY
      }
    },
    getEvent(event) {
      const eventResponse = { y: null }

      if(event.touches && event.touches.length > 0) {
        eventResponse.y = event.touches[0].clientY
      }

      return eventResponse
    },
    setItemRef(el, item) {
      this.itemsRefs.push({ el, item })
    }
  },
  beforeUpdate() {
    this.itemsRefs = []
  },
  unmounted() {
    document.body.removeEventListener("touchmove", this.dragMove, false)
  },
  mounted() {
    this.change()
    document.body.addEventListener("touchmove", this.dragMove, false)
  },
  watch: {
    modelValue(key) {
      this.selected = key
      this.change(false)
    },
    items(value) {
      this.itemsList = value
      this.change(false)
    }
  }
}
</script>

<style lang="scss">
  @import "@/css/variables";

  .item-picker-container {
    overflow: hidden;
    position: relative;
    .item-picker-middle {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .item-picker-middle-inner {
        background-color: $second-bg-color;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        max-width: 224px;
      }
    }
    .item-picker-item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      transition: .3s;
      &.active {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
</style>
