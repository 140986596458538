<template>
  <div @touchstart="onTouchStart" @touchend="onTouchEnd" @touchcancel="onTouchEnd" @touchmove="onTouchMove" ref="drawer" class="drawer-layout">
    <div @click="clickBackdrop" class="drawer-backdrop" :style="backdropStyles"></div>
    <div class="drawer" ref="drawer-side" :style="styles">
      <slot name="drawer" />
    </div>
    <div ref="drawer-content" class="drawer-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
  import { watch } from "vue"
  import { getTouch } from "@/js/touches"

  export default {
    data() {
      return {
        width: 260,
        prevX: 0,
        startX: 0,
        startY: 0,
        translateX: 0,
        changedDeltaX: 0,
        startTranslateX: 0,
        directionX: null,
        isOpen: false,
        isTouch: false,
        isScrolling: false,
        animationTimer: null,
        backdropStyles: {},
        styles: {}
      }
    },
    methods: {
      onTouchStart(event) {
        this.isTouch = false
        this.isScrolling = false
        this.directionX = null

        if(!gui.drawerData.value.disabled) {
          const { x, y } = getTouch(event)

          this.changedDeltaX = 0
          this.prevX = x
          this.startX = x
          this.startY = y

          this.startTranslateX = x - this.translateX
        }
      },
      onTouchMove(event) {
        if(!gui.drawerData.value.disabled) {
          const touch = getTouch(event)
          const clientX = touch.x
          const deltaX = clientX - this.startTranslateX

          this.changedDeltaX = clientX - this.startX
          this.directionX = this.prevX > clientX ? "left" : "right"
          this.prevX = touch.x

          this.isTouch = !this.isScrolling && (this.isTouch || Boolean(Math.abs(touch.x - this.startX) > 20))
          this.isScrolling = !this.isTouch && (this.isScrolling || Boolean(Math.abs(touch.y - this.startY) > Math.abs(touch.x - this.startX)))

          if(this.isScrolling) {
            return false
          }

          this.translateX = deltaX

          if(this.translateX > this.width) {
            this.translateX = this.width
          } else if(this.translateX < 0) {
            this.translateX = 0
          }

          this.update()
        }
      },
      onTouchEnd() {
        if(!gui.drawerData.value.disabled && !this.isScrolling) {
          if(Math.abs(this.changedDeltaX) < 20) {
            this.reset()
          } else {
            if(this.directionX === "left") {
              this.hide()
            } else if(this.directionX === "right") {
              this.open()
            } else {
              this.hide()
            }
          }
        }
      },
      update() {
        const percent = this.translateX / this.width

        this.backdropStyles.opacity = percent
        this.backdropStyles.visibility = percent <= 0.001 ? "hidden" : "visible"

        this.styles.transform = `translateX(${this.translateX}px)`
      },
      reset(animate = 300) {
        if(this.isOpen) {
          this.open(animate)
        } else {
          this.hide(animate)
        }
      },
      open(animate = 300) {
        this.isOpen = true

        this.translateX = this.width
        this.animate(animate)
        this.update()
      },
      hide(animate = 300) {
        this.isOpen = false

        this.translateX = 0
        this.animate(animate, () => {
          this.hideAllAccordions()
        })
        this.update()
      },
      hideAllAccordions() {
        const side = this.$refs["drawer-side"]
        if(side) {
          for(let item of side.querySelectorAll(".accordion-item-opened")) {
            item.classList.remove("accordion-item-opened")
          }
        }
      },
      clickBackdrop() {
        this.hide()
      },
      animate(duration, callback) {
        this.backdropStyles.transition = `${duration}ms`
        this.styles.transition = `${duration}ms`

        if(this.animationTimer !== null) {
          clearTimeout(this.animationTimer)
        }

        this.animationTimer = setTimeout(() => {
          this.backdropStyles.transition = "none"
          this.styles.transition = "none"

          if(typeof callback === "function") {
            callback()
          }

          this.animationTimer = null
        }, duration)
      }
    },
    computed: {
      isVisible() {
        return this.translateX > 0.001
      }
    },
    mounted() {
      watch(gui.drawerData, value => {
        if(value.shown) {
          this.open()
        } else {
          this.hide()
        }
      })
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .drawer-layout {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .drawer-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, .3);
      z-index: 11;
      visibility: hidden;
    }

    .drawer {
      position: absolute;
      top: 0;
      left: -260px;
      width: 260px;
      height: 100%;
      background-color: $second-bg-color;
      z-index: 12;
    }

    .drawer-content {
      height: 100%;
    }
  }
</style>