import Handler from './handler'
import state from '../state'
import gui from '../gui'
import { isNewReceipt, isSelection, calculate, save } from "../actions/receipt"

class Tips extends Handler {
  async setTips({type, value}) {
    const isPercent = type === 'percent'
    const receiptData = state.receiptData
    const translate = {}

    if(!receiptData.tips) {
      return false
    }

    if (!isNewReceipt(receiptData)) {
      return false
    }

    receiptData.tips = {
      tipsEnabled: receiptData.tips.tipsEnabled,
      allowEnterTipsAmount: receiptData.tips.allowEnterTipsAmount,
      showPredefTipsEnabled: receiptData.tips.showPredefTipsEnabled,
      options: receiptData.tips.options,
      tipsIncludedByDefEnabled: receiptData.tips.tipsIncludedByDefEnabled,
      tipsIncludedByDefValue: receiptData.tips.tipsIncludedByDefValue
    }
    
    if (isPercent) {
      receiptData.tipsAmount = 0
      receiptData.tips.percent = value
      translate.key = 'receipts.tipsChanged'
      translate.args = { percent: value }
    } else {
      receiptData.tipsPercent = 0
      receiptData.tips.sum = value
      translate.key = 'receipts.tipsAmountChanged'
      translate.args = { sum: value }
    }

    await this.saveTips(receiptData)

    await gui.message(gui.translate(translate.key, translate.args))

    return true
  }

  async saveTips(receipt) {
    await calculate(receipt)
    save(receipt)

    gui.receiptData = receipt
  }

  async resetTips() {
    const receiptData = state.receiptData

    receiptData.tips.percent = null
    receiptData.tips.sum = null
    receiptData.tips = { ...receiptData.tips }
    receiptData.tipsAmount = 0
    receiptData.tipsPercent = 0

    await this.saveTips(receiptData)

    await gui.message(gui.translate("receipts.tipsReset"))

    return true
  }

	async createReceipt(doc) {
    const tips = state.user.store?.tips
    doc.tips = {
      tipsEnabled: tips?.tipsEnabled || false
    }
    
    if(doc.tips.tipsEnabled) {
      doc.tips.allowEnterTipsAmount = tips?.allowEnterTipsAmount || false,
      doc.tips.showPredefTipsEnabled = tips?.showPredefTipsEnabled || false,
      doc.tips.options = Array.isArray(tips?.showPredefTipsValue) ? tips?.showPredefTipsValue : []

      doc.tips.tipsIncludedByDefEnabled = tips?.tipsIncludedByDefEnabled || false
      doc.tips.tipsIncludedByDefValue = tips?.tipsIncludedByDefValue || null

      if (doc.tips.tipsIncludedByDefEnabled && doc.tips.tipsIncludedByDefValue) {
        doc.tips.percent = +doc.tips.tipsIncludedByDefValue
        if (!doc.tips.options.includes(doc.tips.percent)) {
          doc.tips.options.push(doc.tips.percent)
          doc.tips.options.sort((a,b) => a - b)
        }
      }
    }
	}

  async calc (doc) {
    if (!isNewReceipt(doc)) {
      return false
    }

    const selection = await isSelection(doc)

    doc.tipsPercent = selection ? null : (doc?.tips?.percent || null)
    doc.tipsAmount = selection ? null : (doc?.tips?.sum || null)
  }
}

export default new Tips()
