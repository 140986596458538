<template>
  <div class="calculator-dialog" id="calculator-dialog" :class="{ 'fade-in': calculator, 'fade-out': !calculator }">
    <Page schema="blue">
      <Header>
        <div class="left">
          <f7-link
            @tap="close"
            class="icon-only"
            id="calculator-back-link">
            <i class="icon mpi-back color-black"></i>
          </f7-link>
        </div>
        <div class="title">{{ $t("calculator.calculator") }}</div>
      </Header>
      <Inner>
        <div class="calculator-display">

          <div class="formula-wrap">
            <input
                @paste.prevent
                ref="formula"
                class='formula'
                id="calculator-formula"
                :value="displayedFormula"
                @input="onInput"
                @mouseup="onTextAreaClick"
                inputmode="none"
                @select="onTextAreaClick"
                @blur="onBlur"
            />
            <!-- <textarea
                @paste.prevent
                ref="formula"
                class='formula'
                id="calculator-formula"
                :value="displayedFormula"
                @input="onInput"
                @mouseup="onTextAreaClick"
                inputmode="none"
            ></textarea> -->
            <div class="focus-trap" ref="trap" tabindex="-1"/>
          </div>
          <div class="result-wrap" :style="{fontSize: `${fontSize}px`}" @mousedown.prevent>
            <div class="result" :class="{'error': error}" ref="result" id="calculator-result">
              {{ error ? $t('calculator.errorText') : result }}
            </div>
          </div>
        </div>
        <div class="calculator-keyboard">
          <div class="calculator-keyboard-row">
            <div class="calculator-keyboard-button dark">
              <f7-button @tap="clear" class="ripple-color-black reset" id="calculator-clear">C</f7-button>
            </div>
            <div class="calculator-keyboard-button dark">
              <f7-button @tap="press('\u00F7')" class="ripple-color-black" id="calculator-divide">&divide;</f7-button>
            </div>
            <div class="calculator-keyboard-button dark">
              <f7-button @tap="press('\u00D7')" class="ripple-color-black" id="calculator-times">&times;</f7-button>
            </div>
            <div class="calculator-keyboard-button dark">
              <f7-button @touchend="backspaceEnd" @touchcancel="backspaceEnd" @touchstart="backspaceStart" class="ripple-color-black"  id="calculator-delete">
                <div class="keyboard-backspace">
                  <img @touchstart.prevent src="@/static/imgs/backspace.svg" alt="Backspace">
                </div>
              </f7-button>
            </div>
          </div>
          <div class="calculator-keyboard-row">
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('1')" class="ripple-color-black" id="calculator-1">1</f7-button>
            </div>
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('2')" class="ripple-color-black" id="calculator-2">2</f7-button>
            </div>
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('3')" class="ripple-color-black" id="calculator-3">3</f7-button>
            </div>
            <div class="calculator-keyboard-button dark">
              <f7-button @tap="press('-')" class="ripple-color-black" id="calculator-minus">-</f7-button>
            </div>
          </div>
          <div class="calculator-keyboard-row">
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('4')" class="ripple-color-black" id="calculator-4">4</f7-button>
            </div>
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('5')" class="ripple-color-black" id="calculator-5">5</f7-button>
            </div>
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('6')" class="ripple-color-black" id="calculator-6">6</f7-button>
            </div>
            <div class="calculator-keyboard-button dark">
              <f7-button @tap="press('+')" class="ripple-color-black" id="calculator-plus">+</f7-button>
            </div>
          </div>
          <div class="calculator-keyboard-row">
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('7')" class="ripple-color-black" id="calculator-7">7</f7-button>
            </div>
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('8')" class="ripple-color-black" id="calculator-8">8</f7-button>
            </div>
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('9')" class="ripple-color-black" id="calculator-9">9</f7-button>
            </div>
            <div class="calculator-keyboard-button dark">
              <f7-button @tap="press(decimalSeparator)" class="ripple-color-black" id="calculator-separator">
                <div class="separator">
                  {{ decimalSeparator }}
                </div>
              </f7-button>
            </div>
          </div>
          <div class="calculator-keyboard-row">
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('0')" class="ripple-color-black" id="calculator-0">0</f7-button>
            </div>
            <div class="calculator-keyboard-button">
              <f7-button @tap="press('(')" class="ripple-color-black" id="calculator-left-parentheses">(</f7-button>
            </div>
            <div class="calculator-keyboard-button">
              <f7-button @tap="press(')')" class="ripple-color-black" id="calculator-right-parentheses">)</f7-button>
            </div>
            <div class="calculator-keyboard-button confirm">
              <f7-button @tap="confirm" class="ripple-color-black" id="calculator-confirm">Ok</f7-button>
            </div>
          </div>
        </div>
      </Inner>
    </Page>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue"
import { delay, getDecimalSeparator } from "../../js/helpers"
import { click } from "../../js/audio"

const operator = /[\u00D7\u00F7\+\-]/
const symbols = /[\u00D7\u00F7\+\(\)\-]/

//  \u00F7 = /
//  \u00D7 = *

export default {
  setup() {
    const calculator = ref(gui.calculator);
    const formula = ref('');
    const locale = ref(gui.i18n.locale);

    const formatter = computed( () => new Intl.NumberFormat(locale.value, {
      style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }));

    const decimalSeparator = computed(() => getDecimalSeparator(locale.value));

    watch(gui.calculator, value => {
      calculator.value = value;
      locale.value = gui.i18n.locale;
      if (value) setTimeout(()=>formula.value?.focus(), 500)
    });

    return { calculator, formula, formatter, locale, decimalSeparator }
  },
  data() {
    return {
      // isCalculationOver: false,
      displayedFormula: '',
      result: '',
      start: 0,
      end: 0,
      error: false,
      fontSize: 72,
      backspaceData: {
        touched: false,
        isLongClick: false
      }
    }
  },
  methods: {
    onInput() {
      this.$refs.trap.focus()
      this.$refs.formula.value = this.displayedFormula
      this.returnFocus()
    },
    onTextAreaClick() {
      this.start = this.$refs.formula.selectionStart
      this.end = this.$refs.formula.selectionEnd
      // this.isCalculationOver = false
    },
    confirm() {
      this.close();
      // this.error = !this.calcResult()

      // if(!this.error) this.isCalculationOver = this.result === '' ? false : true
    },
    press(value) {
      if(this.error) this.error = false

      // if(this.isCalculationOver) {
      //   this.displayedFormula = value
      //   this.start = value.length
      //   this.end = value.length
      //   this.result = ''
      //   this.isCalculationOver = false
      //   return
      // }

      if(this.start === 0 && this.end === 0) {
        //add only  (-\d
        if(!(/[)\+\u00D7\u00F7]/gi.test(value))) this.addChar(value)

        this.calcResult()
        this.returnFocus()
        return
      }

      if(this.start !== this.end) {
        this.deleteBetweenStartEnd()
        this.press(value)
        return
      }

      const leftPart = this.displayedFormula.slice(0, this.start)
      const rightPart = this.displayedFormula.slice(this.end, this.$refs.formula.length)

      //handle decimal separator duplicate
      if(/[\.\,]/.test(value) && /[\.\,]\d*$/.test(leftPart)) return

      //move caret outside operator spaces
      if(/\s$/.test(leftPart) && /^[\u00D7\u00F7\+\-]/.test(rightPart)) {
        this.end = this.start -= 1
        this.press(value)
        return
      }
      if(/[\u00D7\u00F7\+\-]$/.test(leftPart) && /^\s/.test(rightPart)) {
        this.end = this.start += 1
        this.press(value)
        return
      }

      const prevChar = this.displayedFormula.slice(this.start - 1, this.start)

      if(prevChar === '(' && !/[\d\-\.\,]/.test(value)) return

      if(/\s/.test(prevChar)) {

        const prevPrevChar = this.displayedFormula.slice(this.start - 2, this.start - 1)

        //if operator and operator
        //if value '-' - insert
        if(operator.test(prevPrevChar) && operator.test(value) && value === '-') {
          this.addChar(` ${ value } `)
          return
        }
        //else -> replace
        if(operator.test(prevPrevChar) && operator.test(value)) {
          this.replaceChar(value, this.start - 2)
          return
        }
      }

      if(operator.test(prevChar) && operator.test(value)) {

        if(value === '-' && /[\u00D7\u00F7]/.test(prevChar)) {
          this.addChar(` ${ value } `)
          return
        }

        this.replaceChar(value, this.start - 1)
        return
      }

      this.addChar(operator.test(value) ? ` ${ value } ` : value)

      this.calcResult()
    },
    addChar(char) {
      this.$refs.trap.focus()

      this.displayedFormula = this.displayedFormula.slice(0, this.start) + char + this.displayedFormula.slice(this.end, this.$refs.formula.length)
      this.end = this.start += char.length

      this.$refs.formula.scrollLeft = this.$refs.formula.scrollWidth;;

      this.returnFocus()

    },
    replaceChar(char, start) {

      this.$refs.trap.focus()

      this.displayedFormula = this.displayedFormula.slice(0, start) + char + this.displayedFormula.slice(start + 1, this.$refs.formula.length)

      this.returnFocus()
    },
    pressDel() {
      this.del()
      if(this.start === 0) this.normalizeStart()
      if(this.start !== 0 && this.start === this.$refs.formula.length) this.normalizeEnd()

      this.returnFocus()
      if(symbols.test(this.displayedFormula)) this.calcResult()
    },
    del() {
      if(this.start === 0 && this.end === 0) {
        return
      }

      if(this.error) this.error = false

      this.$refs.trap.focus()

      //start !== end
      if(this.start !== this.end) {
        this.deleteBetweenStartEnd()

        const leftPart = this.displayedFormula.slice(0, this.start)
        const rightPart = this.displayedFormula.slice(this.end, this.$refs.formula.length)

        //add spaces to operators
        if(/[\u00D7\u00F7\+\-]$/.test(leftPart) && /^[\u00D7\u00F7\+\-]/.test(rightPart)) {
          this.displayedFormula = leftPart + "  " + rightPart
          this.end = this.start += 1
          return
        }
        if(/[\u00D7\u00F7\+\-]$/.test(leftPart) && /^[^\s]/.test(rightPart)) {
          this.displayedFormula = leftPart + " " + rightPart
          this.end = this.start += 1
          return
        }
        if(/[^\s]$/.test(leftPart) && /^[\u00D7\u00F7\+\-]/.test(rightPart)) {
          this.displayedFormula = leftPart + " " + rightPart
          return
        }

        //clear extra spaces
        //notOperator space caret space notOperator
        if(/[^\u00D7\u00F7\+\-]\s$/.test(leftPart) && /^\s[^\u00D7\u00F7\+\-]/.test(rightPart)) {
          this.start -= 1
          this.end += 1
          this.deleteBetweenStartEnd()
          return
        }
        //notOperator space caret space
        if(/[^\u00D7\u00F7\+\-]\s$/.test(leftPart) && /^\s/.test(rightPart)) {
          this.start -= 1
          this.deleteBetweenStartEnd()
          return
        }
        //space caret space notOperator
        if(/\s$/.test(leftPart) && /^\s[^\u00D7\u00F7\+\-]/.test(rightPart)) {
          this.end += 1
          this.deleteBetweenStartEnd()
          return
        }//********
         //notOperator space caret notSpace
        if(/[^\u00D7\u00F7\+\-]\s$/.test(leftPart) && /^[^\s]/.test(rightPart)) {
          this.end -= 1
          this.deleteBetweenStartEnd()
          return
        }
        //notSpace caret space notOperator
        if(/[^\s]$/.test(leftPart) && /^\s[^\u00D7\u00F7\+\-]/.test(rightPart)) {
          this.end += 1
          this.deleteBetweenStartEnd()
          return
        }
        //operator caret space operator
        if(/[\u00D7\u00F7\+\-]$/.test(leftPart) && /^\s[\u00D7\u00F7\+\-]/.test(rightPart)) {
          this.displayedFormula = leftPart + " " + rightPart
          this.end = this.start += 1
          return
        }
        //operator space caret operator
        if(/[^[\u00D7\u00F7\+\-]\s]$/.test(leftPart) && /^[\u00D7\u00F7\+\-]/.test(rightPart)) {
          this.displayedFormula = leftPart + " " + rightPart
          return
        }

        return
      }

      //start === end
      const leftPart = this.displayedFormula.slice(0, this.start)

      if(/\s[\u00D7\u00F7\+\-]\s$/.test(leftPart)) {
        //delete left -3 char
        this.start -= 3
        this.deleteBetweenStartEnd()

        this.returnFocus()
        return
      }
      if(/\s[\u00D7\u00F7\+\-]$/.test(leftPart)) {
        //delete left -2 and right -1 char
        this.start -= 2
        this.end += 1
        this.deleteBetweenStartEnd()

        this.returnFocus()
        return
      }
      if(/\s$/.test(leftPart)) {
        //move caret -1
        this.end = this.start -= 1
        this.returnFocus()
        return
      }

      this.start -= 1
      this.deleteBetweenStartEnd()

      this.returnFocus()

      this.calcResult()
    },
    deleteBetweenStartEnd() {
      this.displayedFormula = this.displayedFormula.slice(0, this.start) + this.displayedFormula.slice(this.end, this.$refs.formula.length)
      this.end = this.start
    },
    normalizeStart() {
      //extra space
      if(/^\s[^\u00D7\u00F7\+\-]/.test(this.displayedFormula)) {
        this.displayedFormula = this.displayedFormula.replace(/^\s/, '')
      }
      //operator without space
      if(/^[\u00D7\u00F7\+\-]/.test(this.displayedFormula)) {
        this.displayedFormula = ' ' + this.displayedFormula
        this.end = this.start = 0
      }
    },
    normalizeEnd() {
      //extra space
      if(/[^\u00D7\u00F7\+\-]\s$/.test(this.displayedFormula)) {
        this.displayedFormula = this.displayedFormula.replace(/\s$/, '')
        this.end = this.start -= 1
      }
      //operator without space
      if(/[\u00D7\u00F7\+\-]$/.test(this.displayedFormula)) {
        this.displayedFormula = this.displayedFormula + ' '
        this.end = this.start += 1
      }
    },
    clear() {
      this.start = 0
      this.end = 0
      this.error = false
      this.displayedFormula = ''
      this.result = ''
      // this.isCalculationOver = false

      this.returnFocus()
    },
    calcResult() {
      try {
        //clear spaces
        let str = this.displayedFormula.replace(/\s/gi, '')

        // fix numbers started with 0 without separator
        str = str.replace(/(?<![\d\.\,])0+(?=\d)/g, '')

        //replace \u00F7 -> / and \u00D7 -> *
        str = str.replace(/[\u00D7]+/g, '*')
        str = str.replace(/[\u00F7]/g, '/')

        //add * between \d(  and  )\d
        str = str.replace(/\)(?=[\d\.,])/g, ')*')
        str = str.replace(/(?<=[\d\.,])\(/g, '*(')

        //replace , => .
        str = this.decimalSeparator === ',' ? str.replace(/,/g, '.') : str


        //clear last operator
        str = str.replace(/[\*\/\+\(\-]$/, '')

        let result = eval(str)

        result = Number.parseFloat(result.toFixed(2))

        const length = result.toString().length


        this.fontSize = length <= 7 && result < 1e+7 ? 72 : length <= 10 && result < 1e+20 ? 48 : 36

        if(length < 14 && result < 1e+14) {
          this.result = this.formatter.format(result)
        } else if(result >= Number(1e+21)) {
          result = result.toString().replace(/(?<=\.\d{9})\d+(?=e\+\d+)/, "");
          this.result = result.replace(/\./, this.decimalSeparator)
        } else {
          this.result = Number(result).toExponential(9).replace(/\./, this.decimalSeparator)
        }

        this.$refs.result.scrollLeft = 0

        return true
      } catch (e) {
        this.result = ''
        return false
      }
    },
    returnFocus() {
      setTimeout(() => {
        this.$refs.formula.setSelectionRange(this.start, this.end);
        this.$refs.formula.focus();
      }, 0)
    },
    onBlur() {
       const scrollLeft = this.$refs.formula.scrollLeft;

      setTimeout(() => {
        this.$refs.formula.scrollLeft = scrollLeft;
      }, 0)
    },
    async backspaceStart() {
      if(this.backspaceData.touched) {
        return false;
      }

      this.backspaceData.touched = true;
      this.backspaceData.isLongClick = false;

      // waiting for long click
      await delay(200);
      if(this.backspaceData.touched) {
        this.backspaceData.isLongClick = true;

        while (this.displayedFormula != "" && this.backspaceData.touched) {
          this.pressDel();
          await delay(100);

          click()
        }
      }
    },
    backspaceEnd() {
      this.backspaceData.touched = false;
      if(!this.backspaceData.isLongClick) {
        this.pressDel();
      }
    },
    close() {
      gui.calculator.value = false;
      this.clear();
    }
  }
}
</script>

<style lang="scss">
@import "@/css/variables";

.calculator-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  transform: translateY(100vh);
  background-color: $main-bg-color;
  z-index: 13499;
  transition: .3s;

  &.fade-out {
    .animate__bounceIn {
      animation-name: none !important;
    }
  }

  &.fade-in {
    visibility: visible;
    transform: translateY(0);
  }

  .page.page-custom .page-header .page-header-height {
    border-radius: 0px;
  }
}

.calculator-display {
  display: flex;
  flex-direction: column;
  background-color: #22272C;
  height: calc(100% - 330px);
  color: #FFFFFF;
  justify-content: center;

  .result-wrap {
    font-weight: 300;
    font-size: 72px;
    line-height: 86px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 93px;
    box-sizing: border-box;
    overflow: hidden;

    .result {
      padding: 0 20px 0 10px;
      overflow-x: auto;
      &.error {
        font-size: 36px !important;
        line-height: 118.75%;
        color: $error-color;
        padding-right: 20px;
      }
    }
  }

  .formula-wrap {
    display: flex;

    .formula {
      font-weight: 300;
      font-size: 32px;
      line-height: 118.75%;
      height: 100%;
      width: 100%;
      text-align: right;
      border: none;
      background: none;
      color: #FFFFFF;
      padding-right: 20px;
    }

    textarea::-moz-selection {
      color: black;
      background-color: #ccdae4 !important;
    }

    textarea::selection {
      color: black;
      background-color: #ccdae4 !important;
    }
  }
}

.calculator-keyboard {
  height: 330px;
  display: flex;
  flex-wrap: wrap;

  .calculator-keyboard-row {
    display: flex;
    width: 100%;

    .calculator-keyboard-button {
      background-color: $input-color;
      flex: 1;

      &.confirm {
        display: flex;
        justify-content: center;
        align-items: center;
        .button {
          background-color: $primary-color;
          color: #FFFFFF;
          font-size: 24px;
          width: 100%;
          border-radius: 0;
        }
      }

      .button {
        height: 100%;
        color: $text-color;
        font-size: 32px;
        font-weight: 600;
        text-transform: none;

        i.icon {
          font-weight: 600;
        }
      }
      .keyboard-backspace img {
        height: 20px;
      }

      &.dark {
        background-color: $second-bg-color;
      }

      .separator {
        height: calc(100% - 25px);
        align-self: normal;
      }

      .reset {
        font-size: 28px;
      }

    }
  }
}

.focus-trap {
  height: 0px;
  width: 1px;
  margin-left: -1px;
  display: inline-block;
}
</style>
