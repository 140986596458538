<template>
  <f7-ripple ref="item" @tap="click" tag="li" class="list-item" :class="{ 'accordion-item': accordionItem }" :hidden="true" :disabled="!clickable || accordionItem">
    <div v-if="$slots.inner" class="item-link">
      <f7-ripple @tap="clickContent" class="item-content" :disabled="!clickable || !accordionItem">
        <div class="item-inner">
          <slot name="inner" />
        </div>
      </f7-ripple>
    </div>
    <slot />
  </f7-ripple>
</template>

<script>
  import { click } from "@/js/audio"

  export default {
    emits: ["tap"],
    props: {
      accordionItem: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      clickable: { type: Boolean, default: false }
    },
    methods: {
      clickContent(event) {
        if(!this.accordionItem) {
          return false
        }

        if(this.disabled || !this.clickable) {
          return false
        }

        click()

        this.$emit("tap", event)

        const isOpen = this.$refs.item.$el.classList.contains("accordion-item-opened")

        this.$parent?.hideAllAccordions()
        if(!isOpen) {
          this.$refs.item.$el.classList.add("accordion-item-opened")
        }
      },
      click(event) {
        if(this.disabled || !this.clickable || this.accordionItem) {
          event.preventDefault()
          return false
        }

        click()

        this.$emit("tap", event)
      }
    }
  }
</script>
