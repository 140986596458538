<template>
  <Page
      ref="page"
      schema="blue"
      id="category-page"
      @page:beforeout="onPageBeforeOut"
      @page:beforein="onPageBeforeIn">
    <!-- Header -->
    <Header
      ref="header"
      v-model="searchingValue"
      @search:update="search"
      @search:enable="enableSearching"
      @search:disable="disableSearching"
      search-id="category-search-field"
      :search-hide-outside="false"
      :search-placeholder="`${$t('receipts.searchIn')} ${category.name}`">
      <div class="left">
        <f7-link @tap="openCategory({ _id: category.parentId })" id="subcategories-back-link" class="icon-only" icon-color="black">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ category.name }}</div>
      <div class="right">
        <f7-link
          v-if="isSearchAvailable"
          id="subcategories-searchbar-link"
          @tap="openSearchBar"
          class="icon-only">
          <i class="icon mpi-search color-black"></i>
        </f7-link>
      </div>
    </Header>

    <!-- Page content -->
    <Inner>
      <Categories @category-click="openCategory" :category="category" />
    </Inner>
  </Page>
</template>

<script>
  import { computed, ref } from "vue"
  import { debounce } from "lodash"

  import Categories from "../../components/Receipt/Categories"

  export default {
    components: { Categories },
    setup() {
      const page = ref(null)
      const header = ref(null)

      const receipt = ref(gui.receiptData.value)
      const searchingValue = ref("")
      const uploading = ref(false)

      const search = () => {
        gui.dispatch("favourites.filterCategory", { filter: searchingValue.value })
      }

      const uploadCategory = async () => {
        uploading.value = true
        await gui.dispatch("favourites.uploadCategory")
        uploading.value = false
      }

      // Barcode scanner
      gui.onScanned.value = barcode => gui.dispatch("receipt.scannedBarcode", { barcode })

      const onScrollEnd = async () => {
        if(!uploading.value && gui.category.value.total > gui.category.value.goods.length) {
          await uploadCategory()
        }
      }

      const scrollTop = () => {
        page.value.scrollTo(0)
      }

      return {
        page,
        header,
        receipt,
        search: debounce(search, 300),
        uploadCategory,
        onScrollEnd,
        searchingValue,
        category: gui.category,
        disableSearching: () => {
          scrollTop()
          searchingValue.value = ""
          search()
        },
        enableSearching: () => {
          scrollTop()
        },
        isSearchAvailable: computed(() => {
          return gui.category.value.children.length === 0
        }),
        openSearchBar: () => {
          searchingValue.value = ""
          header.value.enableSearching()
        },
        openCategory: category => gui.dispatch("favourites.openCategory", { id: category._id }),
        onPageBeforeOut: () => {
          gui.totalSheet.value.hide()
        },
        onPageBeforeIn: () => {
          gui.totalSheet.value.show()
        }
      }
    }
  }
</script>
