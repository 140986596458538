import * as moment from "moment"

import state from "../state"
import { round2 } from "../libs/util"

export function printLogo(rowLength = 32) {
  if(state.cache.logo) {
    const width = rowLength > 40 ? 200 : 150
    return `:ALIGNCENTER::IMAGE:${width}:${state.cache.logo}\n:ALIGNLEFT:`
  }

  return ""
}

export function dateNow() {
  return Date.now()
}

function getOptionsDetails(data) {
  const isPrimitive = typeof data === "number" || typeof data === "string"
  const options = isPrimitive ? {} : data
  let rowLength = isPrimitive ? Number(data) : 32

  if(!isPrimitive && data.rowLength) {
    rowLength = Number(data.rowLength)
  }

  return { options, rowLength }
}

export function dateFormat(date) {
  return moment(new Date(date)).format("MMM DD, YYYY")
}

export function dateTimeFormat(date) {
  return moment(new Date(date)).format("MMM DD, YYYY h:mmA")
}

export function timeFormat(date) {
  return moment(new Date(date)).format("h:mmA")
}

export function centerText(text, data = 32) {
  if(!text) {
    return ""
  }

  let { rowLength, options } = getOptionsDetails(data)
  rowLength = options.doubleText ? Math.floor(rowLength / 2) : rowLength

  const chunkedText = chunkText(text, rowLength)
  const result = chunkedText.split("\n").map(row => centerString(row, rowLength)).join("\n")

  if(options.doubleText) {
    return doubleText(result)
  }

  return result
}

export function doubleText(text) {
  return `:DOUBLE:${text}:NODOUBLE:`
}

export function paddingLeft(text, paddings = 2) {
  if(paddings > 0) {
    return " ".repeat(paddings) + text
  }

  return text
}

export function bold(text) {
  return `:BOLD:${text}:NOBOLD:`
}

export function doubleLine(options = {}, data = 32) {
  let { rowLength, options: optionsParams } = getOptionsDetails(data)

  let rightPadding = 2
  let optimalLeftLength = 14

  if(optionsParams.doubleText) {
    rowLength = Math.floor(rowLength / 2)
    rightPadding = Math.floor(rightPadding / 2)
    optimalLeftLength = Math.floor(optimalLeftLength / 2)
  }

  let left = options.left.length > optimalLeftLength ? options.left.slice(0, optimalLeftLength).trim() : options.left.trim()
  const rightLength = rowLength - left.length
  let right = options.right.slice(0, rightLength - rightPadding).trim().padStart(rightLength, " ")

  if(options.boldLeft) {
    left = bold(left)
  }

  if(options.boldRight) {
    right = bold(right)
  }

  const result = left + right

  if(optionsParams.doubleText) {
    return doubleText(result)
  }

  return result
}

export function priceLine(options = {}, rowLength = 32) {
  const WORD_BREAK = options.worldBreak
  let chunkedName = null

  const priceLength = 9
  const countLength = options.count ? 5 : 0
  const nameLength = rowLength - priceLength - countLength
  const paddingLeftLength = options.paddingLeft ? 2 : 0

  const priceValue = String(isNaN(+options.price) ? options.price : (+options.price).toFixed(2))

  if(WORD_BREAK) {
    chunkedName = chunkText(options.name, nameLength - paddingLeftLength).split("\n")
    options.name = paddingLeft((chunkedName[0] || "").trim(), paddingLeftLength)
  } else {
    options.name = paddingLeft((options.name || "").slice(0, nameLength - paddingLeftLength - 1).trim(), paddingLeftLength)
  }

  let name = options.name.padEnd(nameLength, " ")
  let count = options.count ? String(options.count).padStart(countLength) : ""
  let price = priceValue.padStart(priceLength)

  if(options.boldName) {
    name = bold(name)
  }

  if(options.boldCount) {
    count = bold(count)
  }

  if(options.boldPrice) {
    price = bold(price)
  }

  let result = [name, count, price].join("") + "\n"

  if(Array.isArray(chunkedName) && chunkedName.length > 1) {
    for(let nameRow of chunkedName.slice(1)) {
      result += paddingLeft(nameRow, paddingLeftLength) + "\n"
    }
  }

  return result.trimEnd()
}

export function printTotal(sum, data = 32) {
  let { rowLength, options } = getOptionsDetails(data)

  return doubleLine({
    left: options.title || "Total:",
    right: sum.toFixed(2)
  }, { rowLength, doubleText: true })
}

export function printLine(rowLength = 32) {
  return "- ".repeat(rowLength / 2).trim()
}

export function printPieceLine() {
  return centerString(printSolidLine(15))
}

export function printSolidLine(rowLength = 32) {
  return printFullLine("_", rowLength)
}

export function printFullLine(char = '-', rowLength = 32) {
  return char.repeat(rowLength).trim()
}

export function breakLine() {
  return ":BREAKLINE:"
}

export function chunkText(text, data = 32) {
  let { rowLength, options } = getOptionsDetails(data)
  rowLength = options.doubleText ? Math.floor(rowLength / 2) : rowLength

  const OPERATION_REGEXP = /(:[A-Z0-9]+:)/g
  const SPECIAL_CHAR = String.fromCharCode(0)

  // Get operations list
  const operations = text.match(OPERATION_REGEXP)

  // Replace operation for special char (label)
  const preparedText = text.replace(OPERATION_REGEXP, SPECIAL_CHAR)

  const result = []
  let buffer = ""

  for(let i = 0; i < preparedText.length; i++) {
    const char = preparedText[i]
    const nextChar = preparedText[i + 1] || null
    const isEnd = nextChar === null

    const future = buffer + char
    const isSpace = char === " "
    const isFullWord = isSpace || nextChar === " " || isEnd
    const futureLength = future.replace(new RegExp(SPECIAL_CHAR, "g"), "").length

    if(isEnd) {
      // End of text
      result.push(future)
      break
    } else if(futureLength >= rowLength) {
      // If buffer is full
      if(isFullWord) {
        result.push(future)
        buffer = ""
      } else {
        if(buffer.indexOf(" ") >= 0) {
          // If length of slice words bigger than rowLength - cut last word from buffer
          const currentWords = buffer.split(" ")
          result.push(currentWords.slice(0, -1).join(" "))
          buffer = currentWords.slice(-1)[0] + char
        } else {
          // If word bigger than rowLength
          result.push(buffer.slice(0, rowLength) + char)
          buffer = ""
        }
      }
    } else {
      buffer = future
    }
  }

  let resultText = result.map(row => row.trim()).join("\n")

  // Recovery operations to their places
  if(Array.isArray(operations)) {
    for(let operation of operations) {
      resultText = resultText.replace(SPECIAL_CHAR, operation)
    }
  }

  if(options.doubleText) {
    return doubleText(resultText)
  }

  return resultText
}

function centerString(text, rowLength = 32) {
  const textLength = text.replace(/:[A-Z]+:/g, "").length
  const leftPadding = Math.floor((rowLength - textLength) / 2)

  if(leftPadding > 0) {
    return " ".repeat(leftPadding) + text
  }

  return text
}

export function printTipsLevelAmount(options = {}, rowLength = 32) {
  const sum = round2(options.amount * (+options.percent / 100));
  return priceLine({ name: `${options.percent}%`, price: sum, paddingLeft: true }, rowLength)
}

export function printPhone(phone) {
  if(!phone) {
    return ""
  }

  phone = String(phone).replace(/\D/gi,"");
  return phone.replace(/(^\d{1,3})(\d{3})(\d{3})(\d{4}$)/, "+$1 $2-$3-$4");
}

export function uppreCase(str) {
  return str.toUpperCase();
}

export function fixed2(val) {
  return String(isNaN(+val) ? val : (+val).toFixed(2))
}


