<template>
  <div class="payment-dialog" id="payment-dialog" :class="{ 'fade-in': payment.shown, 'fade-out': !payment.shown }">
    <div class="common-dialog-container flex-ends" :class="{ 'one-button': payment.state !== 'error' }">
      <div class="common-dialog-header">
        <div class="common-dialog-icon">
          <BigIcon class="animate__bounceIn" :state="payment.state" :loading="payment.state === 'active'" icon="card" />
        </div>

        <!-- Title -->
        <div v-if="payment.state === 'success'" class="common-dialog-title">
          <span v-if="payment.type === 'payment'">{{ $t("checkout.successTitle") }}</span>
          <span v-else-if="payment.type === 'refund'">{{ $t("checkout.returnSuccessTitle") }}</span>
          <span v-else>{{ $t("checkout.operationSuccessTitle") }}</span>
        </div>
        <div v-else-if="payment.state === 'error'" class="common-dialog-title">
          <span v-if="payment.type === 'payment'">{{ $t("checkout.paymentFailed") }}</span>
          <span v-else-if="payment.type === 'refund'">{{ $t("checkout.refundFailed") }}</span>
          <span v-else>{{ $t("checkout.operationFailed") }}</span>
        </div>
        <div v-else class="common-dialog-title">{{ payment.title }}</div>

        <!-- Description -->
        <div v-if="payment.state === 'error' && payment.error" class="common-dialog-description error">{{ payment.error }}</div>
        <div v-else-if="payment.state === 'active' && payment.message" class="common-dialog-description">{{ payment.message }}</div>

        <!-- Sum -->
        <template v-if="payment.state !== 'error'">
          <div v-if="payment.tips > 0 && payment.type === 'payment'" class="checkout-result-details">
            <div class="checkout-result-detail-item">
              <div class="checkout-result-detail-item-left">{{ $t("receipts.tips") }}</div>
              <div class="checkout-result-detail-item-value">${{ $filters.sumFixed(payment.tips) }}</div>
            </div>
            <div class="checkout-result-detail-item">
              <div class="checkout-result-detail-item-left">{{ $t("checkout.amount") }}</div>
              <div class="checkout-result-detail-item-value">${{ $filters.sumFixed(payment.sum) }}</div>
            </div>
            <div class="checkout-result-detail-item bold">
              <div class="checkout-result-detail-item-left">{{ $t("checkout.total") }}</div>
              <div class="checkout-result-detail-item-value">${{ $filters.sumFixed(payment.total) }}</div>
            </div>
          </div>
          <div v-else class="payment-dialog-sum-container">
            <div class="payment-dialog-sum-label">
              <span v-if="payment.type === 'payment'">{{ $t("checkout.amountToPay") }}</span>
              <span v-else-if="payment.type === 'refund'">{{ $t("checkout.amountToRefund") }}</span>
              <span v-else>{{ $t("receipts.total") }}</span>
            </div>
            <div class="payment-dialog-sum-value">${{ $filters.sumFixed(payment.total) }}</div>
          </div>
        </template>
      </div>

      <div class="common-dialog-footer">
        <template v-if="payment.state === 'error'">
          <div class="common-dialog-button fill">
            <f7-button id="payment-dialog-retry" @tap="tryAgain" large round fill>{{$t("checkout.tryAgain")}}</f7-button>
          </div>
          <div class="common-dialog-button">
            <f7-button id="payment-dialog-cancel" :disabled="!payment.canCancel" @tap="cancel" large round>{{$t("receipts.cancel")}}</f7-button>
          </div>
        </template>
        <template v-else>
          <div class="common-dialog-button fill">
            <f7-button id="payment-dialog-cancel" :disabled="!payment.canCancel" @tap="cancel" large round fill>{{$t("receipts.cancel")}}</f7-button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from "vue"
  import BigIcon from "../BigIcon"

  export default {
    components: { BigIcon },
    setup() {
      const payment = ref(gui.paymentProcessData)

      watch(gui.paymentProcessData, value => {
        payment.value = value
      })

      return {
        payment,
        cancel: () => gui.dispatch("terminal.cancel"),
        tryAgain: () => gui.dispatch("terminal.tryAgain")
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .payment-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateY(100vh);
    background-color: $main-bg-color;
    z-index: 13499;
    transition: .3s;

    .checkout-result-details {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .common-dialog-header {
      flex: 1;
      display: flex;
      flex-direction: column;
      .payment-dialog-sum-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0;
        color: $text-color;
      }
    }

    &.fade-out {
      .animate__bounceIn {
        animation-name: none !important;
      }
    }

    &.fade-in {
      visibility: visible;
      transform: translateY(0);
    }

    .common-dialog-description {
      min-height: 48px;
    }

    .payment-dialog-sum-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 25px;
      .payment-dialog-sum-label {
        font-size: 16px;
      }
      .payment-dialog-sum-value {
        font-size: 26px;
        font-weight: bold;
      }
    }
  }
</style>
