<template>
  <Page schema="transparent" class="full-report-page" id="full-report-page" @page:beforeout="beforeout">
    <Header>
      <div class="left">
        <f7-link id="full-report-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t("shifts.fullReport") }}</div>
    </Header>

    <Inner>
      <template v-if="isPageEmpty">
        <div class="category-empty">
          <div class="category-empty-text">
            {{ $t("shifts.shiftsIsMissing") }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="full-report-page-container">
          <div v-if="!fullReport" class="title-block">
            <div class="title-block-title">{{ $t('shifts.availableDates') }}</div>
            <div class="title-block-dates">
              {{ `${ availableDates.from } - ${ availableDates.to }` }}
            </div>
            <div class="title-block-notice">
              {{ $t('shifts.fullReportNotice') }}
            </div>
          </div>
          <div class="dates-block" :class="{ details: !fullReport }">
            <DatesBlock
                v-model:from="dateFrom"
                v-model:to="dateTo"
                id="fullreport-date"
                :error="dateRangeError"
            />
          </div>
          <div v-if="dateRangeError" class="date-error">
            {{ dateRangeError.msg }}
          </div>
          <div v-if="fullReport" class="details-block">
            <ShiftDetails :shift="fullReport"/>
          </div>
        </div>
      </template>
    </Inner>

    <!--  Footer  -->
    <Footer>
      <div class="footer-buttons">
        <f7-button v-if="!fullReport" class="fill" :disabled="!disableConfirm" @tap="createFullReport">
          {{ $t("shifts.confirm") }}
        </f7-button>
        <f7-button v-else class="fill" @tap="printFullReport">
          {{ $t("shifts.print") }}
        </f7-button>
      </div>
    </Footer>
  </Page>
</template>

<script>
import ShiftDetails from "../../components/ShiftsPage/ShiftDetails"
import DatePicker from "../../components/DatePicker"
import DatesBlock from "../../components/ShiftsPage/DatesBlock.vue"
import { ref, computed, watch } from "vue"
import { date as formatDate } from '../../js/filters';

export default {
  components: { ShiftDetails, DatePicker, DatesBlock },
  setup() {
    const fullReport = ref(gui.fullReport)
    const dateFrom = ref("")
    const dateTo = ref("")
    const dateRangeError = ref(null)

    const disableConfirm = computed(
        () =>
            dateFrom.value !== "" && dateTo.value !== "" && !dateRangeError.value
    )

    watch(gui.fullReport, (value) => (fullReport.value = value))

    watch(
        [dateFrom, dateTo],
        ([dateFrom, dateTo], [prevDateFrom, prevDateTo]) => {
          if(
              prevDateFrom !== "" &&
              prevDateTo !== "" &&
              (dateFrom !== prevDateFrom || dateTo !== prevDateTo)
          ) {
            gui.dispatch("docs.clearFullReport")
          }

          if(dateFrom !== "" && dateTo !== "") {
            if(
                dateFrom > dateTo ||
                gui.fullReportDates.value.from > dateTo + 24 * 60 * 60 * 1000 - 1 ||
                gui.fullReportDates.value.to < dateFrom
            ) {
              dateRangeError.value = { msg: "Invalid dates range" }
            } else {
              dateRangeError.value = null
            }
          }
        }
    )

    const isPageEmpty = computed(
        () =>
            gui.fullReportDates.value.from === null &&
            gui.fullReportDates.value.from === null
    )

    const availableDates = computed(() => ({
      from: formatDate(gui.fullReportDates.value.from, { dateShort: true }),
      to: formatDate(gui.fullReportDates.value.to, { dateShort: true }),
    }))

    return {
      isPageEmpty,
      dateRangeError,
      fullReport,
      dateFrom,
      dateTo,
      disableConfirm,
      availableDates,
      createFullReport: async () => {
        await gui.dispatch("docs.getFullReport", dateFrom.value, dateTo.value)
      },
      beforeout: () => gui.dispatch("docs.clearFullReport"),
      printFullReport: () => gui.dispatch("shift.printFullReport"),
    }
  },
}
</script>

<style lang="scss">
@import "@/css/variables";

.full-report-page.page.page-custom .page-header .page-header-height {
  box-shadow: none;
}

.full-report-page {

  .dates {
    width: 100% !important;
  }

  .date-error {
    padding: 0 $padding-to-title !important;
    padding-top: $minimal-padding / 2 !important;
    text-align: left !important;
  }

  .page-content {
    .full-report-page-container {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      padding: $padding-arrow 0;
      box-sizing: border-box;
    }

    .title-block {
      padding-left: $padding-to-title;
      text-align: left;
      margin-bottom: 25px;
      .title-block-dates {
        margin-bottom: 5px;
      }
    }

    .dates-block.details {
      padding: 0;
      width: 100%;

      padding: 0 $padding-to-title;
      box-sizing: border-box;

      .dates {
        width: 100%;
      }

      .dates-title {
        padding-left: 0;
        min-width: 75px;
        text-align: left;
        justify-content: flex-start;
      }

      .dates-input-wrap {
        flex: 1;
      }
    }
  }

  .category-empty-text {
    background: $gray-bg-color;
  }

  .title-block {
    font-size: $text-size;
    line-height: 118.75%;
    text-align: center;
    .title-block-title {
    }

    .title-block-dates {
    }

    .title-block-notice {
      font-size: $text-size-secondary;
      line-height: 118.75%;
      color: $text-secondary;
    }
  }

  .dates-block {
    padding: 0 20px;

    &.details {
      padding-top: 23px;

      .form-group {
        margin-bottom: 18px;
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;
      font-size: $text-size;
      line-height: 118.75%;
      font-weight: 600;
      margin-bottom: 18px;

      .dates-title {
        margin-bottom: 10px;
      }

      .dates-input {
        padding-left: 10px !important;
        padding-right: 0px !important;
      }

      .dates-input-wrap {
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }

    }
  }

  .date-error {
    color: $error-color;
    text-align: center;
  }

  .details-block {
    padding: $padding-arrow;
    padding-bottom: 0;
  }

  .btn-block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 32px;
  }

  .btn {
    height: 45px;
    width: $basic-width;
    text-transform: none;
    font-weight: bold;
    font-size: $text-size;
    line-height: 118.75%;
  }

  .float {
    position: fixed;
    bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
