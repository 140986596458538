<template>
  <div v-tap="toggle" class="toggle" :class="{ 'active': active }">
    <div class="toggle-icon"></div>
  </div>
</template>

<script>
  import { tap } from "@/js/touches"
  import { click } from "@/js/audio"

  export default {
    directives: { tap },
    props: {
      modelValue: { type: Boolean, default: false },
      readonly: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false }
    },
    data() {
      return {
        active: this.modelValue
      }
    },
    methods: {
      toggle() {
        if(this.disabled || this.readonly) {
          return false
        }

        click()

        this.active = !this.active
        this.$emit("update:modelValue", this.active)
      }
    },
    watch: {
      modelValue(value) {
        this.active = value
      }
    }
  }
</script>
