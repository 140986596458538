<template>
  <div class="manual-discount">
    <div class="manual-discount_head new-payment-container payment">
      <div class="new-payment-inner" v-if="items.length > 0">
        <div class="manual-discount-title">{{ title }}</div>
        <div class="manual-discount-percents">
          <f7-button
            round
            fill
            v-for="(item, $k) in items"
            :key="$k"
            :class="[
              'manual-discount-percents_item',
              { grey: discountValue !== item.key }
            ]"
            @tap="togglePercent(item)"
          >{{ item.title }}</f7-button>
        </div>
      </div>
      <div class="new-payment-inner" v-if="isSumEditable">
        <div class="new-payment-item cash-field">
          <div class="new-payment-item-label">{{ $t("receipts.enterDiscountAmount") }}</div>
          <PriceField id="discount-sum" @tap="showKeyboard" :modelValue="$filters.sumFixed(discountInputAmount)" input-mode="none" />
        </div>
      </div>
    </div>
    <Footer>
      <div class="footer-buttons some-buttons">
        <f7-button round fill large id="discount-confirm" @tap="onConfirm" >{{ $t("receipts.confirm") }}</f7-button>
        <f7-button round large id="discount-reset" @tap="resetDiscount">{{ $t("receipts.reset") }}</f7-button>
      </div>
    </Footer>

    <f7-sheet
      class="discount-keyboard-sheet"
      backdrop
      :opened="isKeyboardOpened"
      @sheet:closed="hideKeyboard"
      id="discount-keyboard-sheet"
    >
      <f7-page-content>
        <p class="keyboard-sheet-title">{{ $t("receipts.insertDiscountAmount") }}</p>
        <DisplayedValue
          v-model:value="discountInputAmount"
          v-model:rewrited="rewrited"
          ref="sum"
          :error="error"
          :errorText="errorText"
          id="discount-display"
        />
        <Keyboard
          @confirm="onKeyboardConfirm"
          :disabled="error"
          ref="keyboard"
          id="discount-keyboard"
        />
      </f7-page-content>
    </f7-sheet>
  </div>
</template>

<script>
import {ref, watch} from 'vue';
import DisplayedValue from '@/components/CustomStepper/DisplayedValue';
import Keyboard from '@/components/Keyboard';
import PriceField from '@/components/PriceField';

export default {
  components: { DisplayedValue, Keyboard, PriceField },
  props: {
    isSumEditable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    title: { type: String, required: true },
    modelValue: { type: String, default: "" },
    items: { type: Array, default: () => [] },
    sum: { type: Number, required: true },
    discountAmount: { type: Number }
  },
  emits: ['update:value'],
  setup (props, { emit }) {
    const discountValue = ref(props.modelValue)
    const discountInputAmount = ref(props.discountAmount)
    const isKeyboardOpened = ref(false)
    const rewrited = ref(false)
    const error = ref(false);
    const errorText = ref('');

    watch(() => props.modelValue, (key) => {
      discountValue.value = key
    })

    watch(() => props.discountAmount, (value) => {
      discountInputAmount.value = value
    })

    watch(discountInputAmount, (value) => {
      const val = Number(value);
       if (val < props.min) {
        error.value = true;
        errorText.value = gui.translate("receipts.valueMinError", {value: props.min});
        return;
      }
      if (error.value) {
        error.value = false;
      }
    });

    return {
      discountValue,
      discountInputAmount,
      isKeyboardOpened,
      rewrited,
      error,
      errorText,
      togglePercent: async (item) => {
        if (props.disabled) return
        if (+discountValue.value !== +item.key) {
          discountValue.value = item.key
        }
      },
      showKeyboard: () => (isKeyboardOpened.value = true),
      hideKeyboard: () => {
        isKeyboardOpened.value = false;
        rewrited.value = false;
      },
      onKeyboardConfirm: async () => {
        isKeyboardOpened.value = false;
        rewrited.value = false;
      },
      onConfirm: async () => {
        const percentSelected = Boolean(+discountValue.value)

        const sign = percentSelected ? `${discountValue.value}%` : `$${discountInputAmount.value}`
        const confirmed = await gui.dispatch('handlers.manualDiscount.confirmDiscount', sign)

        if (confirmed) {
          if (percentSelected) {
            emit('update:modelValue', discountValue.value)
          }

          await gui.dispatch('handlers.manualDiscount.setDiscount', {
            percent: discountValue.value,
            absDiscount: Boolean(+discountInputAmount.value) || props.discountAmount > 0 ? +discountInputAmount.value : null
          })

          await gui.message(gui.translate('receipts.manualDiscountAmountChanged', { amount: props.sum }))
          await gui.navigate('/receipt')
        }
      },
      resetDiscount: async () => {
        if (!await gui.dispatch('handlers.manualDiscount.confirmDiscount', 0)) return

        if (props.sum > 0) {
          await gui.dispatch('handlers.manualDiscount.resetDiscount')
        }
        discountValue.value = ''
        discountInputAmount.value = 0
      }
    }
  },
  mounted () {
    this.$refs?.keyboard?.setRelation(this.$refs?.sum);
  }
}
</script>

<style lang="scss">
  @import "@/css/variables";

  .manual-discount {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 6px;
    }

    &-percents {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .manual-discount-percents_item {
        color: #ffffff;
        font-size: 24px;
        width: 47%;
        height: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        margin-bottom: 10px;

        &.grey {
          background-color: #A7ADBF !important;
        }
      }
    }

    &-amount {

      &_title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
      &_input {
        display: flex;
        align-items: center;
        margin: 25px auto auto;
        width: 150px;
        &-block {
          font-size: 24px !important;
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &-tip {
      font-size: 14px;
      color: #a7adbf;
      font-weight: 400;
      margin: 0 auto 32px auto;
      max-width: $basic-width;
    }

    &-button {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        min-width: $basic-width;
        max-width: $basic-width;
        justify-content: center;
        display: flex;
        align-items: center;
        text-transform: none;
        font-weight: bold;
        font-size: 18px;

        &:first-child {
          margin-bottom: 5px;
        }
      }
    }
  }

  .discount-keyboard-sheet {
    height: auto;
    background-color: $input-color;
    border-radius: 5px 5px 0 0;

    .keyboard-displayed-value {
      background-color: $input-color
    }

    .keyboard-sheet-title {
      font-size: 18px;
      text-align: center;
      margin: 0;
      padding-top: 20px;
      padding-bottom: 5px;
      color: rgba(#000, .56);
    }
  }
</style>
