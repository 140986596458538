<template>
  <SwipeItem @swipe:click="onGoodClick(good)" :disabled="(groupComponent && good.count <= 0) || isDeleted || $helpers.isCompletedReceipt(receipt) || $helpers.isCompletedRefund(receipt)" class="added-one-item">
    <template #left>
      <f7-button v-if="$helpers.isNewReceipt(receipt) && !$helpers.isSelection(receipt)" class="added-good-swipe-button red" fill @tap="$emit('delete-click', good)" :id="`added-item-delete-button-${good._id}`">{{ $t(groupComponent ? 'receipts.deleteGroup' : 'receipts.delete') }}</f7-button>
    </template>
    <template #right>
      <f7-button v-if="!groupComponent" class="added-good-swipe-button" fill :disabled="count <= minCount" @tap="subCount"><i class="mpi-md-minus"></i></f7-button>
      <f7-button v-if="!groupComponent" class="added-good-swipe-button" fill :disabled="count >= maxCount" @tap="addCount"><i class="mpi-md-plus"></i></f7-button>
    </template>
    <f7-ripple
        tag="div"
        class="added-item"
        :hidden="true"
        :class="{ 'deleted': isDeleted, 'sent': $helpers.isCafe() && !good.sent }"
        :id="`added-items-list-item-${good._id}`">
      <div class="added-restricted" :class="{ 'fade-in': isGoodLocked }"></div>
      <f7-list-item :clickable="false">
        <template #inner>
          <div class="added-item-inner">
            <div class="added-item-header">
              <div class="added-item-header-left">
                <div class="added-item-header-image">
                  <AsyncImage :id="good.mainImage" />
                  <CustomCheckbox v-if="$helpers.isNewRefund(receipt) || isSelection" opacity :checked="count > 0" class="product-list-item-left-checkbox" />
                </div>
                <div class="added-item-header-title">{{ good.name }}</div>
              </div>
              <div class="added-item-header-right">
                <div class="added-item-header-sum bold">${{ $filters.sum(good.sum.totalWithoutTaxes > 0 ? good.sum.totalWithoutTaxes : 0) }}</div>
                <div class="added-item-header-sum crossed-out" :class="{'visible': isSumDifferences}"><span>${{ $filters.sum(good.sum.subTotal) }}</span></div>
                <div class="added-item-header-quantity" :class="{ 'text-highlight': count > 0 && (receipt.type === 'refund' || isSelection) }">
                  <template v-if="isSelection">
                    <span><span v-if="isVisibleQTYShort">{{ $t('receipts.qtyShort') }}: </span>{{ good.selected }} {{ $t('receipts.of') }} {{ good.count }}</span>
                  </template>
                  <template v-else-if="$helpers.isReceipt(receipt) ">
                    <span>{{ $t('receipts.qtyShort') }}: <span class="bold">{{ count }}</span></span>
                  </template>
                  <template v-else-if="$helpers.isRefund(receipt)">
                    <span v-if="good.countToRefund === 0">{{ $t("receipts.qtyShort") }}: <span class="bold">0</span></span>
                    <span v-else><span v-if="isVisibleQTYShort">{{ $t('receipts.qtyShort') }}: </span>{{ good.count }} {{ $t('receipts.of') }} {{ good.countToRefund }}</span>
                  </template>
                </div>
              </div>
            </div>
            <div class="added-item-footer">
              <div v-if="modifiers.length > 0" class="added-item-footer-item double">
                <div class="added-item-footer-item-title">{{ $t("receipts.goodModifiers") }}:</div>
                <div class="added-item-footer-item-value">{{ modifiers.join(", ") }}</div>
              </div>
              <div v-if="comment" class="added-item-footer-item double">
                <div class="added-item-footer-item-title">{{ $t("receipts.comment") }}:</div>
                <div class="added-item-footer-item-value">{{ comment }}</div>
              </div>
              <div v-if="details.length > 0" class="added-item-footer-item added-item-footer-list">
                <div v-for="(detail, index) of details" :key="index" class="added-item-footer-list-item">{{ $t(detail) }}</div>
              </div>
            </div>
          </div>
        </template>
      </f7-list-item>
    </f7-ripple>
  </SwipeItem>
</template>

<script>
  import { click } from "@/js/audio"
  import { isCompletedRefund, isNewRefund, isNewReceipt, isReceipt, MAX_GOODS_COUNT } from "../../js/helpers"

  import SwipeItem from "@/components/f7/SwipeItem"
  import AsyncImage from "../AsyncImage"
  import CustomCheckbox from "../CustomCheckbox"

  export default {
    components: { SwipeItem, AsyncImage, CustomCheckbox },
    props: {
      good: { type: Object, default: () => ({}) },
      receipt: { type: Object, default: () => ({}) },
      groupComponent: { type: Boolean, default: false },
    },
    methods: {
      onGoodClick(good) {
        if(this.isDeleted) {
          return false
        }

        click()
        this.$emit('good-click', good)
      },
      subCount() {
        const count = this.count - 1

        if(count >= this.minCount) {
          gui.dispatch(this.changeCountMethod, { uuid: this.good.receiptGoodUuid, count })
        }
      },
      addCount() {
        const count = this.count + 1

        if(count <= this.maxCount) {
          gui.dispatch(this.changeCountMethod, { uuid: this.good.receiptGoodUuid, count })
        }
      }
    },
    computed: {
      isGoodLocked() {
        return this.$helpers.isGoodLocked(this.good, this.$realTime.value)
      },
      changeCountMethod() {
        if(isNewRefund(this.receipt)) {
          return "refund.changeRefundCount"
        }

        return this.isSelection ? "receipt.changeSelectedCount" : "receipt.changeCount"
      },
      count() {
        return this.isSelection ? this.good.selected : this.good.count
      },
      isSelection() {
        return this.$helpers.isSelection(this.receipt)
      },
      minCount() {
        if(this.isSelection) {
          return 0
        }

        if(isNewRefund(this.receipt)) {
          return 0
        }

        return 1
      },
      maxCount() {
        if(this.isSelection) {
          return this.good.count
        }

        if(isNewRefund(this.receipt)) {
          return this.good.countToRefund
        }

        return MAX_GOODS_COUNT
      },
      comment() {
        return typeof this.good.comments === "string" && this.good.comments.trim() !== "" ? this.good.comments.trim() : ""
      },
      modifiers() {
        if(Array.isArray(this.good.selectedModifiers) && this.good.selectedModifiers.length > 0) {
          return this.good.selectedModifiers
        }

        return []
      },
      isSumDifferences() {
        return (this.good.sum.subTotal - this.good.sum.totalWithoutTaxes) >= 0.01
      },
      isBottomPrice() {
        return this.good.usedFixPrice || this.good.usedMinPrice
      },
      verifyRequired() {
        return this.good.verifyRequired && isNewReceipt(this.receipt)
      },
      details() {
        const details = []

        if(this.verifyRequired) {
          details.push("receipts.ageVerificationRequired")
        }

        if(this.isBottomPrice) {
          details.push("receipts.bottomPrice")
        }

        return details
      },
      isDeleted() {
        if(this.groupComponent) {
          return false
        }

        if(this.good.count === 0 && isReceipt(this.receipt)) {
          return true
        }

        if(this.good.countToRefund === 0 && isNewRefund(this.receipt)) {
          return true
        }

        if(this.good.count === 0 && isCompletedRefund(this.receipt)) {
          return true
        }

        return false
      },
      isVisibleQTYShort() {
        const allCount = this.isSelection ? this.good.count : this.good.countToRefund
        if(allCount > 99) {
          return false
        }

        if(this.count >= 10 && allCount >= 10) {
          return false
        }

        return true
      }
    }
  }
</script>
