<template>
  <div ref="container" class="scanner-container">
    <input autocomplete="off" id="scanner-flying-field" type="text" ref="input" @keypress="addScannerChar" inputmode="none">
  </div>
</template>

<script>
  import { clearSelection } from "@/js/utils"

  export default {
    data() {
      return {
        scannerData: "",
        timer: null
      }
    },
    methods: {
      async addScannerChar(event) {
        const key = event.key || ""

        if(event.ctrlKey && event.key === "b" && navigator.clipboard) {
          try {
            const clipboard = await navigator.clipboard.readText()

            this.scannerData = clipboard
            this.onScanned()
          } catch(e) {}
          return
        }

        if(key.toLowerCase() === "enter" || event.keyCode === 13) {
          this.onScanned()
          return
        }

        this.scannerData += key
      },
      onScanned() {
        let data = this.scannerData
        this.scannerData = ""
        if(data !== "") {
          if (data.length === 12) {
            data = '0' +data
          }

          if(!document.querySelector(".dialog.modal-in")) {
            this.$emit("scanned", data)
          }
        }

        if(this.$refs.input) {
          this.$refs.input.value = ""
        }
      },
      startScanned() {
        this.timer = setInterval(() => {
          if(this.$refs.container && this.isVisible(this.$refs.container)) {
            const tabName = document?.activeElement?.tagName || null
            if(!["INPUT", "TEXTAREA", "SELECT"].includes(tabName)) {
              this.$refs?.input?.focus()
              clearSelection()
            }
          }
        }, 80)
      },
      isVisible(element) {
        return getComputedStyle(element).visibility === "visible"
      }
    },
    unmounted() {
      if(this.timer) {
        clearInterval(this.timer)
      }
    },
    mounted() {
      this.startScanned()
    }
  }
</script>

<style>
.scanner-container input {
  position: absolute;
  top: 0;
  z-index: 100000;
  height: 0;
  opacity: 0;
  outline: none;
}
</style>
