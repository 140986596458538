<template>
  <div class="keyboard-value-wrap">
    <div
      class="keyboard-displayed-value"
      :class="{ error: error }"
      :id="`${id}-value`"
      ref="value"
    >
      <span :class="{ 'will-rewrite': !rewrited }">
        {{ number }}
      </span>
    </div>
    <div v-if="error" class="displayed-error error-text" :id="`${id}-error-text`">
      {{ errorText }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: { type: String, default: "" },
    value: { type: [Number, String], default: 1 },
    error: { type: Boolean, default: false },
    errorText: { type: String, default: "" },
    rewrited: { type: Boolean, default: false },
    min: { type: Number, default: -Infinity },
    integer: { type: Boolean, default: false },
  },
  data() {
    return {
      number: String(this.value) || "0",
    };
  },
  watch: {
    value: function (val) {
      this.number = String(val) || "0";
      this.$nextTick(() =>  this.$refs.value.scrollLeft = this.$refs.value.scrollWidth)
    },
  },
  emits: ["update:value", "update:rewrited"],
  methods: {
    isEmpty() {
      return this.number == "0" || this.number == ""
    },
    press(char) {
      if (char === "." && this.integer) return;
      if (this.error && this.rewrited) return;

      if (!this.rewrited) {
        this.number = "";
        this.$emit("update:rewrited", true);
      }

      if (char === "." && /\./.test(this.number)) return;

      this.number = this.number + char;

      if (this.number === '.') this.number = '0.';

      if (this.number === "0" && this.min !== 0) {
        this.$emit("update:rewrited", false);
      }

      if (/e/.test(this.number)) {
        this.$emit("update:rewrited", false);
      }

      this.update();
    },
    backspace() {
      if (!this.rewrited) {
        this.number = "";
        this.$emit("update:rewrited", true);
      }

      this.number = this.number.slice(0, -1);

      if (this.number === "") {
        this.number = "0";
        this.$emit("update:rewrited", false);
      }

      this.update();
    },
    update() {
      const value = Number(this.number);

      if (Number.isNaN(value)) return;

      this.$emit("update:value", value);
    },
  },
};
</script>

<style lang="scss">
@import "../../css/variables";
.keyboard-value-wrap {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;

  .displayed-error {
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .error {
    color: $error-color;
  }
  .error-text {
    color: $error-color;
    position: relative;
  }
  .keyboard-displayed-value {
    white-space: nowrap;
    text-align: center;
    box-sizing: border-box;
    height: 45px;
    line-height: 45px;
    width: 188px;
    font-size: 24px;
    font-weight: 600;
    background-color: $main-bg-color;
    overflow-x: auto;
    border: none;
    font-size: 35px;
    .will-rewrite {
      background-color: $select-color;
    }
  }
}
</style>