<template>
  <div class="product-dialog product-page white-header-page gray" id="product-dialog" :class="{ 'fade-in': product.shown, 'fade-out': !product.shown }">
    <Page ref="page" schema="blue">
      <Header>
        <div class="title center">{{ title }}</div>
      </Header>

      <!-- Message -->
      <div class="header-messages">
        <HeaderMessage v-model="message.shown" :color="message.color">{{ message.text }}</HeaderMessage>
      </div>

      <Inner>
        <div class="gray-page-container">
          <div class="gray-page-white-container">
            <div class="gray-page-white">
              <div class="product-card">
                <div class="product-card-header">
                  <div class="wrapper-img-title">
                    <div class="product-images-container">
                      <div class="product-card-img-wrapper">
                        <div class="product-card-img">
                          <AsyncImage :id="good?.mainImage" />
                        </div>
                      </div>
                    </div>
                    <div class="img-footer">
                      <div class="form-group">
                        <div class="product-title" id="product-title">{{ good?.name }}</div>
                        <div class="product-card-price">
                          <div v-if="discount" class="product-discount-price price">${{ $filters.sumFixed(good.sum.totalWithoutTaxes > 0 ? good.sum.totalWithoutTaxes : 0) }}</div>
                          <div class="price" :class="{'crossed-out':discount, 'product-discount-price': !discount}">${{ $filters.sumFixed(good?.sum?.subTotal) }}</div>
                          <div v-if="discount" class="product-discount price">-${{ $filters.sumFixed(good.sum.discount) }}</div>
                        </div>
                      </div>
                      <ul v-if="details.length > 0" class="notification-list">
                        <li v-for="(detail, index) in details" :key="index"><span class="text-secondary">{{ $t(detail) }}</span></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="product-card-footer">
                  <div class="product-card-quantity product-block">
                    <div class="form-group">
                      <div v-if="receipt.type === 'receipt'" class="text">
                        <template v-if="isSelection">{{ $t('receipts.selectedQty') }}</template>
                        <template v-else>{{ $t('receipts.qty') }}</template>
                      </div>
                      <template v-else-if="receipt.type === 'refund'">
                        <div v-if="receipt.type === 'active'" class="text">{{ $t('receipts.qty') }}</div>
                        <div v-else class="text">{{ $t('receipts.qtyReturned') }}</div>
                      </template>
                      <template v-if="receipt.type === 'receipt'">
                        <template v-if="receipt.state === 'active'">
                          <CustomStepper
                            id="product-stepper"
                            v-if="isSelection"
                            :from="true"
                            :min="0"
                            :max="good?.count"
                            :step="1"
                            :value="good?.selected"
                            @update:value="updateSelectCount"
                            integer
                          />
                          <CustomStepper
                            v-else
                            id="product-stepper"
                            :min="1"
                            :max="$helpers.MAX_GOODS_COUNT"
                            :step="1"
                            :value="good?.count"
                            @update:value="updateCount"
                            integer
                          />
                        </template>
                        <div v-else class="product-price">{{ good?.count }} {{ $t(good?.count > 1 ? "receipts.productItems" : "receipts.productItem") }}</div>
                      </template>
                      <template v-else-if="receipt.type === 'refund'">
                        <CustomStepper
                          v-if="receipt.state === 'active'"
                          id="product-stepper"
                          :from="true"
                          :min="0"
                          :max="good?.countToRefund"
                          :step="1"
                          :value="good?.count"
                          @update:value="updateRefundCount"
                          integer
                        />
                        <div v-else class="product-price">{{ good?.count }} {{ $t(good?.count > 1 ? "receipts.productItems" : "receipts.productItem") }}</div>
                      </template>
                    </div>
                  </div>
                  <f7-ripple :hidden="true" :media-click="true" v-if="showModifiers && !isSelection" class="product-card-modifiers product-block" @tap="openModifiersSelection">
                    <div class="form-group">
                      <div class="text">{{ $t('receipts.modifiers') }}</div>
                      <div class="text-secondary">
                        {{ good?.selectedModifiers?.join(', ') }}
                      </div>
                      <div class="text"><i class="mpi-expand2"></i></div>
                    </div>
                  </f7-ripple>
                  <div v-if="showComments && !isSelection" class="product-card-comment product-block">
                    <div class="text">{{ $t('receipts.comment') }}</div>
                    <f7-input
                      class="product-card-comment-input"
                      input-id="product-card-comment-input"
                      resizable
                      type="textarea"
                      @blur="addComments"
                      v-model:value="comments"
                      @keyup.enter="blurComments"
                      :placeholder="$t('receipts.addComment')"
                    />
                  </div>
                  <div v-if="showDescription && !isSelection" class="product-card-information product-block">
                    <div class="text">{{ $t('receipts.information') }}</div>
                    <div class="text-secondary">
                      {{ good?.description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Inner>

      <Footer>
        <div class="footer-buttons">
          <f7-button
            round
            large
            class="product-confirm-btn fill"
            id="product-confirm-btn"
            @tap="close">
            <span v-if="receipt.type === 'refund' && receipt.state === 'active'">{{ $t('receipts.qty') }} {{ good.count }} {{ $t('receipts.of') }} {{ good.countToRefund }}</span>
            <span v-else>{{ $t('receipts.confirm') }}</span>
          </f7-button>
        </div>
      </Footer>
    </Page>
  </div>
</template>

<script>
  import { ref, computed, watch } from "vue"
  import { goodDetails, isCafe, isNewReceipt, isSelection } from "../../js/helpers"

  import AsyncImage from "../../components/AsyncImage"
  import CustomStepper from "../../components/CustomStepper/CustomStepper"

  export default {
    components: { AsyncImage, CustomStepper },
    setup() {
      const page = ref(null)
      const receipt = ref(gui.receiptData)

      const getCurrentGood = () => gui.receiptData.value.goods?.find(good => good?.receiptGoodUuid === gui.receiptData.value?.currentGood) || {}
      const good = ref(getCurrentGood())

      const comments = ref(good.value?.comments)

      const product = ref(gui.product)
      const details = ref(goodDetails(good.value, receipt.value))

      watch(gui.product, value => {
        page.value.scrollTo(0)
        product.value = value
        details.value = goodDetails(good.value, receipt.value)
      })

      watch(gui.receiptData, value => {
        const previousGoodsId = good.value?.receiptGoodUuid || null

        receipt.value = value
        good.value = getCurrentGood() || good.value

        if(previousGoodsId !== good.value?.receiptGoodUuid) {
          comments.value = good.value?.comments
        }
      })

      const updateCount = count => {
        gui.dispatch("receipt.changeCount", { uuid: good.value?.receiptGoodUuid, count })
      }

      const updateRefundCount = count => {
        gui.dispatch("refund.changeRefundCount", { uuid: good.value?.receiptGoodUuid, count })
      }

      const title = computed(() => {
        if (receipt.value?.type === "refund") {
          if(receipt.value?.state === "active") {
            return gui.translate("receipts.selectQuantity")
          } else {
            return gui.translate("receipts.refundedQuantity")
          }
        }
        return gui.translate("receipts.productInfo")
      })

      const discount = computed(() => {
        return good.value?.sum?.discount > 0.001
      })

      const showModifiers = computed(() => isCafe() && good.value?.modifiersIds?.length > 0 && isNewReceipt(receipt.value));
      const showComments = computed(() => isCafe() && isNewReceipt(receipt.value));
      const showDescription = computed(() => Boolean(good.value?.description) && isNewReceipt(receipt.value));

      // Barcode scanner
      gui.onScanned.value = barcode => gui.dispatch("receipt.scannedBarcode", { barcode })

      return {
        page,
        good,
        title,
        receipt,
        updateCount,
        updateRefundCount,
        showModifiers,
        showComments,
        showDescription,
        comments,
        discount,
        details,
        product,
        message: computed(() => {
          const message = { shown: false, text: "", color: "primary" }

          if(isNewReceipt(receipt.value) && !isSelection(receipt.value)) {
            message.shown = true
            message.text = gui.translate("receipts.successfullyAdded", { count: good?.value?.count })
          }

          return message
        }),
        blurComments: () => {
          document.activeElement?.blur();
        },
        updateSelectCount: count => gui.dispatch("receipt.changeSelectedCount", { uuid: good.value?.receiptGoodUuid, count }),
        scannedBarcode: barcode => gui.dispatch("receipt.scannedBarcode", { barcode }),
        openModifiersSelection: () => {
          gui.dispatch("modifiers.open", good.value?.modifiersIds);
        },
        addComments: () => {
          gui.dispatch("modifiers.addComments", good.value?.receiptGoodUuid, comments.value);
        },
        isSelection: computed(() => isSelection(receipt.value)),
        close() {
          gui.dispatch("receipt.closeGood");
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";
  @import "../../css/pages/receipts/product-dialog";

  .product-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateY(100vh);
    background-color: $main-bg-color;
    z-index: 11000;
    transition: .3s;

    .gray-page-white-container {
      overflow: visible;
    }

    &.fade-out {
      .animate__bounceIn {
        animation-name: none !important;
      }
    }

    &.fade-in {
      visibility: visible;
      transform: translateY(0);
    }

  }
</style>
