<template>
  <div class="get-sum-ui-dialog-modal" :class="{ 'fade-in': dialog.shown }" id="get-sum-ui-dialog-modal">
    <Page schema="transparent">
      <Header>
        <div class="left">
          <f7-link id="get-sum-ui-back" @tap="resolve(null)" class="icon-only">
            <i class="icon mpi-back color-black"></i>
          </f7-link>
        </div>
        <div class="title">{{ dialog.title }}</div>
      </Header>

      <Inner>
        <div class="new-payment-container payment cashier">
          <div class="new-payment-inner">
            <div class="new-payment-item cash-field">
              <div class="new-payment-item-label">{{ dialog.label }}</div>
              <PriceField :rewrite-first="true" id="get-sum-ui-value" ref="sumRef" v-model="dialog.defaultSum" input-mode="none" />
            </div>
            <div class="new-payment-item-confirm">
              <f7-button @tap="resolve(+dialog.defaultSum)" :disabled="!isValidSum" id="cash-in-out-button" round fill large>{{ $t("ok") }}</f7-button>
            </div>
          </div>
          <div class="new-payment-keyboard">
            <Keyboard id="sum-keyboard" @confirm="resolve(+dialog.defaultSum)" :disabled="!isValidSum" ref="keyboardRef" />
          </div>
        </div>
      </Inner>
    </Page>
  </div>
</template>

<script>
  import { ref, computed, watch, onMounted } from "vue"

  import Keyboard from "../../components/Keyboard"
  import PriceField from "../../components/PriceField"

  export default {
    components: { Keyboard, PriceField },
    setup() {
      const dialog = ref(gui.getSumUIData)
      const keyboardRef = ref(null)
      const sumRef = ref(null)

      watch(gui.getSumUIData, value => {
        dialog.value = value
        sumRef.value?.setRewrited(false)
      })

      onMounted(() => {
        keyboardRef.value?.setRelation(sumRef.value)
      })

      return {
        sumRef,
        keyboardRef,
        dialog,
        isValidSum: computed(() => {
          const sum = Number(dialog.value.defaultSum)
          return !isNaN(sum) && sum >= dialog.value.minSum && sum <= dialog.value.maxSum
        }),
        resolve: value => {
          dialog.value.shown = false
          dialog.value?.resolver?.(value)
        }
      }
    }
  }
</script>

<style lang="sass">
  @import "@/css/pages/checkout"
</style>

<style lang="scss">
  .get-sum-ui-dialog-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateY(100vh);
    z-index: 1000000000;
    transition: .3s;

    &.fade-in {
      visibility: visible;
      transform: translateY(0);
    }
  }
</style>
