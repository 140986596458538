<template>
  <f7-list no-hairlines class="added-items-list" id="added-items-list">
<!--  Groups  -->
<!--    <template v-for="group of receipt.goodsGroups">-->
<!--      <template v-if="group.goods.length === 1">-->
<!--        <AddedGood-->
<!--          @delete-click="$emit('delete-click', $event)"-->
<!--          @good-click="$emit('good-click', $event)"-->
<!--          :key="group.goods[0]._id"-->
<!--          :good="group.goods[0]"-->
<!--          :receipt="receipt" />-->
<!--      </template>-->
<!--      <template v-else>-->
<!--        <AddedGroup-->
<!--          @delete-click="$emit('delete-click', $event)"-->
<!--          @good-click="$emit('good-click', $event)"-->
<!--          :key="group._id"-->
<!--          :group="group"-->
<!--          :receipt="receipt" />-->
<!--      </template>-->
<!--    </template>-->
    <template v-for="good of goods" :key="good.receiptGoodUuid">
      <AddedGood
        @delete-click="$emit('delete-click', $event)"
        @good-click="$emit('good-click', $event)"
        :good="good"
        :receipt="receipt" />
    </template>
  </f7-list>
</template>

<script>
  import { ref, watch } from "vue"
  import AddedGood from "./AddedGood"
  import AddedGroup from "./AddedGroup"

  export default {
    components: { AddedGood, AddedGroup },
    props: {
      goods: { type: Array, default: () => [] },
      receipt: { type: Object, default: () => ({}) },
    },
    setup(props) {
      const receipt = ref(props.receipt)
      const goods = ref(props.goods)

      watch(() => props.receipt, value => {
        receipt.value = value
      })

      watch(() => props.goods, value => {
        goods.value = value
      })

      return { receipt, goods }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .receipt-added-goods-page {
    .added-good-swipe-button {
      height: 100%;
      font-weight: bold;
      font-size: 16px;
      padding: 0 18px;
    }

    .swipe-item-navigation {
      padding: 5px 0;
      box-sizing: border-box;
    }

    .swipe-item-left {
      .added-good-swipe-button {
        &:first-child{
          border-radius: $border-radius 0 0 $border-radius;
        }
      }
    }

    .added-items-list {
      .added-one-item {
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .swipe-item-right {
      .added-good-swipe-button {
        border-right: 1px solid rgba(#fff, .1);
        width: 70px;
        &:last-child {
          border-radius: 0 $border-radius $border-radius 0;
          border-right: none;
        }
      }
    }
  }

  .added-items-list {
    margin: 0 !important;

    .item-content, .item-inner {
      padding: 0 !important;
      &::after {
        display: none;
      }
    }

    ul {
      padding: 0 $minimal-padding !important;
      background-color: transparent;
      .added-item {
        border-radius: $border-radius;
      }
    }

    .swipeout-actions-left a {
      background-color: $error-color;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }

    .swipeout-actions-right .added-item-qty-button {
      background-color: $primary-color;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      min-width: 74px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      &.disabled {
        opacity: 1 !important;
        background-color: $disabled-primary !important;
      }
      &::after {
        border-left: 1px solid rgba(#fff, .1);
      }
      &:last-child:after {
        border-left: none;
      }
    }

    .added-item {
      margin-bottom: $item-margin !important;
      padding: $minimal-padding;
      background-color: #fff;
      box-shadow: $item-shadow;
      position: relative;

      &.sent {
        background-color: $light-bg-color;
      }

      &.deleted {
        opacity: .5;
        pointer-events: none;
        .swipeout-content {
          transform: translate3d(0px, 0px, 0px) !important;
        }
      }

      .added-item-inner {
        width: 100%;
      }

      .added-restricted {
        background-image: repeating-linear-gradient(45deg, #ff7e7e 0px, #ff7e7e 5px, transparent 5px, transparent 10px);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 6px;
        border-radius: 3px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: .3s;

        &.fade-in {
          visibility: visible;
          opacity: 1;
        }
      }

      .added-item-footer {
        font-size: 12px;
        line-height: 12px;
        color: #A7ADBF;
        .added-item-footer-item {
          padding-top: 7px;
          &:first-child {
            padding-top: 13px;
          }
          &.double {
            display: flex;
            align-items: flex-start;
            .added-item-footer-item-title {
              min-width: 62px;
            }
            .added-item-footer-item-value {
              color: $text-color;
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .added-item-footer-list {
          .added-item-footer-list-item {
            margin-bottom: 4px;
            position: relative;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-left: 12px;
            &:last-child {
              margin-bottom: 0;
            }
            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 2.5px;
              left: 0;
              width: 6px;
              height: 6px;
              background-color: #A7ADBF;
              border-radius: 50%;
            }
          }
        }
      }

      .added-item-header {
        display: flex;
        align-items: flex-start;
        .added-item-header-left {
          display: flex;
          align-items: center;
          flex: 1;
          padding-right: 10px;
          .added-item-header-image {
            position: relative;
            min-width: 50px;
            max-width: 50px;
            height: 50px;
            margin-right: 12px;
            .async-image {
              border-radius: $border-radius;
            }
            .custom-checkbox {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
          .added-item-header-title {
            font-size: 16px;
            line-height: 18.4px;
            color: #000;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            max-height: 54px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }
          .added-item-header-left {
            flex: 1;
          }
        }
        .added-item-header-right {
          min-width: 81px;
          max-width: 85px;
          text-align: right;
          .added-item-header-quantity {
            font-size: 15px;
            line-height: 15px;
            color: #A7ADBF;
            .bold {
              font-weight: bold;
              color: #000;
            }
            &.text-highlight {
              color: $primary-color;
              .bold {
                color: $primary-color;
              }
            }
          }
          .added-item-header-sum {
            font-weight: bold;
            font-size: 18px;
            line-height: 18px;
            color: #000;
          }
          .added-item-header-sum.crossed-out {
            color: #A7ADBF;
            font-weight: normal;
            font-size: 15px;
            line-height: 15px;
            opacity: 0;
            &.visible {
              opacity: 1;
            }
            span {
              position: relative;
              display: inline-flex;
              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                background-color: #A7ADBF;
                height: 1px;
                transform: rotate(-10deg)
              }
            }
          }
        }
      }
    }
  }
</style>
