import gui from "../gui"
import user from "../user"

let _routes = []
let _navigationHistory = []
let _currentPage = ""

export async function bindRoutes(routes) {
  _routes = routes
}

export async function popRoute() {
  return _navigationHistory.pop()
}

export async function getPath() {
  return _currentPage
}

export async function navigate(page, navigateBack = false) {
  const lastPage = _navigationHistory.length > 0 ? _navigationHistory[_navigationHistory.length - 1] : null

  _currentPage = page

  if(_navigationHistory.length >= 2) {
    if(_navigationHistory.slice(-2)[0] === _currentPage) {
      _navigationHistory = _navigationHistory.slice(0, -2)
    }
  }

  if(!navigateBack && _routes.filter(item => item.path === page && item.history !== false).length > 0 && lastPage !== page) {
    _navigationHistory.push(page)
  }

  if([
    "/",
    "/start",
    "/welcome",
    "/login",
    "/checkout",
    "/device-disabled",
    "/checkout/result",
    "/modifiers",
    "/settings"
  ].includes(_currentPage) || (_currentPage.startsWith("/receipt") && !_currentPage.startsWith("/receipts"))) {
    gui?.disableSwipePanel()
  } else {
    gui?.enableSwipePanel()
  }


  if (_currentPage === '/tables') {
    if(gui?.tables?.isWidget) {
      gui?.enableSwipePanel()
    } else {
      gui?.disableSwipePanel()
    }
  }

  if(["/checkout/result", "/settings"].includes(_currentPage)) {
    _navigationHistory = ["/home"]
  }

  await gui?.nativeNavigate(page)
}

export async function navigateBack() {
  if(_navigationHistory.length > 0) {
    let page = _navigationHistory.pop()

    while (page === _currentPage && _navigationHistory.length > 0) {
      page = _navigationHistory.pop()
    }

    if(page === _currentPage && user.isAuthorized()) {
      page = "/home"
    }

    await navigate(page, false)
  }
}

export async function clearHistory() {
  _navigationHistory = ["/home"]
}

export async function deleteLastRoute() {
  _navigationHistory = _navigationHistory.slice(0, -1)
}
