const en = {
  loading: "Loading...",
  printError: "Print error",
  printerNotDefined: `Printer "{printerType}" undefined`,
  terminalInitTransaction: "Initializing transaction",
  checkNetworkConnection: "Check your network connection",
  imageUploadError: "Error loading image",
  cancel: "Cancel",
  yes: "Yes",
  no: "No",
  ok: "Ok",
  confirm: "Confirm",
  welcome: {
    hello: "Hello!",
    letsBegin: "Let`s<br>begin!",
    deviceDisabled: "Device<br>disabled"
  },
  dialogDefault: {
    ok: "OK",
    cancel: "Cancel",
    enterSum: "Enter amount"
  },
  menuItems: {
    orders: "Orders",
    newOrder: "New order",
    postponedOrders: "Open orders",
    completedOrders: "Completed orders",
    shift: "Shift",
    shifts: "Shifts",
    shiftsList: "Shifts list",
    currentShift: "Current shift",
    openShift: "Open shift",
    closeShift: "Close shift",
    settings: "Settings",
    receipts: "Receipts",
    newReceipt: "New receipt",
    postponedReceipts: "Postponed receipts",
    completedReceipts: "Completed receipts",
    reports: "Reports",
    fullReports: "Full reports",
    xReports: "X-reports",
    zReports: "Z-reports",
    calculator: "Calculator",
    addWidgets: "Add widgets"
  },
  login: {
    title: "Log in",
    accessRequest: "Access request",
    openShiftRequest: "Only manager can<br>open shift",
    closeShiftRequest: "Only manager can<br>close shift",
    cashInRequest: "Only manager can deposit cash",
    cashOutRequest: "Only manager can withdraw cash",
    scanBadge: "Scan badge",
    enterUsername: "Enter username",
    username: "Username",
    enterPin: "Enter PIN",
    setPin: "Set up a PIN",
    resetPin: "Set up a new PIN",
    next: "Next",
    badgeToLogin: "You can scan badge to log in",
    cardToLogin: "You can use card to log in",
    waitingForBadge: "Waiting for badge",
    cancel: "Cancel",
    logout: "Log out",
    sureLogout: "Log out?",
    sureLogoutNo: "No",
    sureLogoutYes: "Yes",
  },
  logout: {
    warningMessage: 'Logout in <span class="time">{seconds}</span> seconds',
    continueMessage: "Tap anywhere to continue",
  },
  home: {
    confirm: "Confirm",
    selectWidgets: "Select widgets",
    reorder: "Reorder",
    addWidget: "Add widget"
  },
  widgets: {
    tables: "Tables",
    newOrder: "New order",
    orders: "Orders",
    newReceipt: "New receipt",
    receipts: "Receipts",
    closeShift: "Close shift",
    openShift: "Open shift",
    currentShift: "Current shift",
    shiftsList: "Shifts list",
    depositing: "Depositing",
    withdrawal: "Withdrawal",
    printXReport: "Print X-report",
    settings: "Settings",
    calculator: "Calculator",
    contactSupport: "Support",
  },
  certificates: {
    cancelErrorTitle: "Cancel error",
    applyErrorTitle: "Apply error",
    cancelError: "Error when canceling certificates",
    totalError: "Total is under certificate value",
    cancelCertificate: "Cancel certificate?",
    actionTitle: "Gift certificates",
    title: "Gift certificates",
    delete: "Cancel",
    addTitle: "Add gift certificate",
    label: "Scan or enter code",
    next: "Next",
    empty: "No certificates"
  },
  settings: {
    name: "Name",
    serial: "Serial",
    pairedOn: "Connected",
    save: "Save",
    start: "Start",
    delete: "Delete",
    title: "Settings",
    saveError: "Save error",
    synchronization: "Synchronization",
    saveSuccess: "Settings saved successfully",
    addPrinter: "Add printer",
    printersList: "Printers",
    printersEmpty: "Printers empty",
    printerAddress: "Address (IP or MAC)",
    unpairingDevice: "Disconnect device",
    bluetoothDiscovery: "Bluetooth discovery",
    setupDescription: "To get started, customize mandatory settings",
    initialSetupTitle: "Initial setup",
    deviceInformation: "Device information",
    devicePairingTitle: "Connecting device",
    devicePairingDescription: "Please scan QR code  in admin side<br>to connect device",
    printersUnavailable: "Printers unavailable",
    addMasterPrinter: "Please add master printer",
    multiplePrinters: "You added multiple printers of type {type}",
    printerTypeUndefined: "Printer type {type} is not defined",
    incorrectAddress: "Incorrect printer address {address}",
    printerAddressEmpty: "Printer address is empty",
    wantUnpairDevice: "Disconnect device?",
    approveEditDescription: "Only manager can<br>edit settings",
    approveEditTitle: "Editing settings",
    existsActiveDocuments: "Disconnection is not possible. Close all active documents",
    applicationSettings: "Application",
    offlinePayments: "Offline payments",
    disableAnimation: "Disable animation",
    clickVolume: "Click volume"
  },
  shifts: {
    date: "Date",
    tips: "Tips",
    cardTips: "card",
    cashTips: "cash",
    title: "Current shift",
    openShiftButton: "Open shift",
    closeShiftButton: "Close shift",
    cashInTitle: "Depositing",
    cashOutTitle: "Withdrawal",
    cashInLabel: "Enter amount",
    cashOutLabel: "Enter amount",
    openingBalance: "Enter opening balance",
    shiftSuccessfullyOpened: "Opened successfully",
    shiftAlreadyOpened: "Opened successfully",
    shiftSuccessfullyClosed: "Closed successfully",
    shiftIsNotOpened: "Shift is not open",
    maxWithdrawalSum: "Max withdrawal sum is ${sum}",
    cashDescription: "Description",
    cashDescriptionOptional: "optional",
    cashInDescription: "Description (optional)",
    cashOutDescription: "Description (optional)",
    changeStartSumTitle: `Opening balance: <span class="color-primary">\${sum}</span><div class="small-dialog-title">(previous shift closing balance)</div>`,
    changeStartSumDescription: "Change balance?",
    changeStartSumCancel: "No",
    changeStartSumConfirm: "Yes",
    changeStartSumShortTitle: "Opening balance",
    startCashInDescription: "Sum at the start of the shift",
    closingCashOutDescription: "Sum at the closing of the shift",
    wantCloseShiftTitle: "Close shift?",
    wantOpenShiftTitle: "Open shift?",
    wantOpenShiftCancel: "No",
    wantOpenShiftConfirm: "Yes",
    printZeroReceipt: "Print zero receipt",
    sales: "Sales ATI",
    cash: "cash",
    card: "card",
    certificates: "certificates",
    taxes: "Taxes",
    taxesSales: "sales",
    taxesRefunds: "refunds",
    refunds: "Refunds ATI",
    deposit: "Depositing",
    withdrawal: "Withdrawal",
    cashAvailable: "Cash available",
    printXReport: "Print X-report",
    printZReport: "Print Z-report",
    printZReportCopy: "Print Z-report copy",
    haveNiceDay: "Have a nice day",
    haveNiceStay: "Have a nice rest",
    list: "Shifts list",
    sortBy: "Sort by",
    shiftsIsMissing: "Shifts not found",
    fullReport: "Full report",
    shiftId: "Shift #{id}",
    print: "Print",
    confirm: "Confirm",
    from: "From",
    to: "To",
    existsActiveReceipts: "You have {count} postponed receipts. Close them to continue.",
    existsActiveOrders: "You have {count} open orders. Close them to continue.",
    viewOrders: "View orders",
    viewReceipts: "View receipts",
    availableDates: "Available dates:",
    fullReportNotice: "For more data go to WEB admin"
  },
  datePicker: {
    selectDate: "Select date",
    confirmPick: 'Done',
    mounthes: 'January February March April May June July August September October November December',
  },
  receipts: {
    pay: "Pay",
    toPay: "Pay",
    openGroup: "Open products group",
    closeGroup: "Close products group",
    deleteGroup: "Delete group",
    timeRestrictionError: "The item available for sale from {from} to {to}",
    someItemsReceiptNotAvailable: "Sales time restriction set for some items in the receipt",
    searchOrders: "Search orders",
    searchReceipts: "Search receipts",
    newOrder: "New order",
    orders: "Orders",
    split: "Split",
    selectedQty: "Selected",
    splitUnavailable: "At least one item should be in current order",
    notEnoughCash: "Insufficient funds. Try to change refund method.<br>Cash available",
    verifyPostpone: "Postpone",
    ageVerifiedConfirm: "Confirm",
    splitSuccess: "Order split successfully.<br><span class='small-dialog-title'>New order <b>{id}</b></span>",
    actionsTitle: "Options",
    selectForNewOrder: "Select items for new order",
    sumTaxesExempt: "Tax exempted",
    taxExemptionEnabled: "Tax exemption is enabled",
    taxExemptionDisabled: "Tax exemption is disabled",
    bill: "Print bill",
    splitOrder: "Split order",
    cancelRefund: "Close return receipt",
    closeRefund: "Close return receipt",
    printRefund: "Print refund copy",
    printReceipt: "Print receipt copy",
    changeWaiter: "Change waiter",
    changeCashier: "Change cashier",
    reprintRunner: "Reprint order ticket",
    notSentItems: "Some items are not sent to: {items}",
    changeWaiterAccessDescription: "Only manager can change waiter",
    changeCashierAccessDescription: "Only manager can change cashier",
    reprintRunnerAccessDescription: "Only manager can approve reprint",
    changeWaiterTitle: 'Order created by another waiter.',
    changeWaiterQuestion: 'Reassign it to the current waiter?',
    changeCashierTitle: 'Receipt created by another cashier.',
    changeCashierQuestion: 'Reassign it to the current cashier?',
    taxExemption: "Tax exemption",
    runner: "Order ticket",
    calculator: "Calculator",
    favouritesEmpty: "Favourites empty",
    cancelOrder: "Cancel order",
    cancelReceipt: "Cancel receipt",
    cancelOrderQuestion: "Cancel order?",
    cancelReceiptQuestion: "Cancel receipt?",
    manualDiscountChanged: "{percent}% discount set",
    manualDiscountAmountChanged: "${amount} discount set",
    tipsChanged: "{percent}% tips set",
    tipsAmountChanged: "${sum} tips set",
    tipsReset: "Tips reset",
    discountReset: "Discount reset",
    approveCancelReceiptTitle: "Cancel receipt",
    approveCancelReceiptDescription: "Only manager can<br>cancel receipt",
    approveCancelOrderTitle: "Cancel order",
    approveCancelOrderDescription: "Only manager can<br>cancel order",
    manualDiscountAccessTitle: "Manual discount {amount}",
    manualDiscountAccessDescription: "Only manager can apply manual discount",
    refundsAvailableOnline: "Returns are available<br>only in online mode",
    approveRefundTitle: "Approve return",
    approveRefundDescription: "Only manager can<br>approve return",
    previousRefunds: "Total of previous refunds:",
    productInfo: "Product info",
    selectQuantity: "Quantity to return",
    refundedQuantity: "Returned quantity",
    createRefund: "Create refund",
    createReturn: "Create return receipt",
    receipts: "Receipts",
    search: "Search",
    totalItems: "Items: {qty}",
    table: "Table: {name}",
    ordersIsMissing: "Orders not found",
    receiptsIsMissing: "Receipts not found",
    filteredGoodsEmpty: "Product {filter} not found",
    categoryIsEmpty: "Category {name} is empty",
    categoryNotFound: "Category not found",
    receiptNotFound: "Receipt not found",
    productNotFound: "Product not found",
    favourites: "Favorites",
    categories: "Categories",
    menu: "Menu",
    comment: "Comment",
    information: "Information",
    searchAllProducts: "Search product",
    category: "Category",
    nothingFound: "Nothing found",
    scan: "Scan",
    postponed: "Postponed",
    postponedCafe: "Open",
    completed: "Completed",
    qty: "Quantity",
    qtyShort: "Qty",
    qtyReturned: "Returned",
    of: "of",
    totalTaxes: "Total taxes",
    taxes: "Taxes",
    change: "Change",
    total: "Total",
    modifiers: "Modifiers",
    confirm: "Confirm",
    reset: "Reset",
    unselectAll: "Unselect all",
    selectAll: "Select all",
    cashAvailable: "Cash available",
    newReceipt: "New receipt",
    return: "Return",
    refund: "Refund",
    receipt: "Receipt",
    searchIn: "Search in",
    items: "Items",
    subTotal: "Subtotal",
    discount: "Discount",
    swipeUp: "Swipe up for more details",
    swipeDown: "Swipe down to continue",
    cancel: "Cancel",
    postpone: "Postpone",
    checkout: "Checkout",
    verifyAge: "Please verify client age",
    verifyAgeDialogTitle: "Age verification",
    verifyAgeDialogDescription: "Please verify the client`s age",
    ageVerificationRequired: "Age verification required",
    ageVerified: "Verify",
    verifyAgeBack: "Back",
    verifyAgeConfirm: "Verify",
    delete: "Delete",
    ok: "Ok",
    insertQty: "Enter items quantity",
    valueMinError: "Minimum value is {value}",
    valueMaxError: "Maximum value is {value}",
    shift: {
      notOpenedTitle: "Shift is not open",
      notOpenedDescription: "Open shift?",
      openButton: "Open"
    },
    cardAlreadyPayed: "By card",
    cashAlreadyPayed: "By cash",
    cardAlreadyRefunded: "On card",
    cashAlreadyRefunded: "By cash",
    productItem: "item",
    productItems: "items",
    toReturn: "Total",
    returned: "Total",
    itemsSelected: "Items selected",
    itemsReturned: "Items returned",
    discountSelect: "Select discount",
    discountOption: "Discount",
    discountPageTitle: "Discount",
    discountTip: "Discount may not be applied to all the items in the receipt.<br>The discount is not applied to some products",
    returnsParticularStore: "Returns available only in the store where the item was bought",
    returnsBusinessType: "Returns available at any store with the same business type",
    tipsSelect: "Select tips",
    enterTipsAmount: "or type value",
    enterTipsAmountFull: "Type tip value",
    enterDiscountAmount: "or type value",
    insertTipsAmount: "Enter tip amount",
    insertDiscountAmount: "Enter discount amount",
    tipsPageTitle: "Tips",
    tips: "Tips",
    selectWaiter: "Select new waiter",
    selectCashier: "Select new cashier",
    addComment: "Add your comment",
    bottomPrice: "Bottom price",
    goodModifiers: "Modifiers",
    details: "Details",
    successfullyAdded: "{count} items successfully added",
    send: "Send",
    notificationNotSentItems: "Some items are not sent to kitchen/bar",
    notificationPickItems: "What products do you want to send?",
    all: "All",
    new: "New",
    sentSuccesfully: "Sent succesfully",
    sentSuccesfullyAll: "All items sent successfully",
    sentWithErrors: "Printers errors: {errors}",
    orderComment: "Order comment",
    update: "Update",
    add: "Add",
    commentsIsMissing: "No comments"
  },
  filters: {
    filters: 'Filters',
    userName: 'Username',
    tableId: 'Tables',
    paymentType: 'Payment type',
    type: 'Type',
    price: 'Total amount',
    sumtotal: 'Total amount',
    date:'Date',
    apply: 'Apply',
    reset: 'Reset',
    refunded: "Only refunded",
    hideRefunded: "Hide refunded",
    card: "Card",
    cash: "Cash",
    insertPriceMin: "Enter min total amount",
    insertPriceMax: "Enter max total amount",
    insertSumtotalMin: "Enter min total amount",
    insertSumtotalMax: "Enter max total amount",
    null: "Skipped"
  },
  calculator: {
    calculator: "Calculator",
    errorText: "Wrong expression"
  },
  loyalty: {
    // successfullyFound: "Loyalty member successfully found",
    headerTitle: "Loyalty",
    actionTitle: "Loyalty",
    memberTitle: "Loyalty member",
    phone: "Phone number",
    next: "Next",
    clear: "Clear",
    cancel: "Cancel",
    back: "Back",
  },
  tables: {
    selectTable: "Select table",
    orders: "Orders: ",
    items: "Items: ",
    changeTable: 'Change table',
    skip: 'Skip',
    tables: "Tables",
    reserve: "Reserve",
    reserved: "Reserved: ",
    unreserve: "Unreserve",
    notificationReserve: "Reserve table: {table}, date: {date}?",
    notificationUnreserve: "Unreserve table?",
    tablesIsMissing: "Tables not found",
    successfullyReserved: "Reserved successfully",
    successfullyUnreserved: "Unreserved successfully",
    errorReserved: "Something went wrong",
    errorUnreserve: "Something went wrong",
    unreservePermissionTitle: "Unreserve?",
    unreserveTableAccessDescription: "Only manager can unreserve table",
    offlineData: "You are offline. Used data from this device",
    offlineDataWithDate: "Offline mode. Table reservation data last update: {date}",
    errorTime: "The wrong time has been selected"
  },
  support: {
    title: "Contact support",
    addComment: "Add comment",
    commentLabel: "Comment",
    screensLabel: "Add screenshots",
    sendButton: "Send",
    selectedQtyNotice: "Select {qty} of {max}",
    emptyScreens: "Screenshots not found",
    emptyScreensBtn: "Close",
  },
  checkout: {
    ok: "Ok",
    tips: "Tips",
    toPay: "To pay",
    payment: "Payment",
    refund: "Refund",
    amount: "Amount",
    openNextReceipt: "Open split order",
    cashPayment: "Cash payment",
    cashReturn: "Cash refund",
    printingBill: "Printing bill",
    printingZeroReceipt: "Printing zero receipt",
    printingZReportCopy: "Printing Z-report copy",
    printingZReport: "Printing Z-report",
    printingXReport: "Printing X-report",
    printingFullReport: "Printing Full report",
    printingRefund: "Printing refund",
    printingReceipt: "Printing receipt",
    printingReceiptCopy: "Printing receipt copy",
    printingRefundCopy: "Printing refund copy",
    printingRunner: "Printing order ticket",
    tryAgain: "Retry",
    amountToPay: "Amount to pay",
    amountToRefund: "Amount to refund",
    paymentFailed: "Payment failed",
    refundFailed: "Refund failed",
    operationFailed: "Operation failed",
    cash: "Cash",
    card: "Card",
    operationSuccessTitle: "Operation successful",
    returnSuccessTitle: "Refund successful",
    returnErrorTitle: "Refund error",
    successTitle: "Payment successful",
    errorTitle: "Payment error",
    toMainPage: "Home screen",
    waitingForCard: "Waiting for card",
    total: "Total",
    enterPin: "Enter PIN",
    changeDue: "Change due",
    userSumLessThenTotal: "The amount ${sumUserCash} provided by the client is less than the payment amount ${sumTotal}",
    amountToReturn: "Amount to refund",
    cashAvailable: "Cash available",
    return: "Refund",
    confirm: "Confirm",
    notEnoughCash: "Insufficient funds. Try to change refund method",
    paymentMethodUnavailable: "Payment method unavailable",
    refundMethodUnavailable: "Refund method unavailable",
    paymentType: "Payment type",
    cashPaymentType: "cash",
    cardPaymentType: "card"
  },
  synchronization: {
    title: "Synchronization",
    pullTitle: "Pulling data",
    pushTitle: "Pushing data",
    loaded: "Loaded {count} items",
    loadedFrom: "Pulled from <b>{dict}</b><br>{count} items",
    loadedTo: "Pushed to <b>{dict}</b><br>{count} items",
  },
  printers: {
    categories: {
      "0-receipt": "Zero receipt",
      "bill": "Bill",
      "full-report": "Full report",
      "receipt": "Receipt",
      "refund": "Refund",
      "x-report": "X-report",
      "z-report": "Z-report",
      "runner": "Order ticket"
    },
    templates: {
      "default": "Default",
      "wide": "Wide",
      "star": "Star",
      "bold": "Bold",
      "dashed_lines": "Dashed lines",
      "fontX2": "Font x2",
      "info_bottom": "Info bottom",
      "no_logo": "No logo",
      "no_price": "No price",
      "qty_top": "Qty top",
      "solid_lines": "Solid lines",
      "uppercase": "Uppercase",
    }
  },
  terminal: {
    DEVICE_INITIALISING: "Device initialising",
    DEVICE_NOT_CONNECTED: "Device not connected",
    DEVICE_DISCONNECTED: "Device disconnected",
    GOING_ONLINE: "Going online",
    CARD_SWIPE_DETECTED: "Card swipe detected",
    SIGNATURE_REQUIRED: "Signature required",
    CARD_SWIPE: "Card swipe",
    CARD_INSERT_SWIPE: "Card insert swipe",
    CARD_DETECTED: "Card detected",
    CARD_ACCEPTED: "Card accepted",
    AMOUNT: "Amount",
    AMOUNT_OK_OR_NOT: "Amount ok or not",
    APPROVED: "Approved",
    CALL_YOUR_BANK: "Call your bank",
    CANCEL_OR_ENTER: "Cancel or enter",
    CARD_ERROR: "Card error",
    DECLINED: "Declined",
    ENTER_AMOUNT: "Enter amount",
    ENTER_PIN: "Enter pin",
    INCORRECT_PIN: "Incorrect pin",
    INSERT_CARD: "Insert card",
    NOT_ACCEPTED: "Not accepted",
    PIN_OK: "Pin ok",
    PLEASE_WAIT: "Please wait",
    PROCESSING_ERROR: "Processing error",
    REMOVE_CARD: "Remove card",
    USE_CHIP_READER: "Use chip reader",
    USE_MAG_STRIPE: "Use mag stripe",
    TRY_AGAIN: "Try again",
    REFER_TO_YOUR_PAYMENT_DEVICE: "Refer to your payment device",
    TRANSACTION_TERMINATED: "Transaction terminated",
    TRY_ANOTHER_INTERFACE: "Try another interface",
    ONLINE_REQUIRED: "Online required",
    PROCESSING: "Processing",
    WELCOME: "Welcome",
    PRESENT_ONLY_ONE_CARD: "Present only one card",
    CAPK_LOADING_FAILED: "Capk loading failed",
    LAST_PIN_TRY: "Last pin try",
    SELECT_ACCOUNT: "Select account",
    INSERT_OR_TAP_CARD: "Insert or tap card",
    APPROVE_PLEASE_SIGN: "Approve please sign",
    TAP_CARD_AGAIN: "Tap card again",
    AUTHORISING: "Authorising",
    INSERT_OR_SWIPE_CARD_OR_TAP_ANOTHER_CARD: "Insert or swipe card or tap another card",
    INSERT_OR_SWIPE_CARD: "Insert or swipe card",
    MULTIPLE_CARDS_DETECTED: "Multiple cards detected",
    REINSERT_OR_SWIPE: "Reinsert or swipe",
    CARD_REQUIRES_CHIP_READ: "Card requires chip read",
    PIN_ENTRY_SUCCESSFUL: "Pin entry successful",
    INVALID_PIN_ENTERED: "Invalid pin entered",
    INVALID_PIN_ENTERED_LAST_ATTEMPT: "Invalid pin entered last attempt",
    SENDING_TRANSACTION: "Sending transaction",
    INSERT_OR_SWIPE: "Insert or swipe",
    SETTINGS_HERE: "Settings here",
    SETTINGS_UPDATE_SUCCESSFUL: "Settings update successful",
    TRANSACTION_UPDATE_SUCCESSFUL: "Transaction update successful",
    ENTERED: "Entered",
    BYPASS: "Bypass",
    CANCEL: "Cancel",
    TIMEOUT: "Timeout",
    KEY_ERROR: "Key error",
    NO_PIN: "No pin",
    WRONG_PIN_LENGTH: "Wrong pin length",
    CLEAR_DISPLAY_MSG: "Clear display msg",
    INPUT_PIN_ING: "Input pin ing",
    MAG_TO_ICC_TRADE: "Mag to icc trade",
    POWA_CONNECTED: "Powa connected",
    POWA_DISCONNECTED: "Powa disconnected",
    POWA_PRINT_SUCCESSFUl: "Powa print successful",
    BATTERY_LOW: "Battery low",
    TRANSACTION_CANCELLED_BY_USER: "Transaction cancelled by user",
    SELF_TEST_WALKER: "Self test walker",
    SELF_TEST_NOMAD: "Self test nomad",
    SELF_TEST_BLUEPAD: "Self test bluepad",
    SWIPE_AGAIN_PLEASE: "Swipe again please",
    AUTO_CONFIG_COMPLETED: "Auto config completed",
    SCAN_STOPPED: "Scan stopped",
    SCAN_STARTED: "Scan started",
    REVERSAL_ATTEMPTING: "Reversal attempting",
    REVERSAL_FAILED: "Reversal failed",
    REVERSAL_PERFORMED: "Reversal performed",
    PIN_REQUIRED: "Pin required",
    TRYING_TO_CONNECT: "Trying to connect",
    FALLBACK: "Fallback",
    AUTHORIZATION_CONFIRMATION: "Authorization confirmation",
    AUTHORIZATION_REQUEST: "Authorization request",
    INSERT_SWIPE_OR_TRY_ANOTHER_CARD: "Insert swipe or try another card",
    NOT_ICC_CARD: "Not icc card",
    CARD_INSERT: "Card insert",
    CARD_TAP: "Card tap",
    CARD_INSERT_OR_TAP: "Card insert or tap",
    CARD_SWIPE_OR_TAP: "Card swipe or tap",
    INSERT_OR_SWIPE_OR_TAP_CARD: "Insert or swipe or tap card",
    DEVICE_ALREADY_CONNECTED: "Device already connected",
    CARD_COLLISION: "Card collision",
    PRESENT_CARD: "Present card",
    SEE_PHONE: "See phone",
    TOKEN_RETRIEVED: "Token retrieved",
    INVALID_CARD_PLEASE_TRY_AGAIN: "Invalid card please try again",
    AUTHORIZATION_DECISION: "Authorization decision",
    SWIPE_CARD_DETECTED: "Swipe card detected",
    PAYMENT_TYPE_SET: "Payment type set",
    GIFT_CARD_DETECTED: "Gift card detected",
    DELAYED_AUTH_AMOUNT_CONFIRMED: "Delayed auth amount confirmed",
    DELAYED_AUTH_AMOUNT_DECLINED: "Delayed auth amount declined",
    FILE_LOADED_SUCCESSFULLY: "File loaded successfully",
    LEVEL_FULL_NOT_SUPPORTED_IN_LIVE_MODE: "Level full not supported in live mode",
    RECONNECTING: "Reconnecting",
    NULL: "Null",
    DEVICE_CONNECTION_CANCELLED: "Device connection cancelled",
    MSD_CARD_DETECTED: "Msd card detected",
    NO_EMV_APPS: "No emv apps",
    TAP_CARD_DETECTED: "Tap card detected",
    SELF_CHECK_REBOOT: "Self check reboot",
    SWIPE_OR_INSERT_OR_TAP_OR_KEYED_CARD: "Swipe or insert or tap or keyed card",
    SWIPE_OR_KEYED_CARD: "Swipe or keyed card",
    SWIPE_OR_INSERT_OR_KEYED_CARD: "Swipe or insert or keyed card",
    KEYED_CARD: "Keyed card",
    CARD_TAP_DETECTED: "Card tap detected",
    CHIP_ERROR: "Chip error",
    CARD_REMOVED: "Card removed",
    KEYED_CARD_DETECTED: "Keyed card detected",
    TRANSACTION_CANNOT_BE_CANCELED_AT_THIS_STAGE: "Transaction cannot be canceled at this stage"
  },
  rpc: {
    badRequest: "Bad request",
    serverError: "Server error",
    unauthorized: "You are not authorized",
    tokenExpired: "Token expired",
    dataReceived: "Data received",
    accessDenied: "You don`t have access to this operation",
    functionNotFound: "Function not found",
    imagesUploaded: "Images uploaded",
    pleaseEnterLogin: "Please enter a username",
    pleaseEnterBarcode: "Please enter a barcode",
    pinIncorrect: "PIN-code incorrect",
    pinAlreadyDefined: "PIN already defined",
    enterLoginAndPIN: "Please enter a username and PIN",
    enterLoginAndPassword: "Please enter a username and password",
    loginOrPasswordIncorrect: "Wrong login or password",
    userNotFound: "User not found",
    cashierNotAttachedStore: "The cashier is not attached to the store",
    cashierNotAttachedCompany: "The cashier is not attached to the company",
    tryingClosed: "You`ve entered incorrect {field} {maxAttempts} times. Try again in {tryAfter} min.",
    maxUploadedSize: "Max file upload size is {maxUploadedSize} Mb",
    uploadedFormats: "Supports {formats} formats",
    minPictureSize: "Min picture size is {width}x{height}",
    deviceReceived: "Device received",
    deviceNotFound: "Device not found",
    enterDeviceToken: "Enter device token",
    deviceAlreadyPaired: "Device already connected",
    deviceSuccessfullyPaired: "Device successfully connected",
    deviceSuccessfullyUnpaired: "Device successfully disconnected",
    pairedTokenCreated: "Connection token created",
    enterDeviceId: "Enter device id",
    devicePairingState: "Device connection state",
    resetSellerPINOnlineOnly: "Check network connection",
    imageNotFound: "Image not found",
    imageUsed: "The image is used",
    imageDeleted: "Image has been deleted",
    pinOutdated: "PIN code outdated",
    passwordOutdated: "Password outdated",
    enterNewPassword: "Please enter new password",
    sendingUnavailable: "Sending is unavailable",
    supportSendError: "Sending error",
    supportSendSuccess: "Successfully sent",
    noDictAccess: "No access to dictionary",
    pullError: "Pull error",
    documentInvalid: "Document invalid",
    documentCompleted: "Document completed",
    storeEmailInvalid: "The store does not have an email",
    certificateNotFound: "Certificate not found",
    certificateCanceled: "Certificate canceled",
    certificateExpired: "Certificate expired",
    certificateApplied: "Certificate applied",
    unhandledError: "Error",
    errorAlredyReservedTable: "Table already reserved, try another time",
    loyaltyMemberNotFound: "Phone number not found",
  }
}

export { en }
