import dicts from "../dicts"
import * as moment from "moment"

import { push } from "./index"

const SHIFT_HOLD_DAYS = 30

export async function deleteReceivedDocs(types = ["receipt", "refund"]) {
  await dicts.docs.del({ state: "received", "type in": types })
}

export async function deleteHoldShifts() {
  const holdShiftsDate = moment(new Date()).subtract(SHIFT_HOLD_DAYS, "day")
  await dicts.docs.del({ state: "received", "completedOn <=": holdShiftsDate })
}

export async function unpairCleanProcess() {
  if(await dicts.docs.count({ state: "active" }) > 0) {
    return false
  }

  await push()
  await dicts.docs.del({ state: "received" })

  return await dicts.docs.count({ "state in": ["active", "completed"] }) > 0 ? false : true
}