import Handler from './handler'
import state from "../state"

import { isNewReceipt, calculate, save, calculateGood } from "../actions/receipt"
import { round2 } from "../libs/util"
import gui from '../gui'

class AbsoluteDiscount extends Handler {
  constructor() {
    super()
    this.enable = true
    this.options = {}
  }

  async setDiscount(sum, doc = state.receiptData) {
    if(!isNewReceipt(doc)) {
      return false
    }

    doc.sumAbsDiscount = round2(sum)

    await calculate(doc)
    gui.receiptData = doc;

    save(doc).then(() => {
      console.log("Absolute discount saved")
    })
  }

  async calc(doc) {
    if(!isNewReceipt(doc)) {
      return false
    }
    let sumAbsDiscount = doc.sumAbsDiscount || 0
    if (sumAbsDiscount < 0.001) {
      for (const good of doc.goods) {
        good.absDiscount = 0
      }
      return
    }
    
    doc.maxAbsDiscount = 0
    for await (const good of doc.goods) {
      good.absDiscount = 0
      await calculateGood(doc, good)
      good.maxAbsDiscount = good.sum.total // change if need check minPrice
      doc.maxAbsDiscount = round2(doc.maxAbsDiscount + good.maxAbsDiscount)
    }
    if (sumAbsDiscount > doc.maxAbsDiscount) {
      sumAbsDiscount = doc.maxAbsDiscount
    }
    let sumGoodAbsDiscount = 0
    for (const good of doc.goods) {
      good.absDiscount = round2(sumAbsDiscount * (good.maxAbsDiscount / doc.maxAbsDiscount))
      sumGoodAbsDiscount = round2(sumGoodAbsDiscount + good.absDiscount)
    }

    // apply difference
    const diff = round2(sumAbsDiscount - sumGoodAbsDiscount)
    if (Math.abs(diff) > 0.001) {
      for (const good of doc.goods) {
        if ((diff > 0 && round2(good.maxAbsDiscount - good.absDiscount) >= diff) ||
            (diff < 0 && good.absDiscount >= -diff)) {
          good.absDiscount = round2(good.absDiscount + diff)
          break
        }
      }
    }
  }
}


export default new AbsoluteDiscount()