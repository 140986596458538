<template>
  <f7-sheet :opened="opened" class="sheet-calendar" @sheet:closed="onClose" >
    <div v-if="dayData.length > 0" class="day-data">
      <div v-for="attr in dayData" :key="attr.key">
        <div class="form-group">
          <div class="date">{{ $filters.time(attr.customData.reserveDate) }}</div>
          <div class="btn">
            <f7-button @tap="unreserveTable(attr.customData._id)" :disabled="disableConfirm" :id="`delete-reserve-${attr.customData.reserveDate}`">
              {{ $t('tables.unreserve') }}
            </f7-button>
          </div>
        </div>
      </div>
    </div>
    <div class="date-picker-wrap" :class="{ 'error': error }">
      <DatePicker
        v-model="selectedDate"
        :is-range="isRange"
        is-expanded
        :mode="mode"
        :locale="$i18n.locale"
        :min-date="minDate"
        :attributes="attributes"
        ref="datepicker"
        @dayclick="onDayClick"
        :model-config="modelConfig"
      />
      <div v-if="error" class="sheet-calendar-error">
        <div class="sheet-calendar-error-inner">{{ error.msg }}</div>
      </div>
    </div>
    <div v-if="withConfirm" class="calendar-confirm">
      <f7-button @tap="$emit('confirm')" :disabled="disableConfirm || !date.unix" id="calendar-confirm-btn" fill round>
        {{ $t('confirm') }}
      </f7-button>
    </div>
  </f7-sheet>
</template>

<script>
  import { ref, watch } from 'vue';
  import { DatePicker } from 'v-calendar';
  import { date as formatDate } from '../js/filters';
  import { click } from "../js/audio"
  import moment from 'moment';

  export default {
    components: { DatePicker },
    props: {
      error: { type: Boolean, default: false },
      opened: { type: Boolean, required: true },
      valueFrom: { type: [Number, String] },
      valueTo: { type: [Number, String] },
      displayedValueFrom: { type: String },
      displayedValueTo: { type: String },
      id: { type: String, required: true },
      withConfirm: { type: Boolean, default: false },
      isRange: { type: Boolean, default: false },
      disableConfirm: { type: Boolean, default: false },
      mode: { type: String, default: 'single' }, //"date", "dateTime", "time"
      date: { type: Object },
      minDate: { type: Object, default: null },
      attributes: { type: Object, default: undefined },
    },
    emits: [
      "update:date",
      "update:valueFrom",
      "update:valueTo",
      "update:displayedValueFrom",
      "update:displayedValueTo",
      'confirm',
      'sheet:closed',
    ],
    setup(props, { emit }) {
      const selectedDate = ref({
        start: props.valueFrom ? new Date(props.valueFrom) : undefined,
        end: props.valueTo ? new Date(props.valueTo) : undefined,
      });

      const dayData = ref({});
      const datepicker = ref(null);

      const onDayClick = (event) => {
        click();
        dayData.value = event.attributes.filter((item)=>item.customData);
      }

      const unreserveTable = async (id) => {
        const response = await gui.dispatch('tables.unreserve', id);

        if(response) {
          dayData.value = dayData.value.filter((item) => item.customData._id !== id);
        }

      }

      watch(selectedDate, value => {
        if (props.isRange) {
          emit("update:valueFrom", value.start ? value.start.getTime() : '');
          emit("update:valueTo", value.end ? value.end.getTime() : '');
          emit("update:displayedValueFrom", value.start ? formatDate( value.start, { dateShort: true }) : '');
          emit("update:displayedValueTo", value.end ? formatDate( value.end, { dateShort: true }) : '');
        } else {
          emit("update:date", value ? {unix: value.getTime(), displayed: formatDate(value)} : {});
        }
      });

      watch(() => props.attributes, value => {
        const today = moment().startOf('day');
        dayData.value = value.filter((item)=>item?.customData?.reserveDate >= today && item?.customData?.reserveDate <= today+24*60*60*1000-1);
      });

      return {
        datepicker,
        selectedDate,
        onDayClick,
        dayData,
        unreserveTable,
        reset() {
          if (props.isRange) {
            selectedDate.value = {};
          } else {
            selectedDate.value = new Date(moment().startOf('day'));
          }
        },
        onClose() {
          emit('sheet:closed');
          dayData.value = [];

        },
        modelConfig: {
          timeAdjust: '00:00:00',
        },
      }
    },
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .sheet-calendar {
    background-color: $input-color;
    border-radius: $border-radius $border-radius 0 0;
    overflow: hidden;
    .sheet-calendar-error {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      .sheet-calendar-error-inner {
        background-color: $error-color;
        color: #fff;
        font-size: 16px;
        border-radius: $border-radius $border-radius 0 0;
        text-align: center;
        padding: 5px 15px;
        box-sizing: border-box;
        width: 100%;
      }
    }
    .calendar-confirm{
      display: flex;
      justify-content: center;
      padding: $minimal-padding;
      .button {
        width: $basic-width;
        font-size: $text-size;
        font-weight: bold;
      }
    }
  }
  .day-data {
    padding: 0 $minimal-padding;
    max-height: 100px;
    overflow-y: auto;
    border-bottom: 1px solid #cbd5e0;;
    .form-group {
      display: flex;
      justify-content: space-between;
      .date{
        display: flex;
        align-items: center;
      }
      .btn{

      }
    }
  }
</style>
