<template>
  <Page schema="blue" class="certificates-page" id="receipts-certificates-page">
    <Header>
      <div class="left">
        <f7-link id="certificates-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t("certificates.title") }}</div>
    </Header>

    <Inner>
      <div v-if="receipt.certificates && receipt.certificates.length > 0" class="certificates-list">
        <div v-for="certificate of receipt.certificates" :key="certificate._id" class="certificate-item">
          <SwipeItem>
            <template #left>
              <f7-button @tap="cancel(certificate)" class="fill red">{{ $t("certificates.delete") }}</f7-button>
            </template>
            <div class="certificate-item-inner">
              <div class="certificate-item-left">
                <div class="certificate-item-title">{{ certificate.name }}</div>
              </div>
              <div class="certificate-item-right">
                <div class="certificate-item-amount">${{ $filters.sum(certificate.amount) }}</div>
              </div>
            </div>
          </SwipeItem>
        </div>
      </div>
      <div v-else class="category-empty">
        <div class="category-empty-text">{{ $t("certificates.empty") }}</div>
      </div>

      <template #fixed>
        <f7-fab @tap="openManualApply" id="add-certificate" position="right-bottom">
          <i class="mpi-md-add"></i>
        </f7-fab>
      </template>
    </Inner>
  </Page>
</template>

<script>
  import { ref, watch } from "vue"
  import SwipeItem from "@/components/f7/SwipeItem"

  export default {
    components: { SwipeItem },
    setup() {
      const receipt = ref(gui.receiptData)

      watch(gui.receiptData, value => {
        receipt.value = value
      })

      // Barcode scanner
      gui.onScanned.value = barcode => gui.dispatch("receipt.scannedBarcode", { barcode })

      return {
        receipt,
        cancel: certificate => gui.dispatch("certificates.cancelCertificate", certificate._id),
        openManualApply: () => gui.dispatch("certificates.openManualApply")
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .certificates-list {
    padding: $minimal-padding;
    padding-bottom: $fab-bottom + $fab-bottom-margin;

    .certificate-item {
      margin-bottom: $minimal-padding / 2;

      &:last-child {
        margin-bottom: 0;
      }

      .swipe-item-left {
        box-sizing: border-box;
        padding-top: 5px;
        padding-bottom: 5px;
        .button {
          height: 100%;
          font-weight: bold;
          font-size: 16px;
          padding: 0 18px;
          &:last-child {
            border-radius: $border-radius 0 0 $border-radius;
          }
        }
      }

      .certificate-item-inner {
        background-color: #fff;
        border-radius: $border-radius;
        box-shadow: $item-shadow;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 75px;
        padding: 0 20px;
        font-size: 16px;

        .certificate-item-title {
          font-size: 16px;
          line-height: 18.4px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }

        .certificate-item-left {
          flex: 1;
        }

        .certificate-item-right {
          padding-left: 15px;
        }

        .certificate-item-amount {
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
  }
</style>
