<template>
  <f7-list-item class="shifts-list-item" :clickable="clickable">
    <template #inner>
      <div class="shifts-list-item-inner">
        <div class="shifts-list-item-row shifts-list-item-title">
          <div class="row-cell">{{ $filters.date(report.completedOn) }}</div>
          <div class="row-cell">{{ report.sumTotal }}</div>
        </div>
        <div class="shifts-list-item-row shifts-list-item-subtitle">
          <div class="row-cell">{{ report.userName }}</div>
          <div class="row-cell">{{ `#${report.displayedId}` }}</div>
        </div>
      </div>
    </template>
  </f7-list-item>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<style lang='scss'>
  @import "@/css/variables";

  .shifts-list .shifts-list-item:last-child {
    margin-bottom: 0;
  }

  .shifts-list-item {
    margin-bottom: $item-margin;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff;
    border-radius: $border-radius;
    padding: 14px 18px;
    box-shadow: $item-shadow;

    .item-link {
      padding: 0 !important;
      margin: 0 !important;
    }
    .item-content {
      padding: 0 !important;
      .item-inner {
        padding: 0 !important;
        width: 100%;
      }
    }
  }
  .shifts-list-item .item-content .item-inner::after {
    content: none;
  }
  .shifts-list-item-inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .shifts-list-item-row {
    display: flex;
    justify-content: space-between;
    .row-cell {
      display: flex;
      align-items: center;
    }
  }
  .shifts-list-item-title {
    font-weight: 600;
    font-size: $text-size;
    line-height: 118.75%;
    color: $text-color;
    height: 25px;
    margin-bottom: 4px;
  }
  .shifts-list-item-subtitle {
    font-size: $text-size-secondary;
    line-height: 118.75%;
    color: $text-secondary;
    height: 22px;
  }
</style>