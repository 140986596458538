import promotions from "./handlers/promotions/handler"
import taxes from './handlers/taxes';
import manualDiscount from './handlers/manualDiscount';
import absDiscount from './handlers/absDiscount';
import tips from './handlers/tips';

const handlersData = {
  promotions,
  tips,
  manualDiscount,
  taxes,
  absDiscount
}

const handlers = [  //Object.values(handlersData)
  promotions,
  tips,
  manualDiscount,
  taxes,
  absDiscount, // need run after % discount
]

async function defaultUpdate(handler) {
  //TODO: read from dicts
}

async function update() {
  for (const handler of handlers) {
    typeof (handler.update) === 'function'
    ? await handler.update()
    : defaultUpdate(handler)
  }
}

async function calc(doc, options = {}) {
  for (const handler of handlers) {
    typeof (handler.calc) === 'function' && await handler.calc(doc, options)
  }
}

async function checkGood(doc, good) {
  for (const handler of handlers) {
    const res = typeof (handler.checkGood) === 'function' && await handler.checkGood(doc, good)
    if (res === false) {
      return false
    }
  }
}

async function createReceipt(doc) {
  for (const handler of handlers) {
    const res = typeof (handler.createReceipt) === 'function' && await handler.createReceipt(doc)
    if (res === false) {
      return false
    }
  }
}

async function checkDoc(doc) {
  for (const handler of handlers) {
    const res = typeof (handler.checkDoc) === 'function' && await handler.checkDoc(doc, good)
    if (res === false) {
      return false
    }
  }
}

async function account(doc, good) {
  for (const handler of handlers) {
    typeof (handler.account) === 'function' && await handler.account(doc)
  }
}

async function close(doc, good) {
  for (const handler of handlers) {
    typeof (handler.calc) === 'function' && await handler.calc(doc)
  }
}

async function clear(doc) {
  for (const handler of handlers) {
    typeof (handler.clear) === 'function' && await handler.clear(doc)
  }
}

export default {
  ...handlersData,
 calc,
 checkGood,
 checkDoc,
 close,
 account,
 update,
 createReceipt,
}
