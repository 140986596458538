<template>
  <div id="toast-item" class="toast" :class="{ 'fade-in': toastData.shown, 'fade-out': !toastData.shown }">
    <div class="toast-inner">{{ toastData.message }}</div>
  </div>
</template>

<script>
  import { ref, watch } from "vue"

  export default {
    setup() {
      const toastData = ref(gui.toastData.value)

      watch(gui.toastData, value => {
        toastData.value = value
      })

      return { toastData }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .toast {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 13501;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
    pointer-events: none;

    &.fade-in {
      visibility: visible;
      opacity: 1;
      .toast-inner {
        transform: scale(1);
      }
    }

    &.fade-out {
      .toast-inner {
        transform: scale(1.3);
      }
    }

    .toast-inner {
      pointer-events: auto;
      background-color: rgba(#000, .9);
      color: #fff;
      font-size: 18px;
      border-radius: 18.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 14px;
      box-sizing: border-box;
      transform: scale(0);
      transition: .3s;
    }
  }
</style>