<template>
  <f7-page-content
    infinite
    :infinite-distance="50"
    @infinite="loadMore"
    class="tab-content"
  >
    <template v-if="receipts?.length > 0">
      <f7-list class="receipts-list-wrap" no-hairlines no-chevron>
        <ul class="receipts-list">
          <ReceiptsListItem
            v-for="receipt in receipts"
            :key="receipt._id"
            :receipt="receipt"
            @click="$emit('receipt-click', receipt)"
            link
          />
        </ul>
      </f7-list>
    </template>
    <template v-else>
      <div class="category-empty">
        <div class="category-empty-text">
          {{ $helpers.orderOrReceipt("receipts.receiptsIsMissing", "receipts.ordersIsMissing", true).value }}
        </div>
      </div>
    </template>
  </f7-page-content>
</template>
<script>
import ReceiptsListItem from "./ReceiptsListItem";
import { ref, watch } from "vue";

export default {
  components: {
    ReceiptsListItem,
  },
  props: {
    receipts: {
      type: Object,
    },
  },
  setup(props) {
    const receipts = ref(gui.receipts);
    const activeTab = ref(receipts.value.activeTab);
    const allowInfinite = ref(receipts.value.loadMoreEnable[activeTab.value]);

    watch(gui.receipts, (value) => {
      receipts.value = value;
      activeTab.value = value.activeTab;
      allowInfinite.value = value.loadMoreEnable[value.activeTab];
    });

    const loadMore = async () => {
      if (!receipts.value.loadMoreEnable[activeTab.value]) return;
      if (receipts.value.searching.loading) return;
      
      if (
        receipts.value[activeTab.value].length <
        receipts.value.offset[activeTab.value]
      ) {
        await gui.dispatch("docs.disableLoadMore", "receipts");
        return;
      }

      allowInfinite.value = false;
      await gui.dispatch("docs.loadMoreDocs", "receipts");
      allowInfinite.value = true;
    };

    return {
      loadMore,
    };
  },
  emits: ["receipt-click"],
};
</script>

<style lang="scss">
.receipts-list-wrap {
  margin: 0;
  .receipts-list {
    background: none;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0);
  }
}
</style>