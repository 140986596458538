import gui from '../gui';
import * as native from "../native"

let cache = []

export async function getScreenshotsList() {
  const response = await native.call("fs.entryInfoList", "/storage/emulated/0/Pictures/Screenshots");
  return response.sort((a, b) => b.lastModified - a.lastModified).slice(0, 8)
}

export async function getScreenshots() {
  try {
    gui.preloader(true)
    const screenShotsList = await loadScreenshots(await getScreenshotsList())
    gui.preloader(false)

    cache = screenShotsList

    return screenShotsList
  } catch(e) {
    gui.preloader(false)
    return []
  }
}
async function loadScreenshots(list) {
  await Promise.all(list.map(screen => loadScreenshot(screen)))
  return list
}
async function loadScreenshot(screen) {
  const cachedItem = cache.find(item => item.absoluteFilePath === screen.absoluteFilePath)

  if(cachedItem) {
    screen.base64 = cachedItem.base64
  } else {
    screen.base64 = await getBase64FromUrl(screen.absoluteFilePath)
  }
}
async function getBase64FromUrl(url) {
  return "data:text/plain;base64," + await native.call("fs.fileToBase64", url);
}


