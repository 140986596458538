import dicts from "../dicts.js"
import { checkAccessAvailable } from "./user.js"
import { error, success } from "../libs/response.js"

export const NOT_AUTHORIZED = error(401, "You are not authorized", "rpc.unauthorized")
export const NOT_FOUND_RESPONSE = error(400, "Login or password incorrect", "rpc.loginOrPasswordIncorrect")

export const LOCK_ACCOUNT_MINUTES = 15
export const MAX_LOGIN_ATTEMPTS = 3

export async function save(user) {
  await dicts.users.put(user)

  return user
}

export async function updateSellerPIN(authData) {
  const user = await dicts.users.getOne({ login: authData.user.login })

  if(user) {
    user.hashedPin = authData.user.hashedPin
    await save(user)
  }
}

export async function getPopulatedUserData(user) {
  const store = user.storeId ? await dicts.stores.getOne({ _id: user.storeId, enabled: true }) : null
  const company = user.companyId ? await dicts.companies.getOne({ _id: user.companyId, enabled: true }) : null

  if(store) {
    store.business = await dicts.business_types.getOne({ _id: store.businessId })
  }

  return {
    groups: await getUserGroupsData(user),
    company,
    store
  }
}

export async function getUserGroupsData(user) {
  const groups = { names: [], keys: [], permissions: [] }
  if(Array.isArray(user.groups)) {
    for(let key of user.groups) {
      const group = await dicts.groups.getOne({ key })
      if(group) {
        groups.permissions.push(...group.permissions)
        groups.names.push(group.name)
        groups.keys.push(group.key)
      }
    }
  }

  return groups
}

export async function getPreparedUserResponse(user) {
  const token = null

  return { user, populated: await getPopulatedUserData(user), token }
}

export async function identifySeller(query) {
  const user = await dicts.users.getOne({ ...query, enabled: true })

  if(user) {
    const prepared = await getPreparedUserResponse(user)
    const error = await checkPreparedUserAccess(prepared, "all.cashier.login")

    if(error === null) {
      return success("Data received", "rpc.dataReceived", {
        pinDefined: user.pinDefined === true,
        login: user.login
      })
    } else {
      return error
    }
  } else {
    return error(404, "User not found", "rpc.userNotFound")
  }
}

export async function checkPreparedUserAccess(prepared, permission) {
  if(permission === "all.cashier.login" && !prepared.populated.store) {
    return error(400, "The cashier is not attached to the store", "rpc.cashierNotAttachedStore")
  }

  if(permission === "all.cashier.login" && !prepared.populated.company) {
    return error(400, "The cashier is not attached to the company", "rpc.cashierNotAttachedCompany")
  }

  if(prepared.populated && prepared.populated.groups && prepared.populated.groups.permissions && await checkAccessAvailable(permission, prepared.populated.groups.permissions)) {
    return null
  }

  return error(403, "You don`t have access to this operation", "rpc.accessDenied")
}

export async function checkLoginWithAttempts(user, permission, checkHandler, incorrectFieldName = "PIN") {
  const error = await checkUserForAttempts(user, incorrectFieldName)
  if(error !== null) {
    return error
  } else {
    if(checkHandler(user)) {
      user.loginAttempts = []
      await save(user)

      const prepared = await getPreparedUserResponse(user)
      const error = await checkPreparedUserAccess(prepared, permission)

      if(error === null) {
        return success("Data received", "rpc.dataReceived", { authData: prepared })
      } else {
        return error
      }
    } else {
      const loginAttempts = Array.isArray(user.loginAttempts) ? user.loginAttempts : []
      loginAttempts.unshift({ expired: Date.now() + LOCK_ACCOUNT_MINUTES * 60 * 1000 })

      user.loginAttempts = loginAttempts
      await save(user)

      const error = await checkUserForAttempts(user, incorrectFieldName)
      return error || NOT_FOUND_RESPONSE
    }
  }
}

export async function checkUserForAttempts(user, incorrectFieldName) {
  const loginAttempts = Array.isArray(user.loginAttempts) ? user.loginAttempts : []
  const notExpiredAttempts = loginAttempts.filter(attempt => attempt.expired > Date.now())

  if(notExpiredAttempts.length >= MAX_LOGIN_ATTEMPTS) {
    const minutes = Math.ceil((loginAttempts[0].expired - Date.now()) / 60000)

    return error(403, `You\`ve entered incorrect ${incorrectFieldName} ${MAX_LOGIN_ATTEMPTS} times. Try again after in ${minutes} min.`, "rpc.tryingClosed", {
      languageOptions: { field: incorrectFieldName, maxAttempts: MAX_LOGIN_ATTEMPTS, tryAfter: minutes }
    })
  }

  return null
}