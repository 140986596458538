<template>
  <div class="filter-block">
    <div class="filters-group-title">{{ title }}</div>
    <div class="filters-group-content">
      <div class="form-group" :class="{ columns: twoColumns }">
        <div
          v-for="(option, index) in options"
          :key="option.text"
          class="filters-option"
          :id="`filters-option-${id}-${index}`"
          @click="clickOption(option)"
        >
          <div class="filters-option-content ripple" :class="{'disabled': option.disabled}">
            <CustomCheckbox :checked="exactValueMatch ? filter[property]?.toString() === option.value : filter[property]?.includes(option.value)" scale/>
            <div class="filters-option-content-title">
              {{ option.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { click } from "@/js/audio"
  import CustomCheckbox from '../CustomCheckbox';

  export default {
    components: { CustomCheckbox },
    props: {
      title: { type: String, required: true },
      id: { type: String, required: true },
      filter: { type: Object, required: true },
      options: { type: Object, required: true },
      property: { type: String, required: true },
      twoColumns: { type: Boolean, default: false },
      exactValueMatch: { type: Boolean, default: false },
    },
    emits: ["change"],
    methods: {
      clickOption(option) {
        click()
        this.$emit("change", this.property, option.value)
      }
    }
  };
</script>

<style lang="scss">
  @import "@/css/variables";

  .filter-block {
    .filters-group-title {
      margin: 30px 0 10px 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 118.75%;
      color: #22272c;
    }
    .filters-group-content {
      .form-group {
        display: grid;
        &.columns {
          gap: 20px;
          grid-template-columns: repeat(2, 1fr);
        }

        .filters-option {
          overflow: hidden;
          height: 40px;
          .filters-option-content {
            font-size: 18px;
            line-height: 200%;
            color: #000000;
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding-left: 4px;
            height: 40px;
            &.disabled {
              opacity: 0.5;
            }
            .filters-option-content-title {
              padding-left: 14px;

            }
          }
        }
      }
    }
  }
</style>