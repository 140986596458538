<template>
  <div class="favourites-list">
    <div v-for="good of favourites" :key="good._id" class="favourite-item-container">
      <f7-button @tap="$emit('good-click', good)" class="favourite-item ripple-color-black">
        <div class="favourite-item-inner">
          <div class="favourite-item-image">
            <AsyncImage :id="good.mainImage"/>
          </div>
          <div class="favourite-item-title">
            <div class="favourite-item-title-inner">{{ good.name }}</div>
          </div>
        </div>
      </f7-button>
    </div>
  </div>
</template>

<script>
  import { watch, ref } from "vue"

  import AsyncImage from "../AsyncImage"

  export default {
    components: { AsyncImage },
    props: {
      favourites: { type: Array, default: () => [] }
    },
    setup(props) {
      const favourites = ref(props.favourites)

      watch(() => props.favourites, value => {
        favourites.value = value
      })

      return {
        favourites
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .favourites-list {
    padding: $minimal-padding;
    display: grid;
    grid-template-columns: calc(50% - 6px) calc(50% - 6px);
    gap: $minimal-padding;
    .favourite-item-container {
      .favourite-item {
        background-color: #fff;
        border-radius: $border-radius;
        height: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-transform: none;
        font-size: 18px;
        line-height: 18px;
        font-weight: normal;
        color: $text-color;
        box-shadow: $item-shadow;
        padding: 0;
        overflow: hidden;
        .favourite-item-title {
          height: 47px;
          padding: 0 4px;
          box-sizing: border-box;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          .favourite-item-title-inner {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            white-space: normal;
          }
        }

        .favourite-item-inner {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          width: 100%;
          height: 100%;
          padding: 0;
          border-radius: 0;
        }

        .favourite-item-image {
          flex: 1;
          width: 100%;
          box-sizing: border-box;
          .async-image-container {
            padding: $minimal-padding;
            padding-bottom: 0;
            box-sizing: border-box;
            .async-image {
              border-radius: $border-radius;
            }
            &.cover {
              padding: 0 !important;
              .async-image {
                border-radius: 0 !important;
              }
            }
          }
        }
      }
    }
  }
</style>
