<template>
  <Page
      ref="page"
      schema="blue"
      class="add-item-page white-header-page"
      id="add-item-page"
      @page:beforeout="onPageBeforeOut"
      @page:beforein="onPageBeforeIn">
    <!-- Header -->
    <Header
        ref="header"
        v-model="searchingValue"
        @search:update="search"
        @search:enable="enableSearching"
        @search:disable="disableSearching"
        search-id="add-item-search-field"
        :search-hide-outside="false"
        :search-loading="favourites.searching.loading"
        :search-placeholder="$t('receipts.searchAllProducts')">
      <div class="left">
        <f7-link id="add-item-back" @tap="back" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>

      <div class="title">
        <span>{{ navbarTitle }}</span>
        <div v-if="navbarSubtitle !== ''" class="subtitle">{{ navbarSubtitle }}</div>
      </div>

      <div class="right">
        <f7-link id="add-item-search" class="searchbar-enable icon-only" @tap="openSearchBar">
          <i class="icon mpi-search color-black"></i>
        </f7-link>
      </div>

      <template #out>
        <Subnavbar v-if="!isSearchActive">
          <div v-for="tab of tabs" :key="tab.key" class="subnavbar-button-item">
            <f7-button
              round
              :id="`add-item-${tab.key}-btn`"
              :tab-link-active="activeTab === tab.key"
              @tap="changeTab(tab.key)">{{ $t(tab.title) }}</f7-button>
          </div>
        </Subnavbar>
      </template>
    </Header>

    <!-- Page content -->
    <Inner>
      <f7-tabs>
        <f7-tab id="tab-searching" :tab-active="activeTab === 'searching'">
          <Searching @good-click="addGood" :goods="favourites.searching.goods" />
        </f7-tab>
        <f7-tab id="tab-favourites" :tab-active="activeTab === 'favourites'">
          <template v-if="favourites.goods.length > 0">
            <div class="favourites-tab-container">
              <Favourites @good-click="addGood" :favourites="favourites.goods" />
              <Uploading :loading="uploading" />
            </div>
          </template>
          <div v-else class="category-empty">
            <div class="category-empty-text">{{ $t("receipts.favouritesEmpty") }}</div>
          </div>
        </f7-tab>
        <f7-tab id="tab-categories" :tab-active="activeTab === 'categories'">
          <Categories @category-click="openCategory" :category="favourites.category" />
        </f7-tab>
      </f7-tabs>
    </Inner>
  </Page>
</template>

<script>
  import { ref, watch, computed } from "vue"
  import { debounce } from "lodash"
  import { isCafe, orderOrReceipt } from "../../js/helpers"

  import Favourites from "../../components/Receipt/Favourites"
  import Categories from "../../components/Receipt/Categories"
  import Searching from "../../components/Receipt/Searching"
  import Uploading from "../../components/Uploading"

  export default {
    components: {
      Uploading,
      Searching,
      Categories,
      Favourites,
    },
    setup() {
      const page = ref(null)
      const header = ref(null)
      const uploading = ref(false)
      const favourites = ref(gui.favourites)
      const searchingValue = ref("")
      const receipt = ref(gui.receiptData)

      const navbarTitle = computed(() => {
        if(!gui.receiptData.value.displayedId) {
          return orderOrReceipt("receipts.newReceipt", "receipts.newOrder", true).value
        } else {
          return `#${gui.receiptData.value.displayedId}`
        }
      })

      const navbarSubtitle = computed(() => {
        if(gui.receiptData.value.hall && gui.receiptData.value.tableName) return `${ gui.receiptData.value.hall }, ${ gui.receiptData.value.tableName }`
        if(gui.receiptData.value.hall) return `${ gui.receiptData.value.hall }`
        if(gui.receiptData.value.tableName) return `${ gui.receiptData.value.tableName }`
        return ''
      })

      const tabs = [
        { title: "receipts.favourites", key: "favourites" },
        { title: isCafe() ? "receipts.menu" : "receipts.categories", key: "categories" },
      ]

      const activeTab = ref(favourites.value.activeTab)
      const isSearchActive = ref(false)

      const search = () => {
        gui.dispatch("favourites.searchGoods", { filter: searchingValue.value })
      }

      const uploadFavourites = async () => {
        uploading.value = true
        await gui.dispatch("favourites.uploadFavourites")
        uploading.value = false
      }

      const onScroll = async event => {
        if(!uploading.value && favourites.value.total > favourites.value.goods.length) {
          const favouritesContainer = event.target.querySelector(".favourites-tab-container")
          if(favouritesContainer) {
            const scrollTop = event.target.scrollTop
            const offsetHeight = event.target.offsetHeight
            const favouritesHeight = favouritesContainer.offsetHeight

            if((scrollTop + offsetHeight) > (favouritesHeight - 50)) {
              await uploadFavourites()
            }
          }
        }
      }

      watch(isSearchActive, () => {
        if(isSearchActive.value) {
          document.getElementById("add-item-page")?.classList?.add("hidden-sub-navbar")
        } else {
          document.getElementById("add-item-page")?.classList?.remove("hidden-sub-navbar")
        }
      })

      watch(gui.receiptData, (value) => {
        receipt.value = value;
      })

      // Barcode scanner
      gui.onScanned.value = barcode => {
        gui.dispatch("receipt.scannedBarcode", { barcode })
      }

      return {
        page,
        tabs,
        header,
        search: debounce(search, 300),
        receipt,
        activeTab,
        uploading,
        favourites,
        navbarTitle,
        navbarSubtitle,
        searchingValue,
        isSearchActive,
        back: () => gui.dispatch("receipt.back"),
        addGood: good => gui.dispatch("receipt.addGood", { id: good._id }),
        openCategory: category => gui.dispatch("favourites.openCategory", { id: category._id }),
        openSearchBar: () => {
          searchingValue.value = ""
          header.value.enableSearching()
        },
        enableSearching: () => {
          favourites.value.searching.goods = []
          isSearchActive.value = true
          activeTab.value = "searching"
          gui.totalSheet.value.hide()
        },
        disableSearching: () => {
          favourites.value.searching.goods = []
          isSearchActive.value = false
          activeTab.value = "favourites"
          gui.totalSheet.value.show()
        },
        changeTab: key => {
          activeTab.value = key
          gui.dispatch("favourites.changeTab", key)
        },
        onPageBeforeOut: () => {
          gui.totalSheet.value.hide()
          page.value.$el.querySelector(".page-content").removeEventListener("scroll", onScroll, false)
        },
        onPageBeforeIn: () => {
          gui.totalSheet.value.show()
          page.value.$el.querySelector(".page-content").addEventListener("scroll", onScroll, false)
        },
      }
    },
  };
</script>

<style lang="sass">
  @import "../../css/pages/receipts/add-item"
</style>


