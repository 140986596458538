<template>
  <div class="loyalty-dialog" id="loyalty-dialog" :class="{ 'fade-in': loyaltyData.shown, 'fade-out': !loyaltyData.shown }">
    <Page schema="transparent" class="login-page" :class="{ 'first-slide-open': slide === 1 }" >
      <Header>
        <div class="left">
          <f7-link @tap="back" class="icon-only">
            <i class="icon mpi-back color-black"></i>
          </f7-link>
        </div>
        <div class="title" :class="{'login-title-fade-out': slide === 1}">{{ $t("loyalty.headerTitle") }}</div>
      </Header>

      <Inner>
        <div class="loyalty-page-swiper">
          <div class="loyalty-page-inside" :style="sliderStyles">
            <div class="loyalty-slide first-slide">
              <div class="content-wrap">
                <div class="content-header">
                  <div class="input-wrap">
                    <PhoneField 
                      ref="phoneFieldRef"
                      :title="$t('loyalty.phone')"
                      :error="loyaltyData.error ? $t(loyaltyData.error?.key) : ''"
                      v-model="phone"
                    />
                  </div>
                </div>
                <div class="next-button-wrap">
                  <f7-button @click="apply" :disabled="disabledNext" id="loyalty-add-button" round fill large>
                    {{ $t("loyalty.next") }}
                  </f7-button>
                </div>
                <Keyboard 
                  id="loyalty-keyboard" 
                  @confirm="apply" 
                  ref="keyboardInputRef" 
                  :disabled="disabledNext" 
                />
              </div>
            </div>

            <div class="loyalty-slide second-slide">
              <div class="common-dialog-container flex-ends">
                <div class="common-dialog-header">
                  <div class="common-dialog-icon">
                    <BigIcon class="animate__bounceIn" icon="login" :loading="false" />
                  </div>

                  <div class="common-dialog-title">{{ $t("loyalty.memberTitle") }}</div>
                  <div v-if="loyaltyData.member" class="common-dialog-description">
                    <div>{{ loyaltyData.member.name }}</div>
                    <div>{{ `+${loyaltyData.member.phone}` }}</div>
                  </div>
                </div>
                
                <div class="common-dialog-footer">
                  <template v-if="showClear">
                    <div class="common-dialog-button fill">
                      <f7-button id="loyalty-clear-button" @tap="clear" large round fill>{{$t("loyalty.clear")}}</f7-button>
                    </div>
                    <div class="common-dialog-button">
                      <f7-button id="loyalty-back-button" @tap="back" large round>{{$t("loyalty.back")}}</f7-button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="common-dialog-button fill">
                      <f7-button id="loyalty-next-button" @tap="next" large round fill>{{$t("loyalty.next")}}</f7-button>
                    </div>
                    <div class="common-dialog-button">
                      <f7-button id="loyalty-cancel-button" @tap="cancel" large round>{{$t("loyalty.cancel")}}</f7-button>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Inner>
    </Page>
  </div>
</template>

<script>
  import { ref, watch, computed, onMounted } from "vue"

  import Keyboard from "./../Keyboard"
  import BigIcon from "./../BigIcon"
  import PhoneField from "./../PhoneField"

  export default {
    components: { BigIcon, Keyboard, PhoneField },
    setup() {
      const loyaltyData = ref(gui.loyaltyData);
      const phone = ref('');
      const keyboardInputRef = ref(null)
      const phoneFieldRef = ref(null)

      watch(gui.loyaltyData, value => {
        loyaltyData.value = value
      })

      const slide = computed(() => {
        if (gui.loyaltyData.value.error) return 0;
        if (gui.loyaltyData.value.member) return 1;
        if (!gui.loyaltyData.value.member) return 0;
      })

      onMounted(() => {
        keyboardInputRef.value.setRelation(phoneFieldRef.value)
      })

      const disabledNext = computed(() => {
        return phone.value.length <= 10
      })

      const sliderStyles = computed(() => {
        return { transform: `translateX(-${slide.value * 100}%)` }
      })

      const showClear = computed(() => {
        return !!gui.receiptData.value.loyaltyMember;
      })

      return {
        loyaltyData,
        showClear,
        phone,
        slide,
        sliderStyles,
        keyboardInputRef,
        phoneFieldRef,
        disabledNext,
        apply: () => {
          gui.dispatch("loyalty.findMember", { phone: phone.value });
        },
        back: () => {
          gui.dispatch("loyalty.close");
          phone.value = '';
        },
        cancel: () => {
          gui.dispatch("loyalty.clearMemberData");
        },
        next: () => {
          gui.dispatch("loyalty.addMember", loyaltyData.value.member);
          phone.value = '';
        },
        clear: () => {
          gui.dispatch("loyalty.clear");
        },
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .loyalty-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateY(110%);
    background-color: $main-bg-color;
    z-index: 13499;
    transition: .3s;

    &.fade-out {
      .animate__bounceIn {
        animation-name: none !important;
      }
    }

    &.fade-in {
      visibility: visible;
      transform: translateY(0);
    }

    .first-slide {
      height: 100%;
      width: 100%;
      padding-top: $white-navbar-height;
      box-sizing: border-box;
      .content-wrap {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
          .content-header {
            flex-grow: 1;
            font-weight: 600;
            font-size: 18px;
            line-height: 118.75%;
            color: $text-color;
            display: flex;
            justify-content: center;
            align-items: center;
            .input-wrap {
              width: $basic-width;
          }
        }
        .next-button-wrap {
          padding: 0 58px 24px 58px;
          box-sizing: border-box;
          .button {
            font-weight: bold;
            font-size: $text-size;
          }
        }
      }
    }
  }

  .loyalty-page-swiper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    .loyalty-page-inside {
      display: flex;
      height: 100%;
      min-height: 100%;
      transition: .3s;
      .loyalty-slide {
        min-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow: hidden;
      }
    }
  }

</style>
