<template>
  <Page id="settings-page" class="settings-page" schema="blue">
    <!--  Header  -->
    <Header>
      <div v-if="options.closable" class="left">
        <f7-link id="settings-back" @tap="$gui.navigateBack()" class="icon-only" icon-color="black">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ options.isInitialSetup ? $t("settings.initialSetupTitle") : $t("settings.title") }}</div>
      <div class="right">
        <f7-link class="text" @tap="saveSettings" id="settings-save">{{ options.isInitialSetup ? $t("settings.start") : $t("settings.save") }}</f7-link>
      </div>
    </Header>

    <!--  Page content  -->
    <Inner>
      <div class="settings-page-wrapper">
        <div class="settings-list-container">
          <!--  Printers  -->
          <div class="settings-item-container">
            <div class="settings-item-title">{{ $t("settings.printersList") }}</div>
            <div class="settings-item-body">
              <Printers v-if="settings.printers.length > 0" @delete="deletePrinter" ref="printersRef" :options="options" :printersTypes="options.printersTypes" :hints="bluetoothPrinters" v-model="settings.printers" />
              <div v-else class="settings-item-empty">{{ $t("settings.printersEmpty") }}</div>
            </div>
          </div>

          <!--  Application settings  -->
          <div class="settings-item-container application-settings">
            <div class="settings-item-title">{{ $t("settings.applicationSettings") }}</div>
            <div class="settings-item-body">
              <div class="settings-item toggle-item">
                <div class="settings-title">{{ $t("settings.offlinePayments") }}</div>
                <div class="settings-value">
                  <f7-toggle v-model="settings.offlinePayments" />
                </div>
              </div>
              <div class="settings-item toggle-item">
                <div class="settings-title">{{ $t("settings.disableAnimation") }}</div>
                <div class="settings-value">
                  <f7-toggle v-model="settings.disableAnimation" />
                </div>
              </div>
              <div class="settings-item slider-item">
                <div class="settings-title">{{ $t("settings.clickVolume") }}</div>
                <div class="settings-value">
                  <RangeSlider v-model="settings.clickVolume" :min="0" :max="100" />
                </div>
              </div>
            </div>
          </div>

          <!--  Device information  -->
          <div class="settings-item-container">
            <div class="settings-item-title">{{ $t("settings.deviceInformation") }}</div>
            <div class="settings-item-body">
              <div class="settings-item">
                <div class="settings-title">{{ $t("settings.name") }}</div>
                <div class="settings-value">{{ options.device.deviceName }}</div>
              </div>
              <div class="settings-item">
                <div class="settings-title">{{ $t("settings.serial") }}</div>
                <div class="settings-value">{{ options.device.deviceSerialNumber }}</div>
              </div>
              <div class="settings-item">
                <div class="settings-title">{{ $t("settings.pairedOn") }}</div>
                <div class="settings-value">{{ $filters.date(options.device.pairedOn) }}</div>
              </div>
            </div>
          </div>
        </div>

        <!--  Initial setup  -->
        <div v-if="options.isInitialSetup" class="settings-first-mode">{{ $t("settings.setupDescription") }}</div>
      </div>

      <template #fixed>
        <f7-fab :items="fabItems">
          <i class="mpi-md-plus"></i>
        </f7-fab>
      </template>
    </Inner>
  </Page>
</template>

<script>
  import { ref, watch, computed } from "vue"

  import Printers from "../components/Settings/Printers"
  import RangeSlider from "@vueform/slider"

  export default {
    components: { Printers, RangeSlider },
    setup() {
      const getPrintersList = devices => {
        const printers = []
        for(let device of Object.values(devices)) {
          if(device.majorDeviceClass == 6) {
            printers.push(device)
          }
        }
        return printers
      }

      const printersRef = ref(null)
      const bluetoothPrinters = ref(getPrintersList(gui.bluetoothDevices.value))
      const settings = ref(gui.settings.value.settings)
      const options = ref(gui.settings.value.options)

      watch(gui.settings, value => {
        settings.value = value.settings
        options.value = value.options
      })

      watch(gui.bluetoothDevices, value => {
        bluetoothPrinters.value = getPrintersList(value)
      })

      const hidePickers = () => printersRef.value?.hidePickers()
      const unpairDevice = () => gui.dispatch("settings.unpairDevice")
      const synchronization = () => gui.dispatch("settings.synchronization")

      const addPrinter = () => {
        settings.value.printers.push({ type: options.value.printersTypes[0].key, address: "", _id: Date.now() })
        hidePickers()
      }

      return {
        options,
        settings,
        printersRef,
        bluetoothPrinters,
        addPrinter,
        unpairDevice,
        synchronization,
        deletePrinter: printer => {
          settings.value.printers = settings.value.printers.filter(item => printer !== item)
        },
        async saveSettings() {
          await gui.dispatch("settings.saveSettings", { ...settings.value })
          hidePickers()
        },
        fabItems: computed(() => {
          const items = []

          if(!options.value.isInitialSetup) {
            items.push({
              label: gui.translate("settings.unpairingDevice"),
              color: "red",
              icon: "mpi-md-leak-remove",
              click: unpairDevice,
              id: "settings-unpairing-device"
            })

            items.push({
              label: gui.translate("settings.synchronization"),
              color: "green",
              icon: "mpi-md-sync",
              click: synchronization,
              id: "settings-synchronization"
            })
          }

          items.push({
            label: gui.translate("settings.addPrinter"),
            icon: "mpi-md-print",
            click: addPrinter,
            id: "settings-add-printer"
          })

          return items
        })
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";
  $height-item: 50px;

  .settings-page {
    .settings-page-wrapper {
      padding-bottom: 96px !important;
    }
  }

  .settings-item-body {
    .settings-hint-printers {
      background-color: $select-color;
      .settings-item {
        border-top: 1px solid #e0e0e0;
        border-bottom: none;
        height: $height-item;
        align-items: center;
        transition: .3s;
        &:hover {
          background-color: rgba(#000, .05);
        }
        .settings-title, .settings-value {
          overflow: hidden;
          text-overflow: ellipsis;
          padding-top: 0;
          padding-bottom: 0;
          align-items: center;
          white-space: nowrap;
        }
      }
    }
  }

  .settings-item-container.application-settings {
    .settings-title {
      min-width: 180px !important;
    }
  }

  .settings-item-body {
    .settings-item {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #e0e0e0;
      box-sizing: border-box;

      &.toggle-item {
        justify-content: space-between;
        .settings-title {
          flex: 1;
          max-width: 100%;
        }
        .settings-value {
          flex: 0;
        }
      }

      &.slider-item {
        justify-content: space-between;
        .settings-title {
          max-width: 100%;
          white-space: nowrap;
        }
        .settings-value {
          flex: 1;
          align-items: center;
          display: flex;
          padding-top: 0;
          padding-bottom: 0;
          height: 49px;
          box-sizing: border-box;
          .slider-target {
            width: 100%;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }

      .settings-title {
        padding-top: 11px;
        padding-bottom: 11px;
        min-width: 125px;
        max-width: 125px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $text-secondary;
      }

      .settings-value {
        flex: 1;
        padding-right: 20px;
        font-size: 18px;
        padding-top: 11px;
        padding-bottom: 11px;
        word-break: break-all;
      }
    }
  }

  .settings-first-mode {
    padding: 20px;
    padding-bottom: 0;
    font-size: 16px;
    color: rgba(#000, .56)
  }

  .settings-list-container {
    padding: 20px 0;
    padding-bottom: 0;

    * {
      box-sizing: border-box;
    }

    .settings-printer-delete {
      height: 100%;
      font-weight: bold;
      padding: 0 20px;
      font-size: 16px;
    }

    .settings-printer-type.fade-in {
      .settings-printer-type-arrow {
        transform: rotate(180deg)
      }
    }

    .settings-printer-type-select {
      position: relative;
      height: $height-item;
      min-width: 100px;
      max-width: 100px;
      display: flex;
      align-items: center;
      font-size: 18px;
      .settings-printer-type-title {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 25px;
      }
      .settings-printer-type-arrow {
        position: absolute;
        top: 0;
        right: 0;
        width: $height-item;
        height: $height-item;
        display: flex;
        align-items: center;
        justify-content: center;
        transform-origin: center;
        transition: .3s;
        font-size: 12px;
      }
    }

    .settings-printer-type-picker {
      background-color: $main-bg-color;
      transition: .3s;
      visibility: hidden;
      height: 0;
      &.fade-in {
        height: 120px;
        visibility: visible;
      }
    }

    .settings-printers-list {
      margin: 0;

      .settings-printer-item {
        border-bottom: 1px solid #e0e0e0;
        &:last-child {
          border-bottom: none;
        }
      }

      ul::before, ul::after {
        display: none;
      }

      .item-content, .item-inner {
        padding: 0;
        height: auto;
        min-height: 0;
        display: block;
      }
    }

    .settings-item-container {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .settings-item-empty {
        padding: 0 20px;
        display: flex;
        align-items: center;
        font-size: 18px;
        min-height: $height-item;
      }

      .settings-item-title {
        font-size: 16px;
        padding: 0 20px;
        padding-bottom: 10px;
        color: $text-secondary;
      }

      .settings-item-body {
        background-color: #fff;
      }

      .settings-printer-inner {
        display: flex;
        align-items: flex-start;
        .settings-printer-type {
          padding-left: 20px;
          display: flex;
          align-items: center;
          border-radius: 0 25px 25px 0;
        }
        .settings-printer-address {
          padding-left: 5px;
          height: $height-item;
          position: relative;
          flex: 1;
          .settings-printer-edit {
            position: absolute;
            top: 0;
            right: 0;
            width: $height-item;
            height: $height-item;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            background-color: transparent;
            border-radius: 0;
            color: $text-color;
            border-radius: 25px 0 0 25px;
          }
          input {
            height: 100%;
            font-size: 18px;
            padding-right: 20px;
            padding-right: $height-item;
          }
        }
      }
    }
  }
</style>
