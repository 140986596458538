import gui from "../gui"
import user from "../user"
import * as auth from "../auth"

import { login } from "./login"
import { scanStart } from "../bluetooth"
import { navigate } from "./router"
import { isSettingExists, openSettings, initialDeviceSettings, pairDevice } from "./settings"

export async function open() {
  scanStart().then()

  await navigate("/welcome")
  await gui.welcome()

  if(!await pairDevice()) {
    await navigate("/device-disabled")
    return false
  }

  await tryAuthorization()

  if(!await isSettingExists()) {
    await openSettings({ closable: false, promises: true, isInitialSetup: true })
  }

  await initialDeviceSettings()
  await onReady()
}

export async function onReady() {
  if(user.isAuthorized()) {
    await user.synchronization()
    await navigate("/home")
  } else {
    await user.setUser(await login())

    await navigate("/home")
    await gui.loginClose()
  }
}

export async function tryAuthorization() {
  const response = await auth.getUserData()
  if(!response.error && response.authData) {
    await user.setUser(await user.createPreparedUser(response.authData), { withoutSynchronization: true })
  }
}
