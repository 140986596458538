import gui from '../gui';
import dicts from '../dicts';
import state from '../state';
import user from "../user"

import * as settings from '../settings';

import { save } from './receipt';
import { openDocs } from "./docs"
import { navigate, clearHistory } from "./router"

export async function open() {
  gui.preloader(true);
  
  const users = await dicts.users.get({
    storeId: state.user.store._id,
    companyId: state.user.company._id,
  });
  
  gui.allUsers = state.allUsers = users;
  gui.preloader(false);

  await navigate('/receipt/waiter');
}

export async function select(selectedUser) {

  if(!await user.verifyPermissionRequest("all.cashier.receiptSpecials.changeCashier", {
    description: gui.translate(`${await settings.isCafe() ? 'receipts.changeWaiterAccessDescription' : 'receipts.changeCashierAccessDescription'}`),
    title: gui.translate(`${await settings.isCafe() ? 'receipts.changeWaiter' : 'receipts.changeCashier'}`)
  })) {
    return false
  }

  state.receiptData.userName = selectedUser.fullName;
  state.receiptData.userId = selectedUser._id;

  await save(state.receiptData)

  gui.receiptData = state.receiptData;
  
  await clearHistory();
  await openDocs({ activeTab: 'postponed', page: 'receipts' });
}