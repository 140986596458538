<template>
  <div class="new-payment-container refund">
    <div class="new-payment-inner">
      <div class="new-payment-item">
        <div class="new-payment-item-label">{{ $t("checkout.paymentType") }}</div>
        <div class="new-payment-item-value">{{ $t(`checkout.${receipt.paymentType}PaymentType`) }}</div>
      </div>
      <div class="new-payment-item">
        <div class="new-payment-item-label">{{ $t("checkout.amountToReturn") }}</div>
        <div class="new-payment-item-value">${{ $filters.sumFixed(receipt.sumTotal) }}</div>
      </div>
      <div class="new-payment-item">
        <div class="new-payment-item-label">{{ $t("checkout.cashAvailable") }}</div>
        <div class="new-payment-item-value green" :class="{ 'red': receipt.sumTotal > shift.availableCash }">${{ $filters.sumFixed(shift.availableCash) }}</div>
      </div>
    </div>
  </div>

  <Footer class="cash-refund-footer">
    <div class="footer-buttons">
      <f7-button @tap="refundCash" :disabled="receipt.sumTotal > shift.availableCash" class="fill">{{ $t("checkout.confirm") }}</f7-button>
    </div>
  </Footer>
</template>

<script>
  import { ref, watch } from "vue"

  export default {
    props: {
      receipt: { type: Object, default: () => ({}) }
    },
    setup(props) {
      const receipt = ref(props.receipt)

      watch(() => props.receipt, value => {
        receipt.value = value
      })

      return {
        shift: gui.shift,
        receipt,
        refundCash: () => gui.dispatch("refund.refundCash")
      }
    }
  }
</script>
