<template>
  <div :id="id" @touchstart.stop="onTouchStart" @touchmove.stop="onTouchMove" @touchend="onTouchEnd" @touchcancel.stop="onTouchEnd" class="swipe-item-container">
    <div ref="left" class="swipe-item-navigation swipe-item-left" :class="{ 'swipe-item-visible': (leftPxVisible > 0 || this.animation) && !this.disabled }">
      <slot name="left" />
    </div>
    <div v-tap="onInnerClick" :style="styles" ref="inner" class="swipe-item-inner">
      <slot />
    </div>
    <div ref="right" class="swipe-item-navigation swipe-item-right" :class="{ 'swipe-item-visible': (rightPxVisible > 0 || this.animation )&& !this.disabled }">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
  import { nextTick } from "vue"
  import { uuidv4 } from "@/js/utils"
  import { getTouch, getX, tap } from "@/js/touches"

  export default {
    directives: { tap },
    emits: ["swipe:click", "swipe:hidden", "swipe:left", "swipe:right"],
    props: {
      disabled: { type: Boolean, default: false }
    },
    data() {
      return {
        id: "swoi" + uuidv4(),
        prevX: 0,
        startX: 0,
        startY: 0,
        translateX: 0,
        startTranslateX: 0,
        isTouch: false,
        isScrolling: false,
        open: "none",
        styles: {},
        animation: false,
        animationTimer: null,
        width: {
          inner: 0,
          left: 0,
          right: 0
        }
      }
    },
    methods: {
      handleClickOutside(event) {
        if(this.animation) {
          return false
        }

        if(this.open !== "none" && this.canMovable) {
          if(!event.target.closest(`#${this.id}`)) {
            this.hide()
          }
        }
      },
      update() {
        this.styles.transform = `translateX(${this.translateX}px)`
      },
      onInnerClick(event) {
        if(this.animation) {
          return false
        }

        if(this.open !== "none" && this.canMovable) {
          this.hide()
          return
        }

        this.$emit("swipe:click", event)
      },
      onTouchStart(event) {
        this.isTouch = false
        this.isScrolling = false

        if(this.disabled || this.animation) {
          return false
        }

        const { x, y } = getTouch(event)

        this.startX = x
        this.startY = y

        this.startTranslateX = x - this.translateX
      },
      onTouchMove(event) {
        if(this.disabled || this.animation) {
          return false
        }

        const touch = getTouch(event)
        const clientX = touch.x
        const deltaX = clientX - this.startTranslateX

        this.isTouch = !this.isScrolling && (this.isTouch || Boolean(Math.abs(touch.x - this.startX) > 20))
        this.isScrolling = !this.isTouch && (this.isScrolling || Boolean(Math.abs(touch.y - this.startY) > Math.abs(touch.x - this.startX)))

        if(this.isScrolling) {
          return false
        }

        this.translateX = deltaX

        if(this.translateX > this.width.left) {
          this.translateX = this.width.left
        } else if(this.translateX < 0 && Math.abs(this.translateX) > this.width.right) {
          this.translateX = this.width.right * -1
        }

        this.prevX = clientX
        this.update()
      },
      onTouchEnd(event) {
        if(this.disabled || this.animation) {
          return false
        }

        const MIN_MOVE_PX = 10

        if(Math.abs(getX(event) - this.startX) < 4 && this.open !== "none") {
          return false
        }

        if(this.leftPxVisible > 0 || this.rightPxVisible > 0) {
          this.prevX = null

          if(this.open === "none") {
            if(this.leftPxVisible > MIN_MOVE_PX) {
              this.openLeft()
            } else if(this.rightPxVisible > MIN_MOVE_PX) {
              this.openRight()
            } else {
              this.hide()
            }
          } else if(this.open === "left" && this.rightPxVisible > MIN_MOVE_PX) {
            this.openRight()
          }  else if(this.open === "right" && this.leftPxVisible > MIN_MOVE_PX) {
            this.openLeft()
          } else {
            this.hide()
          }
        }
      },
      animate(duration) {
        if(duration === 0) {
          this.animation = false
          this.styles.transition = "none"
        } else {
          this.animation = true
          this.styles.transition = `${duration}ms`

          if(this.animationTimer !== null) {
            clearTimeout(this.animationTimer)
          }

          this.animationTimer = setTimeout(() => {
            this.animationTimer = null
            this.animation = false

            this.styles.transition = "none"
          }, duration)
        }
      },
      hide(animate = 300) {
        this.open = "none"
        this.translateX = 0
        this.animate(animate)
        this.update()

        this.$emit("swipe:hidden")
      },
      openLeft() {
        if(this.$slots.left && this.width.left > 0.001) {
          this.hide(0)
          this.open = "left"

          this.translateX = this.width.left
          this.animate(300)
          this.update()

          this.$emit("swipe:left")
        }
      },
      openRight() {
        if(this.$slots.right && this.width.right > 0.001) {
          this.hide(0)
          this.open = "right"

          this.translateX = this.width.right * -1
          this.animate(300)
          this.update()

          this.$emit("swipe:right")
        }
      },
      updateWidth() {
        this.width = {
          inner: this.$refs?.inner?.offsetWidth || 0,
          right: this.$refs?.right?.offsetWidth || 0,
          left: this.$refs?.left?.offsetWidth || 0
        }
      },
      onUpdateItemSize() {
        this.$nextTick(() => {
          this.updateWidth()

          if(this.open === "left") {
            this.openLeft()
          } else if(this.open === "right") {
            this.openRight()
          }
        })
      }
    },
    computed: {
      canMovable() {
        return !this.disabled && (this.width.left > 0 || this.width.right > 0)
      },
      leftPxVisible() {
        return this.translateX >= 1 ? this.translateX : 0
      },
      rightPxVisible() {
        const translateX = this.translateX < 0 ? Math.abs(this.translateX) : 0
        return translateX >= 1 ? translateX : 0
      }
    },
    beforeUnmount() {
      document.body.removeEventListener("touchend", this.handleClickOutside, false)
    },
    mounted() {
      this.updateWidth()
      document.body.addEventListener("touchend", this.handleClickOutside, false)

      if(this.$refs.left || this.$refs.right) {
        nextTick(() => {
          const resizeObserver = new ResizeObserver(() => this.onUpdateItemSize())
          if(this.$refs.left) {
            resizeObserver.observe(this.$refs.left)
          }

          if(this.$refs.right) {
            resizeObserver.observe(this.$refs.right)
          }
        })
      }
    },
    watch: {
      disabled(value) {
        if(value) {
          this.hide()
        }
      }
    }
  }
</script>
