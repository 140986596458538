<template>
  <Page>
    <Header>
      <div class="left">
        <f7-link id="terminal-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">Development page</div>
    </Header>

    <Inner>
      <select name="" id="">
        <option value="name1">Name 1</option>
        <option value="name2">Name 2</option>
        <option value="name3">Name 3</option>
        <option value="name4">Name 4</option>
        <option value="name5">Name 5</option>
        <option value="name6">Name 6</option>
        <option value="name7">Name 7</option>
      </select>
      <SwipeItem>
        <div style="height: 100px">Hello world</div>
        <template #left>
          <f7-button @tap="count++" v-for="i in count">Left {{ i }}</f7-button>
        </template>
        <template #right>
          <f7-button>Right</f7-button>
          <f7-button>Right2</f7-button>
        </template>
      </SwipeItem>
      <f7-list with-line>
        <f7-list-item @tap="$gui.dispatch('terminal.init')" :clickable="true">
          <template #inner>
            <div class="item-title">Init SDK</div>
          </template>
        </f7-list-item>
        <f7-list-item @tap="$gui.navigate('/test-page')" :clickable="true">
          <template #inner>
            <div class="item-title">Test page</div>
          </template>
        </f7-list-item>
        <f7-list-item @tap="$gui.dispatch('terminal.initDevice')" :clickable="true">
          <template #inner>
            <div class="item-title">Init device</div>
          </template>
        </f7-list-item>
        <f7-list-item @tap="$gui.dispatch('terminal.sale', 0.13)" :clickable="true">
          <template #inner>
            <div class="item-title">Sale</div>
          </template>
        </f7-list-item>
        <f7-list-item @tap="$gui.dispatch('terminal.refund')" :clickable="true">
          <template #inner>
            <div class="item-title">Refund</div>
          </template>
        </f7-list-item>
        <f7-list-item @tap="$gui.dispatch('printer.printText', '88:6b:0f:aa:83:7b', 'test1\ntest2\n')" :clickable="true">
          <template #inner>
            <div class="item-title">Print text bluetooth</div>
          </template>
        </f7-list-item>
        <f7-list-item @tap="$gui.dispatch('printer.printText', '192.168.88.34', 'test1\ntest2\n')" :clickable="true">
          <template #inner>
            <div class="item-title">Print text tcp</div>
          </template>
        </f7-list-item>
        <f7-list-item @tap="clearDocs" :clickable="true">
          <template #inner>
            <div class="item-title">Clear docs</div>
          </template>
        </f7-list-item>
        <f7-list-item @tap="$gui.dispatch('files.getScreenshots')" :clickable="true">
          <template #inner>
            <div class="item-title">getScreenshots</div>
          </template>
        </f7-list-item>
      </f7-list>

      <!--  Test async image uploading  -->
      <div style="width: 300px; height: 300px;margin: 0 auto;">
        <AsyncImage :id="1" />
      </div>
    </Inner>
  </Page>
</template>
<script>
  import AsyncImage from "../../components/AsyncImage"
  import SwipeItem from "@/components/f7/SwipeItem"

  export default {
    components: { SwipeItem, AsyncImage },
    data() {
      return {
        count: 1
      }
    },
    methods: {
      async clearDocs() {
        gui.preloader(true)
        await pos.dicts.docs.del({})
        await pos.dicts.goods.del({})
        await pos.dicts.goods_per_store.del({})
        await pos.dicts.images.del({})
        gui.preloader(false)

        await gui.message("Receipts and shifts and goods deleted")
      }
    }
  };
</script>
<style lang="sass">
</style>
