<template>
  <div class="pairing-dialog" id="pairing-dialog" :class="{ 'fade-in': pairing.shown, 'fade-out': !pairing.shown }">
    <div class="common-dialog-container">
      <div class="common-dialog-header">
        <div class="common-dialog-icon">
          <BigIcon @click="connectDeviceForTest" class="animate__bounceIn" :loading="true" icon="pairing" />
        </div>

        <div class="common-dialog-title">{{ $t("settings.devicePairingTitle") }}</div>
        <div class="common-dialog-description" v-html="$t('settings.devicePairingDescription')"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from "vue"
  import BigIcon from "../BigIcon"

  export default {
    components: { BigIcon },
    setup() {
      const pairing = ref(gui.pairingData)
      watch(gui.pairingData, value => {
        pairing.value = value
      })

      return {
        pairing,
        connectDeviceForTest: () => {
          gui.onScanned.value("SKIP_PAIRING_ACCESS_TOKEN")
        }
      }
    }
  }
</script>

<style lang="scss">
  .pairing-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateY(100vh);
    z-index: 13499;
    background-color: #fff;
    transition: .3s;

    &.fade-in {
      visibility: visible;
      transform: translateY(0);
    }

    &.fade-out {
      .animate__bounceIn {
        animation-name: none !important;
      }
    }

    .common-dialog-container {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .big-icon img {
      height: 120px;
    }
  }

  .pairing-page {
    .pairing-page-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .pairing-page-loader {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pairing-page-header {
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pairing-page-header-description {
      font-size: 16px;
    }

    .pairing-page-header-icon {
      min-width: 60px;
      height: 60px;
      background-image: url(/static/imgs/qr-scanner.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 20px;
    }
  }
</style>