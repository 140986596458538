<template>
  <div class="sheet-details-container">
    <div v-for="(item, index) of details" :key="index" class="sheet-detail-item" :class="{ 'padding-left': item.paddingLeft }">
      <div class="sheet-detail-item-left">{{ item.title }}</div>
      <div class="sheet-detail-item-right" :class="{ 'bold': item.valueBold }">
        <template v-if="item.type === 'number'">{{ +item.value }}</template>
        <template v-else-if="item.type === 'sum'">${{ $filters.sum(+item.value) }}</template>
        <template v-else-if="item.type === 'text'">{{ item.value }}</template>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from "vue"

  export default {
    props: {
      details: { type: Object, default: () => ({}) }
    },
    setup(props) {
      const details = ref(props.details)

      watch(() => props.details, value => {
        details.value = value
      })

      return { details }
    }
  }
</script>