import ReconnectingWebSocket from 'reconnecting-websocket'

let counter = 0
const callbacks = {}

export const rpc = {}

const wsOptions = {
  WebSocket: WebSocket, // custom WebSocket constructor
  connectionTimeout: 5000,
  maxRetries: Infinity,
  debug: false
};

const host = process.env.NODE_ENV !== "production" ? location.hostname : "localhost"
const ws = new ReconnectingWebSocket(`ws://${host}:8820`, [], wsOptions);

ws.onopen = function (event) {
  console.log('ws open', event && event.message)
}
ws.onerror = function (event) {
  console.log('ws error', event && event.message)
}

// обработчик входящих сообщений
ws.onmessage = function (event) {
  var message = event.data
  var res
  try {
      res = JSON.parse(message)
  } catch (e) {
      console.error('Error JSON RPC ' + message)
  }

  if (!res) {
      return
  }

  if (res.method) { 
    for (const key in rpc) {
      const fn = rpc[key]
      if (typeof(fn) === 'function') {
        fn.apply(this, [res.method].concat(res.params))
      } else {
        console.error(`Error call rpc ${key}`)
      }
    }
    return
  }

  if (res.id && callbacks[res.id]) { // res.hasOwnProperty('answer') 
      const cb = callbacks[res.id]
      delete callbacks[res.id]
      cb.resolve(res.error ? res : res.answer)
  }
}

export async function call(method) {
  var params = Array.prototype.slice.call(arguments, 1)
  console.log('$call.' + method, params)
  const id = ++counter
  var json = {
      id: id,
      method: method,
      params: params
  }
  callbacks[id] = {
      date: new Date() 
  }
  const promise = new Promise(function(resolve, reject) {
      callbacks[id].resolve = resolve
      callbacks[id].reject = reject
  })

  if (ws.readyState !== WebSocket.OPEN) {
      return { error: `Device service not available \nws connection readyState = ${ws.readyState}`}
  }
  
  ws.send(JSON.stringify(json))
  
  try {
      const res = await promise
      return res
  } catch (err) {
      return { error: err.message }
  }
}
