<template>
  <SwipeItem>
    <div class="settings-printer-inner">
      <f7-ripple tag="div" :hidden="true" @tap="togglePicker" class="settings-printer-type" :class="{ 'fade-in': shownPicker }">
        <div class="settings-printer-type-select">
          <div class="settings-printer-type-title">{{ printerTypeTitle(printer.type) }}</div>
          <div class="settings-printer-type-arrow">
            <i class="mpi-expand2"></i>
          </div>
        </div>
      </f7-ripple>
      <div class="settings-printer-address">
        <input autocomplete="off" @blur="hideAutocomplete" :id="id" @focus="shownAutocomplete = true" @click="$emit('hide-pickers')" type="text" v-model="printer.address" :placeholder="$t('settings.printerAddress')">
        <f7-button :disabled="options.printCategories.length === 0" @tap="openSettings" class="settings-printer-edit">
          <i class="mpi-md-settings"></i>
        </f7-button>
      </div>
    </div>
    <template #right>
      <f7-button fill @tap="deletePrinter(printer)" class="settings-printer-delete red">{{ $t("settings.delete") }}</f7-button>
    </template>
  </SwipeItem>
  <div v-if="isShownAutocomplete" class="settings-hint-printers">
    <div @click="setAddress(item.address)" v-for="(item, index) of autocompleteItems" :key="index" class="settings-item">
      <div class="settings-title">{{ item.name }}</div>
      <div class="settings-value">{{ item.address }}</div>
    </div>
  </div>
  <div class="settings-printer-type-picker" :class="{ 'fade-in': shownPicker }">
    <ItemPicker @click="$emit('hide-pickers')" :height="120" v-model="printer.type" :items="printersTypes" />
  </div>
  <PrinterSettings :options="options" ref="settings" :printer="printer" />
</template>

<script>
  import { click } from "@/js/audio"

  import PrinterSettings from "./PrinterSettings"
  import ItemPicker from "../ItemPicker"
  import SwipeItem from "@/components/f7/SwipeItem"

  export default {
    components: { PrinterSettings, ItemPicker, SwipeItem },
    props: {
      options: { type: Object, default: () => ({}) },
      printersTypes: { type: Array, default: () => [] },
      modelValue: { type: Object, default: () => ({}) },
      hints: { type: Array, default: () => [] },
      id: { type: String, default: "" }
    },
    data() {
      return {
        shownPicker: false,
        printer: this.modelValue,
        shownAutocomplete: false
      }
    },
    methods: {
      deletePrinter(printer) {
        this.$emit("delete", printer)
      },
      openSettings() {
        this.$refs.settings.open()
      },
      setAddress(address) {
        this.printer.address = address
        this.shownAutocomplete = false
      },
      hideAutocomplete() {
        setTimeout(() => {
          this.shownAutocomplete = false
        }, 0)
      },
      togglePicker() {
        this.shownPicker = !this.shownPicker

        click()

        if(this.shownPicker) {
          this.$emit("open-picker", this)
        }
      },
      hidePicker() {
        this.shownPicker = false
      },
      printerTypeTitle(key) {
        const type = this.printersTypes.find(item => item.key === key)
        if(type) {
          return type.title
        }

        return ""
      }
    },
    computed: {
      autocompleteItems() {
        const address = this.printer.address.toLowerCase()
        const items = this.hints.filter(item => {
          if(item.name.toLowerCase().indexOf(address) >= 0) {
            return true
          }

          if(item.address.toLowerCase().indexOf(address) >= 0) {
            return true
          }

          return false
        })

        if(items.length === 1 && items[0].address.toLowerCase() === address) {
          return []
        }

        return items
      },
      isShownAutocomplete() {
        return this.shownAutocomplete && this.autocompleteItems.length > 0
      }
    },
    watch: {
      modelValue(printer) {
        this.printer = printer
      }
    }
  }
</script>
