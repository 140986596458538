<template>
  <f7-list class="settings-printers-list">
    <f7-list-item v-for="(printer, index) of printers" :key="printer._id" :ripple="false" :swipeout="true" class="settings-printer-item">
      <template #inner>
        <Printer :id="`settings-printer-${index}`" :options="options" :hints="hints" @delete="deletePrinter" @hide-pickers="hidePickers" @open-picker="hideOtherPickers" :ref="setPrinterRef" :printersTypes="printersTypes" v-model="printers[index]" />
      </template>
    </f7-list-item>
  </f7-list>
</template>

<script>
  import Printer from "./Printer"

  export default {
    components: { Printer },
    props: {
      options: { type: Object, default: () => ({}) },
      printersTypes: { type: Array, default: () => [] },
      modelValue: { type: Array, default: () => [] },
      hints: { type: Array, default: () => [] }
    },
    data() {
      return {
        printersRefs: [],
        printers: this.modelValue
      }
    },
    methods: {
      hidePickers() {
        for(let printer of this.printersRefs) {
          printer?.hidePicker()
        }
      },
      hideOtherPickers(el) {
        for(let printer of this.printersRefs) {
          if(printer !== el) {
            printer?.hidePicker()
          }
        }
      },
      deletePrinter(printer) {
        this.$emit("delete", printer)
      },
      setPrinterRef(el) {
        this.printersRefs.push(el)
      }
    },
    beforeUpdate() {
      this.printersRefs = []
    },
    watch: {
      modelValue(printers) {
        this.printers = printers
      }
    }
  }
</script>
