<template>
  <Page schema="blue" class="support-page" id="support-page">
    <Header>
      <div class="left">
        <f7-link id="support-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t('support.title') }}</div>
    </Header>

    <Inner>
      <div class="support-page-container">
        <div class="support-page-container-white">
          <div class="support-page-comment support-page-block">
            <div class="label">{{ $t('support.commentLabel') }}</div>
            <div class="support-page-comment-input">
              <input :class="['input-item', {error: commentError}]" v-model="comments" @keyup.enter="blurComments" @input="commentError = false" :placeholder="$t('support.addComment')" />
            </div>
          </div>
          <div class="support-page-screens support-page-block">
            <div class="label">{{ $t('support.screensLabel') }}</div>
            <f7-button
                fill
                id="screen-button-plus"
                class="screen-button-plus"
                @tap="openScreenshotsSheet">
              <i class="mpi-md-plus"></i>
            </f7-button>
          </div>
          <div v-if="supportData.screens.length" class="support-page-screen-previews support-page-block">
            <div class="support-page-screen-previews_inner">
              <div
                  v-for="(screen, $k) of supportData.screens"
                  :key="$k"
                  class="support-page-screen-previews_item"
                  v-tap="() => removeScreen($k)">
                <div class="support-page-screen-previews_item_inner" :style="{ backgroundImage: `url(${screen.base64})`}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <SelectScreenshotsSheet />
    </Inner>

    <Footer>
      <div class="footer-buttons">
        <f7-button class="footer-btn fill" @tap="send" :disabled="supportData.loading">{{ $t('support.sendButton') }}</f7-button>
      </div>
    </Footer>
  </Page>
</template>

<script>
  import SelectScreenshotsSheet from "../components/SelectScreenshotsSheet.vue";
  import { tap } from "@/js/touches"
  import { onUnmounted, ref } from 'vue';
  import { click } from "@/js/audio"

  export default {
    components: { SelectScreenshotsSheet },
    directives: { tap },
    setup() {
      const supportData = ref(gui.supportData);
      const comments = ref('');
      const commentError = ref(false);

      onUnmounted(()=> gui.dispatch('support.clear'));

      return {
        supportData,
        comments,
        commentError,

        openScreenshotsSheet () { 
          // gui.dispatch('support.openScreenshotsSheet');
          gui.dispatch('support.getSelectedScreenshots');
        },
        send() { 
          if (comments.value === '') {
            commentError.value = true
            return
          }
          gui.dispatch('support.send', comments.value);
        },
        blurComments() {
          document.activeElement?.blur();
        },
        removeScreen($k) {
          gui.dispatch('support.removeScreen', $k);
        },
        onClose() {
          gui.dispatch('support.closeScreenshotsSheet');
        },
        select(screen) {
          click()
          gui.dispatch('support.select', screen);
        },
        checkIsSelected(screen) {
          return supportData.value.selectedScreens.find((item) => item.absoluteFilePath === screen.absoluteFilePath);
        },
        onConfirm() {
          gui.dispatch('support.addSelected');
        }
      }
    },
  }
</script>


<style lang="scss">
@import '@/css/variables';

.support-page {
  &-container {
    padding: $minimal-padding;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &-white {
      background-color: #ffffff;
      border-radius: $border-radius;
      padding: $padding-arrow;
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-grow: 1;
    }
  }

  &-block {
    border-top: 1px solid #CCDAE4;
    padding: 18px 0;

    &:first-child {
      border: none;
      padding-top: 0;
    }

    .label {
      font-size: 16px;
      line-height: 118.75%;
      color: #22272C;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
    }
  }

  &-comment {
    &-input input {
      font-weight: normal;
      box-sizing: border-box;
    }
  }

  &-screens {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      padding: 0;
    }
    .screen-button-plus {
      width: 45px;
      height: 45px;
      border-radius: 5px;
    }
  }

  &-screen-previews {

    &_inner {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      box-sizing: border-box;
      margin: 0 -5px;
    }

    &_item {
      padding: 5px;
      &_inner {
        width: 66px;
        height: 117px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: $border-radius;
      }
    }
  }
}

.support-page .footer {
  width: 100%;
  height: 67px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: $main-bg-color;
  box-shadow: $footer-shadow;
  border-radius: $border-radius $border-radius 0 0;
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .footer-btn {
      width: $basic-width;
      height: 46px;
      text-transform: none;
      font-size: $text-size;
      line-height: 118.75%;
      font-weight: bold;
      background-color: $primary-color;
      border-radius: 23px;
    }
  }
}

</style>
