<template>
  <div :id="id" class="keyboard-container">
    <div class="keyboard-footer">
      <div class="keyboard-left">
        <div class="keyboard-row">
          <div class="keyboard-button">
            <f7-button :id="idFor('1')" @tap="press('1')" class="ripple-color-black">1</f7-button>
          </div>
          <div class="keyboard-button">
            <f7-button :id="idFor('2')" @tap="press('2')" class="ripple-color-black">2</f7-button>
          </div>
          <div class="keyboard-button">
            <f7-button :id="idFor('3')" @tap="press('3')" class="ripple-color-black">3</f7-button>
          </div>
        </div>
        <div class="keyboard-row">
          <div class="keyboard-button">
            <f7-button :id="idFor('4')" @tap="press('4')" class="ripple-color-black">4</f7-button>
          </div>
          <div class="keyboard-button">
            <f7-button :id="idFor('5')" @tap="press('5')" class="ripple-color-black">5</f7-button>
          </div>
          <div class="keyboard-button">
            <f7-button :id="idFor('6')" @tap="press('6')" class="ripple-color-black">6</f7-button>
          </div>
        </div>
        <div class="keyboard-row">
          <div class="keyboard-button">
            <f7-button :id="idFor('7')" @tap="press('7')" class="ripple-color-black">7</f7-button>
          </div>
          <div class="keyboard-button">
            <f7-button :id="idFor('8')" @tap="press('8')" class="ripple-color-black">8</f7-button>
          </div>
          <div class="keyboard-button">
            <f7-button :id="idFor('9')" @tap="press('9')" class="ripple-color-black">9</f7-button>
          </div>
        </div>
        <div class="keyboard-row">
          <div class="keyboard-button double">
            <f7-button :id="idFor('0')" @tap="press('0')" class="keyboard-button-zero ripple-color-black">
              <div class="keyboard-button-zero-inner">0</div>
            </f7-button>
          </div>
          <div class="keyboard-button">
            <f7-button :id="idFor('dot')" @tap="press('.')" class="ripple-color-black">.</f7-button>
          </div>
        </div>
      </div>
      <div class="keyboard-right">
        <div class="keyboard-button">
          <f7-button :id="idFor('backspace')" @touchend="backspaceEnd" @touchcancel="backspaceEnd" @touchstart="backspaceStart" class="ripple-color-black">
            <div class="keyboard-backspace">
              <img @touchstart.prevent src="@/static/imgs/backspace.svg" alt="Backspace">
            </div>
          </f7-button>
        </div>
        <div class="keyboard-button primary">
          <f7-button :id="idFor('confirm')" :disabled="disabled" @tap="confirm" fill class="ripple-color-black">{{ $t('ok') }}</f7-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { delay } from "../js/helpers"
  import { click } from "../js/audio"

  export default {
    emits: ["confirm"],
    props: {
      id: { type: String, default: "keyboard" },
      disabled: { type: Boolean, default: false }
    },
    data() {
      return {
        ref: null,
        backspaceData: {
          touched: false,
          isLongClick: false
        }
      }
    },
    methods: {
      setRelation(ref) {
        this.ref = ref
      },
      press(char) {
        this.ref?.press(char)
      },
      idFor(suffix) {
        return `${this.id}-${suffix}`
      },
      async backspaceStart() {
        if(this.backspaceData.touched) {
          return false
        }

        this.backspaceData.touched = true
        this.backspaceData.isLongClick = false

        // waiting for long click
        if(typeof this.ref?.isEmpty === "function") {
          await delay(200)
          if(this.backspaceData.touched) {
            this.backspaceData.isLongClick = true

            while (!this.ref?.isEmpty() && this.backspaceData.touched) {
              this.backspace()
              await delay(100)

              click()
            }
          }
        }
      },
      backspaceEnd() {
        this.backspaceData.touched = false
        if(!this.backspaceData.isLongClick) {
          this.backspace()
        }
      },
      backspace() {
        this.ref?.backspace()
      },
      confirm() {
        this.$emit("confirm")
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  $keyboard-base-button-color: transparent;
  $keyboard-right-button-color: #CCDAE4;
  $keyboard-primary-button-color: $primary-color;

  .keyboard-container {
    background-color: $input-color;

    .keyboard-backspace img {
      height: 20px;
    }

    .keyboard-footer {
      display: flex;
      .keyboard-left {
        flex: 1;
        .keyboard-button {
          box-sizing: border-box;
          .button {
            &.keyboard-button-zero {
              justify-content: flex-start;
              padding: 0;
              .keyboard-button-zero-inner {
                min-width: 50%;
                max-width: 50%;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
      .keyboard-right {
        min-width: 90px;
        max-width: 90px;
        display: flex;
        flex-direction: column;
        .keyboard-button {
          flex: 1;
          box-sizing: border-box;
          &.primary .button {
            background-color: $keyboard-primary-button-color;
            font-size: 24px;
            color: #fff;
          }
          .button {
            height: 100%;
            border-radius: 0;
            background-color: $keyboard-right-button-color;
            color: #000;
            font-size: $text-size;
            font-weight: bold;
            text-transform: none;
          }
        }
      }
    }

    .keyboard-container * {
      box-sizing: border-box;
    }

    .keyboard-left .keyboard-row:last-child {
      border-bottom: none;
    }

    .keyboard-row {
      display: flex;
      height: 65px;
      .keyboard-button {
        flex: 1;
        &.double {
          flex: 2;
        }
        &:last-child .button {
          border-right: none;
        }
        .button {
          border-radius: 0;
          background-color: $keyboard-base-button-color;
          font-size: 32px;
          height: 100%;
          font-weight: bold;
          color: $text-color;
        }
      }
    }
  }
</style>
