<template>
  <div class="login-dialog" :id="isVerifyPage ? 'login-screen-verify' : 'login-screen'" :class="{ 'fade-in': loginData.shown, 'fade-out': !loginData.shown }">
    <Page schema="transparent" class="login-page" :class="{ 'first-slide-open': slide === 0 && !isVerifyPage, 'verify-page': isVerifyPage }" login-screen no-swipeback>
      <Header>
        <div class="left">
          <f7-link @tap="back" class="icon-only">
            <i class="icon mpi-back color-black"></i>
          </f7-link>
        </div>
        <div class="title" :class="{'login-title-fade-out': isVerifyPage && slide === 0}">{{ title }}</div>
      </Header>

      <Inner>
        <div class="login-page-swiper">
          <div class="login-page-inside" :style="sliderStyles">
            <div class="login-slide first-slide">
              <div class="login-page-content" :class="{'jcs': true}">
                <div class="login-icon-header">
                  <div class="login-icon">
                    <BigIcon class="animate__bounceIn" icon="login" :loading="$gui.isIdentifySellerProcess.value" />
                  </div>
                </div>
                <div class="login-page-form">
                  <div class="login-header-title">{{ title }}</div>
                  <div v-if="loginData.description && isVerifyPage" class="login-header-description" v-html="loginData.description"></div>
                  <div class="login-page-input">
                    <input @keyup.enter="identifySeller" class="input-item" :class="{'error': loginData.errors?.userName}" @input="updateUserName" id="login-username" :value="loginData.userName" type="text" :placeholder="$t('login.username')" autocomplete="off">
                  </div>
                </div>
              </div>
              <div class="login-page-footer first-footer">
                <div class="login-page-footer-inside">
                  <div class="login-page-footer-buttons">
                    <div class="login-page-footer-button">
                      <f7-button id="login-identify-seller-button" @tap="identifySeller" :disabled="loginData.userName === ''" round fill large>{{$t('login.next')}}</f7-button>
                    </div>
                    <div class="login-page-footer-link">
                      <div class="login-use-card-manual badge-to-login ripple-color-black">
                        <div class="login-page-footer-link-container">
                          <div class="login-page-footer-link-icon">
                            <i class="mpi-info"></i>
                          </div>
                          <div class="login-page-footer-link-text">{{$t('login.badgeToLogin')}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="login-slide with-keyboard">
              <div class="login-page-content">
                <div class="login-page-form">
                  <div class="login-page-input">
                    <label>{{$t('login.enterPin')}}</label>
                    <PIN :error="loginData.errors?.pin" input-mode="none" @pin-entered="loginData.action('loginSeller')" id="login-enter-pin" ref="pinInputRef" :show-eye="false" v-model="loginData.PIN" />
                  </div>
                </div>
              </div>
              <div class="login-page-keyboard">
                <div class="login-page-footer">
                  <div class="login-page-footer-inside">
                    <div class="login-page-keyboard-button">
                      <div class="login-page-footer-buttons">
                        <div class="login-page-footer-button">
                          <f7-button id="login-sign-in-button" @tap="loginData.action('loginSeller')" :disabled="loginData.PIN?.length !== 4" round fill large>{{$t('login.title')}}</f7-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Keyboard id="login-keyboard" @confirm="loginData.action('loginSeller')" ref="keyboardInputRef" :disabled="loginData.PIN?.length !== 4" />
              </div>
            </div>
            <div class="login-slide with-keyboard">
              <div class="login-page-content">
                <div class="login-page-form">
                  <div class="login-page-input">
                    <label>{{$t(loginData.pinOutdated ? 'login.resetPin' : 'login.setPin')}}</label>
                    <PIN :error="loginData.errors?.pin" input-mode="none" id="login-reset-pin" ref="pinResetRef" :show-eye="true" v-model="loginData.PIN" />
                  </div>
                </div>
              </div>
              <div class="login-page-keyboard">
                <div class="login-page-footer">
                  <div class="login-page-footer-inside">
                    <div class="login-page-keyboard-button">
                      <div class="login-page-footer-buttons">
                        <div class="login-page-footer-button">
                          <f7-button id="login-reset-pin-button" @tap="loginData.action('resetSellerPIN')" :disabled="loginData.PIN?.length !== 4" round fill large>OK</f7-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Keyboard id="reset-keyboard" @confirm="loginData.action('resetSellerPIN')" ref="keyboardResetRef" :disabled="loginData.PIN?.length !== 4" />
              </div>
            </div>
          </div>
        </div>
      </Inner>
    </Page>
  </div>
</template>

<script>
  import { ref, watch, computed, onMounted } from "vue"

  import PIN from "./Login/PIN"
  import Keyboard from "./Keyboard"
  import BigIcon from "./BigIcon"

  export default {
    components: { BigIcon, PIN, Keyboard },
    setup() {
      const loginData = ref({
        state: "identification",
        errors: { pin: false, userName: false },
        shownScanner: false,
        barcode: "",
        userName: "",
        PIN: ""
      })
      const slide = ref(0)

      const PINError = ref(false)
      const userNameError = ref(false)

      const pinInputRef = ref(null)
      const pinResetRef = ref(null)

      const keyboardInputRef = ref(null)
      const keyboardResetRef = ref(null)

      watch(gui.loginData, value => {
        loginData.value = value

        if(gui.loginData.value.state === "identification") {
          slideTo(0)
        } else if(gui.loginData.value.state === "input-pin") {
          slideTo(1)
        } else if(gui.loginData.value.state === "reset-pin") {
          slideTo(2)
        }
      })

      const slideTo = (index) => {
        slide.value = index
      }

      const bindKeyboards = () => {
        keyboardInputRef.value.setRelation(pinInputRef.value)
        keyboardResetRef.value.setRelation(pinResetRef.value)
      }

      onMounted(() => {
        gui.disableSwipePanel()
        bindKeyboards()
      })

      const sliderStyles = computed(() => {
        return { transform: `translateX(-${slide.value * 100}%)` }
      })

      const isVerifyPage = computed(() => {
        return loginData.value.cancel === true
      })

      return {
        // pos data
        loginData,
        isVerifyPage,
        // UI data
        slide,
        slideTo,
        PINError,
        pinInputRef,
        pinResetRef,
        sliderStyles,
        keyboardInputRef,
        keyboardResetRef,
        userNameError,
        title: computed(() => {
          if(typeof loginData.value.title === "string") {
            return loginData.value.title
          }

          return gui.translate('login.title')
        }),
        back: () => {
          if(slide.value === 0 && isVerifyPage) {
            gui.loginData.value.action("cancel")
          } else {
            gui.loginData.value.action("back")
          }
        },
        cancel: () => gui.loginData.value.action("cancel"),
        identifySeller: () => {
          if(gui.loginData.value.userName !== "") {
            document.activeElement?.blur()
            gui.loginData.value.action("identifySeller")
          }
        },
        updateUserName: event => {
          loginData.value.errors.userName = false
          gui.loginData.value.userName = event.target.value
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/pages/login";

  .login-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateY(110%);
    background-color: $main-bg-color;
    z-index: 13499;
    transition: .3s;

    &.fade-out {
      .animate__bounceIn {
        animation-name: none !important;
      }
    }

    &.fade-in {
      visibility: visible;
      transform: translateY(0);
    }
  }
</style>
