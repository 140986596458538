import gui from "../gui"
import state from "../state"

import { cloneDeep } from "lodash"
import { checkIsCafeGoodSent } from "./runner"
import { getTableOfReceipt } from "./tables"
import { calculate, create, endSelection, isNewReceipt, isSplitting, save, SELECTION, startSelection } from "./receipt"

export async function splitOrder(receipt = state.receiptData) {
  if(await isSplitting(receipt) && receipt.selectedCount > 0) {
    let splitCount = 0
    const splitGoods = []

    for await (let good of receipt.goods.filter(good => good.selected > 0)) {
      const newGood = { ...cloneDeep(good), count: good.selected, selected: 0 };

      await splitRunnerCount(good, newGood, good.selected)

      splitGoods.push(newGood)

      splitCount += good.selected
      good.count = good.count - good.selected
      good.selected = 0
      // for newGood is no needed to check sent status, because it equal good status before split
      good.sent = await checkIsCafeGoodSent(good)
    }

    await endSelection(receipt)
    await calculate(receipt)
    await save(receipt)

    // Create new receipt
    const newReceipt = await create(splitGoods, {
      notOpenReceipt: true,
      parentSplitUuid: receipt.parentSplitUuid || receipt.uuid,
      table: await getTableOfReceipt(receipt),
      comments: receipt.isAllSent ? [] : cloneDeep(receipt.comments)
    })

    gui.receiptData = receipt

    await gui.message(gui.translate("receipts.splitSuccess", {
      splitCount,
      id: newReceipt.displayedId
    }))
  }
}

export async function startSplitting(receipt = state.receiptData) {
  if(isNewReceipt(receipt)) {
    await startSelection(receipt, SELECTION.SPLITTING)
  }
}

export async function splitRunnerCount(good, newGood, count) {
  for (const key in good.countRunner) {
    if (good.count  > count + good.countRunner[key]) {
      newGood.countRunner[key] = 0;
      good.countRunner[key] = good.countRunner[key];
    } else {
      newGood.countRunner[key] = Math.abs(good.count - good.countRunner[key] - count);
      good.countRunner[key] = good.count - count;
    }
  }
}