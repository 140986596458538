import dicts from "../dicts"

export async function getImageById(id) {
  const image = await dicts.images.getOne({ _id: Number(id) })

  if(image) {
    return image
  }

  return null
}
