<template>
  <div ref="list" class="list" :class="{ 'accordion-list': accordionList, 'with-line': withLine }">
    <ul>
      <slot ref="slot" />
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      accordionOpenFirst: { type: Boolean, default: false },
      accordionList: { type: Boolean, default: false },
      withLine: { type: Boolean, default: false },
    },
    data() {
      return {
        allOpened: false,
      }
    },
    methods: {
      getAccordionItems() {
        const list = this.$refs.list
        if(list) {
          return list.querySelectorAll(".accordion-item")
        }

        return []
      },
      hideAllAccordions() {
        for(let item of this.getAccordionItems()) {
          item.classList.remove("accordion-item-opened")
        }
      },
      openAllAccordions() {
        for(let item of this.getAccordionItems()) {
          item.classList.add("accordion-item-opened")
        }
      },
      openAccordionItemAt(index) {
        let currentIndex = 0

        for(let item of this.getAccordionItems()) {
          if(currentIndex === index) {
            item.classList.add("accordion-item-opened")
          } else {
            item.classList.remove("accordion-item-opened")
          }

          currentIndex++
        }
      },
      toogleAccordionItemsAll(isOpened) {
        this.allOpened = isOpened;

        if(isOpened) {
          this.openAllAccordions()
          } else {
          this.hideAllAccordions()
        }
      }
    },
    mounted() {
      if(this.accordionOpenFirst) {
        this.openAccordionItemAt(0)
      }
    }
  }
</script>