<template>
  <f7-ripple tag="div" @tap="onClick" class="actions-button" :clickable="true">
    <div class="actions-button-text">
      <slot />
    </div>
  </f7-ripple>
</template>

<script>
  import { click } from "@/js/audio"

  export default {
    emits: ["tap"],
    props: {
      close: { type: Boolean, default: true }
    },
    methods: {
      onClick(event) {
        click()

        if(this.close) {
          this.$parent?.closeActions?.()
        }

        this.$emit("tap", event)
      }
    }
  }
</script>
