import gui from "../gui"
import dicts from "../dicts"
import state from "../state"
import * as printerStrings from "./printer-strings"

import { round2 } from "./util"
import { template } from "lodash"
import { printText } from "../printer"
import { getPrinter, isCafe } from "../settings"

const helpers = {
  WIDE_LENGTH: 43,
  round2,
  ...printerStrings
}

export async function printBill(category, templateDocument = {}, options = {}) {
  await initLogo()

  const queue = Array.isArray(options.queue) ? options.queue : []
  const count = isNaN(+options.count) ? 1 : Math.abs(+options.count)

  templateDocument._copy = typeof options.copy === "undefined" ? false : options.copy
  templateDocument.isCafe = await isCafe()
  templateDocument.printerKey = typeof options.printerKey === "undefined" ? '' : options.printerKey

  for(const printerType of queue) {
    try {
      const printer = await getPrinter(printerType)

      if(printer && printer.address) {
        const deviceAddress = printer.address
        const templateResponse = await getTemplate(printer, category)

        if(!templateResponse) {
          await printError(`Template for "${category}" not found`)
          return false
        }

        const billTemplate = templateResponse.template
        const templateOptions = templateResponse.options || {}
        const units = Array(count).fill("").map((_, index) => createBillUnit(billTemplate, templateDocument, index + 1, count))

        const delimiter = templateOptions.delimiter || ":CUT:"
        const ending = templateOptions.ending || ":CUT::BEEP:"

        await gui.printing({ shown: true, title: options.processMessage, error: null })
        const text = units.join("\n" + delimiter + "\n")
        const res = await printText(deviceAddress, text + "\n" + ending + "\n")
        await gui.printing(false)

        if(process.env.VUE_APP_IS_TEST) {
          await logBill(text)
        }

        if (!res || (res && res.error)) {
          await printError(res && res.error ? res.error : "Printing error...")
          return false
        }

        return true
      } else {
        await printError(gui.translate("printerNotDefined", { printerType }))
        return false
      }
    } catch(e) {
      await printError(e.message)
      return false
    }
  }
}

export async function printError(message) {
  await gui.printing({ shown: true, title: null, error: message })
}

export async function getTemplate(printer, category) {
  const query = { category }

  if(printer.templates && typeof printer.templates[category] === "string") {
    query.name = printer.templates[category].trim()
  } else {
    query.isDefault = true
  }

  const bill = await dicts.bills_templates.getOne(query)

  if(bill) {
    const rows = []
    const options = {}

    for(let row of bill.template.split("\n")) {
      row = row.trim()
      if(row.startsWith("@")) {
        const expression = row.split("=")
        const key = expression[0].slice(1).trim()
        const value = expression[1] ? expression[1].replace(/\\n/g, "\n") : null

        options[key] = value
      } else {
        rows.push(row)
      }
    }

    return {
      template: template(rows.join("\n")),
      options
    }
  }

  return null
}

export async function initLogo() {
  if(typeof state.cache.logo === "undefined" && state.user && state.user.company && state.user.company.logoId) {
    const logo = await dicts.images.getOne({ _id: state.user.company.logoId })

    if(logo) {
      state.cache.logo = logo.thumbnailBase64.slice(logo.thumbnailBase64.indexOf(",") + 1)
    } else {
      state.cache.logo = null
    }
  }
}

export async function logBill(bill) {
  let fontSize = 100
  const styles = []
  const matches = bill.match(/(:IMAGE:\d+:.+\n)|(:[A-Z0-9]+:)/g)

  if(Array.isArray(matches)) {
    for(let match of matches) {
      if(match.startsWith(":IMAGE:")) {
        const parts = match.split(":")
        const width = +parts[2]
        const height = Math.round(width * 0.23)
        const image = "data:text/plain;base64," + parts[3].replace("\n", "")
        const paddings = " ".repeat(width === 150 ? 3 : 6)

        bill = bill.replace(match, `${paddings}%c %c\n\n`)
        styles.push(`background-image: url(${image});padding-left: ${width}px;box-sizing: border-box;padding-top: ${height}px;background-size: contain; background-position: center; background-repeat: no-repeat;`)
        styles.push("background-image: none; padding: 0;")
      } else if(match === ":BOLD:") {
        bill = bill.replace(match, "%c")
        styles.push(`font-weight: bold; font-size: ${fontSize}%`)
      } else if(match === ":NOBOLD:") {
        bill = bill.replace(match, "%c")
        styles.push(`font-weight: normal; font-size: ${fontSize}%`)
      } else if(match === ":DOUBLE:") {
        fontSize = 200
        bill = bill.replace(match, "%c")
        styles.push("font-size: 200%;")
      } else if(match === ":NODOUBLE:") {
        fontSize = 100
        bill = bill.replace(match, "%c")
        styles.push("font-size: 100%;")
      } else if(match === ":CUT:" || match === ":CUTPART:" || match === ":CUTSTAR:") {
        bill = bill.replace(match, "\n\n\n")
      } else {
        bill = bill.replace(match, "")
      }
    }
  }

  console.log(bill, ...styles)
}

function createBillUnit(billTemplate, templateDocument, number = 1, count = 1) {
  return billTemplate({
    ...templateDocument, ...helpers,
    _number: number,
    _count: count
  }).split("\n").map(row => {
    if(row.trim() === "") {
      return null
    } else if(row.trim() === ":BREAKLINE:") {
      return ""
    }

    return row
  }).filter(row => row !== null).join("\n").replace(/:BREAKLINE:/g, "\n")
}
