<template>
  <Page class="waiter-page" id="waiter-page">
    <Header>
      <div class="left">
        <f7-link id="waiter-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ title }}</div>
    </Header>

    <Inner>
      <f7-list class="options-list-wrap" no-hairlines no-hairlines-between>
        <ul class="options-list">
          <f7-list-item
            class="options-list-item"
            v-for="user in users"
            :clickable="true"
            :key="user._id">
            <div class="options-list-item-content ripple" @touchstart="onChange(user)">
              <CustomCheckbox :checked="user === waiter"/>
              <div class="options-list-item-title">
                {{ user.fullName }}
              </div>
            </div>
          </f7-list-item>
        </ul>
      </f7-list>
    </Inner>

    <Footer>
      <div class="footer-buttons">
        <f7-button
          round
          large
          :disabled="!waiter"
          class="fill"
          id="confirm-waiter-button"
          @tap="confirm">
          {{ $t("receipts.confirm") }}
        </f7-button>
      </div>
    </Footer>
  </Page>
</template>

<script>
  import { ref, computed } from 'vue';
  import { isCafe } from '../../js/helpers'
  import CustomCheckbox from '../../components/CustomCheckbox';

  export default {
    components: { CustomCheckbox },
    setup() {
      const waiter = ref(null);
      const title = computed(()=> isCafe() ? gui.translate("receipts.selectWaiter") : gui.translate("receipts.selectCashier"));

      return {
        waiter,
        title,
        users: gui.allUsers.value,
        onChange(value) {
          if (waiter.value === value) {
            waiter.value = null;
          } else {
            waiter.value = value;
          }
        },
        confirm() {
          gui.dispatch('waiter.select', waiter.value);
        },
      }
    },
  };
</script>

<style lang="scss">
  @import "@/css/variables";
  .waiter-page.page.page-custom .page-header .page-header-height {
    box-shadow: none;
  }
  .waiter-page {

  }
</style>
