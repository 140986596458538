<template>
  <div class="header-message-container" :class="{ 'visible': isVisible }">
    <div v-if="isVisible" class="header-message">
       <div class="header-message-inner" :class="{ [color]: true }">
         <div class="header-message-left">
           <slot />
         </div>
         <div v-if="closable" class="header-message-right">
           <f7-button @tap="hide" class="header-message-hide">
             <i class="mpi-del"></i>
           </f7-button>
         </div>
         <slot name="price" />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from "vue"

  export default {
    props: {
      modelValue: { type: Boolean, default: false },
      color: { type: String, default: "primary" },
      closable: { type: Boolean, default: false }
    },
    setup(props, { emit }) {
      const isVisible = ref(props.modelValue)

      watch(() => props.modelValue, value => {
        isVisible.value = value
      })

      return {
        isVisible,
        hide: () => {
          isVisible.value = false
          emit("update:modelValue", isVisible.value)
        }
      }
    }
  }
</script>
