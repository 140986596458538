import gui from "../gui"
import user from "../user"
import state from "../state"
import * as settings from "../settings"
import { v4 as uuid } from "uuid"

import { printBill } from "../libs/bills"
import { isNewReceipt, save } from "./receipt"

export async function checkIsAllSent(receipt = state.receiptData) {
  if (!await settings.isCafe()) return true;

  const result = await checkGoodsSent(receipt);
  
  if (result && receipt.goodsCount < 0.0001) return true;
  
  if (receipt.runnerErrors.length > 0 || !result) return false;

  return true;
}

export async function checkGoodsSent(receipt) {

  for (const good of receipt.goods) {
    if (!good.sent || !await checkIsCafeGoodSent(good)) return false;
  }

  return true;
}

export async function checkIsCafeGoodSent(good) {
  const printers = state?.settings?.printers || [];
  const intersection = good.runner.filter(runner => printers.find(printer => printer.type === runner));

  for (const key of intersection) {
    if (typeof good.countRunner[key] === 'undefined' && good.count < 0.0001) continue;
    if (typeof good.countRunner[key] === 'undefined' || good.countRunner[key] !== good.count) return false;
  }
  return true
}

export async function checkIsGoodSent(good) {
  if (!await settings.isCafe()) return true;

  return await checkIsCafeGoodSent(good);
}

export async function createOrderTicket() {
  if (state.receiptData.isAllSent && state.receiptData.goodsCount < 0.0001) return;

  if (!state.receiptData.isAllSent && await gui.questionUI({
    message: gui.translate("receipts.notificationPickItems"),
    cancelButton: gui.translate("receipts.all"),
    confirmButton: gui.translate("receipts.new")
  })) {
    await printRunner()
  } else {
    await printRunnerAll()
  }

}

export async function printRunnerAll(receipt = state.receiptData) { 

  if(!await user.verifyPermissionRequest("all.cashier.receiptSpecials.approveRunner", {
    description: gui.translate('receipts.reprintRunnerAccessDescription'),
    title: gui.translate('receipts.reprintRunner')
  })) {
    return false
  }

  //reset good
  for (let good of receipt.goods) {
    for (let key in good.countRunner) {
      good.countRunner[key] = 0;
    } 
  } 

  //reset comments
  receipt.comments.forEach(comment => comment.sent = {});

  await printRunner(undefined, true);
}

export async function printRunner(receipt = state.receiptData, all = false) {

  if(isNewReceipt(state.receiptData) && state.receiptData.goods.find(good => good.verifyRequired && !good.sent)) {
    if(!await gui.ageVerification()) {
      return false
    }
  }

  receipt.runnerErrors = [];
  const printers = state?.settings?.printers || []
  
  if (!printers || !printers.length) {
    console.log(`printRunner no printers`)
    return true
  }

  
  for (let printer of printers) {
    if (!printer.type) {
      continue
    }
    
    let send = false;

    for (let good of receipt.goods) {
      const runner = Array.isArray(good?.runner)
      if (!runner) {
        continue
      }

      if (good.runner.includes(printer.type)) {
        good.countPrint[printer.type] = good.count - (good.countRunner[printer.type] || 0);
        if (good.countPrint[printer.type] !== 0) send = true;
      } else {
        good.countPrint[printer.type] = 0
      }

    }

    if (send) {
      //mark comments for printing
      receipt.comments.forEach(comment => {
        if (comment.sent[printer.type] !== true) {
          comment.sent[printer.type] = false;
        }
      });

      if (!await printBill("runner", receipt, {
        queue: [printer.type],
        processMessage: gui.translate("checkout.printingRunner"),
        printerKey: printer.type
      })) {
        //print error
        state.receiptData.runnerErrors.push(settings.PRINTERS_NAMES[printer.type]);
        gui.receiptData = state.receiptData;
        await save(receipt)
        continue;
      }
    
      //if no errors
      for (let good of receipt.goods) {
        if (good.runner.includes(printer.type)) good.countRunner[printer.type] = good.count;
      }

      //mark printed comments
      receipt.comments.forEach(comment => {
        comment.sent[printer.type] = true;
      });
    }
  }
  //mark comments as not new and is check fully sent
  receipt.comments.forEach(comment => {
    comment.new = false;
    comment.sentFull = !Object.values(comment.sent).includes(false);
  });
  
  //mark sent goods
  for (let good of receipt.goods) {
    good.sent = await checkIsCafeGoodSent(good);
  }


  receipt.isAllSent = await checkIsAllSent();
  //save
  await save(receipt)
  gui.receiptData = state.receiptData;

  //message
  if (receipt.runnerErrors.length === 0) {
    await gui.message(all ? gui.translate("receipts.sentSuccesfullyAll") : gui.translate("receipts.sentSuccesfully"))
  }

}

export async function addOrderComment(text) {
  state.receiptData.comments?.push({text, id: uuid(), new: true, sent: {}, sentFull: false})
  await save(state.receiptData)
  gui.receiptData = state.receiptData
}

export async function updateOrderComment(text, id) {
  state.receiptData.comments?.forEach(comment => {
    if (comment.id === id) comment.text = text
  });
  await save(state.receiptData)
  gui.receiptData = state.receiptData
}

export async function deleteOrderComment(id) {
  state.receiptData.comments?.splice(state.receiptData.comments.findIndex((item) => item.id === id), 1);
  await save(state.receiptData)
  gui.receiptData = state.receiptData
}
