import rpc from '../libs/rpc';
import gui from '../gui';
import dicts from '../dicts';
import state from '../state';
import user from "../user"

import { cloneDeep } from "lodash"
import { openReceipt, save } from './receipt';
import { navigateBack, navigate, deleteLastRoute } from "./router"
import { isOnline } from '../online';

const startShowReserveNoticeMins = 360;
const startShowReserveNotice = startShowReserveNoticeMins * 1000 * 60; 
const notificationTimeAfterReservationMins = 20;
const cachingTimeSec = 30;

export async function open(options = {}) {
  const noNeedUpdate = (Date.now() - state.tables?.lastUpdate) < cachingTimeSec * 1000;

  if (!options.needUpdate && noNeedUpdate ) {
    state.tables.change = options.change ?? false;

    gui.tables = state.tables;
    await navigate('/tables');
    return;
  } 
  
  gui.preloader(true);

  const halls = await dicts.halls.get({
    storeId: state.user.store._id,
    enabled: true,
  });

  const tables = await prepareTablesData(halls);

  state.tables = getHallsSchema();
  state.tables.tables = tables;
  state.tables.change = options.change ?? false;
  state.tables.reserved = noNeedUpdate ? state.tables.reserved : await getReservedTables();
  state.tables.lastUpdate = Date.now();

  await setReservedToday();
  
  gui.tables = state.tables;

  gui.preloader(false);

  if (!options.needUpdate) await navigate('/tables');
}

export async function getTableOfReceipt(receipt) {
  if(receipt.tableId) {
    return { _id: receipt.tableId, tableName: receipt?.tableName, hall: receipt?.hall }
  }

  return null
}

export async function setReceiptTable(receipt, table = null) {
  if(table) {
    receipt.hall = table.hall
    receipt.tableId = table._id
    receipt.tableName = table.tableName
  }
}

export async function reserve(reserveData) {

  if (reserveData.date.unix <= Date.now()) {
    await gui.message( gui.translate("tables.errorTime") );
    return false;
  }

  if (!isOnline || !await gui.questionUI({
    message: gui.translate("tables.notificationReserve", { table: reserveData.table.tableName, date: reserveData.date.displayed }),
    cancelButton: gui.translate("cancel"),
    confirmButton: gui.translate("tables.reserve")
  })) {
    return false;
  }

  gui.preloader(true);
  
  const response = await rpc('pos.tables.reserve', reserveData.table._id, reserveData.date.unix );

  gui.preloader(false);

  if(response.error) {
    await gui.message( gui.translate(response.error) ); 
  } else {
    await updateLocalReserveData({ _id: response[0], tableId: reserveData.table._id, reserveDate: reserveData.date.unix });

    if (reserveData.date.unix <= Date.now() + startShowReserveNotice) await setReservedToday();

    gui.tables = state.tables;

    await gui.message( gui.translate("tables.successfullyReserved") );
  }
 
}

export async function unreserve(id) {
  if (!isOnline || !await gui.questionUI({
    message: gui.translate("tables.notificationUnreserve"),
    cancelButton: gui.translate("no"),
    confirmButton: gui.translate("yes")
  })) {
    return false;
  } 

  if(!await user.verifyPermissionRequest("all.cashier.tables.unreserveTable", {
    description: gui.translate('tables.unreserveTableAccessDescription'),
    title: gui.translate('tables.unreservePermissionTitle')
  })) {
    return false;
  }

  const response = await rpc('pos.tables.delReserve',  id );
  
  if(response.error) {
    await gui.message( gui.translate("tables.errorUnreserve") ); 
    return false;
  } else {
    await clearLocalReserveData(id);
    await setReservedToday();

    gui.tables = state.tables;

    await gui.message( gui.translate("tables.successfullyUnreserved") );
    return true;
  }
}

async function clearLocalReserveData(id) {
  state.tables.reserved.data = state.tables.reserved.data.filter((reserved) => reserved._id !== id);
  // gui.tables = state.tables;
}

async function updateLocalReserveData(data) {
  state.tables.reserved.data.push(data);
  state.tables.reserved.data.sort((a, b) => a.reserveDate - b.reserveDate);

  // gui.tables = state.tables;
}

export async function checkOnline() {
  return isOnline;
}

export async function getReservedTables() {
  if (isOnline) {
    return { data: await rpc('pos.tables.getReserved', notificationTimeAfterReservationMins), isOnline };
    
  } else {
    return { data: await dicts.tables_reserve.get({
        where: {
          storeId: state.user.store._id,
          companyId: state.user.company._id,
          'reserveDate >=': Date.now(),
        },
        order: 'reserveDate ASC',
      }),
      isOnline
    }
  }
}


async function setReservedToday() {

  for (let hall of state.tables.tables) {
    hall.tables.forEach((table)=> {
      const reservedToday = state.tables.reserved.data.find(
        (item) => table._id === item.tableId && item.reserveDate <= Date.now() + startShowReserveNotice);

        table.reserved = reservedToday ? reservedToday : false;
    })
  }

  return;
}


export async function select(hallName, table) {
  state.receiptData.hall = hallName;
  state.receiptData.tableName = table?.tableName || null;
  state.receiptData.tableId = table?._id || null;

  gui.receiptData = state.receiptData;

  await deleteLastRoute();

  if (state.tables.change) {
    state.tables.change = false;
    gui.tables = state.tables;

    await save(state.receiptData);
    await navigateBack();
  } else {
    await openReceipt();
  }
}

async function getOccupiedTables() {
  const remote = await rpc('pos.tables.getOccupied');
  const local = await dicts.docs.get({
    fields: [
      'COUNT(*) as "tableReceipts"',
      'SUM("goodsCount") as "tableItems"',
      '"tableId"',
      'MAX("userName") as "userName"',
      'MAX("createdOn")',
    ],
    group: '"tableId"',
    where: { type: 'receipt', state: 'active', storeId: state.user.store._id, },
  });

  return {
    remote: remote.error ? [] : Array.from(remote).map(item => ({...item, tableId: +item.tableId})), 
    local: local.error ? [] : Array.from(local),
  }
}

async function prepareTablesData(halls) {
  const tablesFull = [];

  const occupiedTables = await getOccupiedTables();
  for (let hall of halls) {
    const tablesByHall = await dicts.tables.get({
      storeId: state.user.store._id,
      hallId: hall._id,
      enabled: true,
    });

    tablesByHall.forEach((table) => {
      const remoteOccupiedTable = occupiedTables.remote.find(
        (item) => item.tableId === table._id
      );
      const localOccupiedTable = occupiedTables.local.find(
        (item) => item.tableId === table._id
      );

        table.userName = remoteOccupiedTable?.userName ?? localOccupiedTable?.userName;
        table.tableReceipts = { remote: remoteOccupiedTable?.tableReceipts, local: localOccupiedTable?.tableReceipts };
        table.tableItems = { remote: remoteOccupiedTable?.tableItems, local: localOccupiedTable?.tableItems };
      
    });

    tablesFull.push({
      hallName: hall.name,
      tables: tablesByHall,
    });
  }

  //SKIPPED HALL  --- start
  const remoteSkippedData = occupiedTables.remote.find(
    (item) => item.tableId === null
  );

  const localSkippedData = occupiedTables.local.find(
    (item) => item.tableId === null
  );

  tablesFull.push({
    hallName: null,
    tables: [{
      _id: null,
      hallId: null,
      tableName: null,
      tableReceipts: { remote: remoteSkippedData?.tableReceipts, local: localSkippedData?.tableReceipts },
      tableItems: { remote: remoteSkippedData?.tableItems, local: localSkippedData?.tableItems },
      userName: remoteSkippedData?.userName
    }],
  })
  //SKIPPED HALL  --- end

  return tablesFull;
}

function createRegExp(string) {
  return new RegExp(string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
}

export async function search(searchValue) {
  const regexp = createRegExp(searchValue);

  //search tables
  const results = cloneDeep(state.tables.tables);

  results.forEach((hall) => {
    hall.tables = hall.tables.filter((item) => regexp.test(item.tableName));
  });

  //filter empty halls
  state.tables.search.results = results.filter(hall => hall.tables?.length > 0);

  gui.tables = state.tables;
}

export async function disableSearching() {
  state.tables.search.results = [];
  gui.tables = state.tables;
}


function getHallsSchema() {
  return {
    tables: {
      delivery: [], //delivery tables
      all: [], //tables by halls { hallName: '', tables: [] }
    },
    change: false,
    search: {
      results: [],
    },
    updated: true,
    reserved: state.tables?.reserved ? state.tables.reserved : {data: []}
  };
}

export default {
  open,
};
