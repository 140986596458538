<template>
  <div class="custom-stepper">
    <div class="form-group input-item">
      <f7-button
        @tap="changeValue(-step)"
        fill
        :id="`${id}-button-minus`"
        class="stepper-btn"
        :disabled="stepperValue <= min"><i class="mpi-md-minus"></i></f7-button>
      <div class="value" :id="`${id}-value`" @click="showKeyboard">
        <span v-if="from && max < 100 && stepperValue < 10" class="from">{{ stepperValue }} / {{ max }}</span>
        <span v-else>{{ stepperValue }}</span>
      </div>
      <f7-button
        @tap="changeValue(+step)"
        fill
        :id="`${id}-button-plus`"
        class="stepper-btn"
        :disabled="stepperValue >= max"><i class="mpi-md-plus"></i></f7-button>
    </div>
  </div>
  <KeyboardSheet
    :opened="isKeyboardOpened"
    @sheet:closed="hideKeyboard"
    @onConfirm="onConfirm"
    v-model:value="stepperValue"
    :title="$t('receipts.insertQty')"
    id='stepper-sheet'
    :min="min"
    :max="max"
    :integer="integer"
  />
</template>

<script>
import KeyboardSheet from "./KeyboardSheet";
import { ref, watch } from "vue";

export default {
  components: { KeyboardSheet },
  props: {
    value: { type: Number, default: 1 },
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    step: { type: Number, default: 1 },
    from: { type: Boolean, default: false },
    integer: { type: Boolean, default: false },
    id: { type: String, default: "stepper" },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const stepperValue = ref(props.value);
    const isKeyboardOpened = ref(false);

    watch(stepperValue, (value) => {
      if (props.value !== stepperValue.value) emit("update:value", value);
    });

    watch(() => props.value, (value) => {
      stepperValue.value = value;
    });

    return {
      stepperValue,
      isKeyboardOpened,
      showKeyboard: () => {
        isKeyboardOpened.value = true;
      },
      hideKeyboard: () => {
        isKeyboardOpened.value = false;
      },
      changeValue: (value) => {
        const newValue = stepperValue.value + value;
        if (newValue >= props.min && newValue <= props.max) {
          stepperValue.value = newValue;
        }
      },
      onConfirm: () => {
        isKeyboardOpened.value = false;
      },
    };
  },
};
</script>



<style lang="scss">
@import "@/css/variables";
.custom-stepper {
  display: flex;

    .input-item {
      padding: 0;
      border-radius: $border-radius;
    }
  .form-group {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    .stepper-btn {
      border-radius: $border-radius;
      width: $input-height;
      height: $input-height;
      .icon {
        font-size: 9px;
      }
    }
    .value {
      width: 63px;
      color: #000000;
      font-size: 24px;
      line-height: 118.75%;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0 2px;
    }
  }
}
</style>
