import { sum } from "@/js/filters"

const _cache = {}

function playSound(name, volume) {
  if(_cache[name]) {
    _cache[name].volume = volume
    _cache[name].currentTime = 0
    _cache[name].play()
  } else {
    const audio = new Audio(require(`@/static/audio/${name}.ogg`))

    audio.volume = volume
    audio.addEventListener("canplaythrough", async () => {
      _cache[name] = audio
      await audio.play()
    }, false)
  }
}

export function click() {
  let volume = gui.settingsData.value.clickVolume
  volume = sum(typeof volume === "undefined" ? 0.15 : (volume / 100))

  playSound("click", volume)
}
