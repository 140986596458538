import Dict from './libs/dict'
import state from "./state"

const db = openDatabase("MPlus", "1.0", "dicts", 2 * 1000 * 1000 * 1000)
console.log("DB", db)

const dicts = {}

export function getDictWrapper(key, dict) {
  return {
    async count(obj) {
      return await dict.count(obj)
    },
    async get(obj) {
      return await dict.get(obj)
    },
    async getOne(obj) {
      return await dict.getOne(obj)
    },
    async put(obj) {
      obj = Array.isArray(obj) ? obj : [obj]

      return await dict.put(obj.map(item => {
        item._updateon = Date.now()
        item._updateby = state.user ? state.user._id : null
        return item
      }))
    },
    async del(obj) {
      return await dict.del(obj)
    },
    async truncate() {
      return await dict.truncate()
    },
    async restartAutoIncrement(value) {
      await dict.sql(`UPDATE sqlite_sequence SET seq = ${value} WHERE NAME = '${dict.table}'`)
    },
    async transaction() {
      return await dict.transaction()
    },
    async commit() {
      return await dict.commit()
    },
    async rollback() {
      return await dict.rollback()
    }
  }
}

export async function Dicts(dictsConfig) {
  const keys = Object.keys(dictsConfig)
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i][0] === '!' ? keys[i].substr(1) : keys[i]
    const dict = await new Dict({
      fields: dictsConfig[keys[i]],
      name: keys[i],
      db: db
    })

    dicts[key] = getDictWrapper(key, dict)
  }
}

export default dicts
