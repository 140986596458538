import rpc from '../libs/rpc';
import gui from '../gui';
import state from '../state';

import { save } from "./receipt"

export async function open(options = {}) {
  state.loyaltyData = state.loyaltyData ? state.loyaltyData : {}
  state.loyaltyData.shown = true;
  state.loyaltyData.member = state.receiptData.loyaltyMember;
  gui.loyaltyData = state.loyaltyData;
}

export async function close() {
  state.loyaltyData.shown = false;
  state.loyaltyData.member = null;
  state.loyaltyData.error = "";
  gui.loyaltyData = state.loyaltyData;
}

export async function clearMemberData() {
  state.loyaltyData.member = null;
  gui.loyaltyData = state.loyaltyData;
}

export async function findMember(options = {}) {
  gui.preloader(true);

  const response = await rpc('pos.loyalty.findMember', options.phone);

  state.loyaltyData.error = response.error;
  state.loyaltyData.member = response.error ? null : response;
  gui.loyaltyData = state.loyaltyData;

  gui.preloader(false);
}

async function setMemberToReceipt(member) {
  state.receiptData.loyaltyMember = member;

  await save(state.receiptData);

  gui.receiptData = state.receiptData;
}

export async function addMember(member) {
  await setMemberToReceipt(member);
  await close();
}

export async function clear() {
  await setMemberToReceipt(null);
  await clearMemberData();
}



