<template>
  <Page
    class="modifiers-dialog"
    id="modifiers-dialog"
    :class="{ 'fade-in': modifiers.shown, 'fade-out': !modifiers.shown }">
    <Header>
      <div class="left">
        <f7-link @tap="goBack" class="icon-only" id="modifiers-back-link">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t("receipts.modifiers") }}</div>
    </Header>
    <Inner>
      <div class="modifiers-page-content">
        <Options
          @onChange="onChange"
          :all="modifiers.list"
          :selected="good?.selectedModifiers"
        />
      </div>
    </Inner>
  </Page>
</template>

<script>
  import { ref, watch } from "vue";

  import Options from "../Receipt/Options";

  export default {
    components: { Options },
    setup() {
      const getCurrentGood = () => gui.receiptData.value.goods?.find((good) => good.receiptGoodUuid === gui.receiptData.value.currentGood);
      const good = ref(getCurrentGood());

      const modifiers = ref(gui.modifiers);

      const onChange = (value) => {
        gui.dispatch("modifiers.change", good.value.receiptGoodUuid, value);
      };

      watch(gui.receiptData, value => {
        good.value = getCurrentGood()
      });

      watch(gui.modifiers, value => {
        modifiers.value = value
      });

      return {
        modifiers,
        good,
        onChange,
        goBack: () => {
          gui.dispatch("modifiers.close");
        }
      };
    },
  };
</script>

<style lang="scss">
  @import "@/css/variables";
  .modifiers-dialog.page.page-custom .page-header .page-header-height {
    box-shadow: none;
  }
  .modifiers-page-content {
    overflow-y: auto;
    height: 100%;
  }
  .modifiers-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateY(100vh);
    background-color: $main-bg-color;
    z-index: 13499;
    transition: .3s;

    &.fade-out {
      .animate__bounceIn {
        animation-name: none !important;
      }
    }

    &.fade-in {
      visibility: visible;
      transform: translateY(0);
    }

  }
</style>
