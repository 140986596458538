<template>
  <div
    class="custom-checkbox"
    :class="{
      scale: scale,
      opacity: opacity && !checked,
      visible: visible,
    }"
  >
    <div
      class="custom-checkbox-check"
      :class="{
        checked: checked,
        visible: checked,
        scale: scale,
      }"
    >
      <i class="icon mpi-expand2 color-white"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: [Boolean, Object],
      default: true,
    },
    opacity: {
      type: [Boolean, Object],
      default: false,
    },
    checked: {
      type: [Boolean, Object],
      default: false,
    },
    scale: {
      type: [Boolean, Object],
      default: false,
    },
  },
};
</script>
<style lang="scss">
@import "@/css/variables";

.custom-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
  background: $input-color;
  transform: scale(.6);
  &.opacity {
    opacity: 0.5 !important;
  }
  &.visible {
    opacity: 1;
    visibility: visible;
    transform: scale(1)
  }
  .custom-checkbox-check {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.6);
    background-color: $primary-color;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    transition: 0.3s;
    &.checked {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
    .icon {
    }
  }
}

</style>
