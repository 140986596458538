import rpc from "../libs/rpc"
import gui from "../gui"
import state from "../state"

import { round2 } from "../libs/util"
import { isOnline } from "../online"
import { navigate, getPath } from "./router"
import { save, calculate, isNewReceipt } from "./receipt"

export async function applyCertificate(barcode) {
  if(isNewReceipt(state.receiptData) && state.receiptData.sumTotal > 0.001) {
    if(isOnline) {
      gui.preloader(true)
      const response = await rpc("pos.certificates.apply", barcode, state.receiptData.uuid)

      if(response.error) {
        gui.preloader(false)
        await gui.error(gui.translate(response.error), gui.translate("certificates.applyErrorTitle"))
      } else {
        const certificate = response.certificate
        certificate.amount = round2(certificate.amount)

        state.receiptData.certificates.unshift(certificate)
        await calculate(state.receiptData)
        await save(state.receiptData)
        gui.receiptData = state.receiptData

        gui.preloader(false)
        await gui.message(gui.translate(response))

        if(await getPath() === "/receipt/certificates/add") {
          await open()
        }
      }

      gui.preloader(false)
    } else {
      gui.toast(gui.translate("checkNetworkConnection"))
    }
  }
}

export async function cancelCertificate(id) {
  if(isNewReceipt(state.receiptData)) {
    if(isOnline) {
      if(!await gui.question(gui.translate("certificates.cancelCertificate"))) {
        return false
      }

      gui.preloader(true)
      const response = await rpc("pos.certificates.cancel", id)

      if(response.error) {
        gui.preloader(false)
        await gui.error(gui.translate(response.error), gui.translate("certificates.cancelErrorTitle"))
      } else {
        state.receiptData.certificates = state.receiptData.certificates.filter(certificate => certificate._id != id)
        await calculate(state.receiptData)
        await save(state.receiptData)
        gui.receiptData = state.receiptData

        gui.preloader(false)
        await gui.message(gui.translate(response))

        await open()
      }

      gui.preloader(false)
    } else {
      gui.toast(gui.translate("checkNetworkConnection"))
    }
  }
}

export async function cancelCertificates(receipt) {
  if(Array.isArray(receipt.certificates) && receipt.certificates.length > 0) {
    for await (let certificate of receipt.certificates) {
      const response = await rpc("pos.certificates.cancel", certificate._id)
      if(response.error) {
        return false
      }
    }
  }
  return true
}

export async function openManualApply() {
  if(isNewReceipt(state.receiptData)) {
    await navigate("/receipt/certificates/add")
  }
}

export async function open() {
  if(isNewReceipt(state.receiptData)) {
    await navigate("/receipt/certificates")
  }
}
