import * as pos from "mplus-pos"
import * as state from "./state"

import Cache from "./cache"
import { delay } from "./utils"
import { sum } from "./filters"

let _lastPage = null
let _toastTimer = null

export async function ageVerification() {
  return new Promise(resolve => {
    state.ageVerificationData.value = {
      shown: true,
      resolver: value => {
        state.ageVerificationData.value.shown = false
        resolve(value)
      }
    }
  })
}

export async function periodicAgeVerification() {
  return new Promise(resolve => {
    state.periodicAgeVerificationData.value = {
      shown: true,
      resolver: value => {
        state.periodicAgeVerificationData.value.shown = false
        resolve(value)
      }
    }
  })
}

export async function selectScreenshots(options) {
  const list = await dispatch('files.getScreenshots');
  return new Promise(resolve => {
    state.screens.value = {
      shown: true,
      selected: [...options.selected] || [],
      list,
      max: options.max,
      resolver: value => {
        state.screens.value.shown = false;
        resolve(value);
      }
    }
  })
}

export function printing(printingData = {}) {
  if(printingData === false) {
    state.printingProcess.value.shown = false
    return true
  }

  const error = printingData.error || null
  state.printingProcess.value = {
    error,
    shown: Boolean(printingData.shown),
    title: printingData.title || "Printing...",
    resolver: null
  }

  if(error) {
    return new Promise(resolve => {
      state.printingProcess.value.resolver = value => {
        state.printingProcess.value.shown = false
        resolve(value)
      }
    })
  }
}

export async function loginClose() {
  state.onLoginScanned.value = null
  gui.loginData.value = {
    ...gui.loginData.value,
    shown: false
  }

  setTimeout(() => {
    if(gui.loginData.value && gui.loginData.value.state) {
      gui.loginData.value = {
        ...gui.loginData.value,
        state: "identification"
      }
    }
  }, 400)

  return true
}

export async function login(loginData) {
  // Barcode scanner
  state.onLoginScanned.value = barcode => {
    state.loginData.value.barcode = barcode
    state.loginData.value.action("identifySellerBarcode")
  }

  return new Promise(resolve => {
    state.loginData.value = {
      ...loginData,
      shown: true,
      action: (action, data = {}) => {
        resolve({ action, data })
      }
    }
  })
}

export async function welcome(welcomeData) {
  return new Promise(resolve => {
    state.welcomeData.value = {
      ...welcomeData,
      action: (action, data = {}) => {
        resolve({ action, data })
      }
    }
  })
}

export function paymentClose() {
  state.paymentProcessData.value.shown = false
}

export function pairingClose() {
  state.pairingData.value.shown = false
}

export function pairing() {
  return new Promise(resolve => {
    state.onScanned.value = resolve

    state.pairingData.value = {
      shown: true
    }
  })
}

export function payment(options = {}) {
  const sum = isNaN(Number(options.sum)) ? 0 : Number(options.sum)
  const type = options.type || ""
  const title = options.title || ""
  const error = options.error || ""
  const message = options.message || ""
  const canCancel = typeof options.canCancel === "undefined" ? false : options.canCancel

  state.paymentProcessData.value = {
    ...options,
    shown: true,
    sum,
    type,
    title,
    message,
    canCancel,

    error,
    state: options.state ? options.state : "active",
  }
}

export async function questionUI(options = {}) {
  const message = options.message || ""
  const description = options.description || null
  const cancelButton = options.cancelButton === undefined ? gui.translate("dialogDefault.cancel") : options.cancelButton
  const confirmButton = options.confirmButton === undefined ? gui.translate("dialogDefault.ok") : options.confirmButton

  return new Promise(async resolve => {
    if(state.questionUIData.value.lastUpdate) {
      const delayMs = Date.now() - state.questionUIData.value.lastUpdate
      if(delayMs < 300) {
        await delay(200)
      }
    }
    state.questionUIData.value = {
      shown: true,
      message,
      description,
      cancelButton,
      confirmButton,
      lastUpdate: Date.now(),
      resolver: condition => {
        state.questionUIData.value.lastUpdate = Date.now()
        resolve(condition)
      }
    }
  })
}

export async function getSumUI(options = {}) {
  return new Promise(resolve => {
    const minSum = isNaN(Number(options.minSum)) ? 0 : Number(options.minSum)
    const maxSum = isNaN(Number(options.maxSum)) ? Number.MAX_SAFE_INTEGER : Number(options.maxSum)

    let defaultSum = isNaN(Number(options.defaultSum)) ? "" : sum(Number(options.defaultSum))

    if(+defaultSum > maxSum) {
      defaultSum = maxSum
    }

    if(+defaultSum < minSum) {
      defaultSum = minSum
    }

    state.getSumUIData.value = {
      shown: true,
      title: options.title || gui.translate("dialogDefault.enterSum"),
      label: options.label || gui.translate("dialogDefault.enterSum"),
      minSum,
      maxSum,
      defaultSum,
      resolver: resolve
    }
  })
}

export async function message(message, description) {
  return questionUI({
    message,
    description,
    cancelButton: null,
    confirmButton: gui.translate("ok")
  })
}

export async function error(error, title) {
  return questionUI({
    message: title,
    description: error,
    cancelButton: null,
    confirmButton: gui.translate("ok")
  })
}

export async function question(message, description) {
  return questionUI({
    message,
    description,
    cancelButton: gui.translate("no"),
    confirmButton: gui.translate("yes")
  })
}

export function toast(message, delay = 2200, position = "center") {
  state.toastData.value = { shown: true, message, position }

  if(_toastTimer !== null) {
    clearTimeout(_toastTimer)
  }

  _toastTimer = setTimeout(() => {
    state.toastData.value.shown = false
    _toastTimer = null
  }, delay)
}

export function preloader(shown = true) {
  state.preloaderShown.value = shown
}

export async function dispatch(name, ...args) {
  const names = name.split(".")
  let current = pos

  for(let key of names) {
    if(current[key]) {
      if(typeof current[key] === "function") {
        return await current[key]?.(...args)
      } else {
        current = current[key]
      }
    } else {
      return undefined
    }
  }
}

function isPromise(obj) {
  return (
    !!obj && typeof obj.then === 'function' && typeof obj.catch === 'function'
  )
}

export default {
  _cache: new Cache(),
  ...state,
  printing,
  ageVerification,
  periodicAgeVerification,
  selectScreenshots,
  login,
  loginClose,
  welcome,
  payment,
  paymentClose,
  pairing,
  pairingClose,
  callbacks: {},
  routes: [],//routes.map(route => ({ path: route.path, history: route.history })),
  async openWidgetsControl() {
    await this.navigate("/home")

    setTimeout(() => {
      if(typeof this.callbacks.onWidgetControl === "function") {
        this.callbacks.onWidgetControl()
      }
    }, 30)
  },
  async nativeNavigate(page) {
    if(page !== _lastPage) {
      state.onScanned.value = null
    }

    _lastPage = page
    state.page.value = page

    await gui.router.push(page)
  },
  async navigate(page) {
    await dispatch("router.navigate", page)
  },
  async navigateBack() {
    await dispatch("router.navigateBack")
  },
  async clearHistory() {
    await dispatch("router.clearHistory")
  },
  translate(data, languageOptions = null) {
    languageOptions = languageOptions ? languageOptions : {}

    if(typeof data === "string") {
      return this.i18n.t(data, languageOptions)
    } else {
      if(this.i18n.te(data.key)) {
        return this.i18n.t(data.key, languageOptions)
      } else {
        return data.message || ""
      }
    }
  },
  getPath() {
    return gui.router.currentRoute.value.fullPath
  },
  enableSwipePanel() {
    state.drawerData.value.disabled = false
  },
  disableSwipePanel() {
    state.drawerData.value = {
      ...state.drawerData.value,
      shown: false,
      disabled: true
    }
  },
  openPanel() {
    state.drawerData.value = {
      ...state.drawerData.value,
      shown: true
    }
  },
  hidePanel() {
    state.drawerData.value = {
      ...state.drawerData.value,
      shown: false
    }
  },
  closeAll() {
    preloader(false)

    state.ageVerificationData.value = { shown: false }
    state.calculator.value = false
    state.getSumUIData.value = { ...state.getSumUIData.value, shown: false, resolver: null }
    state.modifiers.value = { shown: false }
    state.pairingData.value = { shown: false }
    state.paymentProcessData.value = { ...state.paymentProcessData.value, shown: false, resolver: null }
    state.periodicAgeVerificationData.value = { shown: false }
    state.printingProcess.value =  { ...state.printingProcess.value, shown: false, }
    state.product.value = { shown: false }
    state.questionUIData.value = { ...state.questionUIData.value, shown: false, resolver: null }
    state.syncShown.value = false

  },
  error,
  toast,
  message,
  question,
  preloader,
  getSumUI,
  questionUI,
  dispatch
}
