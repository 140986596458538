<template>
  <f7-button :id="getButtonId(item.title)" @tap.prevent="$emit('item-click', item)" class="actions-sheet-group-item ripple-color-black" :class="{ [item.color || 'base']: true}">
    <div class="actions-sheet-group-item-title">{{ $t(item.title) }}</div>
    <div v-if="item.toggleItem" class="actions-sheet-group-item-toggle">
      <f7-toggle :readonly="true" v-model="item.toggleValue.value" />
    </div>
  </f7-button>
</template>

<script>
  export default {
    props: {
      item: { type: Object, default: () => ({}) }
    },
    methods: {
      getButtonId(title) {
        return "receipt-option-" + title.toLowerCase().replace(/\./g, "-")
      },
    }
  }
</script>
