import {
  PIECES,
  superForPromo,
  applyPromoPrice,
  splitAndHandleGoodsPieces
} from "./helpers"

export default async function (promotion, receipt) {
  if(!await superForPromo(promotion, receipt)) {
    return false
  }

  const mainGoodsIds = promotion.options.mainGoodsIds || []
  const actionGoodsIds = promotion.options.actionGoodsIds || []

  if(actionGoodsIds.length > 0 && mainGoodsIds.length > 0) {
    await splitAndHandleGoodsPieces(PIECES.ONE_PLUS_ONE, receipt, mainGoodsIds, actionGoodsIds, promotion.options.maxQty, async good => {
      await applyPromoPrice(promotion, good, 0)
    })
  }
}
