const MAX_CACHE_SIZE = 100

export default class Cache {
  constructor() {
    this._cache = new Map()
  }

  add(id, value) {
    if(this.contains(id)) {
      return true
    }

    this._cache.set(id, { value, id })
    if(this._cache.size > MAX_CACHE_SIZE) {
      const firstItemId = this._cache.values().next().value.id
      this._cache.delete(firstItemId)
    }

    return true
  }

  get(id) {
    if(this.contains(id)) {
      return this._cache.get(id).value
    }

    return null
  }

  contains(id) {
    return this._cache.has(id)
  }
}