<template>
  <transition name="notification" v-if="timer !== null">
    <div class="notification-backdrop">
      <div class="notification-content elevation-24">
        <div
          class="text-primary"
          v-html="$t('logout.warningMessage', { seconds: timer })"
        ></div>
        <div class="text-secondary">{{ $t("logout.continueMessage") }}</div>
      </div>
    </div>
  </transition>
</template>
<script>
import { ref, watch } from "vue";

export default {
  setup() {
    const timer = ref(gui.idleСountdown);

    watch(gui.idleСountdown, (value) => (timer.value = value));

    return {
      timer,
    };
  },
};
</script>
<style lang="scss">
@import "@/css/variables";
.notification-backdrop {
  position: absolute;
  z-index: 14999;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  .notification-content {
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    color: $text-color;
    font-size: 20px;
    display: flex;
    justify-content: center;
    width: calc(100% - 40px);
    margin: 20px;
    z-index: 15000;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    height: 120px;
    .text-secondary {
      color: #a7adbf;
      font-size: 16px;
    }
    .time {
      color: $error-color;
    }
  }
}
.notification-enter-active,
.notification-leave-active {
  transition: opacity 0.5s;
}
.notification-enter,
.notification-leave-to {
  opacity: 0;
}
</style>