<template>
  <Page
      schema="blue"
      class="receipts-page"
      id="receipts-page"
      @page:beforein="onPageBeforeIn">
    <Header
        ref="header"
        v-model="searchingValue"
        @search:update="search"
        @search:enable="enableSearching"
        @search:disable="disableSearching"
        search-id="receipts-search-field"
        :search-hide-outside="false"
        :search-placeholder="$helpers.orderOrReceipt('receipts.searchReceipts', 'receipts.searchOrders', true).value">
      <div class="left">
        <f7-link id="receipts-back" @tap="goBack" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ title }}</div>
      <div class="right">
        <f7-link
          id="receipts-filter-btn"
          class="icon-only"
          @tap="openFilters">
          <i class="icon mpi-filter color-black"></i>
        </f7-link>
        <f7-link
          id="receipts-search-btn"
          class="icon-only searchbar-enable"
          @tap="openSearchBar">
          <i class="icon mpi-search color-black"></i>
        </f7-link>
      </div>

      <template #out>
        <Subnavbar>
          <div v-for="tab of tabs" :key="tab.key" class="subnavbar-button-item">
            <f7-button
              round
              :id="`receipts-tab-${tab.key}-btn`"
              :tab-link-active="activeTab === tab.key"
              @tap="changeTab(tab.key)">{{ $t(tab.title) }}</f7-button>
          </div>
        </Subnavbar>
      </template>
    </Header>

    <Inner>
      <f7-tabs>
        <f7-tab id="tab-postponed" :tab-active="activeTab === 'postponed'">
          <ReceiptsList
            :receipts="searchingValue.length > 0 ? receipts.searching.docs : receipts.postponed"
            @receipt-click="openReceipt" />
        </f7-tab>
        <f7-tab id="tab-completed" :tab-active="activeTab === 'completed'">
          <ReceiptsList
            :receipts="searchingValue.length > 0 ? receipts.searching.docs : receipts.completed"
            @receipt-click="openReceipt" />
        </f7-tab>
      </f7-tabs>

      <template #fixed>
        <f7-fab
          id="receipts-float-btn"
          position="right-bottom"
          @tap="createReceipt">
          <i class="mpi-md-add"></i>
        </f7-fab>
      </template>
    </Inner>
  </Page>
</template>

<script>
  import { ref, watch } from "vue";
  import { debounce } from "lodash"
  import { isCafe, orderOrReceipt } from "../../js/helpers"

  import ReceiptsListItem from "../../components/ReceiptsPage/ReceiptsListItem";
  import ReceiptsList from "../../components/ReceiptsPage/ReceiptsList";

  export default {
    components: {
      ReceiptsListItem,
      ReceiptsList,
    },
    setup() {
      const header = ref(null)

      const receipts = ref(gui.receipts);
      const activeTab = ref(receipts.value.activeTab);
      const searchingValue = ref("");

      watch(gui.receipts, value => {
        receipts.value = value
        activeTab.value = receipts.value.activeTab
      })

      const tabs = [
        { title: isCafe() ? "receipts.postponedCafe" : "receipts.postponed", key: "postponed" },
        { title: "receipts.completed", key: "completed" },
      ];

      const search = () => {
        if (searchingValue.value !== "") {
          gui.dispatch("docs.searchDocs", "receipts", searchingValue.value);
        }
      };

      return {
        tabs,
        header,
        activeTab,
        searchingValue,
        receipts,
        title: orderOrReceipt("receipts.receipts", "receipts.orders", true),
        openFilters: async () => {
          await gui.dispatch("docs.openFilters", "receipts");
        },
        search: debounce(search, 300),
        openSearchBar: () => {
          header.value.enableSearching()
        },
        enableSearching: () => {
          receipts.value.searching.docs = []
        },
        disableSearching: () => {
          searchingValue.value = ""
          receipts.value.searching.docs = []
        },
        changeTab: (key) => {
          header.value.disableSearching()

          activeTab.value = key;
          gui.dispatch("docs.changeTab", "receipts", key);
        },
        openReceipt: (receipt) => {
          gui.dispatch("receipt.open", receipt);
        },
        createReceipt() {
          gui.dispatch("receipt.create", [], {
            tableData: receipts.value.tableData ? {
              hall: receipts.value.tableData.hall,
              tableName: receipts.value.tableData.table.tableName,
              _id:  receipts.value.tableData.table._id
            } : null
          });
        },
        goBack: () => {
          gui.navigateBack();
          gui.dispatch("docs.resetOffsetByPage", "receipts");
        },
        onPageBeforeIn() {
          if (!receipts.value.updated) gui.dispatch("docs.update", { page: 'receipts', activeTab: activeTab.value, needUpdate: true});
        }
      };
    },
  };
</script>

<style lang="sass">
  @import "../../css/pages/receipts/list"
</style>
