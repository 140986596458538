<template>
  <div class="big-icon-container" :class="{'loading': loading}">
    <div class="big-icon-loader"></div>
    <div class="big-icon-inner">
      <div class="big-icon">
        <img :src="require(`@/static/imgs/big-icons/${icon}.svg`)" :alt="icon">
      </div>
      <div :class="`animate__bounceIn big-icon-state-icon success ${state === 'success' ? 'fade-in' : 'fade-out'}`"></div>
      <div :class="`animate__bounceIn big-icon-state-icon error ${state === 'error' ? 'fade-in' : 'fade-out'}`"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      loading: { type: Boolean, default: false },
      state: { type: String, default: "active" },
      icon: { type: String, default: "" }
    }
  }
</script>