import rpc from "../libs/rpc"
import gui from "../gui"
import user from "../user"
import dicts from "../dicts"
import state, { initState } from "../state"

import { isOnline } from "../online"
import { PRINTERS, PRINTERS_NAMES, SERVER_DOWN_CODE } from "../settings"
import { open as openWelcome } from "./welcome"
import { syncData } from "../user"
import { navigate } from "./router"
import { scanStart } from "../bluetooth"
import { unpairCleanProcess } from "../sync/cleaner"

let _resolve = null

const DEFAULT_CLICK_VOLUME = 15 // 0.15

export async function openSettings(options = {}) {
  if(!options.isInitialSetup) {
    if(!await user.verifyPermissionRequest("all.cashier.editingSettings", {
      description: gui.translate("settings.approveEditDescription"),
      title: gui.translate("settings.approveEditTitle")
    })) {
      return false
    }
  }

  gui.preloader(true)

  // Scanning bluetooth devices
  scanStart().then()

  options.closable = options.closable === false ? false : true
  options.device = state.device

  options.printCategories = await getPrintCategories()
  options.printersTypes = await dicts.printers_slots.get()

  if(options.printersTypes.length === 0) {
    options.printersTypes = Object.keys(PRINTERS_NAMES).map(key => ({ title: PRINTERS_NAMES[key], key }))
  }

  const settings = await getSettings()

  // Default printer
  if(options.isInitialSetup && settings.printers.length === 0) {
    settings.printers = [{ type: PRINTERS.MASTER, address: "", _id: Date.now(), templates: {} }]
  }

  state.settings = settings
  gui.settings = { settings: state.settings, options }

  await navigate("/settings")

  gui.preloader(false)

  if(options.promises) {
    return new Promise(resolve => {
      _resolve = resolve
    })
  }

  return null
}

export async function synchronization() {
  await syncData({ sync: true, withoutTruncateData: true })

  gui.preloader(true)
  state.cache = {}
  await initState()
  gui.preloader(false)
}

export async function saveSettings(settings = {}) {
  gui.preloader(true)

  const { errors } = await checkSettings(settings)
  if(errors.length === 0) {
    // Save device settings
    await dicts.vars.put({ ...settings, name: "deviceSettings" })

    // Set settings to state
    gui.settingsData = state.settings = settings

    gui.preloader(false)
    await gui.message(gui.translate("settings.saveSuccess"))

    if(typeof _resolve === "function") {
      await _resolve()
    }
  } else {
    gui.preloader(false)
    await gui.error(errors.join("<br>"), gui.translate("settings.saveError"))
  }

  gui.preloader(false)
}

export async function pairDevice() {
  const device = await getDevicePair()

  if(!device) {
    while(true) {
      const token = await gui.pairing()

      // for test
      if(token === "SKIP_PAIRING_ACCESS_TOKEN" && process.env.VUE_APP_IS_TEST) {
        state.device = { _id: 125, storeId:1, companyId:1, paired:true,pairedOn: Date.now(), deviceName: "Test device", deviceType:0, deviceSerialNumber:"SR13T1E3S4TD3EV", description:"Test device description", enabled:true, controlToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VJZCI6IjE4IiwiaWF0IjoxNjIxMjUzNzg1LCJleHAiOjE2MjEyNTM3ODV9.MEZLaA00gtZe-XRafcy5Nm_ryZMdVnSQ_hLmev9sNkE"}
        gui.pairingClose()
        return true
      }

      if(!isOnline) {
        gui.toast(gui.translate("checkNetworkConnection"))
        continue
      }

      gui.preloader(true)
      const response = await rpc("pairing.pairDevice", token)
      gui.preloader(false)

      if(response.error) {
        gui.toast(gui.translate(response.error, response.languageOptions))
      } else {
        const startAutoIncrement = response.lastId > 0 ? response.lastId : 0

        // save device
        await dicts.vars.del({ name: "device" })
        await dicts.vars.put({ name: "device", device: response.device })
        await dicts.docs.restartAutoIncrement(startAutoIncrement)
        await dicts.bills_templates.put(response.billsTemplates)
        await dicts.printers_slots.put(response.printersSlots)

        state.device = response.device

        gui.pairingClose()
        return true
      }
    }
  } else if(!device.enabled) {
    return false
  }

  state.device = device
  return true
}

export async function unpairDevice() {
  if(state.device) {
    if(isOnline) {
      if(await gui.questionUI({
        message: gui.translate("settings.wantUnpairDevice"),
        cancelButton: gui.translate("no"),
        confirmButton: gui.translate("yes")
      })) {
        gui.preloader(true)

        if(!await unpairCleanProcess()) {
          gui.preloader(false)
          gui.message(gui.translate("settings.existsActiveDocuments"))
          return false
        }

        const response = await rpc("pairing.unpairDevice", state.device.controlToken)
        gui.preloader(false)

        if(response.error) {
          gui.toast(gui.translate(response.error, response.languageOptions))
        } else {
          // delete device
          await dicts.vars.del({ name: "device" })

          state.device = null

          await user.clear()
          await openWelcome()
        }
      }
    } else {
      gui.toast(gui.translate("checkNetworkConnection"))
    }
  }
}

export async function getDevicePair() {
  const variable = await dicts.vars.getOne({ name: "device" })

  if(variable?.device) {
    if(isOnline) {
      const response = await rpc("pairing.getDevice", variable.device.controlToken)
      if(response.error === null) {
        const remoteDevice = response.device
        // update device
        await dicts.vars.put({ _id: variable._id, name: "device", device: remoteDevice })
        return remoteDevice
      } else if(response.error?.code === SERVER_DOWN_CODE) {
        return variable.device
      }
    } else {
      return variable.device
    }
  }

  return null
}

export async function isSettingExists() {
  const settings = await dicts.vars.getOne({ name: "deviceSettings" })

  if(settings) {
    const { errors } = await checkSettings(settings)
    if(errors.length > 0) {
      return false
    }
  } else {
    return false
  }

  return true
}

export async function initialDeviceSettings() {
  gui.settingsData = state.settings = await getSettings()
}

export async function getSettings() {
  const settings = await dicts.vars.getOne({ name: "deviceSettings" })
  if(settings) {
    settings.clickVolume = typeof settings.clickVolume === "undefined" ? DEFAULT_CLICK_VOLUME : settings.clickVolume
    settings.disableAnimation = settings.disableAnimation || false
    settings.offlinePayments = settings.offlinePayments || false

    return settings
  }

  return {
    clickVolume: DEFAULT_CLICK_VOLUME,
    disableAnimation: false,
    offlinePayments: false,
    printers: []
  }
}

async function checkSettings(settings) {
  const response = { errors: [] }

  if(Array.isArray(settings.printers)) {
    const filledPrinters = {}
    for(let printer of settings.printers) {
      if(!isValidPrinterAddress(printer.address || "")) {
        if(printer.address.trim() === "") {
          response.errors.push(gui.translate("settings.printerAddressEmpty"))
        } else {
          response.errors.push(gui.translate("settings.incorrectAddress", { address: printer.address }))
        }
      }

      if(filledPrinters[printer.type] === true) {
        response.errors.push(gui.translate("settings.multiplePrinters", { type: printer.type }))
      } else {
        filledPrinters[printer.type] = true
      }
    }

    if(!filledPrinters[PRINTERS.MASTER]) {
      response.errors.push(gui.translate("settings.addMasterPrinter"))
    }
  } else {
    response.errors.push(gui.translate("settings.printersUnavailable"))
  }

  response.errors = response.errors.slice(0, 1)

  return response
}

async function getPrintCategories() {
  const result = {}

  for(let template of await dicts.bills_templates.get({})) {
    const item = { key: template.name, default: template.isDefault }

    if(result[template.category]) {
      result[template.category].list.push(item)
    } else {
      result[template.category] = {
        list: [item]
      }
    }
  }

  return Object.keys(result).map(key => ({
    key,
    ...result[key]
  }))
}

function isValidPrinterAddress(address) {
  if(/^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/.test(address)) {
    return true
  }

  if(/^(([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2}))$/.test(address)) {
    return true
  }

  return false
}
