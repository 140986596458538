import happyHours from "./happyHours"
import buy1Get2nd from "./buy1Get2nd"
import buy2Get3nd from "./buy2Get3nd"
import announcement from "./announcement"

import { round2 } from "../../libs/util"
import { calculateGood, isLoyaltyMemberAvailable, createGoodSplitCopy } from "../../actions/receipt"

export const PROMOTIONS_HANDLERS = { happyHours, buy1Get2nd, buy2Get3nd, announcement }
export const MAX_GOODS_FOR_COMBINATION = 6
export const PIECES = {
  ONE_PLUS_ONE: 2,
  SINGLE: 1
}

export async function calculatePromo(promotion, receipt) {
  const handler = PROMOTIONS_HANDLERS[promotion.type]
  if(handler) {
    await handler(promotion, receipt)
  }
}

export function isPromoAvailable(promotion, receipt) {
  const currentTime = Date.now()

  // Check is options not defined
  if(!promotion.options) {
    return false
  }

  // Check is promotion disabled
  if(!promotion.enabled) {
    return false
  }

  // Check is promotion not started
  if(promotion.startDate > currentTime) {
    return false
  }

  // Check is promotion not finished
  if(promotion.endDate < currentTime) {
    return false
  }

  // Check promotion period
  if(!isActivePeriod(promotion)) {
    return false
  }

  // Check promotion for loyalty only and LoyaltyMember not defined
  if(promotion.isLoyaltyOnly && !isLoyaltyMemberAvailable(receipt)) {
    return false
  }

  return true
}

export async function initPromoCalculating(receipt) {
  // Calculate subTotal of receipt
  let subTotal = 0

  for await (let good of receipt.goods) {
    await calculateGood(receipt, good)
    subTotal = round2(subTotal + good.sum.subTotal)

    // Clear promotion data from previous calculating
    good.priceAction = null
    good.pricePercent = null
    good.promoTaxExempt = false
    good.promoApplied = false
    good.promoDetails = []
  }

  receipt.announcements = []
  receipt.promoSubTotal = subTotal
}

export async function superForPromo(promotion, receipt) {
  promotion.options.maxQty = Number(promotion.options.maxQty || 0)
  promotion.options.minSubtotal = Number(promotion.options.minSubtotal || 0)

  if(promotion.options.maxQty <= 0.001) {
    return false
  }

  if(receipt.promoSubTotal < promotion.options.minSubtotal) {
    return false
  }

  return true
}

export async function splitAndHandleGoods(receipt, goodsIds, handleForQty = 0, handler = null) {
  goodsIds = Array.isArray(goodsIds) ? goodsIds.map(id => String(id)) : []

  if(goodsIds.length > 0 && handleForQty > 0) {
    let foundGoods = receipt.goods.filter(good => goodsIds.includes(String(good._id)) && good.count > 0)

    // Not logic, just a helper ( found perfect combination of goods )
    const combination = getCombinationByCount(foundGoods, handleForQty)

    // Combination found
    if(combination) {
      foundGoods = combination
    } else {
      foundGoods.sort((a, b) => a.count < b.count ? -1 : 1)
    }

    let lastQty = handleForQty

    for(let good of foundGoods) {
      if(good.count > lastQty) {
        await createGoodSplitCopy(receipt, good, lastQty)
      }

      lastQty -= good.count
      await handler(good)

      if(lastQty <= 0) {
        return 0
      }
    }

    return lastQty
  }

  return 0
}

export async function applyPromoPercent(promotion, good, percent) {
  good.promoApplied = true
  good.pricePercent = isNaN(+good.pricePercent) || good.pricePercent <= percent ? percent : good.pricePercent

  good.promoDetails.push({
    _id: promotion._id,
    name: promotion.name,
    type: "changePercent",
    value: percent
  })
}

export async function applyPromoPrice(promotion, good, price) {
  good.promoApplied = true
  good.priceAction = isNaN(+good.priceAction) || good.priceAction <= price ? price : good.priceAction

  good.promoDetails.push({
    _id: promotion._id,
    name: promotion.name,
    type: "changePercent",
    value: price
  })
}

export function isActivePeriod(promotion) {
  if(promotion.period && promotion.period.type) {
    const currentDate = new Date()

    const type = promotion.period.type
    const values = Array.isArray(promotion.period.values) ? promotion.period.values.map(value => String(value)) : []

    if(type === "any") {
      return true
    } else if(type === "daily" && values.includes(String(currentDate.getDate()))) {
      return true
    } else if(type === "weekly" && values.includes(String(currentDate.getDay()))) {
      return true
    } else if(type === "monthly" && values.includes(String(currentDate.getMonth() + 1))) {
      return true
    }
  }

  return false
}

export function getCombinationByCount(goods, targetCount) {
  targetCount = Number(targetCount)

  const groups = {}
  let goodsTotalCount = 0
  let filteredGoods = []

  for(let good of goods) {
    const count = Number(good.count)
    const countId = String(good.count)

    if(count < targetCount) {
      groups[countId] = (groups[countId] || []).concat(good)
    } else if(count === targetCount) {
      return [good]
    }

    goodsTotalCount += good.count
  }

  if(targetCount >= goodsTotalCount) {
    return goods
  }

  for(let [key, group] of Object.entries(groups)) {
    const groupCount = Number(key)
    if(targetCount % groupCount === 0 && groupCount * group.length >= targetCount) {
      const groupCountForTarget = Math.trunc(targetCount / groupCount)
      return group.slice(0, groupCountForTarget)
    }

    filteredGoods.push(...group)
  }

  filteredGoods.sort((a, b) => a.count > b.count ? -1 : 0)
  filteredGoods = filteredGoods.slice(0, MAX_GOODS_FOR_COMBINATION)

  const ps = [[]]
  for (let i = 0; i < filteredGoods.length; i++) {
    for (let j = 0, len = ps.length; j < len; j++) {
      const item = ps[j].concat(filteredGoods[i])
      const countItem = item.reduce((acc, cur) => acc + cur.count, 0)

      if(countItem === targetCount) {
        return item
      }

      ps.push(item)
    }
  }

  return null
}

export function countGoodsById(goods, id) {
  return goods.filter(good => String(good._id) === String(id)).reduce((acc, cur) => acc + Number(cur.count), 0)
}

export function getMainGoodsInfo(onePiece, goods, mainGoodsIds, actionGoodsIds) {
  const ids = mainGoodsIds.map(id => String(id))
  const groups = {}

  let count = 0
  let notCrossedCount = 0

  for(let id of ids) {
    const goodCount = countGoodsById(goods, id)
    const goodPieces = goodCount / onePiece

    groups[id] = {
      count: goodCount,
      using: 0,
      pieces: Math.floor(goodPieces)
    }

    count += goodCount

    if(!actionGoodsIds.includes(id)) {
      notCrossedCount += goodCount
    }
  }


  return { pieces: Math.floor(count / onePiece), groups, notCrossedCount, notCrossedQty: Math.floor(notCrossedCount / onePiece) }
}

export async function splitAndHandleGoodsPieces(onePiece = 2, receipt, mainGoodsIds, actionGoodsIds, maxQty, handler = null) {
  mainGoodsIds = mainGoodsIds.map(id => String(id))
  actionGoodsIds = actionGoodsIds.map(id => String(id))

  const { pieces: countPieces, groups, notCrossedQty, notCrossedCount } = getMainGoodsInfo(onePiece, receipt.goods, mainGoodsIds, actionGoodsIds)
  const actionGoodsQueue = [{ isCrossed: false, id: null, ids: [] }]

  let usingQty = 0
  let startQty = Math.min(maxQty, countPieces)
  let lastQty = startQty
  let lastNotCrossedQty = notCrossedQty
  let lastNotCrossedCount = notCrossedCount

  for(let id of actionGoodsIds) {
    if(mainGoodsIds.includes(id)) {
      actionGoodsQueue.push({ isCrossed: true, id, ids: [id] })
    } else {
      actionGoodsQueue[0].ids.push(id)
    }
  }

  let crossedPrevRemainder = 0
  for(let queueItem of actionGoodsQueue) {
    const isCrossed = queueItem.isCrossed
    const ids = queueItem.ids
    let applyQty = lastQty
    let isUsingCrossed = false

    if(isCrossed) {
      const group = groups[queueItem.id]
      if(!group) {
        continue
      }

      isUsingCrossed = true

      let result = Math.floor(((group.count + crossedPrevRemainder) - (group.using * onePiece)) / (onePiece + 1))
      result = result > 0 ? result : 0

      if(lastNotCrossedQty > 0) {
        isUsingCrossed = false
        result = lastNotCrossedQty

        const remainder = Math.floor((group.count - result) / (onePiece + 1))
        if(remainder > 0) {
          result += remainder
        }
      }

      if(result <= 0 && group.count === onePiece && lastNotCrossedCount > 0) {
        result = group.count
      }

      result = Math.min(result, lastQty)
      crossedPrevRemainder = group.count - (result * (onePiece + 1) + (group.using * onePiece))// + crossedNextRemainder
      crossedPrevRemainder = crossedPrevRemainder > 0 ? crossedPrevRemainder : 0

      applyQty = result
    }

    const remainderQty = await splitAndHandleGoods(receipt, ids, applyQty, async good => {
      await handler(good)
    })

    const currentUsingQty = (applyQty - remainderQty)
    lastQty = lastQty - currentUsingQty

    lastNotCrossedQty -= isUsingCrossed ? 0 : currentUsingQty
    lastNotCrossedCount -= isUsingCrossed ? 0 : (currentUsingQty * onePiece)

    usingQty += currentUsingQty

    lastNotCrossedQty = lastNotCrossedQty > 0 ? lastNotCrossedQty : 0
    lastNotCrossedCount = lastNotCrossedCount > 0 ? lastNotCrossedCount : 0

    if(lastNotCrossedQty <= 0) {
      const usingCrossedQty = usingQty - notCrossedQty
      let lastCrossed = usingCrossedQty

      for(let group of Object.values(groups)) {
        group.using = Math.min(group.pieces, lastCrossed)
        lastCrossed -= group.using

        if(lastCrossed <= 0) {
          break
        }
      }
    }

    if(lastQty <= 0) {
      return 0
    }
  }

  return lastQty
}
