import Handler from '../handler'
import state from "../../state"

import { isNewReceipt, isOpenedProduct, isSelection } from "../../actions/receipt"
import { calculatePromo, initPromoCalculating, isPromoAvailable } from "./helpers"

class PromotionHandler extends Handler {
  async calc(receipt, options = {}) {
    if(!isNewReceipt(receipt)) {
      return false
    }

    await initPromoCalculating(receipt)
    const canCalculate = ["checkout", "bill"].includes(options.from)

    if(isOpenedProduct(receipt) || await isSelection(receipt) || !canCalculate) {
      return false
    }

    if(Array.isArray(state.promotions) && state.promotions.length > 0) {
      for(let promotion of state.promotions) {
        if(isPromoAvailable(promotion, receipt)) {
          await calculatePromo(promotion, receipt)
        }
      }
    }
  }

  createReceipt() {
    return true
  }
}


export default new PromotionHandler()
