<template>
  <div ref="page" class="page page-custom" :class="{[schema]: true}">
    <slot />
  </div>
</template>

<script>
  import { onBeforeRouteLeave } from "vue-router"

  export default {
    emits: ["page:beforein", "page:beforeout", "page:scroll", "page:scrollend", "page:scrollup", "page:scrolldown"],
    props: {
      schema: { type:String, default: "white" },
      hideFabOnScroll: { type: Boolean, default: false },
      hideHeaderOnScroll: { type: Boolean, default: false },
      hideFooterOnScroll: { type: Boolean, default: false },
      pageEndOffset: { type: Number, default: 0 },
    },
    setup(_, { emit }) {
      onBeforeRouteLeave(() => {
        emit("page:beforeout")
      })
    },
    data() {
      return {
        scrollEnd: false,
        offsetHeight: 0,
        headerHeight: 0,
        footerHeight: 0,
        scrollDirection: null,
        prevY: 0
      }
    },
    methods: {
      getMaxScroll() {
        const pageContent = this.$refs.page.querySelector(".page-content")
        if(pageContent) {
          return pageContent.scrollHeight - pageContent.offsetHeight
        }

        return 0
      },
      resetHeight() {
        if(this.$refs.page) {
          const pageContent = this.$refs.page.querySelector(".page-content")
          if(pageContent) {
            this.offsetHeight = pageContent.offsetHeight
          }

          if(this.hideHeaderOnScroll) {
            const pageHeader = this.$refs.page.querySelector(".page-header-height")
            if(pageHeader) {
              const previousHeight = this.headerHeight
              this.headerHeight = pageHeader.offsetHeight
              if(Math.abs(previousHeight - this.headerHeight) >= 1) {
                pageHeader.parentNode.style.height = this.headerHeight + "px"
                this.hideHeader(pageContent.scrollTop, pageContent.scrollHeight)
              }
            }
          }

          if(this.hideFooterOnScroll) {
            const pageFooter = this.$refs.page.querySelector(".page-footer-height")
            if(pageFooter) {
              this.footerHeight = pageFooter.offsetHeight
              pageFooter.style.height = this.footerHeight + "px"
            }
          }
        }
      },
      scrollTo(y = 0) {
        const pageContent = this.$refs.page.querySelector(".page-content")
        if(pageContent) {
          pageContent.scrollTo(0, y)
        }
      },
      scroll(event) {
        this.$emit("page:scroll", event)

        const scrollTop = event.target.scrollTop
        const scrollHeight = event.target.scrollHeight
        const height = event.target.offsetHeight
        const offset = this.pageEndOffset

        if((scrollTop + height + offset) >= scrollHeight) {
          this.$emit("page:scrollend", event)
        }

        if((scrollTop + height) >= scrollHeight) {
          this.scrollEnd = true
        } else {
          this.scrollEnd = false
        }

        if(this.prevY > scrollTop) {
          this.scrollDirection = "up"
          this.$emit("page:scrollup", event)
        } else {
          this.scrollDirection = "down"
          this.$emit("page:scrolldown", event)
        }

        this.prevY = scrollTop

        if(this.hideHeaderOnScroll) {
          this.hideHeader(scrollTop, scrollHeight)
        }

        if(this.hideFooterOnScroll) {
          this.hideFooter(scrollTop)
        }

        if(this.hideFabOnScroll) {
          this.hideFab(scrollTop)
        }
      },
      hideFab(scrollTop) {
        const pageFab = this.$refs.page.querySelector(".fab")
        if(pageFab) {
          if(scrollTop > 0) {
            if(this.scrollDirection === "up") {
              pageFab.classList.remove("hide-fab")
            } else {
              pageFab.classList.add("hide-fab")
            }
          } else {
            pageFab.classList.remove("hide-fab")
          }
        }
      },
      hideFooter(scrollTop) {
        const pageFooter = this.$refs.page.querySelector(".page-footer")
        if(pageFooter) {
          const newHeight = this.footerHeight - scrollTop
          pageFooter.style.height = (newHeight > 0 ? newHeight : 0) + "px"
          pageFooter.style.opacity = newHeight <= 0.01 ? 0 : 1
        }
      },
      hideHeader(scrollTop, scrollHeight) {
        if((scrollHeight - this.offsetHeight - 10) > this.headerHeight) {
          const pageHeader = this.$refs.page.querySelector(".page-header")
          if(pageHeader) {
            const newHeight = this.headerHeight - scrollTop

            if(this.scrollEnd) {
              pageHeader.style.height = "0px"
              pageHeader.style.transform = `translateY(-${this.headerHeight + 40}px)`
            } else {
              pageHeader.style.height = (newHeight > 0 ? newHeight : 0) + "px"
              pageHeader.style.transform = `translateY(-${scrollTop}px)`
            }
          }
        }
      }
    },
    mounted() {
      this.$emit("page:beforein")

      const pageContent = this.$refs.page.querySelector(".page-content")
      if(pageContent) {
        this.offsetHeight = pageContent.offsetHeight
        pageContent.addEventListener("scroll", this.scroll, false)

        setTimeout(() => {
          this.resetHeight()

          const header = this.$refs.page.querySelector(".page-header-height")
          if(header) {
            // Observe height changes
            const resizeObserver = new ResizeObserver(() => this.resetHeight())
            resizeObserver.observe(header)
          }
        }, 30)
      }
    },
    beforeUnmount() {
      const pageContent = this.$refs.page.querySelector(".page-content")
      if(pageContent) {
        pageContent.removeEventListener("scroll", this.scroll, false)
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .page.page-custom {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $main-bg-color;

    &.blue {
      background-color: $second-bg-color;
    }

    &.transparent {
      .page-header .page-header-height {
        border-radius: 0;
        box-shadow: none;
      }
    }

    .page-content {
      padding-top: 0 !important;
      overflow-x: hidden;
      overflow-y: auto;
      touch-action: pan-y !important;
      height: 100%;
    }

    .page-inner {
      flex: 1;
      height: auto;
      overflow: hidden;
      position: relative;
      .fab {
        --f7-fab-size: 64px;
        transition: .3s;
        &.hide-fab {
          transform: translateX(90px);
          visibility: hidden;
        }
        .icon {
          font-size: 39px;
        }
        .fab-buttons {
          --f7-fab-button-size: 50px;
          .icon {
            font-size: 24px;
          }
          .fab-label {
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 16px;
            min-width: 150px;
          }
        }
      }
    }

    .header-messages {
      display: flex;
      flex-direction: column-reverse;
    }

    .header-message-container {
      position: relative;
      overflow: hidden;
      margin-top: 0;

      &.visible {
        margin-top: -$border-radius;
      }

      .header-message {
        color: #fff;
        border-radius: 0 0 $border-radius $border-radius;
        z-index: 1;
        overflow: hidden;

        b {
          font-weight: bold;
          color: #fff;
        }
      }

      &.flex-end .header-message-inner {
        align-items: flex-end;
      }

      .header-message-inner {
        padding: 0 14px;
        padding-top: 6px + $border-radius;
        padding-bottom: 6px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;

        .price {
          font-weight: bold;
        }

        .header-message-right {
          padding-left: 10px;
          .header-message-hide {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 12px;
            width: 20px;
            min-width: 20px;
            max-width: 20px;
            height: 20px;
            padding: 0;
            border-radius: 50%;
          }
        }

        .header-message-left {
          flex: 1
        }

        &.primary {
          background-color: $primary-color;
        }

        &.error {
          background-color: $error-color;
        }

        &.success {
          background-color: $success-color;
        }
      }
    }

    .page-footer {
      .page-footer-height {
        position: relative;
        z-index: 2;
        background-color: $main-bg-color;
        border-radius: $border-radius $border-radius 0 0;
        box-shadow: $footer-shadow;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 0;
        box-sizing: border-box;
      }

      .footer-buttons {
        &.some-buttons {
          padding-top: $minimal-padding;
        }
        .button {
          height: 45px;
          border-radius: 24px;
          font-size: 18px;
          font-weight: bold;
          text-transform: none;
          min-width: $basic-width;
          max-width: $basic-width;
          margin-bottom: 8px;
          &.fill {
            background-color: $primary-color;
            color: #fff;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .page-header {
      position: relative;
      z-index: 10;

      .page-header-height {
        position: relative;
        z-index: 1;
        border-radius: 0 0 $border-radius $border-radius;
        overflow: hidden;
        box-shadow: $header-shadow;
        background-color: $main-bg-color;
      }

      .page-header-inner {
        position: relative;
        width: 100%;
        min-height: $white-navbar-height;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $padding-arrow;
        box-sizing: border-box;

        .page-header-searching-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: $padding-arrow;
          box-sizing: border-box;
          visibility: hidden;
          transition: .3s;

          .page-header-searching-input-clear {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            color: $text-color;
            visibility: hidden;
            opacity: 0;
            transition: .3s;
          }

          &.with-clear {
            .page-header-searching-input input {
              padding-right: 40px;
            }

            .page-header-searching-input-clear {
              visibility: visible;
              opacity: 1;
            }
          }

          &.fade-in {
            visibility: visible;
            background-color: $main-bg-color;
            .page-header-searching-cancel, .page-header-searching-input {
              opacity: 1;
              transform: translateX(0px) translateY(0px);
              .page-header-searching-input-icon {
                transform: translateX(0px) !important;
              }
              input {
                padding-left: 40px !important;
              }
            }
          }

          .page-header-searching-cancel {
            opacity: 0;
            transform: translateX(80px);
            padding-right: 10px;
            transition: .3s;
            .link {
              border-radius: none;
              font-size: 18px;
              color: $primary-color;
              padding: 0 10px;
            }
          }

          .page-header-searching-input {
            flex: 1;
            box-sizing: border-box;
            padding-right: 2px;
            position: relative;
            overflow: hidden;
            opacity: 0;
            transform: translateX(-80px);
            transition: .3s;
            &.loading {
              .page-header-searching-input-loading {
                visibility: visible;
                opacity: 1;
                transform: scale(1);
              }
              input {
                padding-right: 40px;
              }
            }
            .page-header-searching-input-icon {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              font-size: 18px;
              transform: translateX(-40px);
              transition: .3s;
              transition-delay: .2s;
            }
            .page-header-searching-input-loading {
              position: absolute;
              top: 0;
              right: 2px;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              visibility: hidden;
              opacity: 0;
              transform: scale(.4);
              transition: .3s;

              .preloader {
                width: 20px;
                height: 20px;
              }
            }
            input {
              height: 40px;
              transition: .3s;
              transition-delay: .2s;
            }
          }
        }

        .icon-only {
          font-size: 18px;
          line-height: 18px;
        }

        .title {
          flex: 1;
          font-weight: bold;
          font-size: 24px;
          line-height: 27px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &.center {
            text-align: center;
          }

          .subtitle {
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
          }
        }

        .left {
          display: flex;
          align-items: center;
          padding-right: $padding-arrow;
        }

        .link[disabled=true] {
          opacity: .6;
          .ripple-wave {
            display: none !important;
          }
        }

        .right {
          display: flex;
          align-items: center;
          padding-left: $padding-arrow;
          .link {
            margin-right: 25px;
            &:last-child {
              margin-right: 0;
            }
            &.text {
              font-size: 21px;
            }
          }
        }
      }

      .page-subnavbar {
        display: flex;
        padding: 0 15px;
        padding-bottom: 12px;
        box-sizing: border-box;
        .subnavbar-button-item {
          padding: 0 5px;
          flex: 1;
          .button {
            height: 48px;
            border-radius: 24px;
            font-size: 18px;
            font-weight: bold;
            text-transform: none;
            &.active {
              background-color: $primary-color;
              color: #fff;
            }
          }
        }
      }
    }
  }
</style>
