<template>
  <div class="actions-group">
    <slot />
  </div>
</template>

<script>
  export default {
    methods: {
      closeActions() {
        this.$parent?.hide?.()
      }
    }
  }
</script>