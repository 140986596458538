<template>
  <Page schema="transparent" id="checkout-page">
    <Header>
      <div class="left">
        <f7-link id="checkout-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t(receipt.type === "receipt" ? "checkout.cashPayment" : "checkout.cashReturn" )}}</div>
      <div class="right">
        <f7-link @tap="openInfo" v-if="receipt.type === 'receipt'" class="icon-only">
          <i class="icon mpi-info color-black"></i>
        </f7-link>
        <f7-link :disabled="!receipt.availablePayments.includes('card')" @tap="executeCardMethod" class="icon-only">
          <i class="icon mpi-card color-black"></i>
        </f7-link>
      </div>
    </Header>

    <Inner>
      <CashMethod ref="cash" v-if="receipt.type === 'receipt'" :receipt="receipt" />
      <CashRefund v-else-if="receipt.type === 'refund'" :receipt="receipt" />
    </Inner>
  </Page>
</template>
<!--<template>-->
<!--  <f7-page class="white-header-page checkout-page title-center absolute gray">-->
<!--    <f7-navbar>-->
<!--      <f7-nav-left>-->
<!--        <f7-link @touchstart="$gui.navigateBack()" class="ripple-color-black icon-only">-->
<!--          <i class="icon mpi-back color-black"></i>-->
<!--        </f7-link>-->
<!--      </f7-nav-left>-->
<!--      <f7-nav-title>{{ $t(receipt.type === "receipt" ? "checkout.payment" : "checkout.return" )}}</f7-nav-title>-->
<!--      <f7-subnavbar>-->
<!--        <div class="subnavbar-button-item">-->
<!--          <f7-button-->
<!--            round-->
<!--            id="checkout-method-cash-button"-->
<!--            :tab-link-active="true">{{$t('checkout.cash')}}</f7-button>-->
<!--        </div>-->
<!--        <div class="subnavbar-button-item">-->
<!--          <f7-button-->
<!--            :disabled="!receipt.availablePayments.includes('card')"-->
<!--            @click="executeCardMethod"-->
<!--            round-->
<!--            id="checkout-method-card-button"-->
<!--            :tab-link-active="false">{{$t('checkout.card')}}</f7-button>-->
<!--        </div>-->
<!--      </f7-subnavbar>-->
<!--    </f7-navbar>-->
<!--    <f7-tabs>-->
<!--      <f7-tab id="tab-cash" :tab-active="true">-->
<!--        <CashMethod v-if="receipt.type === 'receipt'" :receipt="receipt" />-->
<!--        <CashRefund v-else-if="receipt.type === 'refund'" :receipt="receipt" />-->
<!--      </f7-tab>-->
<!--    </f7-tabs>-->
<!--  </f7-page>-->
<!--</template>-->

<script>
  import { ref, watch, computed } from "vue"

  import CashMethod from "../../components/Checkout/CashMethod"
  import CashRefund from "../../components/Checkout/CashRefund"

  export default {
    components: { CashMethod, CashRefund },
    setup() {
      const cash = ref(null)
      const receipt = ref(gui.receiptData)

      watch(gui.receiptData, value => {
        receipt.value = value
      })

      return {
        cash,
        receipt,
        openInfo: () => {
          cash.value.openInfo()
        },
        executeCardMethod: () => {
          if(receipt.value.type === "receipt") {
            gui.dispatch("receipt.executeCardPayment")
          } else if(receipt.value.type === "refund") {
            gui.dispatch("refund.refundCard")
          }
        },
      }
    }
  }
</script>

<style lang="sass">
  @import "@/css/pages/checkout.sass"
</style>
