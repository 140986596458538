import gui from "../gui"
import state from "../state"
import user from "../user"

import * as auth from "../auth"

export async function login(options = {}) {
  state.loginData = gui.loginData = { ...getDefaultLoginData(), ...options, shown: true }

  while(true) {
    const { action } = await gui.login({ ...state.loginData, ...options })

    if(action === "back") {
      state.loginData = getDefaultLoginData()
    } else if(action === "cancel") {
      return { cancel: true }
    } else if(action === "identifySeller") {
      gui.isIdentifySellerProcess = true
      await identifySeller()
      gui.isIdentifySellerProcess = false
    } else if(action === "identifySellerBarcode") {
      gui.isIdentifySellerProcess = true
      await identifySellerBarcode()
      gui.isIdentifySellerProcess = false
    } else if(action === "loginSeller") {
      const response = await loginSeller()
      if(response) {
        state.loginData.state = "success"
        gui.loginData = state.loginData

        return await user.createPreparedUser(response)
      }
    } else if(action === "resetSellerPIN") {
      const response = await resetSellerPIN()

      if(response) {
        state.loginData.state = "success"
        gui.loginData = state.loginData

        return await user.createPreparedUser(response)
      }
    }

    gui.loginData = state.loginData
  }
}

export function getDefaultLoginData() {
  return {
    state: "identification",
    errors: { pin: false, userName: false },
    shownScanner: false,
    barcode: "",
    userName: "",
    PIN: "",
  }
}

export async function identifySeller() {
  const response = await auth.identifySeller({ login: gui.loginData.userName })
  return await identifySellerResponse(response, "userName")
}

export async function identifySellerBarcode() {
  const response = await auth.identifySellerBarcode({ barcode: gui.loginData.barcode })
  return await identifySellerResponse(response, "barcode")
}

export async function identifySellerResponse(response, type) {
  state.loginData.shownScanner = false

  if(response.error) {
    state.loginData.userName = ""

    if(type === "userName") {
      state.loginData.errors.userName = true
    }

    gui.toast(gui.translate(response.error, response.languageOptions))
  } else {
    state.loginData.errors.userName = false
    state.loginData.userName = response.login
    state.loginData.pinOutdated = response.pinOutdated
    state.loginData.state = response.pinDefined ? "input-pin" : "reset-pin"

    return true
  }

  return false
}

export async function loginSeller() {
  gui.preloader(true)
  const response = await auth.loginSeller({ login: gui.loginData.userName, pin: gui.loginData.PIN })
  gui.preloader(false)

  if(response.error) {
    if(response.error.code === 426) {
      state.loginData.errors.userName = false
      state.loginData.pinOutdated = true
      state.loginData.errors.pin = false
      state.loginData.state = "reset-pin"
    } else {
      state.loginData.errors.pin = true
      state.loginData.PIN = ""

      gui.toast(gui.translate(response.error, response.languageOptions))
    }
  } else {
    return response.authData
  }

  return null
}

export async function resetSellerPIN() {
  gui.preloader(true)
  const response = await auth.resetSellerPIN({ login: gui.loginData.userName, pin: gui.loginData.PIN })
  gui.preloader(false)

  if(response.error) {
    state.loginData.errors.pin = true
    state.loginData.PIN = ""

    gui.toast(gui.translate(response.error, response.languageOptions))
  } else {
    return response.authData
  }

  return null
}






