<template>
  <div class="uploading-container" :class="{ 'fade-in': loading }">
    <Preloader />
  </div>
</template>

<script>
  import Preloader from "@/components/f7/Preloader"

  export default {
    components: { Preloader },
    props: {
      loading: { type: Boolean, default: false }
    }
  }
</script>