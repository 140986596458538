<template>
  <Page schema="transparent" class="tips-page" id="tips-page">
    <Header>
      <div class="left">
        <f7-link
          id="tips-back"
          @tap="$gui.navigateBack()"
          class="ripple-color-black icon-only"
        >
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t("receipts.tipsPageTitle") }}</div>
      <div class="right tips-sum">${{ $filters.sumFixed(tips.tipsValue) }}</div>
    </Header>

    <Inner>
      <div class="tips-content">
        <div class="tips">
          <div class="tips-head new-payment-container payment">
            <div class="new-payment-inner" v-if="showTipsPercents">
              <div class="tips-title">{{ $t("receipts.tipsSelect") }}</div>
              <div class="tips-percents">
                <f7-button
                  round
                  fill
                  v-for="(item, $k) in tips.percents"
                  :key="$k"
                  :class="[
                    'tips-percents_item',
                    { grey: +tips.percent !== +item.key }
                  ]"
                  @tap="togglePercent(item)"
                >{{ item.title }}</f7-button>
              </div>
            </div>
            <div v-if="showAddTipsField" class="new-payment-inner">
              <div class="new-payment-item cash-field">
                <div class="new-payment-item-label">{{ $t(showTipsPercents ? "receipts.enterTipsAmount" : "receipts.enterTipsAmountFull") }}</div>
                <PriceField id="tips-sum" @click="showKeyboard" :modelValue="$filters.sumFixed(+tips.percent === 0 ? tips.tipsValue : 0)" input-mode="none" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Inner>

    <Footer>
      <div class="footer-buttons some-buttons">
        <f7-button
          round
          fill
          large
          id="tips-confirm"
          @tap="$gui.navigate('/receipt')"
          >{{ $t("receipts.confirm") }}</f7-button
        >
        <f7-button round large id="tips-reset" @tap="resetTips">{{
          $t("receipts.reset")
        }}</f7-button>
      </div>
    </Footer>

    <f7-sheet
      class="tips-keyboard-sheet keyboard-sheet"
      backdrop
      :opened="isKeyboardOpened"
      @sheet:closed="hideKeyboard"
      id="tips-keyboard-sheet"
    >
      <f7-page-content>
        <p class="keyboard-sheet-title">{{ $t("receipts.insertTipsAmount") }}</p>
        <DisplayedValue
          v-model:value="tipsSum"
          v-model:rewrited="rewrited"
          ref="sum"
          :min="0"
          id="tips-display"
        />
        <Keyboard
          @confirm="onConfirm"
          ref="keyboard"
          id="tips-keyboard"
        />
      </f7-page-content>
    </f7-sheet>
  </Page>
</template>

<script>
import { ref, watch, computed } from 'vue';
import DisplayedValue from '@/components/CustomStepper/DisplayedValue';
import Keyboard from '@/components/Keyboard';
import PriceField from '@/components/PriceField';

export default {
  name: 'TipsPage',
  components: {
    DisplayedValue, Keyboard, PriceField
  },
  setup() {
    const tipsSum = ref(0);
    const isKeyboardOpened = ref(false);
    const rewrited = ref(false);

    const showTipsPercents = computed(() => gui.receiptData.value.tips.showPredefTipsEnabled)
    const showAddTipsField = computed(() => gui.receiptData.value.tips.allowEnterTipsAmount)

    const tipsDefault = () => ({
      tipsValue: 0,
      percent: '',
      percents: [],
    });

    const getTips = (receipt) => {
      tipsSum.value = receipt.tipsAmount || 0;

      if (
        showTipsPercents.value &&
        Array.isArray(receipt?.tips?.options) &&
        receipt?.tips?.options?.length > 0
      ) {
        return {
          tipsValue: receipt?.sumTips,
          percent: receipt.tips?.percent ? String(receipt.tips?.percent) : '0',
          percents: receipt.tips.options.map((el) => {
            return { title: `${el}%`, key: String(el) };
          }),
        };
      } else {
        return tipsDefault();
      }
    };

    const tips = ref(getTips(gui.receiptData.value));

    watch(gui.receiptData, (value) => {
      tips.value = getTips(value);
    });

    return {
      tipsSum,
      tips,
      isKeyboardOpened,
      rewrited,
      showTipsPercents,
      showAddTipsField,
      togglePercent: async (percent) => {
        if (+tips.value.percent !== +percent.key) {
          await gui.dispatch('handlers.tips.setTips', {
            type: 'percent',
            value: percent.key
          });
        }
      },
      resetTips: async () => {
        if (+tips.value.tipsValue > 0) {
          await gui.dispatch('handlers.tips.resetTips');
        }
      },
      showKeyboard: () => {
        isKeyboardOpened.value = true;
      },
      hideKeyboard: () => {
        isKeyboardOpened.value = false;
        rewrited.value = true;
        tipsSum.value = gui.receiptData.value?.tipsAmount || 0;
      },
      onConfirm: async () => {
        isKeyboardOpened.value = false;
        rewrited.value = false;
        if (+tips.value.tipsValue !== +tipsSum.value) {
          await gui.dispatch('handlers.tips.setTips', {
            type: 'amount',
            value: +tipsSum.value
          });
        }
      },
    };
  },
  mounted() {
    this.$refs?.keyboard?.setRelation(this.$refs?.sum);
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables";

.tips-content {
  position: relative;
  min-height: 100%;
  background-color: #fff;
  padding-top: $padding-arrow;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.tips-sum {
  font-size: 24px;
  font-weight: 600;
}

.tips {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 6px;
  }

  &-percents {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &_item {
      color: #ffffff;
      font-size: 24px;
      width: 47%;
      height: 40px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      margin-bottom: 10px;

      &.grey {
        background-color: #A7ADBF !important;
      }
    }
  }

  &-amount {
    margin-top: 40px;

    &_title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: #000000;
    }
    &_input {
      display: flex;
      align-items: center;
      margin: 25px auto auto;
      width: 150px;
      &-block {
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      min-width: $basic-width;
      max-width: $basic-width;
      justify-content: center;
      display: flex;
      align-items: center;
      text-transform: none;
      font-weight: bold;
      font-size: 18px;

      &:first-child {
        margin-bottom: 5px;
      }
    }
  }

  div:first-child {
    margin-top: 0;
  }
}
.tips-keyboard-sheet {
  height: auto;
  background-color: #fff;
  border-radius: 5px 5px 0 0;

  .keyboard-displayed-value {
    background-color: $main-bg-color
  }

  .keyboard-row .keyboard-button .button {
    background-color: #fff;
  }

  .keyboard-sheet-title {
    font-size: 18px;
    text-align: center;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 5px;
    color: rgba(#000, .56);
  }
}
</style>
