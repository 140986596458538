import dicts from '../dicts';
import gui from '../gui';
import state from '../state';
import { isNewReceipt, save } from './receipt';

export async function open(modifiersIds) {
  gui.preloader(true);

  const modifiers = await dicts.good_modifiers.get({
    '_id IN': modifiersIds,
    companyId: state.user.company._id,
  });

  state.modifiers = gui.modifiers = { list: modifiers, shown: true };

  gui.preloader(false);
}

export async function close() {
  state.modifiers = gui.modifiers = { list: [], shown: false };
}

export async function change(goodId, value) {
  if (isNewReceipt(state.receiptData)) {
    const good = state.receiptData.goods.find((good) => good.receiptGoodUuid === goodId);

    if (good) {
      if (!good.selectedModifiers) good.selectedModifiers = [];

      if (!good.selectedModifiers.includes(value)) {
        good.selectedModifiers.push(value)
      } else {
        good.selectedModifiers.splice(good.selectedModifiers.indexOf(value), 1);
      }

      await save(state.receiptData);
      gui.receiptData = state.receiptData;
    } else {
      gui.toast(gui.translate('receipts.productNotFound'));
    }
  }
}

export async function addComments(uuid, comments) {
  if (isNewReceipt(state.receiptData)) {
    const good = state.receiptData.goods.find((good) => good.receiptGoodUuid === uuid);

    if (good) {
      good.comments = comments;
      await save(state.receiptData);
      gui.receiptData = state.receiptData;
    } else {
      gui.toast(gui.translate('receipts.productNotFound'));
    }
  }
}
