<template>
  <div class="custom-dialog-container" :class="{ 'fade-in': shown, 'fade-out': !shown }">
    <div class="custom-dialog-container-backdrop"></div>
    <div class="dialog" :class="{ 'dialog-no-buttons dialog-progress': noButtons }">
      <div :id="id" class="dialog-inner">
        <slot />
      </div>
      <slot name="outside" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      id: { type: String, default: "" },
      modelValue: { type: Boolean, default: false },
      noButtons: { type: Boolean, default: true }
    },
    data() {
      return {
        shown: this.modelValue
      }
    },
    methods: {
      show() {
        this.shown = true
        this.changeVisibility()
      },
      hide() {
        this.shown = false
        this.changeVisibility()
      },
      changeVisibility() {
        this.$emit("input", this.shown)
      }
    },
    mounted() {
      this.$nextTick(() => this.$root.$el.append(this.$el))
    },
    unmounted() {
      if(this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
    },
    watch: {
      modelValue(shown) {
        this.shown = shown
      }
    }
  }
</script>
