<template>
  <div class="phone-field">
    <div class="input-title"> {{ title }}</div>
    <div class="input-item" :class="{'error': error}">
      <div class="value" v-html="displayedValue"></div>
    </div>
    <div v-if="error" class="input-error"> {{ error }}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "" },
    error: { type: String, default: "" },
    modelValue: { type: String, default: "" },
    maxLength: { type: Number, default: 13 },
    mask: { type: String, default: "+_ (___) ___-____" },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      number: String(this.modelValue) || "",
      styledMask: this.mask.replace(/_{1,}/g, "<span class='mask-filler'>$&</span>")
    }
  },
  computed: {
    displayedValue() {
      let newVal = this.styledMask;
      if (this.number.length <= 10) {

        for (let i=0; i<this.number.length; i++) {
          newVal = newVal.replace( "_", this.number.charAt(i));
        }

        return newVal
      } else {

        for (let i=this.number.length-1; i>this.number.length-10-1; i--) {
          const index = newVal.lastIndexOf("_");
          newVal = newVal.slice(0, index) + this.number.charAt(i) + newVal.slice(index+1);
        }

        newVal = newVal.replace( "_", this.number.slice( 0, this.number.length - 10));

        return newVal
      }
    }
  },
  methods: {
    press(char) {
      if (/[\.,]/.test(char) || this.number.length >= this.maxLength) return;

      this.number = this.number + char;
      this.update();
    },
    isEmpty() {
      return this.number == ""
    },
    backspace() {
      this.number = this.number.slice(0, -1);
      this.update();
    },
    update() {
      this.$emit("update:modelValue", this.number);
    },
  },
  watch: {
    modelValue(number) {
      this.number = String(number);
    }
  }
}
</script>
<style lang="scss">
@import "@/css/variables";
  .phone-field {
    display: flex;
    flex-direction: column;
    .input-item {
      box-sizing: border-box;
      margin: 5px 0;
      .value {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        white-space: pre-wrap;
      }
    }
    .input-title {
      text-align: center;
      margin-bottom: 17px;
    }
    .input-error {
      color: $error-color;
      text-align: center;
    }
    .mask-filler {

    }
  }

</style>
