<template>
  <div v-tap="onTap" class="price-field-container">
    <div @click="focus" class="price-field-value-container input-item" :class="{ 'error': error }">
      <div v-if="isPositiveNumber" class="price-field-symbol" :class="{ 'empty': isEmptyNumber }">{{ currencySymbol }}</div>
      <div v-if="isEmptyNumber" class="price-field-placeholder">
        <div v-if="placeholder" class="prprice-field-placeholder-valueice-field-placeholder-value">{{ placeholder }}</div>
        <div v-else class="price-field-placeholder-value sum">0.00</div>
      </div>
      <div v-else class="price-field-value-input" :class="{ 'will-rewrite': rewriteFirst && !rewrited }">
        <div class="price-field-value">{{ number }}</div>
        <div v-if="showEndDot" class="price-field-end">.</div>
        <div v-if="showFirstNumberAfterDot" class="price-field-end">0</div>
        <div v-if="showSecondNumberAfterDot" class="price-field-end">0</div>
      </div>
    </div>
    <input @input="update" v-model="number" ref="input" type="text" :id="id" :inputmode="inputMode" />
  </div>
</template>

<script>
  import { tap } from "@/js/touches"

  export default {
    directives: { tap },
    props: {
      id: { type: String, default: "" },
      error: { type: Boolean, default: false },
      modelValue: { type: String, default: "" },
      inputMode: { type: String, default: "decimal" },
      currencySymbol: { type: String, default: "$" },
      placeholder: { type: String, default: null },
      rewriteFirst: { type: Boolean, default: false }
    },
    data() {
      return {
        number: String(this.modelValue) || "",
        rewrited: false
      }
    },
    methods: {
      onTap(event) {
        this.$emit("tap", event)
      },
      setRewrited(rewrited) {
        this.rewrited = rewrited
      },
      isEmpty() {
        return this.number == ""
      },
      press(char) {
        if(this.rewriteFirst && !this.rewrited) {
          this.number = ""
          this.rewrited = true
        }

        this.number = this.number + char
        this.update()
      },
      checkValidPressed() {
        const key = this.number.slice(-1)
        const previousNumber = this.number.slice(0, -1)

        if(this.lengthAfterDot > 2) {
          this.backspace()
          return false
        }

        if(key !== "." && previousNumber === "0") {
          this.backspace()
          return false
        }

        if(key === "." && (previousNumber.indexOf(".") > -1 || previousNumber === "" || previousNumber === "-")) {
          this.backspace()
          return false
        }

        if(key === "-" && previousNumber !== "") {
          this.backspace()
          return false
        }
      },
      backspace() {
        if(this.rewriteFirst && !this.rewrited) {
          this.number = ""
          this.rewrited = true
        }

        this.number = this.number.slice(0, -1)
        this.update()
      },
      update() {
        this.checkValidPressed()

        this.number = this.number.replace(/[^\d\-\.]+/g, "")

        this.$emit("update:modelValue", this.number)
        this.$emit("input", this.number)
      },
      focus() {
        this.$refs?.input?.focus()
      }
    },
    computed: {
      lengthAfterDot() {
        if(this.number.indexOf(".") === -1) {
          return 0
        }

        return this.number.length - this.number.indexOf(".") - 1
      },
      isPositiveNumber() {
        return !this.number.startsWith("-")
      },
      isStartedNumber() {
        return this.number !== "-"
      },
      isEmptyNumber() {
        return this.number === ""
      },
      showEndDot() {
        if(this.isEmptyNumber || !this.isStartedNumber) {
          return false
        }

        return this.number.indexOf(".") === -1
      },
      showFirstNumberAfterDot() {
        if(this.isEmptyNumber || !this.isStartedNumber) {
          return false
        }

        return this.lengthAfterDot < 1
      },
      showSecondNumberAfterDot() {
        if(this.isEmptyNumber || !this.isStartedNumber) {
          return false
        }

        return this.lengthAfterDot < 2
      }
    },
    watch: {
      modelValue(number) {
        this.number = String(number)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../css/variables";

  .price-field-container {
    position: relative;

    input {
      caret-color: transparent;
      height: 0 !important;
      opacity: 0 !important;
    }

    .price-field-end {
      color: rgba(#000, .2)
    }

    .price-field-symbol.empty {
      color: $input-placeholder;
    }

    .price-field-value-container {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      overflow: hidden;
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      overflow: hidden;
      .price-field-placeholder {
        display: flex;
        flex: 1;
        overflow: hidden;
        width: 100%;
        box-sizing: border-box;
        justify-content: flex-end;
        padding-left: 10px;
        font-size: $text-size;
        font-weight: normal;
        color: $input-placeholder;
        .price-field-placeholder-value.sum {
          font-weight: 600;
          font-size: 32px;
          line-height: 32px;
          color: rgba(#000, .2)
        }
      }
      .price-field-value-input {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 10px;
        flex: 1;
        overflow: hidden;
        &::before {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 40px;
          height: 100%;
          background-image: linear-gradient(90deg, $input-color, $input-color 20%, transparent);
        }
        &.will-rewrite {
          .price-field-symbol, .price-field-value {
            background-color: $select-color;
          }
        }
      }
      &.error {
        border-color: $error-color !important;
      }
    }
  }
</style>
