import gui from './gui'
import dicts, { Dicts } from './dicts'
import user from './user'
import lifeCycle from "./lifeCycle"
import dictsConfig from './config/dicts.json'
import handlers from './handlers'
import state from "./state"

import { isOnline, startPingInterval } from "./online"

import * as welcome from "./actions/welcome"
export * as router from "./actions/router"
export * as idleTimer from "./actions/idleTimer"
export * as images from "./actions/images"
export * as files from "./actions/files"

export * as native from "./native"
export * as printer from "./printer"
export * as terminal from "./terminal"
export * as bluetooth from "./bluetooth"

export * as split from "./actions/split"
export * as refund from "./actions/refund"
export * as receipt from "./actions/receipt"
export * as favourites from "./actions/favourites"
export * as certificates from "./actions/certificates"
export * as runner from "./actions/runner"

export * as tables from "./actions/tables"
export * as loyalty from "./actions/loyalty"
export * as modifiers from "./actions/modifiers"
export * as docs from "./actions/docs"
export * as login from "./actions/login"
export * as shift from "./actions/shift"
export * as settings from "./actions/settings"
export * as waiter from "./actions/waiter"
export * as support from "./actions/support"


export async function setGui(_gui) {
  for (const key in _gui) {
    const data = _gui[key]
    if (typeof(data?.value) === 'undefined') {
      gui[key] = data
    } else {
      Object.defineProperty(gui, key, {
        get: function () {
          return data.value;
        },
        set: function (value) {
          data.value = JSON.parse(JSON.stringify(value));
        }
      });
    }
  }
}

export async function init() {
  await Dicts(dictsConfig)

  // start ping
  startPingInterval()

  // start application updates
  lifeCycle()

  await handlers.update()
  await welcome.open()
}

export { isOnline, dicts, user, welcome, handlers, state }
