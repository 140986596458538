import { round2 } from "../libs/util"

export default {
  sumTaxes: {
    calc: {
      type: "aggregate",
      field: "sumTaxes",
      query: { type: "receipt", "state in": ["completed", "received"] }
    },
    merge: (left, right) => round2(left.sumTaxes + right.sumTaxes)
  },
  sumTaxesRefund: {
    calc: {
      type: "aggregate",
      field: "sumTaxes",
      query: { type: "refund", "state in": ["completed", "received"] }
    },
    merge: (left, right) => round2(left.sumTaxesRefund + right.sumTaxesRefund)
  },
  sumCash: {
    calc: {
      type: "aggregate",
      field: "sumCash",
      query: {
        type: "receipt",
        "state in": ["completed", "received"]
      }
    },
    merge: (left, right) => round2(left.sumCash + right.sumCash)
  },
  sumCard: {
    calc: {
      type: "aggregate",
      field: "sumCard",
      query: {
        type: "receipt",
        "state in": ["completed", "received"]
      }
    },
    merge: (left, right) => round2(left.sumCard + right.sumCard)
  },
  sumCertificates: {
    calc: {
      type: "aggregate",
      field: "sumCertificates",
      query: {
        type: "receipt",
        "state in": ["completed", "received"]
      }
    },
    merge: (left, right) => round2(left.sumCertificates + right.sumCertificates)
  },
  sumPayments: {
    calc: shift => {
      return round2(shift.sumCash + shift.sumCard + shift.sumCertificates)
    },
    merge: (left, right) => round2(left.sumPayments + right.sumPayments)
  },
  sumTotal: {
    calc: shift => shift.sumPayments,
    merge: (left, right) => round2(left.sumTotal + right.sumTotal)
  },
  cashRefund: {
    calc: {
      type: "aggregate",
      field: "sumTotal",
      query: {
        type: "refund",
        "state in": ["completed", "received"],
        paymentType: "cash"
      }
    },
    merge: (left, right) => round2(left.cashRefund + right.cashRefund)
  },
  cardRefund: {
    calc: {
      type: "aggregate",
      field: "sumTotal",
      query: {
        type: "refund",
        "state in": ["completed", "received"],
        paymentType: "card"
      }
    },
    merge: (left, right) => round2(left.cardRefund + right.cardRefund)
  },
  sumRefunds: {
    calc: shift => {
      return round2(shift.cashRefund + shift.cardRefund)
    },
    merge: (left, right) => round2(left.sumRefunds + right.sumRefunds)
  },
  cashIn: {
    calc: {
      type: "aggregate",
      field: "sumTotal",
      query: { type: "cashin", "state in": ["completed", "received"] }
    },
    merge: (left, right) => round2(left.cashIn + right.cashIn)
  },
  cashOut: {
    calc: {
      type: "aggregate",
      field: "sumTotal",
      query: { type: "cashout", "state in": ["completed", "received"] }
    },
    merge: (left, right) => round2(left.cashOut + right.cashOut)
  },
  cashTips: {
    calc: {
      type: "aggregate",
      field: "sumTips",
      query: {
        type: "receipt",
        "state in": ["completed", "received"],
        paymentType: "cash"
      }
    },
    merge: (left, right) => round2(left.cashTips + right.cashTips)
  },
  cardTips: {
    calc: {
      type: "aggregate",
      field: "sumTips",
      query: {
        type: "receipt",
        "state in": ["completed", "received"],
        paymentType: "card"
      }
    },
    merge: (left, right) => round2(left.cardTips + right.cardTips)
  },
  sumTips: {
    calc: shift => {
      return round2(shift.cardTips + shift.cashTips)
    },
    merge: (left, right) => round2(left.sumTips + right.sumTips)
  },
  availableCash: {
    calc: shift => {
      return round2(shift.sumCash + shift.cashIn - shift.cashOut - shift.cashRefund)
    },
    merge: (left, right) => round2(left.availableCash + right.availableCash)
  },
  taxes: {
    calc: shift => shift.taxes,
    merge: (left, right) => {
      return mergeTaxes([left.taxes, right.taxes])
    }
  },
  taxesRefund: {
    calc: shift => shift.taxesRefund,
    merge: (left, right) => {
      return mergeTaxes([left.taxesRefund, right.taxesRefund])
    }
  }
}

export function mergeTaxes(taxesList) {
  const result = {}

  for(let taxes of taxesList) {
    for(let [taxId, tax] of Object.entries(taxes)) {
      if(result[taxId]) {
        result[taxId].sum = round2(result[taxId].sum + tax.sum)
      } else {
        result[taxId] = { ...tax, sum: tax.sum }
      }
    }
  }

  return result
}
