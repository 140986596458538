<template>
  <Page class="current-shift-page" id="current-shift-page" schema="transparent" @page:beforein="pageBeforeIn">
    <!-- Header -->
    <Header>
      <div class="left">
        <f7-link id="shift-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t('shifts.title') }}</div>
      <div class="right">
        <f7-link id="shift-options" v-if="shift.state === 'active'" @tap="shownActions = true" class="icon-only">
          <i class="icon mpi-options color-black"></i>
        </f7-link>
      </div>
    </Header>

    <!-- Page content -->
    <Inner>
      <div v-if="!shift.state || ['completed', 'received'].includes(shift.state)" class="shift-page-content closed-shift">
        <div class="shift-have-nice">
          <div class="shift-have-nice-icon" :class="{ 'shown': animated, 'shine': !shift.state, 'rise': shift.state }"></div>
          <div class="shift-have-nice-title">{{ $t(!shift.state ? "shifts.haveNiceDay" : "shifts.haveNiceStay") }}</div>
          <div class="shift-have-nice-description">{{ $filters.date($realTime.value) }}</div>
        </div>
      </div>

      <!--  Opened shift view  -->
      <div v-if="shift.state === 'active'" class="shift-page-content opening-shift">
        <ShiftDetails :shift="shift" />
      </div>

      <!--  Actions available for shift  -->
      <f7-actions v-model="shownActions" id="shift-actions" backdrop class="shift-actions">
        <f7-actions-group>
          <f7-actions-button id="shift-option-deposit" @tap="openCashIn">{{ $t("shifts.deposit") }}</f7-actions-button>
          <f7-actions-button id="shift-option-withdrawal" @tap="openCashOut">{{ $t("shifts.withdrawal") }}</f7-actions-button>
          <f7-actions-button id="shift-option-x-report" @tap="printXReport">{{ $t("shifts.printXReport") }}</f7-actions-button>
        </f7-actions-group>
      </f7-actions>
    </Inner>

    <Footer>
      <!--  Toggle button  -->
      <div class="footer-buttons">
        <f7-button @tap="toggleShift" v-if="shift.state === 'active'" id="close-shift-button" round fill large>{{ $t("shifts.closeShiftButton") }}</f7-button>
        <f7-button @tap="toggleShift" v-else id="open-shift-button" round fill large>{{ $t("shifts.openShiftButton") }}</f7-button>
      </div>
    </Footer>
  </Page>
</template>

<script>
  import { ref, watch } from "vue";
  import ShiftDetails from '../../components/ShiftsPage/ShiftDetails'

  export default {
    components: { ShiftDetails },
    setup() {
      const shift = ref(gui.shift)
      const animated = ref(false)
      const shownActions = ref(false)

      watch(gui.shift, value => {
        shift.value = value
        setTimeout(() => {
          animated.value = shift.value.state === "active" ? false : true
        }, 100)
      })

      return {
        shift,
        animated,
        shownActions,
        toggleShift: () => gui.dispatch("shift.toggle"),
        openCashIn: () => gui.dispatch("shift.openCashIn"),
        openCashOut: () => gui.dispatch("shift.openCashOut"),
        printXReport: () => gui.dispatch("shift.printXReport"),
        pageBeforeIn: () => {
          animated.value = shift.value.state === "active" ? false : true
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .shift-button {
    position: fixed;
    padding-bottom: 30px;
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background-image: linear-gradient(rgba($main-bg-color-rgb, 0), rgba($main-bg-color-rgb, .7) 30%, rgba($main-bg-color-rgb, 1));
    }
    .button {
      position: relative;
      z-index: 1;
      min-width: $basic-width;
      max-width: $basic-width;
      justify-content: center;
      display: flex;
      align-items: center;
      text-transform: none;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .shift-page-content {
    position: relative;
    min-height: 100%;
    background-color: #fff;
    padding-bottom: 80px;
    box-sizing: border-box;
  }

  .shift-have-nice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 35px 30px;
    box-sizing: border-box;
    .shift-have-nice-icon {
      min-width: 90px;
      height: 90px;
      background-image: url(~@/static/imgs/sunshine.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-bottom: 30px;
      &.rise {
        transform: translateY(-80px);
        background-image: url(~@/static/imgs/sunrise.svg);
      }
      transform: translateY(80px);
      opacity: 0;
      transition: .8s;
      &.shown {
        transform: translateY(0px);
        opacity: 1;
      }
    }
    .shift-have-nice-title {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 10px;
      position: relative;
      z-index: 1;
    }
    .shift-have-nice-description {
      text-align: center;
      font-size: 16px;
      color: #979797;
      position: relative;
      z-index: 1;
    }
  }

  .shift-page-content.opening-shift {
    padding: $padding-arrow;
  }
</style>
