import gui from '../gui';
import rpc from '../libs/rpc'
import state from '../state';

import { isOnline } from '../online';
import { navigate, navigateBack } from "./router"

const SUPPORT_MAX_SCREENS_COUNT = 4;

export async function openSupportEmail () {
  if (!isOnline) {
    await gui.message(gui.translate('rpc.sendingUnavailable'));
    return;
  }

  state.supportData = getDefaultSchema();
  gui.supportData = state.supportData;

  await navigate('/support')
}

export async function sendMail(message) {
  gui.preloader(true)

  const html = `
    <p>Store: <b>${state.user.store.name}</b></p>
    <p>Device: #${state.device._id}, <b>${state.device.deviceName}</b> (${state.device.deviceSerialNumber})</p>
    <hr>
    <p>${message.comment}</p>
  `

  const response = await rpc("pos.support.send", {
    html,
    from: { email: 'devops@temabit.com' },
    subject: 'Screenshots for support',
    attachments: message?.files?.map(el => {
      return  {
        content: el.base64.split('base64,')[1],
        filename: el.fileName,
        type: 'text/plain'
      }
    }) || []
  })

  gui.preloader(false)

  if (response.error) {
    await gui.message(gui.translate("rpc.supportSendError"))
  } else {
    await gui.message(gui.translate("rpc.supportSendSuccess"))
    await navigateBack()
  }
}

export async function removeScreen($k) {
  state.supportData.screens.splice($k, 1);
  gui.supportData = state.supportData;
}

export async function send(comments) {
  state.supportData.loading = true;
  gui.supportData = state.supportData;

  const postData = { comment: comments }

  if (state.supportData.screens.length > 0) {
    postData.files = state.supportData.screens
  }

  await sendMail(postData);

  state.supportData.loading = false;
  gui.supportData = state.supportData;
}

export async function clear() {
  state.supportData = getDefaultSchema();
  gui.supportData = state.supportData;
}

export async function getSelectedScreenshots() {
  const selectedScreens = await gui.selectScreenshots({max: SUPPORT_MAX_SCREENS_COUNT, selected: state.supportData.screens});
  if (selectedScreens.length) {
    state.supportData.screens = selectedScreens;
    gui.supportData = state.supportData;
  }
}

function getDefaultSchema() {
  return {
    loading: false,
    screens: [],
    maxScreensCount: SUPPORT_MAX_SCREENS_COUNT,
  }
}
