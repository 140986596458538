<template>
  <f7-list
    ref="tablesAccordion"
    :accordion-open-first="false"
    accordion-list
    no-hairlines
    no-hairlines-between
    class="halls-list-wrap">
      <f7-list-item
        class="halls-list-item"
        :id="`halls-list-item-${index}`"
        v-for="(hall, index) in halls"
        :key="index"
        accordion-item
        :clickable="!disabled"
      >
        <template #inner>
          <div class="halls-list-content">
            <div class="halls-arrow" :class="{ 'hide-arrow': disabled }">
              <i class="mpi-expand2"></i>
            </div>
            <div class="halls-list-title">{{ hall.hallName ?? $t('filters.null') }}</div>
          </div>
        </template>

        <f7-accordion-content>
          <div class="tables-list">
            <TableListItem
              v-for="(table, i) in hall.tables"
              :key="i"
              :id="`hall-${index}-table-${i}`"
              :table="table"
              @swipe:click="$emit('table-click', hall.hallName, table)"
            />
          </div>
        </f7-accordion-content>
      </f7-list-item>
  </f7-list>
</template>
<script>
import TableListItem from "./TableListItem";

export default {
  components: { TableListItem },
  props: {
    halls: { type: Array, required: true },
    disabled: { type: Boolean, default: true },
  },
  emits: ["table-click"],
};
</script>
<style lang="scss">
  @import "@/css/variables";
  .halls-list-wrap {
    margin-top: 0;

    &>ul>li .ripple-item {
      border-radius: $border-radius;
      overflow: hidden;
    }

    .halls-list-item {
      position: relative;
      & > .item-link {
        padding: 0 $minimal-padding;
      }
      &.accordion-item-opened .halls-arrow {
        transform: rotate(180deg);
      }
      .halls-arrow {
        position: absolute;
        top: 0;
        right: 0;
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform-origin: center;
        font-size: 14px;
        transition: .3s;
        &.hide-arrow {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .tables-list {
      background: none;
      padding: 4px $minimal-padding;
    }

    .halls-list-item {
      background: none;
      margin: 10px 0 !important;
      font-weight: 600;
      font-size: $text-size;
      line-height: 118.75%;
      color: $text-color;
      .item-link {
        overflow: hidden;
        border-radius: $border-radius;
      }
      .item-inner:before {
        color: $text-color;
      }
    }
    .empty {
      .item-inner::before {
        content: none !important;
      }
      .accordion-item-content {
        display: none;
      }
    }
  }
  .halls-list-wrap > ul {
    background: none;
    border: 1px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
  }
  .halls-list-wrap > ul > .item-link {
    .item-content {
      padding: 0px;
      .item-inner {
        padding: 0px;
        height: 52px;
        .halls-list-content {
          padding: 0px 16px 0px 18px;
        }
      }
    }
  }
</style>
