<template>
  <Page class="checkout-result-page" id="checkout-result-page" :class="{ 'one-button': $helpers.isRefund(receipt) }">
    <Inner>
      <div class="common-dialog-container flex-ends">
        <div class="common-dialog-header">
          <div class="common-dialog-icon">
            <BigIcon class="animate__bounceIn" :loading="false" :icon="receipt.paymentType === 'card' ? 'card' : 'money'" :state="error ? 'error' : 'success'" />
          </div>

          <div class="common-dialog-title">{{ title }}</div>
          <div v-if="error" class="common-dialog-description error">{{ error }}</div>
          <div v-else class="checkout-result-details">
            <div v-for="(detail, index) of paymentsList" :key="index" class="checkout-result-detail-item" :class="{ 'bold': detail.total }">
              <div class="checkout-result-detail-item-left">{{ $t(detail.title) }}</div>
              <div class="checkout-result-detail-item-value">{{ detail.value }}</div>
            </div>
          </div>
        </div>

        <div class="common-dialog-footer">
          <div class="checkout-footer-inner">
            <template v-if="$helpers.isReceipt(receipt)">
              <template v-if="receipt.nextRelatedReceipt">
                <div class="common-dialog-button fill">
                  <f7-button id="result-to-main-page" @tap="openNextReceipt" large round fill>{{$t("checkout.openNextReceipt")}}</f7-button>
                </div>
                <div class="common-dialog-button">
                  <f7-button id="result-new-receipt" @tap="openHomePage" large round>{{$t("checkout.toMainPage")}}</f7-button>
                </div>
              </template>
              <template v-else>
                <div class="common-dialog-button fill">
                  <f7-button id="result-to-main-page" @tap="openHomePage" large round fill>{{$t("checkout.toMainPage")}}</f7-button>
                </div>
                <div class="common-dialog-button">
                  <f7-button id="result-new-receipt" @tap="createNewReceipt" large round>{{$helpers.orderOrReceipt("receipts.newReceipt", "receipts.newOrder", true).value}}</f7-button>
                </div>
              </template>
            </template>
            <template v-else-if="$helpers.isRefund(receipt)">
              <div class="common-dialog-button fill">
                <f7-button id="result-to-main-page" @tap="openHomePage" large round fill>{{$t("checkout.ok")}}</f7-button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </Inner>
  </Page>
</template>

<script>
  import { ref, computed } from "vue"
  import { getPaymentsList } from "../../js/helpers"

  import BigIcon from "../../components/BigIcon"

  export default {
    components: { BigIcon },
    setup() {
      const receipt = ref(gui.receiptData)
      const error = ref(receipt.value.resultError)

      if(receipt.value.type === "receipt" && error.value === null) {
        // Barcode scanner
        gui.onScanned.value = barcode => gui.dispatch("receipt.createReceiptWithQuery", { barcode })
      }

      return {
        error,
        receipt,
        title: computed(() => {
          if(error.value === null) {
            return gui.translate(receipt.value.type === "receipt" ? "checkout.successTitle" : "checkout.returnSuccessTitle")
          } else {
            return gui.translate(receipt.value.type === "receipt" ? "checkout.errorTitle" : "checkout.returnErrorTitle")
          }
        }),
        paymentsList: computed(() => {
          return getPaymentsList(receipt.value)
        }),
        createNewReceipt: () => gui.dispatch("receipt.create"),
        openHomePage: () => {
          gui.navigate("/home")
        },
        openNextReceipt: async () => {
          if(receipt.value.nextRelatedReceipt) {
            await gui.dispatch("receipt.open", receipt.value.nextRelatedReceipt)
          }
        }
      }
    }
  }
</script>

<style lang="sass">
  @import "../../css/pages/checkout"
</style>
