<template>
  <div class="fab" :class="{ 'fade-in': shown }">
    <template v-if="items.length > 0">
      <div @click="shown = false" class="fab-backdrop"></div>

      <div class="fab-buttons-list">
        <div v-for="(button, index) of items" :key="index" :style="{ 'transition-delay': `${index * 100}ms` }" @click="clickButton(button)" class="fab-button">
          <div class="fab-button-label">{{ button.label }}</div>
          <div class="fab-button-right">
            <f7-button :pointer="false" class="fill" :class="{[button.color || 'primary']: true}">
              <i :class="button.icon"></i>
            </f7-button>
          </div>
        </div>
      </div>
    </template>
    <f7-button :pointer="false" @tap="onClick" class="fab-main-button fill" :disabled="disabled">
      <slot />
    </f7-button>
  </div>
</template>

<script>
  export default {
    emits: ["tap"],
    props: {
      items: { type: Array, default: () => [] },
      disabled: { type: Boolean, default: false }
    },
    data() {
      return {
        shown: false
      }
    },
    methods: {
      clickButton(button) {
        this.shown = false
        if(typeof button.click === "function") {
          button.click()
        }
      },
      onClick(event) {
        if(this.disabled) {
          event.preventDefault()
          return false
        }

        if(this.items.length > 0) {
          this.shown = !this.shown
        }

        this.$emit("tap", event)
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";
  .fab {
    position: absolute;
    right: $fab-bottom;
    bottom: $fab-bottom;
    z-index: 1;

    &.fade-in {
      .fab-main-button i {
        transform: rotate(45deg);
      }

      .fab-backdrop {
        visibility: visible;
        opacity: 1;
      }

      .fab-buttons-list {
        visibility: visible;
        .fab-button {
          opacity: 1;
          transform: translateX(0);
          visibility: visible;
        }
      }
    }

    .fab-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, .3);
      z-index: 10;
      visibility: hidden;
      opacity: 0;
      transition: .3s;
    }
    .fab-main-button {
      overflow: hidden;
      border-radius: 50%;
      width: 64px;
      height: 64px;
      color: #fff;
      font-size: 28px;
      font-weight: bold;
      z-index: 11;
      box-shadow: 0px 5px 20px rgba(#000, .2);
      i {
        transform-origin: center;
        transition: .3s;
      }
    }
    .fab-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 6px;
      .fab-button-label {
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        box-shadow: 0 5px 10px rgba(#000, .12);
        border-radius: $border-radius;
        white-space: nowrap;
        text-align: center;
        font-size: 16px;
        box-sizing: border-box;
        padding: 0 10px;
        min-width: 150px;
      }
      .fab-button-right {
        margin-left: 14px;
        .button {
          min-width: 52px;
          max-width: 52px;
          height: 52px;
          border-radius: 50%;
          font-size: 20px;
        }
      }
    }
    .fab-buttons-list {
      position: absolute;
      z-index: 11;
      right: 0;
      bottom: $fab-bottom-margin;
      visibility: hidden;
      transition: .3s;
      display: flex;
      flex-direction: column-reverse;
      .fab-button {
        visibility: hidden;
        opacity: 0;
        transform: translateX(40px);
        transition: .3s;
        margin-bottom: 10px;
        &:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
