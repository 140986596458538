import rpc from "../libs/rpc"
import dicts from "../dicts"

export default {
  vars: { pull: false },
  images: { pull: false },
  goods: { pull: false },
  users_settings: { pull: false },
  companies: {
    pull: {
      after: async item => {
        if(item.logoId) {
          const images = await rpc("pos.sync.pull", "images", { idEqual: item.logoId })
          if(Array.isArray(images) && images.length > 0) {
            await dicts.images.put(images[0])
          }
        }
      }
    }
  },
  goods_per_store: {
    pull: {
      handler: "pos.sync.getGoods",
      putRules: async items => {
        const goodsPerStore = []
        const images = []
        const goods = []

        for(let item of items) {
          goodsPerStore.push(item.goodPerStore)
          goods.push(item.good)
          images.push(...item.images)
        }

        await dicts.goods_per_store.put(goodsPerStore)
        await dicts.goods.put(goods)
        await dicts.images.put(images)
      }
    }
  },
  docs: {
    pull: false,
    push: {
      handler: "pos.sync.pushDocument",
      where: () => ({ state: "completed" }),
      before: async doc => {
        doc.state = "received"
      },
      extend: async doc => {
        return { _id: undefined, deviceDocId: doc._id }
      },
      after: async doc => {
        await dicts.docs.put({ ...doc, state: "received" })
      }
    }
  }
}
