import gui from "./gui"
import user from "./user"
import state from "./state"
import dicts from "./dicts"

import * as moment from "moment"

import { isOnline } from "./online"
import { sync } from "./sync"
import { isCafe } from "./settings"
import { initState } from "./state"
import { isActiveTimeRange } from "./libs/util"

export const UPDATE_INTERVAL = 10  // every 10 minutes

export default function () {
  setInterval(async () => {
    periodicAgeVerification().then(condition => {
      if(condition) {
        console.log("Confirmed periodic age verification")
      } else {
        console.log("Passed periodic age verification")
      }
    })

    if(isOnline && user.isAuthorized()) {
      await sync()
    }

    await initState()
  }, UPDATE_INTERVAL * 60000)
}

export async function periodicAgeVerification() {
  if(user.isAuthorized() && await isCafe()) {
    if(state.user.store.customerAgeVerification !== true) {
      return false
    }

    const currentDay = moment(new Date()).format("YYYY-MM-DD")

    if(isActiveTimeRange(state.user.store.beginCheckTime, state.user.store.endCheckTime)) {
      const previousCheck = await dicts.vars.getOne({ name: "periodicAgeVerification" })

      if(previousCheck && previousCheck.day === currentDay) {
        return true
      }

      if(await gui.periodicAgeVerification()) {
        // save current day for next check
        await dicts.vars.del({ name: "periodicAgeVerification" })
        await dicts.vars.put({ name: "periodicAgeVerification", day: currentDay })

        return true
      }

      return false
    }
  }
}
