<template>
  <div class="total-block-header">
    <div class="total-block-header-left">
      <div class="total-block-header-total" :class="{ 'small': needSmall }">
        <template v-if="isVisibleSumToPay">
          <div class="total-block-header-total-title">{{ $t("receipts.toPay") }}</div>
          <div class="total-block-header-total-value">${{ $filters.sum(sumToPay) }}</div>
        </template>
        <template v-else>
          <div class="total-block-header-total-title">{{ $t("receipts.total") }}</div>
          <div class="total-block-header-total-value">${{ $filters.sum(receipt.sumTotal) }}</div>
        </template>
      </div>
      <div class="total-block-header-details">{{ hintText }}</div>
    </div>
    <div class="total-block-header-right">
      <template v-if="detailsButton">
        <f7-button id="receipt-block-details" :disabled="receipt.goods.length === 0" fill round @tap="openReceipt">{{ $t("receipts.details") }}</f7-button>
      </template>
      <template v-else-if="confirmButton">
        <f7-button id="receipt-block-confirm" :disabled="receipt.goods.length === 0" fill round @tap="confirmOrder">{{ $t("receipts.confirm") }}</f7-button>
      </template>
      <template v-else-if="$helpers.isSelection(receipt)">
        <f7-button id="receipt-block-split" :disabled="!$helpers.isSplitAvailable(receipt)" fill round @tap="split">{{ $t("receipts.split") }}</f7-button>
      </template>
      <template v-else>
        <f7-button id="receipt-block-checkout" v-if="$helpers.isNewReceipt(receipt)" :disabled="checkoutDisabled" fill round @tap="checkout">{{ $t("receipts.checkout") }}</f7-button>
        <div id="receipt-block-ready" v-else-if="$helpers.isCompletedReceipt(receipt)" class="checkout-payed-like-button">
          <div class="checkout-payed-like-button-icon">
            <i class="mpi-md-done"></i>
          </div>
          <div class="checkout-payed-like-button-text">{{ $t(`receipts.${receipt.paymentType}AlreadyPayed`) }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { isCafe, isSelection } from "../../../js/helpers"

  export default {
    props: {
      receipt: { type: Object, default: () => ({}) },
      sheetExpanded: { type: Boolean, required: true, default: false },
    },
    methods: {
      split() {
        gui.dispatch("split.splitOrder")
      },
      checkout() {
        gui.dispatch("receipt.checkout")
      },
      openReceipt() {
        gui.navigate("/receipt")
      },
      confirmOrder() {
        gui.dispatch("runner.printRunner")
      },
    },
    computed: {
      isVisibleSumToPay() {
        return this.$helpers.isNewReceipt(this.receipt) && !this.$helpers.isSelection(this.receipt)
      },
      checkoutDisabled() {
        return this.receipt.goodsCount <= 0 || this.receipt.sumCertificates > this.receipt.sumTotal
      },
      sumToPay() {
        return this.receipt.sumToPay >= 0 ? this.receipt.sumToPay : 0
      },
      needSmall() {
        return this.receipt.sumTotal > 99999 || (this.isVisibleSumToPay && this.receipt.sumToPay > 99999)
      },
      hintText() {
        return this.sheetExpanded ? gui.translate("receipts.swipeUp") : gui.translate("receipts.swipeDown")
      },
      detailsButton() {
        return ["/receipt/add-item", '/receipt/category'].includes(gui.page.value)
      },
      confirmButton() {
        if(isCafe() && !this.receipt.isAllSent && !isSelection(this.receipt)) {
          if(this.receipt.runnerErrors && this.receipt.runnerErrors.length > 0) {
            return false
          }

          return true
        }

        return false
      },
    }
  }
</script>
