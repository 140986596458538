<template>
  <Page schema="blue" @page:beforein="onPageBeforeIn" class="table-selection-page" id="tables-selection-page">
    <!--  Header  -->
    <Header
      ref="header"
      v-model="searchingValue"
      @search:update="search"
      @search:disable="disableSearching"
      search-id="table-selection-search-field"
      :search-hide-outside="false"
      :search-placeholder="$t('receipts.search')">
      <div class="left">
        <f7-link
          class="icon-only"
          id="table-selection-back-link"
          @tap="$gui.navigateBack()">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ title }}</div>
      <div class="right">
        <f7-link
            id="table-expand-all"
            @tap="toggleTables"
            :class="{'opened': listOpened}"
            class="icon-only">
          <i class="icon mpi-md-swap-vert color-black"></i>
        </f7-link>
        <f7-link
          id="table-selection-searchbar-link"
          @tap="openSearchBar"
          class="icon-only">
          <i class="icon mpi-search color-black"></i>
        </f7-link>
      </div>
    </Header>

    <!-- Message -->
    <div class="header-messages">
      <HeaderMessage :key="tablesMessage.index" v-model="tablesMessage.shown" :color="tablesMessage.color" class="flex-end">
        <span v-html="tablesMessage.text"></span>
      </HeaderMessage>
    </div>

    <!--  Page content  -->
    <Inner>
      <template v-if="loading">
        <div class="category-empty">
          <div class="category-empty-text">{{ $t("loading") }}</div>
        </div>
      </template>
      <template v-else>
        <Tables v-if="searchingValue === ''" :halls="tablesData.tables" @table-click="onTableClick" ref="tablesList" :disabled="listOpened"/>
        <Search v-else :halls="tablesData.search.results" @table-click="onTableClick"/>
      </template>

      <CalendarSheet
        ref="calendar"
        :opened="showDatepicker"
        @sheet:closed="onClose"
        v-model:date="reserveData.date"
        id="tables"
        withConfirm
        @confirm="confirm"
        mode="datetime"
        :min-date="new Date()"
        :attributes="reserveAttr"
        :disableConfirm="offline"
      />

    </Inner>

    <!--  Footer  -->
    <Footer>
      <div class="footer-buttons">
        <f7-button class="fill" @tap="skip">{{ $t("tables.skip") }}</f7-button>
      </div>
    </Footer>
  </Page>
</template>

<script>
  import { ref, watch, computed, provide, onMounted } from "vue";
  import { date as formatDate } from "../../js/filters";
  import { click } from "../../js/audio"

  import Tables from "../../components/Receipt/Tables/Tables";
  import Search from "../../components/Receipt/Tables/Searching";
  import CalendarSheet from "../../components/CalendarSheet";

  export default {
    components: { Tables, Search, CalendarSheet },
    setup() {
      const loading = ref(true)
      const tablesData = ref(gui.tables.value)
      const header = ref(null)
      const calendar = ref(null)
      const tablesList = ref(null)
      const listOpened = ref(false)
      const searchingValue = ref("");
      const reserveData = ref({ table: null, date: {} });
      const showDatepicker = ref(false);
      const offline = ref(!gui.tables.value.reserved.isOnline);

      const toggleTables = () => {
        listOpened.value = !listOpened.value;
        tablesList.value.$refs.tablesAccordion.toogleAccordionItemsAll(listOpened.value);
      };

      onMounted(() => {
        setTimeout(() => {
          loading.value = false
        }, 10)
      })

      watch(gui.tables, (value) => {
        tablesData.value = value;
        offline.value = !value.reserved.isOnline
      });

      const tablesMessage = computed(() => {
        const message = { shown: false, index: 0, text: "", color: "error" }

       message.shown = offline.value;
       if (gui.syncCompletedOn.value === null) {
         message.text = gui.translate("tables.offlineData");
       } else {
         message.text = gui.translate("tables.offlineDataWithDate", {date: formatDate(gui.syncCompletedOn.value)});
       }

        return message
      })


      const reserveAttr = computed(()=> {
        return tablesData.value.reserved.data.filter((reserved)=>reserved.tableId === reserveData.value.table?._id).map((table)=> ({
                    dates: new Date(table.reserveDate),
                    key: table.reserveDate,
                    dot: 'red',
                    customData: table
                    }))

      });

      const setReservedTable = (table) => {
        reserveData.value.table = table;
        calendar.value.reset();
        showDatepicker.value = true;
      }

      const unreserveTable = (id) => {
        gui.dispatch('tables.unreserve', id);
      }

      provide('setReservedTable', setReservedTable);
      provide('unreserveTable', unreserveTable);
      provide('offline', offline);

      return {
        loading,
        reserveAttr,
        tablesMessage,
        tablesData,
        header,
        searchingValue,
        tablesList,
        listOpened,
        toggleTables,
        reserveData,
        showDatepicker,
        calendar,
        offline,
        onClose: () => {
          reserveData.value.table = null
          showDatepicker.value = false
        },
        confirm: () => {
          showDatepicker.value = false;
          gui.dispatch('tables.reserve', reserveData.value);
        },
        search: () => gui.dispatch("tables.search", searchingValue.value),

        onTableClick: (hall, table) => {
          click();
          if (table?.tableReceipts.local > 0 && !tablesData.value?.change) {
            gui.dispatch('docs.openDocs', { activeTab: "postponed", page: "receipts", filter: { tableId: [table._id]}, tableData: { hall, table } })
          } else {
            gui.dispatch("tables.select", hall, table);
          }
        },
        skip: () => gui.dispatch("tables.select", ''),
        openSearchBar: () => {
          if (listOpened.value) toggleTables();
          searchingValue.value = ""
          header.value.enableSearching()
        },
        disableSearching: () => {
          searchingValue.value = "";
          gui.dispatch("tables.disableSearching");
        },
        title: computed(() => gui.translate("tables.selectTable")),
        onPageBeforeIn() {
          if (!tablesData.value.updated) gui.dispatch("tables.open", { needUpdate: true, change: tablesData.value.change })
        },

      };
    },
  };
</script>

<style lang="scss">
  @import "@/css/variables";
  .table-selection-page {
    .icon-only {
      transition: .3s;
      &.opened {
        transform: rotateX(180deg);
      }
    }
  }
</style>
