<template>
  <Page
    schema="blue"
    class="shifts-page"
    id="shifts-page">
    <Header>
      <div class="left">
        <f7-link id="shifts-back" @tap="goBack" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t("shifts.list") }}</div>
      <div class="right">
        <f7-link
          id="shifts-filter-btn"
          class="icon-only"
          @tap="openFilters">
          <i class="icon mpi-filter color-black"></i>
        </f7-link>
        <f7-link
          id="shift-list-options"
          @tap="shownActions = true"
          class="icon-only">
          <i class="icon mpi-options color-black"></i>
        </f7-link>
      </div>
    </Header>

    <Inner>
      <ShiftsList :docs="shifts.shifts" type="shifts" />

      <!--  Actions available for shifts  -->
      <f7-actions v-model="shownActions">
        <f7-actions-group>
          <f7-actions-button id="shift-list-option-full-report" @click="goToFullReport">
            {{ $t("shifts.fullReport") }}
          </f7-actions-button>
        </f7-actions-group>
      </f7-actions>
    </Inner>

  </Page>
</template>

<script>
  import ShiftsList from "../../components/ShiftsPage/List";
  import { ref, watch } from "vue";

  export default {
    components: {
      ShiftsList,
    },
    setup() {
      const shifts = ref(gui.shifts);
      const shownActions = ref(false);

      watch(gui.shifts, (value) => {
        shifts.value = value;
      });

      return {
        shifts,
        shownActions,
        openFilters: async () => {
          await gui.dispatch("docs.openFilters", "shifts");
        },
        goBack: () => {
          gui.navigateBack();
          gui.dispatch("docs.resetOffsetByPage", "shifts");
        },
        goToFullReport: () => {
          gui.dispatch("docs.openFullReportPage");
        },
      };
    },
  };
</script>



<style lang="scss">
  @import "@/css/variables";
  .shifts-page {
    .shifts-list {
      .shifts-list-item {
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
