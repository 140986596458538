<template>
  <div class="total-sheet-header">
    <template v-if="$helpers.isReceipt(receipt)">
      <ReceiptBlock :details-button="detailsButton" :sheet-expanded="sheetExpanded" :receipt="receipt" />
    </template>
    <template v-else-if="$helpers.isRefund(receipt)">
      <RefundBlock :details-button="detailsButton" :sheet-expanded="sheetExpanded" :receipt="receipt" />
    </template>
  </div>
</template>

<script>
  import { ref, watch } from "vue"

  import ReceiptBlock from "./ReceiptBlock"
  import RefundBlock from "./RefundBlock"

  export default {
    components: { ReceiptBlock, RefundBlock },
    props: {
      receipt: { type: Object, default: () => ({}) },
      sheetExpanded: { type: Boolean, required: true, default: false },
      hasHiddenContent: { type: Boolean, required: true, default: false },
      detailsButton: { type: Boolean, default: false }
    },
    emits: ["confirm"],
    setup(props) {
      const receipt = ref(props.receipt)

      watch(() => props.receipt, value => {
        receipt.value = value
      })

      return { receipt }
    }
  }
</script>

<style lang="scss">
@import "@/css/variables";

.total-block-header {
  background-color: $main-bg-color;
  height: 90px;
  display: flex;
  align-items: center;
  padding: 0 13px;
  padding-left: 16px;
  .total-block-header-left {
    flex: 1;
    overflow: hidden;
    .total-block-header-details {
      font-size: 13px;
      color: $text-secondary;
    }
    .total-block-header-total {
      display: flex;
      align-items: center;
      font-size: 22px;
      line-height: 22px;
      font-weight: bold;
      transition: .3s;
      &.small {
        .total-block-header-total-title {
          display: none;
        }
      }
      .total-block-header-total-value {
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .total-block-header-total-title {
        padding-right: 6px;
        white-space: nowrap;
      }
    }
  }
  .total-block-header-right {
    padding-left: 15px;
    .button {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      text-transform: none;
      border-radius: 23px;
      font-size: 18px;
      min-width: 135px;
      box-sizing: border-box;
      padding: 0 20px;
    }
  }
}
</style>

<!--<style lang="scss">-->
<!--  @import "@/css/variables";-->
<!--  -->
<!--  .total-sheet-header {-->
<!--    background-color: #fff;-->
<!--    padding: 0 32px;-->
<!--    padding-top: 8px;-->
<!--    height: 164px;-->
<!--    display: flex;-->
<!--    justify-content: center;-->
<!--    flex-direction: column;-->
<!--    width: 100%;-->
<!--    box-sizing: border-box;-->
<!--    font-size: 16px;-->
<!--    .total-sheet-header-footer {-->
<!--      text-align: center;-->
<!--      font-size: 12px;-->
<!--      color: #A7ADBF;-->
<!--      padding-top: 4px;-->
<!--    }-->
<!--    .max-container {-->
<!--      max-width: 224px;-->
<!--      margin: 0 auto;-->
<!--    }-->
<!--    .total-sheet-header-button {-->
<!--      padding-top: 11px;-->
<!--      .button {-->
<!--        background-color: $primary-color;-->
<!--        height: 46px;-->
<!--        border-radius: 23px;-->
<!--        max-width: 224px;-->
<!--        text-transform: none;-->
<!--        color: #fff;-->
<!--        font-weight: bold;-->
<!--        font-size: 18px;-->
<!--        align-items: center;-->
<!--        justify-content: center;-->
<!--        display: flex;-->
<!--        box-sizing: border-box;-->
<!--        margin: 0 auto;-->
<!--      }-->
<!--    }-->
<!--    .total-sheet-header-price {-->
<!--      display: flex;-->
<!--      justify-content: space-between;-->
<!--      align-items: flex-end;-->
<!--    }-->
<!--    .big {-->
<!--      font-size: 32px;-->
<!--      line-height: 38px;-->
<!--    }-->
<!--    .bold {-->
<!--      font-weight: bold;-->
<!--    }-->
<!--    .gray {-->
<!--      color: #A7ADBF;-->
<!--    }-->
<!--  }-->
<!--</style>-->
