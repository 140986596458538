import { orderOrReceipt } from "../helpers"

export default [
  {
    title: "menuItems.shifts", menu: [
      { title: "menuItems.currentShift", do: () => gui.dispatch("shift.openPage") },
      { title: "menuItems.shiftsList", do: () => gui.dispatch('docs.openDocs', { activeTab: 'shifts', page: 'shifts' }) }
    ]
  },
  {
    title: orderOrReceipt("menuItems.receipts", "menuItems.orders"), menu: [
      { title: orderOrReceipt("menuItems.newReceipt", "menuItems.newOrder"), do: () => gui.dispatch("receipt.create") },
      {
        title: orderOrReceipt("menuItems.postponedReceipts", "menuItems.postponedOrders"),
        do: () => gui.dispatch('docs.openDocs', { activeTab: 'postponed', page: 'receipts' })
      },
      {
        title: orderOrReceipt("menuItems.completedReceipts", "menuItems.completedOrders"),
        do: () => gui.dispatch('docs.openDocs', { activeTab: 'completed', page: 'receipts' })
      },
    ]
  },
  { title: "menuItems.calculator", do: () => gui.calculator.value = true },
  { title: "menuItems.settings", do: () => gui.dispatch("settings.openSettings") },
  { title: "menuItems.addWidgets", do: () => gui.openWidgetsControl() },
]