const TAP_MAX_DIFF = 8
let lastTouch = null

export function handleTouches() {
  document.addEventListener("touchstart", event => {
    lastTouch = event.touches[0]
  }, false)

  document.addEventListener("touchend", event => {
    const touch = event.changedTouches[0]
    gui.dispatch("idleTimer.reset").then()
  }, true);
}

export function getTouch(event) {
  if(event.touches && event.touches[0]) {
    return {
      pageX: event.touches[0].pageX,
      pageY: event.touches[0].pageY,
      x: event.touches[0].clientX,
      y: event.touches[0].clientY
    }
  } else if(event.changedTouches && event.changedTouches[0]) {
    return {
      pageX: event.changedTouches[0].pageX,
      pageY: event.changedTouches[0].pageY,
      x: event.changedTouches[0].clientX,
      y: event.changedTouches[0].clientY,
    }
  } else {
    return {
      pageX: event.pageX,
      pageY: event.pageY,
      x: event.clientX,
      y: event.clientY
    }
  }

  return { x: 0, y: 0, pageX: 0, pageY: 0 }
}

export function getX(event) {
  return getTouch(event).x
}

export function getY(event) {
  return getTouch(event).x
}

function handleTouchStart(event, state) {
  const touch = getTouch(event)

  state.x = touch.x
  state.y = touch.y
}

function insideElement(touch, el) {
  const elRect = el.getBoundingClientRect()

  const top = touch.y - elRect.top
  const left = touch.x - elRect.left

  if(top < 0) {
    return false
  }

  if(left < 0) {
    return false
  }

  if(left > elRect.width) {
    return false
  }

  if(top > elRect.height) {
    return false
  }

  return true
}

function handleTouchEnd(event, state, el, callback) {
  const touch = getTouch(event)
  const moveX = Math.abs(state.x - touch.x)
  const moveY = Math.abs(state.y - touch.y)

  const isInside = insideElement(touch, el)
  const lessMove = moveX < TAP_MAX_DIFF && moveY < TAP_MAX_DIFF
  const bigMove = moveX < 20 && moveY < 20

  if(lessMove || (isInside && bigMove)) {
    callback(event)
  }
}

function unbindInstance(el, instance) {
  if(instance && instance._handleTouchStart) {
    el.removeEventListener("touchstart", instance._handleTouchStart, false)
  }

  if(instance && instance._handleTouchEnd) {
    el.removeEventListener("touchend", instance._handleTouchEnd, false)
  }
}

export const tap = {
  beforeMount(el, binding) {
    if(binding.instance) {
      const state = {}

      unbindInstance(el, binding.instance)

      binding.instance._handleTouchStart = event => handleTouchStart(event, state, el)
      binding.instance._handleTouchEnd = event => handleTouchEnd(event, state, el, binding.value)

      el.addEventListener("touchstart", binding.instance._handleTouchStart, false)
      el.addEventListener("touchend", binding.instance._handleTouchEnd, false)
    }
  },
  beforeUnmount(el, binding) {
    unbindInstance(el, binding.instance)
  }
}
