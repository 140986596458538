<template>
  <Page
      ref="page"
      :class="{ 'with-fab': canAddProduct }"
      id="receipt-page"
      schema="blue"
      :page-end-offset="182"
      :hide-fab-on-scroll="true"
      @page:beforeout="onPageBeforeOut"
      @page:beforein="onPageBeforeIn">

    <!-- Header -->
    <Header>
      <div class="left">
        <f7-link id="receipt-back" @tap="back" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">
        <span>{{ navbarTitle }}</span>
        <div v-if="navbarSubtitle !== ''" class="subtitle">
          {{ navbarSubtitle }}
        </div>
      </div>
      <div v-if="!$helpers.isSelection(receiptData)" class="right">
        <f7-link v-if="$helpers.isCafe()" @tap="openOrderComments" class="icon-only">
          <i class="icon mpi-comment color-black" :class="{'new-comments': newComments}"></i>
        </f7-link>
        <f7-link id="receipt-options" @tap="actions.open()" class="icon-only">
          <i class="icon mpi-options color-black"></i>
        </f7-link>
      </div>

      <template #out>
        <div v-if="receiptData.type === 'refund'" class="receipt-original-container">
          <div class="receipt-original">
            <ReceiptsListItem :clickable="false" :receipt="receiptData.originalReceipt"/>
          </div>
        </div>
      </template>
    </Header>

    <!-- Message -->
    <div class="header-messages">
      <HeaderMessage v-model="receiptMessage.shown" :color="receiptMessage.color" class="flex-end">
        <span v-html="receiptMessage.text"></span>
        <template v-if="receiptMessage.price !== null" #price>
          <span class="price">${{ receiptMessage.price }}</span>
        </template>
      </HeaderMessage>
    </div>

    <!-- Page content -->
    <Inner>
      <template v-if="loading">
        <div class="category-empty">
          <div class="category-empty-text">{{ $t("loading") }}</div>
        </div>
      </template>
      <template v-else>
        <!--  Added goods list  -->
        <div class="receipt-added-goods-page">
          <AddedGoods @delete-click="deleteGood" @good-click="openGood" :receipt="receiptData" :goods="receiptData.goods" />
        </div>
      </template>

      <template #fixed>
        <!--  Add product button  -->
        <f7-fab
          v-if="canAddProduct"
          class="receipt-page-fab"
          id="receipts-float-btn"
          position="right-bottom"
          @tap="goToAddProduct">
          <i class="mpi-md-add"></i>
        </f7-fab>

        <!--  Actions available for receipt  -->
        <ActionsSheet @change:state="onActionsChanged" ref="actions" />
      </template>
    </Inner>
  </Page>
</template>

<script>
  import { ref, computed, watch, onMounted } from "vue"
  import { isNewReceipt, isNewRefund, isReceipt, isSelection, isSplitting } from "../../js/helpers"
  import { sum } from "../../js/filters"

  import ReceiptsListItem from "../../components/ReceiptsPage/ReceiptsListItem"
  import ActionsSheet from "../../components/Receipt/ActionsSheet"
  import AddedGoods from "../../components/Receipt/AddedGoods"

  export default {
    components: { ReceiptsListItem, ActionsSheet, AddedGoods },
    setup() {
      const page = ref(null)
      const loading = ref(true)
      const isPageActive = ref(false)
      const actions = ref(null)
      const goods = ref(gui.receiptData.value.goods)

      const navbarTitle = computed(() => {
        if(gui.receiptData.value.type === "receipt") {
          if(!gui.receiptData.value.displayedId) {
            return gui.translate("receipts.newReceipt")
          } else {
            return `#${ gui.receiptData.value.displayedId }`
          }
        } else if(gui.receiptData.value.type === "refund") {
          if(!gui.receiptData.value.displayedId) {
            return gui.translate("receipts.return")
          } else {
            return `#${ gui.receiptData.value.displayedId }`
          }
        }

        return ""
      })

      onMounted(() => {
        setTimeout(() => {
          loading.value = false
        }, 10)
      })

      const navbarSubtitle = computed(() => {
        if(gui.receiptData.value.hall && gui.receiptData.value.tableName) return `${ gui.receiptData.value.hall }, ${ gui.receiptData.value.tableName }`
        if(gui.receiptData.value.hall) return `${ gui.receiptData.value.hall }`
        if(gui.receiptData.value.tableName) return `${ gui.receiptData.value.tableName }`
        return ''
      })

      // Barcode scanner
      gui.onScanned.value = barcode => gui.dispatch("receipt.scannedBarcode", { barcode })

      const receiptMessage = computed(() => {
        const message = { shown: false, text: "", color: "primary", price: null }

        if(isSplitting(gui.receiptData.value)) {
          message.shown = true
          message.text = gui.translate("receipts.selectForNewOrder")

          if(gui.receiptData.value.goodsCount <= 1 || gui.receiptData.value.selectedCount >= gui.receiptData.value.goodsCount) {
            message.text = gui.translate("receipts.splitUnavailable")
            message.color = "error"
          }
        } else if(isReceipt(gui.receiptData.value) && gui.receiptData.value.sumCertificates > gui.receiptData.value.sumTotal) {
          message.shown = true
          message.color = "error"
          message.text = gui.translate("certificates.totalError", {
            sumTotal: gui.receiptData.value.sumTotal,
            sumCertificates: gui.receiptData.value.sumCertificates,
          })
        } else if(isNewRefund(gui.receiptData.value)) {
          message.shown = true
          message.price = sum(gui.shift?.value?.availableCash)

          if(message.price >= gui.receiptData.value.sumTotal) {
            message.text = gui.translate("receipts.cashAvailable")
          } else {
            message.text = gui.translate("receipts.notEnoughCash")
            message.color = "error"
          }

        } else if(!gui.receiptData.value.isAllSent && gui.receiptData.value.runnerErrors && gui.receiptData.value.runnerErrors.length > 0) {
          message.shown = true
          message.color = "error"
          message.text = gui.translate("receipts.notSentItems", {items: gui.receiptData.value.runnerErrors.join(', ')})
        }

        return message
      })

      watch(gui.receiptData, value => {
        setTimeout(() => {
          goods.value = value.goods
        }, 0)
      })

      const canAddProduct = computed(() => {
        return isNewReceipt(gui.receiptData.value) && !isSelection(gui.receiptData.value)
      })

      return {
        page,
        loading,
        actions,
        navbarTitle,
        canAddProduct,
        navbarSubtitle,
        receiptMessage,
        receiptData: gui.receiptData,
        back: () => gui.dispatch("receipt.back"),
        goToAddProduct: () => gui.dispatch("favourites.selectGoods"),
        deleteGood: good => gui.dispatch("receipt.deleteGood", { uuid: good.receiptGoodUuid }),
        openGood: good => gui.dispatch("receipt.openGood", { uuid: good.receiptGoodUuid }),
        onPageBeforeOut: () => {
          isPageActive.value = false
          gui.totalSheet.value.hide()
        },
        onPageBeforeIn: () => {
          isPageActive.value = true
          gui.totalSheet.value.show()
        },
        onActionsChanged: event => {
          if(isPageActive.value === true) {
            if(event.state === "closed") {
              gui.totalSheet.value.show()
            } else {
              gui.totalSheet.value.hide()
            }
          }
        },
        newComments: computed(() => {
          return gui.receiptData.value.comments.find((comment) => comment.new === true)
        }),
        openOrderComments() {
          gui.navigate('/receipt/comment')
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  #receipt-page.with-fab .receipt-added-goods-page {
    padding-bottom: $total-height + $minimal-padding + $fab-bottom-margin;
  }

  .receipt-added-goods-page {
    padding-top: $minimal-padding;
    padding-bottom: $total-height + $minimal-padding;
  }

  .receipt-page-fab {
    bottom: $total-height + $fab-bottom !important;
  }

  .receipt-original-container {
    .receipts-list-item {
      margin: 0;
      .receipts-list-item-content {
        padding: $padding-arrow !important;
        padding-bottom: $minimal-padding !important;
        padding-top: 0 !important;
      }
    }
  }

  .new-comments {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      height: 8px;
      width: 8px;
      border-radius: 8px;
      background: $error-color;
      display: block;
      top: -2px;
      right: -2px;
    }
  }
</style>
