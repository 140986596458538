import TestPage from '../pages/test-page.vue';

import StartPage from '../pages/start.vue';
import WelcomePage from '../pages/welcome.vue';
import DeviceDisabledPage from '../pages/device-disabled.vue';
import HomePage from '../pages/home.vue';

import ReceiptsPage from '../pages/receipts/receipts.vue';

import ReceiptPage from '../pages/receipt/receipt.vue';
import DiscountPage from '../pages/receipt/discount.vue';
import TipsPage from '../pages/receipt/tips.vue';
import ReceiptAddItemPage from '../pages/receipt/add-item.vue';
import CategoryPage from '../pages/receipt/category.vue';
import TableSelectionPage from '../pages/receipt/table-selection.vue';
import NewWaiterPage from '../pages/receipt/waiter.vue';
import CommentPage from '../pages/receipt/comment.vue';
import CertificatesPage from "../pages/receipt/certificates"
import CertificatesAddPage from "../pages/receipt/certificates/add"

import ShiftsPage from '../pages/shifts/list.vue';
import ViewedShiftPage from '../pages/shifts/viewed-shift';
import ShiftsFullReportPage from '../pages/shifts/full-report.vue';
import Filter from '../pages/shifts/filter.vue';

import ShiftPage from '../pages/shift/index.vue';
import CashInOutPage from '../pages/shift/cash-in-out.vue';

import CheckoutPage from '../pages/checkout/index';
import CheckoutResultPage from '../pages/checkout/result';

import SettingsPage from '../pages/settings.vue'
import TerminalSettingsPage from '../pages/terminal/settings';

import ContactSupportPage from '../pages/support';

import NotFoundPage from '../pages/404.vue';

const routes = [
  {
    path: '/test-page',
    component: TestPage,
    history: false
  },
  {
    path: '/',
    component: StartPage,
    history: false
  },
  {
    path: '/welcome',
    component: WelcomePage,
    history: false
  },
  {
    path: '/device-disabled',
    component: DeviceDisabledPage,
    history: false
  },
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/receipts/list',
    component: ReceiptsPage,
  },
  {
    path: '/receipt',
    component: ReceiptPage,
  },
  {
    path: '/receipt/comment',
    component: CommentPage,
    history: false
  },
  {
    path: '/receipt/discount',
    component: DiscountPage,
  },
  {
    path: '/receipt/tips',
    component: TipsPage,
  },
  {
    path: '/receipt/add-item',
    component: ReceiptAddItemPage,
  },
  {
    path: '/receipt/category',
    component: CategoryPage,
    history: false
  },
  {
    path: '/receipt/certificates',
    component: CertificatesPage,
    history: true
  },
  {
    path: '/receipt/certificates/add',
    component: CertificatesAddPage,
    history: false
  },
  {
    path: '/tables',
    component: TableSelectionPage,
  },
  {
    path: '/receipt/waiter',
    component: NewWaiterPage,
    history: false
  },
  {
    path: '/shifts/list',
    component: ShiftsPage,
  },
  {
    path: '/shifts/view',
    component: ViewedShiftPage,
    history: false
  },
  {
    path: '/shifts/full-report',
    component: ShiftsFullReportPage,
  },
  {
    path: '/:page/filter',
    component: Filter,
    history: false,
  },
  {
    path: '/shift',
    component: ShiftPage,
  },
  {
    path: '/shift/cash-in-out',
    component: CashInOutPage,
    history: false
  },
  {
    path: '/checkout',
    component: CheckoutPage
  },
  {
    path: '/checkout/result',
    component: CheckoutResultPage,
    history: false
  },
  {
    path: '/settings',
    component: SettingsPage,
    history: true
  },
  {
    path: '/terminal/settings',
    component: TerminalSettingsPage,
    history: false
  },
  {
    path: '/support',
    component: ContactSupportPage,
    history: false
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundPage,
  },
];

export default routes;
