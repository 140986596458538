<template>
  <Page schema="transparent" class="manual-discount" id="discount-page">
    <Header>
      <div class="left">
        <f7-link id="discount-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t('receipts.discountPageTitle') }}</div>
    </Header>

    <Inner>
      <div class="discount-content">
        <ManualDiscount
          v-model="manualDiscount.percent"
          :items="manualDiscount.percents"
          :is-sum-editable="manualDiscount.isSumEditable"
          :title="$t('receipts.discountSelect')"
          :sum="manualDiscount.discount"
          :discountAmount="discountAmount"
        />
      </div>
    </Inner>
  </Page>
</template>

<script>
import { ref, watch } from 'vue'
import ManualDiscount from '@/components/Receipt/TotalsSheet/ManualDiscount';
export default {
  name: '',
  components: {
    ManualDiscount,
  },
  setup() {
    const manualDiscountDefault = () => ({
      isSumEditable: false,
      discount: 0,
      percent: '',
      percents: [],
    });
    const discountAmount = ref(0)

    const getManualDiscount = (receipt) => {
      discountAmount.value = receipt.sumAbsDiscount || 0;

      if (
        Array.isArray(receipt?.manualDiscount?.discounts) &&
        receipt?.manualDiscount?.discounts?.length > 0
      ) {
        return {
          isSumEditable: receipt.manualDiscount?.isSumEditable === true,
          discount: receipt.sumDiscount,
          percent: receipt.manualDiscount.percent ? String(receipt.manualDiscount.percent) : '0',
          percents: receipt.manualDiscount.discounts.map((discount) => {
            return { title: `${discount}%`, key: String(discount) };
          }),
        };
      } else {
        return manualDiscountDefault();
      }
    }

    const manualDiscount = ref(getManualDiscount(gui.receiptData.value));

    watch(gui.receiptData, (value) => {
      manualDiscount.value = getManualDiscount(value)
    });

    return {
      manualDiscount,
      discountAmount
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "@/css/variables";

  .discount-content {
    position: relative;
    min-height: 100%;
    background-color: #fff;
    padding-top: $padding-arrow;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .discount-sum {
    font-size: 24px;
    font-weight: 600;
    padding-right: 33px !important;
  }
</style>
