// POS ref store
import { ref } from 'vue';

export const preloaderShown = ref(false)
export const drawerData = ref({ shown: false, disabled: false, _prevent: false })
export const toastData = ref({ shown: false, message: "" })

export const user = ref({})
export const shift = ref({})
export const cashInOut = ref({})
export const bluetoothDevices = ref({})

export const welcomeData = ref({})
export const loginData = ref({})
export const settings = ref({})
export const settingsData = ref({})
export const idleСountdown = ref(null)
export const receipts = ref({})
export const shifts = ref({})
export const fullReport = ref(null)
export const fullReportDates = ref({})
export const viewedShift = ref({})
export const receiptData = ref({})
export const favourites = ref({})
export const category = ref({})
export const tables = ref({})
export const allUsers = ref([])
export const supportData = ref({})

export const doc = ref({})
export const docs = ref([])

export const syncCompletedOn = ref(null)
export const syncShown = ref(false)
export const syncDetails = ref({
  dict: null,
  state: null,
  loaded: 0,
})

export const onScanned = ref(null)
export const onLoginScanned = ref(null)
export const totalSheet = ref({})

export const page = ref('')

export const isIdentifySellerProcess = ref(false)
export const ageVerificationData = ref({ shown: false })
export const periodicAgeVerificationData = ref({ shown: false })
export const printingProcess = ref({ shown: false, error: null, title: "" })


// FOR UI
export const questionUIData = ref({
  shown: false,
  message: "",
  description: null,
  cancelButton: "",
  confirmButton: "",
  resolver: null
})

export const screens = ref({
  shown: false,
  selected: [],
  list: [],
  max: 5,
  resolver: null
})

export const getSumUIData = ref({
  shown: false,
  title: "",
  label: "",
  minSum: 0,
  maxSum: 0,
  defaultSum: "",
  resolver: null
})

export const paymentProcessData = ref({
  shown: false,
  sum: 0,
  title: "",
  canCancel: false,
  description: "",
  canChangePaymentMethod: true,
  resolver: null
})

export const pairingData = ref({
  shown: false
})

export const product = ref({
  shown: false,
})

export const orderComment = ref(false)
export const calculator = ref(false)

export const modifiers = ref({
  shown: false,
  list: [],
})

export const loyaltyData = ref({
  shown: false,
  member: null,
  error: ''
})
