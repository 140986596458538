import * as moment from "moment"

export function round2(num) {
  num = +num
  return Math.round((num + (num > 0 ? 0.0000001 : -0.0000001)) * 100) / 100
}

export function round2floor(num) {
  num = +num
  return Math.floor((num + (num > 0 ? 0.0000001 : -0.0000001)) * 100) / 100
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function base64Encode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1)
    }))
}

export async function delay(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
}

export async function createDisplayedId(doc) {
  return `${doc.deviceId || 0}/${doc._id}`
}

export function isActiveTimeRange(from, to) {
  if(typeof from === "string" && typeof to === "string") {
    const currentTime = Date.now()
    const currentDay = moment(new Date()).format("YYYY-MM-DD")

    if(/^\d{2}:\d{2}$/.test(from) && /^\d{2}:\d{2}$/.test(to)) {
      let beginCheckTime = new Date(currentDay + " " + from + ":00").getTime()
      let endCheckTime = new Date(currentDay + " " + to + ":00").getTime()

      if(!isNaN(beginCheckTime) && !isNaN(endCheckTime)) {
        if(beginCheckTime > endCheckTime) {
          endCheckTime += 86400 * 1000
        }
        if(currentTime >= beginCheckTime && currentTime <= endCheckTime) {
          return true
        }
      }
    }
  }

  return false
}
