class Handler {

  constructor() {
    this.name = ''
    this.enabled = false
    this.options = {}
  }

  update() {}
}
export default Handler
