<template>
  <f7-sheet :opened="screens.shown" class="sheet-support" @sheet:closed="screens.resolver([])" >
    <template v-if="screens.list.length > 0">
      <div class="pictures">
        <div class="pictures-view">
          <div
            v-for="screen in screens.list"
            :key="screen"
            v-tap="() => select(screen)"
            class="picture"
            :class="{ 'inactive': screens.selected.length >= screens.max && !checkIsSelected(screen) }"
            :style="{ 'backgroundImage': `url(${screen.base64})` }"
          >
            <CustomCheckbox :checked="checkIsSelected(screen)" class="picture-checkbox"/>
          </div>
        </div>
      </div>
      <div class="btns">
        <f7-button @tap="screens.resolver(screens.selected)" :disabled="!screens.selected.length" id="support-pictures-confirm-btn" fill round>
          {{ $t("support.selectedQtyNotice", {qty: screens.selected.length, max: screens.max}) }}
        </f7-button>
      </div>
    </template>
    <template v-else>
      <div class="empty-screens">
        <div class="category-empty">
          <div class="category-empty-text">{{ $t("support.emptyScreens") }}</div>
        </div>
      </div>
      <div class="btns">
        <f7-button @tap="screens.resolver(screens.selected)" id="support-pictures-confirm-btn" fill round>
          {{ $t('support.emptyScreensBtn') }}
        </f7-button>
      </div>
    </template>
  </f7-sheet>
</template>

<script>
  import { ref, watch } from "vue"
  import { tap } from "@/js/touches"
  import { click } from "@/js/audio"
  import CustomCheckbox from "../components/CustomCheckbox.vue";

  export default {
    directives: { tap },
    components: { CustomCheckbox },
    setup() {
      const screens = ref(gui.screens);

      watch(gui.screens, value => {
        screens.value = value;
      })

      return {
        screens,
        checkIsSelected(screen) {
          return screens.value.selected.find((item) => item.absoluteFilePath === screen.absoluteFilePath);
        },
        select(screen) {
          click()
          if (screens.value.selected.find((item) => item.absoluteFilePath === screen.absoluteFilePath )) {
            screens.value.selected = screens.value.selected.filter((item) => item.absoluteFilePath !== screen.absoluteFilePath);
          } else {
            if (screens.value.selected.length < screens.value.max) {
              screens.value.selected.push(screen);
            }
          }
        }
     }
    }
  }
</script>

<style lang="scss">
@import '@/css/variables';

.sheet-support {
  background: #FFFFFF;
  border-radius: $border-radius $border-radius 0 0;
  box-sizing: border-box;
  .empty-screens {
    position: relative;
    height: 150px;
    .category-empty-text {
      background-color: $light-bg-color;
    }
  }
  .pictures {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: $minimal-padding;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .pictures-view {
      display: flex;
      width: fit-content;
      height: 100%;
      padding: 0 $minimal-padding - 4px;
      box-sizing: border-box;
      .picture {
        width: 120px;
        height: 213px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        margin: 0 4px;
        border-radius: $border-radius;
        overflow: hidden;
        transition: .2s;
        &.inactive {
          opacity: .5;
          pointer-events: none;
          .picture-checkbox {
            opacity: 0;
            visibility: hidden;
          }
        }
        &:active {
          transform: scale(.96);
        }
        .picture-checkbox {
          position: absolute;
          top: $minimal-padding / 2;
          right: $minimal-padding / 2;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(#fff, .3);
          border-radius: 1px solid rgba(#000, .05);
          transform: .3s;
        }
      }
    }

  }
  .btns {
    display: flex;
    justify-content: center;
    padding: $minimal-padding;
    .button {
      width: $basic-width;
      font-size: $text-size;
      font-weight: bold;
    }
  }
}

</style>
