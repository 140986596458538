<template>
  <Page ref="page" @page:beforein="onPageBeforeIn" @page:scroll="onScroll" schema="blue" class="home-page" id="home-page">
    <Header>
      <div class="home-navbars" :class="{ 'home-add-border': scrolledDown }">
        <div class="home-navbar hidden" :class="{ 'active': !editableMode }">
          <div class="left icon">
            <f7-link id="home-menu" class="ripple-color-black icon-only" @tap="$gui.openPanel()">
              <i class="icon mpi-menu color-black"></i>
            </f7-link>
          </div>
          <div class="center hidden-opacity" :class="{'visible': scrolledDown}">
            <div class="home-navbar-title">{{ user.fullName }}</div>
          </div>
          <div class="right hidden-opacity" :class="{'visible': scrolledDown}">
            <div class="user-image">{{ $filters.getInitials(user.fullName) }}</div>
          </div>
        </div>
        <div class="home-navbar hidden" :class="{ 'active': editableMode }">
          <div class="left icon">
            <f7-link id="home-back" @tap="disableEditableMode" class="ripple-color-black icon-only">
              <i class="icon mpi-back color-black"></i>
            </f7-link>
          </div>
          <div class="center">
            <div class="home-navbar-title">{{$t('home.selectWidgets')}}</div>
          </div>
          <div class="right">
            <f7-link v-if="sortableMode" @tap="disableSortableMode" round class="text">{{ $t("ok") }}</f7-link>
            <f7-link v-else @tap="enableSortableMode" round class="text">{{ $t("home.reorder") }}</f7-link>
          </div>
        </div>
      </div>

      <template #out>
        <div v-if="!editableMode"  class="home-user-item-static" :style="userItemStyles">
          <div class="home-user-item fixed">
            <div class="home-user-item-image">
              <div class="user-image big">{{ $filters.getInitials(user.fullName) }}</div>
            </div>
            <div class="home-user-item-name">{{ user.fullName }}</div>
            <div class="home-user-item-group">{{ $filters.displayGroups(user.groups) }}</div>
            <div class="home-user-item-date">{{ $filters.date($realTime.value) }}</div>
          </div>
        </div>
      </template>
    </Header>

    <Inner>
      <div class="home-content">
        <div class="home-front-part">
          <draggable class="home-widgets-container" animation="150" ghost-class="home-widget-ghost" v-model="widgetsList" handle=".home-widget-draggable" :force-fallback="true" :scroll-sensitivity="200" :disabled="!sortableMode" group="widgets" item-key="key" @start="drag = true" @end="onDragEnd">
            <template #item="{element}">
              <div v-show="!element.hidden && (element.visible || editableMode)" class="home-widget-container">
                <f7-button @tap="clickWidget(element)" :id="`widget-${element.key}`" class="home-widget-inner ripple-color-black" >
                  <div class="home-widget-draggable" :class="{ 'fade-in': sortableMode }">
                    <div class="home-widget-draggable-line"></div>
                    <div class="home-widget-draggable-line"></div>
                  </div>
                  <div class="home-widget-checkbox-container">
                    <CustomCheckbox :visible="editableMode" :checked="element.visible" />
                  </div>
                  <div class="home-widget-icon">
                    <i :class="`mpi-${element.icon}`"></i>
                  </div>
                  <div class="home-widget-title">{{ $t(element.name) }}</div>
                </f7-button>
              </div>
            </template>
            <template #footer>
              <div class="home-widget-container" :class="{'center': visibleWidgets === 0}" v-if="!editableMode">
                <f7-button @tap="enableEditableMode" id="add-widget-button" class="home-widget-inner ripple-color-black">
                  <div class="home-widget-icon">
                    <i class="mpi-add_widget"></i>
                  </div>
                  <div class="home-widget-title">{{$t('home.addWidget')}}</div>
                </f7-button>
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </Inner>

    <Footer class="home-footer" :class="{'visible-footer': editableMode}">
      <div class="footer-buttons">
        <f7-button @tap="disableEditableMode" class="fill">{{ $t("home.confirm") }}</f7-button>
      </div>
    </Footer>
  </Page>
</template>

<script>
  import { ref, watch, onUnmounted, onMounted, computed, nextTick } from "vue"
  import { dispatch } from "../js/gui"

  import draggable from "vuedraggable"
  import configWidgets from "../js/config/widgets-list"
  import CustomCheckbox  from "../components/CustomCheckbox"
  import Header from "../components/Page/Header"

  const defaultWidgetsVisible = ["new-receipt", "receipts", "toggle-shift"]

  export default {
    components: { Header, draggable, CustomCheckbox },
    setup() {
      const page = ref(null)
      const drag = ref(null)
      const maxScroll = ref(0)
      const sortableMode = ref(false)
      const editableMode = ref(false)
      const widgetsList = ref(getWidgets())
      const scrolledDown = ref(false)
      const hiddenPercent = ref(0)
      const userItemStyles = ref({})
      const _mounted = ref(false)

      // Barcode scanner
      gui.onScanned.value = barcode => gui.dispatch("receipt.createReceiptWithQuery", { barcode })

      watch(editableMode, () => nextTick(updateMaxScroll))

      watch(() => gui.user.value.login, login => {
        widgetsList.value = getWidgets()
        nextTick(() => {
          scrollTop()
          updateMaxScroll()
        })
      })

      function getWidgets () {
        const saved = getSavedWidgetsSettings()
        const widgets = configWidgets.map(widget => {
          const sort = saved?.sortable?.indexOf(widget.key) || 0

          widget.sort = sort === -1 ? configWidgets.length + 1 : sort
          widget.visible = saved?.visible?.includes(widget.key) || false

          return widget
        })

        widgets.sort((a, b) => a.sort < b.sort ? -1 : 1)

        return widgets
      }

      const clickWidget = widget => {
        if(editableMode.value) {
          toggleWidget(widget)
        } else if(!editableMode.value && typeof widget.do === "function") {
          widget.do(this)
        }
      }

      const toggleWidget = async widget => {
        if(editableMode.value) {
          widget.visible = !widget.visible
          await save()
        }
      }

      const enableSortableMode = () => {
        sortableMode.value = true
        gui.disableSwipePanel()
      }

      const disableSortableMode = () => {
        sortableMode.value = false
        gui.enableSwipePanel()
      }

      const enableEditableMode = () => {
        disableSortableMode()
        editableMode.value = true
        scrollTop()
      }

      const disableEditableMode = () => {
        disableSortableMode()
        editableMode.value = false
        scrollTop()
      }

      const saveWidgetPosition = widget => {
        widget._index = getWidgetIndex(widget)
      }

      const getWidgetIndex = widget => {
        let index = 0
        for(let item of widgetsList.value) {
          if(item.key === widget.key) {
            break
          }
          index++
        }

        return index
      }

      const onDragEnd = async () => {
        drag.value = false
        await  save()
      }

      const save = async () => {
        const visible = widgetsList.value.filter(widget => widget.visible).map(widget => widget.key)
        const sortable = widgetsList.value.map(widget => widget.key)

        await dispatch("user.merge", { widgetsInfo: { visible, sortable } })
      }

      function getSavedWidgetsSettings() {
        const defaultWidgetsSettings = { visible: defaultWidgetsVisible, sortable: [] }

        try {
          if(gui.user.value.widgetsInfo && Array.isArray(gui.user.value.widgetsInfo.visible) && Array.isArray(gui.user.value.widgetsInfo.sortable)) {
            return gui.user.value.widgetsInfo
          }
        } catch(e) {}

        return defaultWidgetsSettings
      }

      const onScroll = event => {
        if(maxScroll.value > 190) {
          const scrollTop = event.target.scrollTop
          const percent = scrollTop * 100 / 60

          hiddenPercent.value = percent < 0 ? 0 : (percent > 100 ? 100 : percent)
          scrolledDown.value = scrollTop > 30

          userItemStyles.value = {
            marginTop: `${ -30 - hiddenPercent.value * -30 / 100 }px`,
            height: `${ 175 - hiddenPercent.value * 175 / 100 }px`
          }
        }
      }

      const scrollTop = () => {
        page.value.scrollTo(0)
        onScroll({ target: { scrollTop: 0 } })
      }

      const updateMaxScroll = () => {
        if(page.value) {
          maxScroll.value = page.value.getMaxScroll()
        }
      }

      const visibleWidgets = computed(() => {
        return widgetsList.value.filter(widget => widget.visible).length
      })


      onUnmounted(() => {
        _mounted.value = false
      })

      onMounted(() => {
        _mounted.value = true

        gui.callbacks.onWidgetControl = () => {
          enableEditableMode()
        }
      })

      const onPageBeforeIn = () => {
        nextTick(() => {
          updateMaxScroll()
          gui.enableSwipePanel()
        })
      }

      return {
        page,
        user: gui.user,
        drag,
        sortableMode,
        editableMode,
        widgetsList,
        scrolledDown,
        hiddenPercent,
        userItemStyles,

        onScroll,
        onDragEnd,
        clickWidget,
        toggleWidget,
        onPageBeforeIn,
        visibleWidgets,
        disableEditableMode,
        enableEditableMode,
        disableSortableMode,
        enableSortableMode,
        saveWidgetPosition
      }
    }
  }
</script>

<style lang="sass">
  @import "../css/pages/home"
</style>
