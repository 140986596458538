<template>
  <div class="pin-container" :class="{ 'show-eye': showEye }">
    <div class="pin-container-left">
      <div @click="focus" class="pin-background input-item" :class="{ 'error': error }">
        <div class="pin-circles">
          <div v-for="(_, index) in PINLength" :key="index" class="pin-item-container">
            <div v-if="isVisiblePassword && isPINIndexFilled(index)" class="pin-number">{{ getPINNumberByIndex(index) }}</div>
            <div v-else class="pin-circle" :class="{ 'filled': isPINIndexFilled(index) }"></div>
          </div>
        </div>
      </div>
      <input :id="id" ref="input" @input="onUpdate" type="text" :inputmode="inputMode" v-model="PIN" autocomplete="off" />
    </div>
    <div v-if="showEye" class="pin-container-right">
      <f7-button round @tap="togglePasswordVisibility" class="pin-eye">
        <i :class="isVisiblePassword ? 'mpi-md-visibility-off' : 'mpi-md-visibility'"></i>
      </f7-button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      id: { type: String, default: "" },
      modelValue: { type: String, default: "" },
      PINLength: { type: Number, default: 4 },
      showEye: { type: Boolean, default: false },
      inputMode: { type: String, default: "numeric" },
      error: { type: Boolean, default: false },
      visiblePasswordForce: { type: Boolean, default: false }
    },
    data() {
      return {
        PIN: this.modelValue,
        visiblePassword: false
      }
    },
    methods: {
      isEmpty() {
        return this.PIN == ""
      },
      press(char) {
        this.PIN = this.PIN + char
        this.onUpdate()
      },
      backspace() {
        this.PIN = this.PIN.slice(0, -1)
        this.onUpdate()
      },
      onUpdate() {
        const saved = this.PIN

        this.PIN = this.PIN.replace(/[^\d]+/g, "").slice(0, this.PINLength)
        this.$emit("update:modelValue", this.PIN)

        if(saved.length === this.PINLength) {
          this.$emit("pin-entered", this.PIN)
        }
      },
      togglePasswordVisibility() {
        this.visiblePassword = !this.visiblePassword
        this.$nextTick(() => {
          this.focus()
        })
      },
      getPINNumberByIndex(index) {
        return this.PIN.slice("")[index]
      },
      isPINIndexFilled(index) {
        return (this.PIN.length - 1) >= index
      },
      blur() {
        this.$refs?.input?.blur()
      },
      focus() {
        this.$refs?.input?.focus()
      }
    },
    computed: {
      isVisiblePassword() {
        return this.visiblePasswordForce || this.visiblePassword
      }
    },
    watch: {
      modelValue(PIN) {
        this.PIN = PIN
      }
    }
  }
</script>

<style type="text/css" lang="scss" scoped>
  @import "./src/css/variables";

  .pin-container {
    display: flex;
    align-items: center;
  }

  .pin-container input {
    height: 0 !important;
    opacity: 0 !important;
  }

  .pin-container.show-eye {
    position: relative;
    .pin-container-left {
      .pin-background {
        padding-right: 42px;
      }
    }
    .pin-container-right {
      padding-left: 14px;
      position: absolute;
      top: 0px;
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: $input-height;
      min-width: $input-height + 15px;
      box-sizing: border-box;
    }
  }

  .pin-container .pin-container-right .pin-eye {
    color: rgba(0, 0, 0, 0.6);
    max-width: 36px;
    height: 36px;
    box-sizing: border-box;
    border-radius: 50%;
    padding: 0;
    font-size: 18px;
  }

  .pin-container .pin-container-left {
    position: relative;
    width: 100%;
  }

  .pin-container input {
    caret-color: transparent;
  }

  .pin-container .pin-background {
    align-items: center;
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }

  .pin-container .pin-background .pin-circles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .pin-container .pin-background .pin-circles .pin-item-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pin-container .pin-background .pin-circles .pin-number {
    font-weight: bold;
    font-size: 18px;
  }

  .pin-container .pin-background .pin-circles .pin-circle {
    width: 12px;
    height: 12px;
    border: 3px solid $text-secondary;
    transform-origin: center;
    border-radius: 50%;
    box-sizing: border-box;
    transition: .3s;
  }

  .pin-container .pin-background .pin-circles .pin-circle.filled {
    background-color: $text-color;
    border-color: $text-color;
    transform: scale(1.1)
  }
</style>
