import sha256 from "sha256"

export function createCacheHandler(expired, handler, maxCapacity = 900) {
  const _innerCache = new Map()

  return async function (...params) {
    const id = sha256(params.join(":"))

    if(_innerCache.has(id)) {
      const innerItem = _innerCache.get(id)

      if(innerItem.expired > Date.now()) {
        return JSON.parse(innerItem.value)
      }
    }

    const result = await handler(...params)

    // Remove old item
    if(_innerCache.size > maxCapacity) {
      const firstItemId = _innerCache.values().next().value.id
      _innerCache.delete(firstItemId)
    }

    _innerCache.set(id, {
      id,
      value: JSON.stringify(result),
      expired: Date.now() + expired
    })

    return result
  }
}
