<template>
  <Page
      schema="blue"
      class="comments-page"
      id="comments-page"
      @page:beforeout="beforeout">
    <Header>
      <div class="left">
        <f7-link id="comment-back" @tap="goBack" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">
        <span>{{ $t('receipts.orderComment') }}</span>
        <div v-if="navbarSubtitle !== ''" class="subtitle">
          {{ navbarSubtitle }}
        </div>
      </div>
    </Header>

    <Inner>
      <div class="wrap">
        <div class="comments-block" ref="commentsBlock">
          <template v-if="comments?.length > 0">
            <div v-for="comment in comments" :key="comment.id" class="comment-wrap" >
              <div class="form-group">
                <f7-button :disabled="!isEditable" v-if="comment.new" :id="`del-btn-${comment.id}`" @tap="deleteComment(comment.id)"><i class="icon mpi-del color-black"></i></f7-button>
                <div v-else class="spacer"></div>
                <div class="comment" :class="{'not-sent': !comment.sentFull, 'update': activeCommentId === comment.id}">
                  <div class="comment-text" @click="setActive(comment)" :id="`comment-${comment.id}`">
                    {{ comment.text }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="category-empty">
              <div class="category-empty-text">
                {{ $t("receipts.commentsIsMissing") }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="isEditable" class="comments-footer">
        <div class="form-group">
          <f7-input
            :outline="false"
            class="order-comment-input"
            input-id="order-comment-input"
            resizable
            type="textarea"
            v-model:value="commentText"
            @keyup.enter="blurComment"
            :placeholder="$t('receipts.addComment')"
          />
          <f7-button v-if="updating" :disabled="commentText===''" id="update-order-comment-button" @tap="updateComment">{{ $t('receipts.update') }}</f7-button>
          <f7-button v-else :disabled="commentText===''" id="add-order-comment-button" @tap="addComment">{{ $t('receipts.add') }}</f7-button>
        </div>
      </div>
    </Inner>
  </Page>
</template>

<script>
  import { ref, watch, computed } from "vue"
  import { isNewReceipt } from '../../js/helpers'

  export default {
    setup() {
      const commentText = ref('');
      const activeCommentId = ref('');
      const updating = ref(false);
      const comments = ref(gui.receiptData.value.comments);

      watch(gui.receiptData, value => {
        comments.value = value.comments
      })

      const navbarSubtitle = computed(() => {
        if(gui.receiptData.value.hall && gui.receiptData.value.tableName) return `${ gui.receiptData.value.hall }, ${ gui.receiptData.value.tableName }`
        if(gui.receiptData.value.hall) return `${ gui.receiptData.value.hall }`
        if(gui.receiptData.value.tableName) return `${ gui.receiptData.value.tableName }`
        return ''
      })

      const cancelUpdate = () => {
        updating.value = false;
        commentText.value = '';
        activeCommentId.value = '';
      };

      const isEditable = computed(() => isNewReceipt(gui.receiptData.value));

      return {
        commentText,
        comments,
        navbarSubtitle,
        updating,
        activeCommentId,
        isEditable,
        goBack() {
          gui.navigate('/receipt');
        },
        updateComment() {
          gui.dispatch('runner.updateOrderComment', commentText.value, activeCommentId.value);
          cancelUpdate();
        },
        deleteComment(id) {
          cancelUpdate();
          gui.dispatch('runner.deleteOrderComment', id);
        },
        setActive(comment) {
          if (!comment.new || !isEditable.value) return;
          if (activeCommentId.value === comment.id) {
            cancelUpdate();
            return;
          }
          updating.value = true;
          commentText.value = comment.text;
          activeCommentId.value = comment.id;
        },
       beforeout() {
          commentText.value = '';
        }
      }
    },
    methods: {
      async addComment() {
        await gui.dispatch('runner.addOrderComment', this.commentText);
        this.commentText = '';
        this.$refs.commentsBlock.scrollTop = this.$refs.commentsBlock.scrollHeight;
      },
      blurComment() {
        this.addComment();
        document.activeElement?.blur();
      },
    },
    mounted() {
      this.$refs.commentsBlock.scrollTop = this.$refs.commentsBlock.scrollHeight;
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";
  .comments-page {
    .page-content {
      display: flex;
      flex-direction: column;
      .wrap {
        height: calc(100% - 40px);
      }
      .comments-block {
        padding-right: 20px;
        height: 100%;
        overflow: auto;

        .comment-wrap {
          margin-top: 6px;
          &:first-child {
            margin-top: 15px
          }
          &:last-child {
            margin-bottom: 15px
          }
          .form-group {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .spacer {
              min-width: 20px;
            }
            .button {
              font-size: 9px;
              min-width: 53px;
              max-width: 53px;
            }
            .comment {
              display: flex;
              flex-direction: column;

              .comment-text {
                border-radius: $border-radius $border-radius 0px $border-radius;
                background-color: #FFFFFF;
                padding: 8px 9px 8px 18px;
                font-size: 16px;
                line-height: 118.75%;
                word-break: break-word;
              }

              &::after {
                content: '';
                width: 0;
                height: 0;
                border-top: 5px solid #FFFFFF;
                border-left: 5px solid transparent;
                position: relative;
                left: calc(100% - 5px);
              }

              &.not-sent {
                .comment-text {
                  background-color: #EDEDF2;
                }
                &::after {
                  border-top: 5px solid #EDEDF2;
                  border-left: 5px solid transparent;
                }
              }
              &.update {
                .comment-text {
                  background-color: $error-color;
                }
                &::after {
                  border-top: 5px solid $error-color;
                  border-left: 5px solid transparent;
                }
              }
            }
          }
        }
      }
    }
    .comments-footer {
      min-height: 40px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      background-color: #FFFFFF;
      .form-group {
        display: grid;
        grid-template-columns:  1fr 90px;

        .order-comment-input {
          &.input-focused::after {
            display: none;
          }
          .resizable {
            background-color: $input-color;
            box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
            font-size: $text-size;
            line-height: $text-size;
            height: 40px;
            padding: 10px;
            box-sizing: border-box;
            &:focus-within {
              box-shadow: inset 0px 0px 4px $input-focus-shadow
            }
          }
        }

        .button {
          color: #FFFFFF;
          font-size: $text-size;
          background-color: $primary-color;
          height: 100%;
          width: 100%;
          text-transform: none;
          border-radius: 0px;
        }
      }
    }
  }
</style>
