import { computed } from "vue"
import { sum, currency } from "./filters"
import { isActiveTimeRange } from "@/js/utils"

export const MAX_GOODS_COUNT = 999

export function isReceipt(doc) {
  return doc.type === "receipt"
}

export function isRefund(doc) {
  return doc.type === "refund"
}

export function isNewReceipt(doc) {
  return doc.type === "receipt" && doc.state === "active"
}

export function isCompletedReceipt(doc) {
  return ["completed", "received"].includes(doc.state) && doc.type === "receipt"
}

export function isNewRefund(doc) {
  return doc.type === "refund" && doc.state === "active"
}

export function isCompletedRefund(doc) {
  return ["completed", "received"].includes(doc.state) && doc.type === "refund"
}

export function isSelection(doc) {
  return doc?._selection === true
}

export function isSplitting(doc) {
  return isSelection(doc) && doc._selectionType === 0
}

export function isSplitAvailable(receipt) {
  if(receipt.selectedCount === 0 || receipt.goodsCount <= 1) {
    return false
  }

  if(receipt.selectedCount >= receipt.goodsCount) {
    return false
  }

  return true
}

export function isCafe() {
  return gui.user.value.store?.business?.key === "cafe"
}

export function getDecimalSeparator(locale) {
    const numberWithDecimalSeparator = 1.1;
    return Intl.NumberFormat(locale)
        .formatToParts(numberWithDecimalSeparator)
        .find(part => part.type === 'decimal')
        .value;
}

export function getPaymentsList(receipt) {
  const details = []

  if(receipt.sumChange > 0.001) {
    details.push({ title: "receipts.change", key: "change", value: currency(sum(receipt.sumChange)) })
  }

  if(receipt.sumCertificates > 0.001) {
    details.push({ title: "certificates.actionTitle", key: "certificates", value: currency(sum(receipt.sumCertificates)) })
  }

  details.push({ title: "receipts.subTotal", key: "subTotal", value: currency(sum(receipt.sum.subTotal)) })

  if(receipt.sumTaxes > 0.001) {
    details.push({ title: "receipts.taxes", key: "taxes", value: currency(sum(receipt.sumTaxes)) })
  } else if(receipt.sumTaxesExempt > 0.001) {
    details.push({ title: "receipts.sumTaxesExempt", key: "taxes", value: currency(sum(receipt.sumTaxesExempt)) })
  }

  if(receipt.sumDiscount > 0.001) {
    details.push({ title: "receipts.discount", key: "discount", value: currency(sum(receipt.sumDiscount)) })
  }

  details.push({ title: "receipts.total", key: "total", value: currency(sum(receipt.sumTotal)), total: true })

  if(receipt.sumTips > 0.001) {
    details.push({ title: "receipts.tips", key: "tips", value: currency(sum(receipt.sumTips)) })
  }

  return details
}

export async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const orderOrReceipt = (receipt, order, translate = false) => computed(() => {
  const text = isCafe() ? order : receipt

  return translate ? gui.translate(text) : text
})

export function goodDetails(good, receipt) {
  const details = []

  if(good?.verifyRequired && isNewReceipt(receipt)) {
    details.push("receipts.ageVerificationRequired")
  }

  if(good?.usedFixPrice || good?.usedMinPrice) {
    details.push("receipts.bottomPrice")
  }

  return details
}

export function isGoodLocked(good, forTime = null) {
  if(good.timeRestrictions && good.count > 0) {
    if(!isActiveTimeRange(good.timeRestrictionsFrom, good.timeRestrictionsTo, forTime)) {
      return true
    }
  }

  return false
}
