<template>
  <div class="left-panel">
    <div class="left-panel-container">
      <div class="left-panel-header">
        <div v-if="user?.company?.logoId" class="left-panel-header-brand-name">
          <AsyncImage ref="logo" :id="user?.company?.logoId" align="left" />
        </div>
        <div v-else class="left-panel-header-brand-name default"></div>
        <div class="left-panel-header-user">
          <div class="left-panel-header-user-name" @touchend="openDevSettings">{{ user.fullName }}</div>
          <div class="left-panel-header-user-group">{{ $filters.displayGroups(user.groups) }}</div>
        </div>
      </div>
      <div class="left-panel-body">
        <f7-list accordion-list>
          <template v-for="(item, index) of menuItems" :key="index">
            <f7-list-item v-if="Array.isArray(item.menu)" :id="getId(item.title)" accordion-item :clickable="true">
              <template #inner>
                <div class="left-panel-angle-right">
                  <i class="mpi-expand2"></i>
                </div>
                <div class="item-title">{{ $t(item.title) }}</div>
              </template>
              <f7-accordion-content>
                <f7-list>
                  <f7-list-item v-for="(subitem, jindex) of item.menu" :key="jindex" :id="getId(subitem.title)" @tap="doItemAction($event, subitem)" :clickable="true">
                    <template #inner>
                      <div class="item-title">{{ $t(subitem.title) }}</div>
                    </template>
                  </f7-list-item>
                </f7-list>
              </f7-accordion-content>
            </f7-list-item>
            <f7-list-item v-else :id="getId(item.title)" @tap="doItemAction($event, item)" :clickable="true">
              <template #inner>
                <div class="item-title">{{ $t(item.title) }}</div>
              </template>
            </f7-list-item>
          </template>
        </f7-list>
      </div>
      <div class="left-panel-footer">
        <f7-list>
          <f7-list-item id="menuitems-logout" @tap="logout" :clickable="true">
            <template #inner>
              <div class="item-title">{{ $t('login.logout') }}</div>
            </template>
          </f7-list-item>
        </f7-list>
      </div>
    </div>
  </div>
</template>

<script>
  import { watch } from "vue"

  import AsyncImage from "@/components/AsyncImage"
  import menuItems from "../js/config/menu-items"

  export default {
    components: { AsyncImage },
    data() {
      return {
        user: gui.user,
        menuItems
      }
    },
    methods: {
      getId(title) {
        return title.toLowerCase().replace(/\./g, "-")
      },
      doItemAction(event, item) {
        gui.hidePanel()

        if(typeof item.do === "function") {
          item.do(this)
        }
      },
      async logout() {
        if(await gui.questionUI({
          message: this.$t("login.sureLogout"),
          cancelButton: this.$t('login.sureLogoutNo'),
          confirmButton: this.$t('login.sureLogoutYes')
        })) {
          gui.hidePanel(false)
          await gui.dispatch("user.logout")
        }
      },
      openDevSettings() {
        if(process.env.VUE_APP_IS_TEST) {
          gui.hidePanel(false)
          gui.navigate("/terminal/settings")
        }
      }
    },
    mounted() {
      watch(() => gui.syncShown.value, value => {
        if(!value && this.$refs.logo) {
          this.$refs.logo.update()
        }
      })
    }
  }
</script>
