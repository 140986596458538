import sha256 from "sha256"
import dicts from "../dicts"
import * as auth from "./helper"

import { error, success } from "../libs/response.js"

export async function getUserData() {
  if(localStorage.getItem("OFFLINE_USERNAME") && localStorage.getItem("OFFLINE_PIN")) {
    const user = await dicts.users.getOne({ login: localStorage.getItem("OFFLINE_USERNAME"), hashedPin: localStorage.getItem("OFFLINE_PIN"), enabled: true })
    if(user) {
      return success("Data received", "rpc.dataReceived", {
        authData: await auth.getPreparedUserResponse(user)
      })
    } else {
      return auth.NOT_AUTHORIZED
    }
  } else {
    return auth.NOT_AUTHORIZED
  }
}

export async function identifySeller({ login }) {
  if(typeof login === "string") {
    let barcode = login.replace(/[^\d]/g, "")

    if(barcode.length === 12) {
      barcode = "0" + barcode
    }

    return auth.identifySeller({ or: { login, barcode } })
  } else {
    return error(400, "Please enter a username", "rpc.pleaseEnterLogin")
  }
}

export async function identifySellerBarcode({ barcode }) {
  if(typeof barcode === "string") {
    return auth.identifySeller({ barcode })
  } else {
    return error(400, "Please enter a barcode", "rpc.pleaseEnterBarcode")
  }
}

export async function loginSeller({ login, pin }) {
  if(typeof login === "string" && typeof pin === "string") {
    const user = await dicts.users.getOne({ login, enabled: true })
    if(user && user.pinDefined === true) {
      return await auth.checkLoginWithAttempts(user, "all.cashier.login", () => user.hashedPin === sha256(pin))
    } else {
      return auth.NOT_FOUND_RESPONSE
    }
  } else {
    return error(400, "Please enter a username and PIN-code", "rpc.enterLoginAndPIN")
  }
}