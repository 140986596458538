<template>
  <div class="tab" :class="{ 'tab-active': tabActive }">
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      tabActive: { type: Boolean, default: false }
    }
  }
</script>