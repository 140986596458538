<template>
  <div class="added-group added-one-item" :class="{ 'opened': group.opened, 'deleted': isDeleted, 'sent': $helpers.isCafe() && !group.sent }">
    <div class="added-group-inner">
      <AddedGood
          @delete-click="deleteGroup"
          @good-click="toggle"
          :receipt="receipt"
          :good="group"
          :group-component="true" />
    </div>
    <div v-if="group.opened" class="added-group-list">
      <template v-for="good of group.goods" :key="good.receiptGoodUuid">
        <AddedGood
          @delete-click="$emit('delete-click', $event)"
          @good-click="$emit('good-click', $event)"
          :good="good"
          :receipt="receipt" />
      </template>
    </div>
    <div class="added-group-expand">
      <f7-button @tap="toggle" class="added-group-expand-button fill" :class="{'opened': group.opened}">
        <div class="added-group-expand-left"><i class="mpi-expand2"></i></div>
        <div class="added-group-expand-right">{{ $t(group.opened ? "receipts.closeGroup" : "receipts.openGroup") }}</div>
      </f7-button>
    </div>
  </div>
</template>

<script>
  import AddedGood from "@/components/Receipt/AddedGood"
  import { isCompletedRefund, isNewRefund, isReceipt } from "@/js/helpers"
  export default {
    components: { AddedGood },
    props: {
      group: { type: Object, default: () => ({}) },
      receipt: { type: Object, default: () => ({}) }
    },
    methods: {
      deleteGroup() {
        this.$gui.dispatch("receipt.deleteGroup", this.group._id)
      },
      toggle() {
        this.$gui.dispatch("receipt.toggleGroup", this.group._id)
      }
    },
    computed: {
      isDeleted() {
        if(this.group.count === 0 && isReceipt(this.receipt)) {
          return true
        }

        if(this.group.countToRefund === 0 && isNewRefund(this.receipt)) {
          return true
        }

        if(this.group.count === 0 && isCompletedRefund(this.receipt)) {
          return true
        }

        return false
      },
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";
  .added-group.deleted {
    opacity: .5;
    .added-item.deleted {
      opacity: 1;
    }
  }

  .added-group.sent {
    .added-item.deleted {
      background-color: $light-bg-color;
    }
  }

  .added-group-expand-button {
    height: 30px !important;
    font-weight: bold;
    background-color: $primary-color !important;
    &.opened {
      .added-group-expand-left {
        transform: rotate(180deg);
      }
    }
    .added-group-expand-left {
      margin-right: 10px;
      transform-origin: center;
      transition: .3s;
      height: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .added-group {
    background-color: $main-bg-color;
    border-radius: $border-radius;
    box-shadow: $item-shadow;
    overflow: hidden;
    &.opened .added-group-inner {
      box-shadow: 0 10px 10px rgba(#000, .12);
    }
    .swipe-item-right .added-good-swipe-button:last-child,
    .swipe-item-left .added-good-swipe-button:first-child {
      border-radius: 0 !important;
    }
    .swipe-item-navigation {
      padding: 0 !important;
    }
    .added-group-list .added-one-item {
      margin-bottom: 0 !important;
    }
    .added-group-list .added-item {
      border-top: 1px solid rgba(#000, .12);
    }
    .added-group-list .added-item, .added-group-inner .added-item {
      margin-bottom: 0 !important;
      box-shadow: none !important;
      border-radius: 0 !important;
    }
    .added-group-inner {
      position: relative;
      z-index: 2;
      box-shadow: 0 10px 10px rgba(#000, 0);
      transition: .3s;
    }
  }
</style>
