export async function checkAccessAvailable(verifyPermission, userPermissions) {
  const splittedVerifyPermission = verifyPermission.split(".")

  for(let i = 0; i < splittedVerifyPermission.length; i++) {
    const nextVerifyPermission = splittedVerifyPermission.slice(0, i + 1).join(".")
    if(userPermissions.includes(nextVerifyPermission)) {
      return true
    }
  }

  return false
}