import {
  PIECES,
  superForPromo,
  applyPromoPercent,
  splitAndHandleGoodsPieces
} from "./helpers"

export default async function (promotion, receipt) {
  if(!await superForPromo(promotion, receipt)) {
    return false
  }

  const discountPercent = Number(promotion.options.discountPercent)

  if(!isNaN(discountPercent) && discountPercent > 0) {
    const mainGoodsIds = promotion.options.mainGoodsIds || []
    const actionGoodsIds = promotion.options.actionGoodsIds || []

    if(actionGoodsIds.length > 0 && mainGoodsIds.length > 0) {
      await splitAndHandleGoodsPieces(PIECES.SINGLE, receipt, mainGoodsIds, actionGoodsIds, promotion.options.maxQty, async good => {
        await applyPromoPercent(promotion, good, discountPercent)
      })
    }
  }
}
