<template>
  <f7-list class="options-list-wrap" no-hairlines no-hairlines-between>
    <ul class="options-list">
      <f7-list-item
        class="options-list-item"
        v-for="option in all"
        :clickable="true"
        :key="option._id">
        <div class="options-list-item-content ripple" @touchstart="$emit('onChange', option.text)">
          <CustomCheckbox 
            :checked="selected?.includes(option.text)"
          />
            <div class="options-list-item-title">
              {{ option.text }}
            </div>
        </div>
      </f7-list-item>
    </ul>
  </f7-list>
</template>
<script>
  import CustomCheckbox from '../CustomCheckbox';

  export default {
    components: { CustomCheckbox },
    emits: ['onChange'],
    props: {
      all: {
        type: Array,
        default: () => [],
      },
      selected: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>
<style lang="scss">
  @import "@/css/variables";
  .options-list-wrap {
    margin-top: 0px;
    .options-list {
      background: none;
      padding: 0;
      .options-list-item {
        border-bottom: 1px solid $second-bg-color;
        overflow: hidden;
        * {
          padding: 0;
        }
        .options-list-item-content {
          display: flex;
          align-self: stretch;
          flex-grow: 1;
          align-items: center;
          padding: 0 $padding-arrow;
          overflow: hidden;
          position: relative;
          .options-list-item-title {
            font-size: $text-size;
            line-height: 300%;
            color: #000000;
            padding-left: 20px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
</style>