<template>
  <div class="filter-block">
    <div v-if="title !== ''" class="filters-group-title">{{ title }}</div>
    <div
      class="dates-input-wrap"
      @click="open"
    >
      <div class="dates-input input-item"  :class="{ error: error, focus: isOpen }">
        <div
          v-if="displayedValueFrom !== ''"
          :id="`date-${id}`"
        >
          {{ displayedValueFrom }} <span v-if="displayedValueTo!==''">{{ ` - ${displayedValueTo}`}}</span>
        </div>
        <div v-else class="dates-input-placeholder">
          {{ placeholder }}
        </div>
      </div>
    </div>
  </div>
  <CalendarSheet 
    ref="calendar"
    :opened="isOpen" 
    @sheet:closed="onClose" 
    v-model:valueFrom="valueFrom"
    v-model:valueTo="valueTo"
    v-model:displayedValueFrom="displayedValueFrom"
    v-model:displayedValueTo="displayedValueTo"
    :id="`date-calendar-${id}`"
    isRange
  />
</template>

<script>
import CalendarSheet from "@/components/CalendarSheet.vue"
import { date as formatDate } from '../../js/filters';

export default {
  components: { CalendarSheet },
  props: {
    from: { type: [Number, String] },
    to: { type: [Number, String] },
    placeholder: { type: String, default: "" },
    id: { type: String, required: true },
    error: { type: Boolean, default: false },
    title: { type: String, default: "" },
  },
  emits: ["update:from", "update:to"],
  data() {
    return {
      isOpen: false,
      displayedValueFrom: this.from !== "" ? formatDate(this.from, { dateShort: true }) : "",
      displayedValueTo: this.to !== "" ? formatDate(this.to, { dateShort: true }) : "",
      valueFrom: this.from || '',
      valueTo: this.to || '',
      date: undefined,
    };
  },
  watch: {
    from(value) {
      if (value === '') this.$refs.calendar.reset();
      this.valueFrom = value;
    },
    to(value) {
      this.valueTo = value;
    },
    valueFrom(value) {
      this.$emit("update:from", value);
    },
    valueTo(value) {
      this.$emit("update:to", value);
    },
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    onClose() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/css/variables";
.filter-block {
  .dates-input-wrap {
    border-radius: $input-border-radius;
    height: $input-height;
    width: 100%;
    display: flex;
    align-items: center;

    .dates-input {
      padding-left: 21px;
      font-size: 18px;
      line-height: 118.75%;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    .dates-input-placeholder {
      font-weight: normal;
      color: #a7adbf;
    }
  }
}
</style>