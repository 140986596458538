<template>
  <div ref="container" class="async-image-container" :class="{ [align]: true, 'small': small, 'cover': image?.sizeType === 'cover' }">
    <div v-if="error" class="async-error">
      <div class="async-error-icon">
        <i class="mpi-md-warning-amber"></i>
      </div>
      <div class="async-error-title">{{ $t("imageUploadError") }}</div>
    </div>
    <div v-else-if="image" class="async-image" :class="{ 'blend-multiply': blendMultiply, 'cover': image.sizeType === 'cover' }" :style="{ 'backgroundImage': `url(${image.thumbnailBase64})` }"></div>
    <div v-else-if="imageEmpty" class="async-image-empty"></div>
    <div v-else class="async-loader">
      <Preloader />
    </div>
  </div>
</template>

<script>
  import Preloader from "@/components/f7/Preloader"

  export default {
    components: { Preloader },
    props: {
      id: { type: [Number, String], default: null },
      blendMultiply: { type: Boolean, default: false },
      align: { type: String, default: "center" }
    },
    data() {
      return {
        imageEmpty: false,
        small: false,
        error: false,
        image: null
      }
    },
    watch: {
      id() {
        this.update()
      }
    },
    methods: {
      async load(id) {
        if(id === null || id === undefined) {
          this.imageEmpty = true
          this.image = null
          return false
        }

        let image = null

        if(gui._cache.contains(id)) {
          image = gui._cache.get(id)
        } else {
          image = await gui.dispatch("images.getImageById", id)
          if(image) {
            gui._cache.add(id, image)
          }
        }

        if(image) {
          this.error = false
          this.imageEmpty = false
          
          this.image = image
        } else {
          this.error = true
        }

        this.$emit("loaded", { error: this.error })
      },
      async update() {
        const container = this.$refs.container.getBoundingClientRect()
        this.small = container.width < 80 || container.height < 80

        await this.load(this.id)
      }
    },
    async mounted() {
      await this.update()
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .async-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &.left {
      justify-content: flex-start;

      .async-error {
        align-items: flex-start;
      }

      .async-image-empty, .async-image {
        background-position: left center;
      }
    }

    &.small {
      .async-loader .preloader {
        max-width: 20px;
        max-height: 20px;
      }

      .async-error {
        font-size: 9px;

        .async-error-icon {
          margin-bottom: 2px;
          .icon {
            font-size: 12px;
          }
        }
      }
    }

    .async-error {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $error-color;
      text-align: center;

      .async-error-icon {
        margin-bottom: 6px;

        .icon {
          font-size: 24px;
          color: $error-color;
        }
      }
    }

    .async-image-empty {
      width: 40px;
      height: 40px;
      background-image: url(~@/static/imgs/camera.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .async-image {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      &.cover {
        background-size: cover;
      }
      &.blend-multiply {
        mix-blend-mode: multiply;
      }
    }
  }
</style>
