<template>
  <Dialog v-model="dialog.shown" :no-buttons="false" class="question-ui-dialog" id="question-ui-dialog">
    <div class="dialog-title" v-html="dialog.message"></div>
    <div v-if="dialog.description" class="dialog-text" v-html="dialog.description"></div>

    <template #outside>
      <div class="dialog-buttons">
        <f7-button v-if="dialog.cancelButton" id="question-ui-cancel" @tap="resolve(false)" class="dialog-button">{{ dialog.cancelButton }}</f7-button>
        <f7-button id="question-ui-confirm" @tap="resolve(true)" class="dialog-button dialog-button-bold">{{ dialog.confirmButton }}</f7-button>
      </div>
    </template>
  </Dialog>
</template>

<script>
  import { ref, watch } from "vue"
  import Dialog from "../Dialog"

  export default {
    components: { Dialog },
    setup() {
      const dialog = ref(gui.questionUIData)
      watch(gui.questionUIData, value => {
        dialog.value = value
      })

      return {
        dialog,
        resolve: condition => {
          dialog.value.shown = false
          if(typeof dialog.value.resolver === "function") {
            dialog.value.resolver(condition)
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../css/variables";

  .question-ui-dialog {
    --f7-dialog-box-shadow: none;

    .bold {
      font-weight: bold;
    }

    .primary {
      color: $primary-color;
    }

    .bigger {
      font-size: 22px;
    }

    .margin-top {
      margin-top: 5px;
    }
  }
</style>
