import rpc from '../libs/rpc'

import gui from '../gui'
import state from "../state"
import dicts from '../dicts'

import { isOnline } from "../online"
import { debounce } from "lodash"

import syncConfig from "./config"
import dictsConfig from "../config/dicts.json"

let limit = 1000

const DATA_DICTS = ["images", "goods", "goods_categories", "goods_per_store", "handlers", "good_modifiers", "tables", "halls", "stores", "companies", "taxes", "taxes_holidays", "promotions"]

const DOC_DEBOUNCER = {}
const DOC_DEBOUNCE_TIME = 5000

const syncProcess = {
  waiting: false,
  resolvers: []
}

export async function sync() {
  console.log("Starting synchronization...", syncProcess.resolvers.length)

  if(syncProcess.waiting) {
    return new Promise(resolve => {
      syncProcess.resolvers.push(() => {
        console.log("Synchronization resolver finished!")
        resolve()
      })
    })
  } else {
    syncProcess.waiting = true

    await pull()
    await push()
  }

  for(let resolver of syncProcess.resolvers) {
    if(typeof resolver === "function") {
      resolver()
    }
  }

  syncProcess.waiting = false
  syncProcess.resolvers = []
}

export async function truncateData() {
  for await (let dict of DATA_DICTS) {
    await dicts[dict].truncate()
  }
}

export async function pull() {
  state.syncDetails = gui.syncDetails = {
    dict: null,
    state: "pull",
    loaded: 0
  }

  if(isOnline) {
    const dicts = Object.keys(dictsConfig).filter(key => {
      if (syncConfig?.[key]?.pull === false) {
        return false
      }

      return true
    }).map(key => ({key, config: syncConfig[key] || {}}))

    for await (let dict of dicts) {
      await pullDict(dict.key, dict.config.pull)
    }

    state.syncCompletedOn = gui.syncCompletedOn = Date.now()
  } else {
    console.log("Offline mode enabled... Synchronization was passed.")
  }
}

export async function push() {
  state.syncDetails = gui.syncDetails = {
    dict: null,
    state: "push",
    loaded: 0
  }

  if(isOnline) {
    const dicts = Object.keys(dictsConfig).filter(key => {
      return syncConfig?.[key]?.push
    }).map(key => ({key, config: syncConfig[key] || {}}))

    for await (let dict of dicts) {
      await pushDict(dict.key, dict.config.push)
    }
  } else {
    console.log("Offline mode enabled... Synchronization was passed.")
  }
}

export async function pushDocument(doc) {
    if(doc.state === "active") {
      if(typeof doc.uuid === "string") {
        if(!DOC_DEBOUNCER[doc.uuid]) {
          DOC_DEBOUNCER[doc.uuid] = debounce(async doc => {
            if(isOnline) {
              const response = await rpc("pos.sync.pushDocument", doc)
              console.log("Document sent successfully", response)
            } else {
              console.log("Push document error: Check network connection")
            }
          }, DOC_DEBOUNCE_TIME)
        }

        // Debounce pushing document
        await DOC_DEBOUNCER[doc.uuid](doc)
      } else {
        console.log("Push document error: Document does not contain UUID")
      }
    } else {
      console.log("Push document error: Document is not active")
    }
}

export function merge(leftItem, rightItem) {
  const newItem = {...leftItem}

  for (let [key, value] of Object.entries(rightItem)) {
    if (value !== null) {
      newItem[key] = value
    }
  }

  return newItem
}

async function pushItem(item, dictName, config = {}) {
  if(typeof config.before === "function") {
    await config.before(item)
  }

  const extend = typeof config.extend === "function" ? await config.extend(item) : {}
  const handler = typeof config.handler === "string" ? config.handler : `dicts.${dictName}.put`
  const response = await rpc(handler, { ...item, ...extend })

  if(!response) {
    return false
  }

  if(response.error) {
    return false
  }

  if(typeof config.after === "function") {
    await config.after(item)
  }
}

async function pushDict(dictName, config = {}) {
  state.syncDetails.dict = dictName
  gui.syncDetails = state.syncDetails

  let length = 0
  let offset = 0

  do {
    const baseWhere = config?.where ? config?.where() : {}
    const items = await dicts[dictName].get({ where: baseWhere, limit, offset, order: '_id' })

    if(Array.isArray(items) && items.length > 0) {
      for await (let item of items) {
        await pushItem(item, dictName, config)
      }
    }

    length = items.length || 0
    offset += length

    state.syncDetails.loaded += items.length || 0
    gui.syncDetails = state.syncDetails
  } while(length > 0)
}

async function pullDict(dictName, config = {}) {
  state.syncDetails.dict = dictName
  gui.syncDetails = state.syncDetails

  const dict = dicts[dictName]
  const baseWhere = config?.where ? config?.where() : {}

  const rpcHandler =
    typeof config?.handler === "string"
      ? data => rpc(config.handler, data)
      : data => rpc("pos.sync.pull", dictName, data)

  let res, count = 0

  do {
    let where = {}
    const maxModified = (await dict.get({fields: "max(_modified) as _modified"}))[0]?._modified

    if (maxModified) {
      const maxId = (await dict.get({fields: "max(_id) as _id", where: { _modified: maxModified }}))[0]?._id
      if (maxId) {
        where = {
          modifiedEqual: maxModified,
          idGreater: maxId,
          ...baseWhere
        }
      }
    }

    res = await rpcHandler({ ...where, order: ["_modified", "_id"]})
    if (res.error) {
      console.error('error sync', dictName, res.error)
      break
    }

    if (maxModified && res?.length === 0) {
      res = await rpcHandler({
        modifiedGreater: maxModified,
        ...baseWhere,
        order: ["_modified", "_id"]
      })
    }

    if (res.error) {
      console.error('error sync', dictName, res.error)
      break
    }

    count += res?.length
    gui.syncStatus = `sync ${dictName} ${count}`

    if (Array.isArray(res) && res.length > 0) {
      if(typeof config.putRules === "function") {
        await config?.putRules(res)
      } else {
        const ids = await dict.put(res)

        if (typeof config.after === "function") {
          const items = res.map((item, index) => ({...item, _id: ids[index]}))
          for await (let item of items) {
            await config.after(item)
          }
        }
      }

      state.syncDetails.loaded += res.length
      gui.syncDetails = state.syncDetails
    }

  } while (res?.length)
}
