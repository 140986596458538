import Handler from './handler'
import state from "../state"
import gui from '../gui'
import user from '../user'
import { isNewReceipt, isSelection, calculate, save } from "../actions/receipt"
import { round2 } from '../libs/util'

export const MAX_DISCOUNT_PERCENTS = 5

class ManualDiscount extends Handler {
  async setDiscount({percent, absDiscount}) {
    const receiptData = state.receiptData

    if (!isNewReceipt(receiptData)) {
      return false
    }

    receiptData.manualDiscount = {
      isSumEditable: receiptData.manualDiscount?.isSumEditable,
      discounts: receiptData.manualDiscount?.discounts,
      percent: percent
    }

    if (absDiscount !== null) {
      receiptData.sumAbsDiscount = round2(absDiscount)
    }

    await this.saveDiscount(receiptData)

    return true
  }

  async saveDiscount(receipt) {
    await calculate(receipt)
    save(receipt)

    gui.receiptData = receipt
  }

  async confirmDiscount(amount) {
    if(!await user.verifyPermissionRequest("all.cashier.receiptSpecials.manualDiscount", {
      description: gui.translate("receipts.manualDiscountAccessDescription"),
      title: gui.translate("receipts.manualDiscountAccessTitle", { amount })
    })) {
      return false
    }
    return true
  }

  async resetDiscount() {
    const receiptData = state.receiptData

    receiptData.sumAbsDiscount = null
    receiptData.manualDiscount = {
      isSumEditable: receiptData.manualDiscount?.isSumEditable,
      discounts: receiptData.manualDiscount?.discounts
    }
    receiptData.manualDiscount.percent = '0'

    await this.saveDiscount(receiptData)

    await gui.message(gui.translate("receipts.discountReset"))

    return true
  }

  async createReceipt(doc) {
    //FIXME: store.manualDiscount vs store.manualDiscounts
    if (!state.user.store.manualDiscount && state.user.store.manualDiscounts) {
      state.user.store.manualDiscount = state.user.store.manualDiscounts
    }

    if(state.user.store.manualDiscount && state.user.store.manualDiscount.enabled) {
      const percents = Array.isArray(state.user.store?.manualDiscount?.discounts) ? state.user.store?.manualDiscount?.discounts : []
      doc.manualDiscount = {
        isSumEditable: state.user.store?.manualDiscount?.isSumEditable === true,
        discounts: percents.slice(0, MAX_DISCOUNT_PERCENTS)
      }
    }
  }

  async calc(doc) {
    if (!isNewReceipt(doc)) {
      return false
    }
    const selection = await isSelection(doc)
    const percent = selection ? null : Number(doc?.manualDiscount?.percent)

    for (let good of doc.goods) {
      if(percent > Number(good.pricePercent) || isNaN(Number(good.pricePercent))) {
        good.pricePercent = percent || 0
      }
    }
  }
}

export default new ManualDiscount()
