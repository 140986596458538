import gui from "./gui"
import state, { initState, clearState } from "./state"
import dicts from "./dicts"
import idleTimer from "./actions/idleTimer"

import { isOnline } from "./online"
import { login } from "./actions/login"
import { navigate } from "./actions/router"
import { sync, truncateData } from "./sync"
import { getCurrentShift } from "./actions/shift"
import { checkAccessAvailable } from "./auth/user.js"
import { createSessionDocument } from "./doc"

export async function syncData(options = {}) {
  if(isOnline) {
    const synchronization = await dicts.vars.getOne({ name: "synchronization" })

    if(!options.sync && synchronization && synchronization.storeId === state.user.storeId) {
      sync().then() // do not block
    } else {
      gui.syncShown = true

      // truncate other store data
      if(!options.withoutTruncateData) {
        await truncateData()
      }
      // synchronization
      await sync()

      // set synchronization variable
      await dicts.vars.del({ name: "synchronization" })
      await dicts.vars.put({ name: "synchronization", storeId: state.user.storeId })

      gui.syncShown = false
    }
  }
}

export default {
  async verifyPermissionRequest(permission, options= {}) {
    let user = state.user

    while (true) {
      if(await checkAccessAvailable(permission, user?.groups?.permissions || [])) {
        if(user.companyId === state.user.companyId) {
          await gui.loginClose()
          return true
        } else {
          gui.toast(gui.translate("rpc.accessDenied"))
        }
      }

      user = await login({
        title: options.title || gui.translate("login.accessRequest"),
        description: options.description || null,
        cancel: typeof options.cancel === "undefined" ? true : options.cancel
      })

      if(user.cancel === true) {
        await gui.loginClose()
        return false
      }
    }

    return false
  },
  async merge(data = {}) {
    state.user = gui.user = { ...state.user, ...data }

    const oldSettings = await dicts.users_settings.getOne({ userId: state.user._id })
    await dicts.users_settings.put({ ...oldSettings, ...data, userId: state.user._id })
  },
  async createPreparedUser(authData) {
    const settings = await dicts.users_settings.getOne({ userId: authData.user._id })
    return { ...authData.user, ...authData.populated, ...settings, _id: authData.user._id, token: authData.token }
  },
  async setUser(user, options = {}) {
    state.user = gui.user = user
    state.shift = gui.shift = await getCurrentShift() || {}

    if(process.env.NODE_ENV !== "production") {
      localStorage.setItem("OFFLINE_USERNAME", user.login)
      localStorage.setItem("OFFLINE_PIN", user.hashedPin)
      localStorage.setItem("JWT_TOKEN", user.token)
    }

    if(typeof options.withoutSynchronization === "undefined" || options.withoutSynchronization === false) {
      await this.synchronization()
    }

    await initState()

    idleTimer.register(user.company?.inactiveDeviceTimeMins)

    createSessionDocument("login", false).then(() => {
      console.log("Login document successfully created!")
    })
  },
  async clear() {
    // clear cache
    clearState()

    state.user = {}
    state.shift = {}

    localStorage.removeItem("JWT_TOKEN")
    localStorage.removeItem("OFFLINE_USERNAME")
    localStorage.removeItem("OFFLINE_PIN")

    idleTimer.unregister()
    gui.closeAll()
  },
  async logout(isForce = false) {
    createSessionDocument("logout", isForce).then(() => {
      console.log("Logout document successfully created!")
    })

    await this.clear()
    await this.setUser(await login())

    await navigate("/home")
    await gui.loginClose()
  },
  async synchronization() {
    await syncData()
  },
  async checkAccess(permission) {
    if(this.isAuthorized()) {
      return await checkAccessAvailable(permission, state.user?.groups?.permissions || [])
    }

    return false
  },
  isAuthorized() {
    return state.user && Object.keys(state.user).length > 0
  }
}
