/**
 * @fileOverview
 * Dict
 * @copyright (c) Ax
 */
/**
 * Dict
 * @class Dict
 */
/*
{
    db: Db
    name: 'goods'
    keys: {
        'lagerid' : Number
        'barcode': String
        'modified': Date
        'count' : {
            type : Number
            typeDb: 'numeric(20,3)'
        }
    }
}
*/

export default function Dict(args) {
  return (async () => {
    this.args = args || {}
    this.db = args.db
    // modified
    this.isModified = false
    // thin dict withot _data
    if(args.name[0] === '!') {
      this.name = args.name.substr(1)
    } else {
      this.isData = true
      this.name = args.name
    }
    this.table = '_' + this.name
    this.fields = args.fields || {}
    // skip #
    for (const key in this.fields) {
      if(key.indexOf('#') === 0) {
        delete this.fields[key]
      }
    }
    this.keys = Object.keys(this.fields)
    // if (this.isModified) {
    //     this.sequence = `_${this.table}_sequence` // `_dicts_sequence`
    //     this.keys.push('_modified')
    //     this.fields._modified = {
    //         type: 'INTEGER',
    //         typeDb: 'INTEGER',
    //     }
    // }

    this.keys.push('_modified')
    this.fields._modified = {
      type: 'INTEGER',
      typeDb: 'INTEGER',
    }

    if(this.isData) {
      this.keys.push('_data')
      this.fields._data = '!String'
    }
    // проверить fields
    for (var i = 0, l = this.keys.length; i < l; ++i) {
      var key = this.keys[i]
      var options = this.fields[key]
      if(!options.type) {
        options = {
          type: options
        }
      }

      if(typeof (options.type) === 'string') {
        var arr = options.type.split(':')
        options.type = arr[0]
        // no index "!Number"
        if(options.type[0] === '!') {
          options.type = options.type.substring(1)
          options.noindex = true
        }

        options.length = +arr[1]
        options.precision = +arr[2]
      }

      if(!options.typeDb) {
        var typeDb
        switch (options.type) {
          case Number:
          case 'Number':
            typeDb = 'INTEGER'
            if(options.length) {
              typeDb = `REAL`
            }
            break

          case Date:
          case 'Date':
            typeDb = 'INTEGER'
            break

          case Boolean:
          case 'Boolean':
            typeDb = 'NUMERIC'
            break

          case String:
          case 'String':
          default:
            typeDb = 'TEXT'
            if(options.length) {
              typeDb = `TEXT`
            }
            options.type = 'String'
            break
        }
        options.typeDb = typeDb
      }
      this.fields[key] = options
    }
    await this.check()
    return this // when done
  })()
}
/**
 * старт транзакции
 * @return {boolean} статус транзакции
 */
Dict.prototype.transaction = async function () {
//   console.log('TRANSACTION')
  this.isTransaction = true
  const promise = new Promise((resolve, reject) => {
    this.resolve = resolve
  })
  return new Promise((resolve, reject) => {
    this.db.transaction(async (tx) => {
      this.tx = tx
      resolve()
      await promise
    })
  })
  return true
}
/**
 * commit транзакции
 * @return {boolean} статус транзакции
 */
Dict.prototype.commit = async function () {
//   console.log('COMMIT')
  this.isTransaction = false
  this.resolve(true)
  this.tx = null
  return true
}
/**
 * отмена транзакции
 * @return {boolean} статус транзакции
 */
Dict.prototype.rollback = async function () {
  console.log('ROLLBACK')
  this.isTransaction = false
  this.resolve(false)
  this.tx = null
  return true
}
/**
 * создание в базе данных
 * @private
 */

Dict.prototype.check = async function () {
  const res = await this.sql(`CREATE TABLE IF NOT EXISTS ${ this.table } (_id INTEGER PRIMARY KEY AUTOINCREMENT);`)
  await this.checkColumns()
}

Dict.prototype.checkColumns = async function () {
  var db = this.db
  for (var i = 0, l = this.keys.length; i < l; ++i) {
    var key = this.keys[i]
    var typeDb = this.fields[key].typeDb
    var noindex = this.fields[key].noindex

    if(!typeDb) {
      console.error(`Error type for ${ key } in ${ this.table }`)
      continue
    }

    const res = await this.sql(`ALTER TABLE ${ this.table } ADD COLUMN ${ key } ${ typeDb };`)
    if(!noindex) {
      await this.sql(`CREATE INDEX idx_${ this.table }_${ key } ON ${ this.table } (${ key });`, [])
    }
  }
}

/**
 * записать объект или массив объектов в БД
 * @param {object|array}
 * @return {boolean} статус записи
 * @example
 * dict.put({
 *      _id : 1,
 *      length; 22,
 *      name: 'key'
 * })
 */
Dict.prototype.put = Dict.prototype.set = async function (data) {
  if(!data) {
    this.error = `Error put null to ${ this.name }`
    return false
  }

  var arr = data.unshift ? data : [data]
  var keys = this.keys
  var db = this.db

  var localTransaction = !this.isTransaction
  if(localTransaction) {
    await this.transaction()
  }

  let modified = 0
  if(this.isModified) {
    // var res = await db.query(`SELECT nextval('${this.sequence}')`)
    // modified = (res.rows[0] || {}).nextval || 0
  }
  this.error = ''
  let query = {}
  // insert records
  let prepared = false
  for (var i = 0, l = arr.length; i < l; ++i) {
    var obj = arr[i]

    if(!prepared) {
      let number = 0
      let sql = (`INSERT OR REPLACE into ${ this.table } (${ keys.join(', ') }, _id) values (${ '?, '.repeat(keys.length) } ?) `).replace(/\?/g, () => `$${ ++number }`)
      query = {
        name: `insert_${ this.table } `,
        text: sql,
        values: []
      }
      prepared = true
    }
    let values = []

    for (var j = 0; j < keys.length; ++j) {
      if(keys[j] === '_data' && this.isData) {
        values.push(JSON.stringify(obj, 0, 2))
      } else if(keys[j] === '_modified' && this.isModified) {
        values.push(modified)
      } else {
        values.push(obj[keys[j]])
      }
    }
    values.push(obj._id || null)
    query.values = values

    const res = await this.sql(query.text, query.values)
    if(res.error || !res.result) {
      this.error = res.error.toString()
      console.error(this.error, query)
      if(localTransaction) {
        await this.rollback()
      }
      return false
    }

    obj._id = obj._id || res.result.insertId
  }

  if(localTransaction) {
    await this.commit()
  }

  if(this.error) {
    return false
  } else {
    var ids = []
    for (var i = 0, l = arr.length; i < l; ++i) {
      ids.push(arr[i]._id)
    }
    return ids
  }
}
/**
 * Запит наить массив объектов
 * @param {object} args объект Запит наа
 * @return {object[]} массив найденных
 * @example
 * var goods = dict.get({
 *      lagerid; 25,
 *      quality: 0
 * })
 *
 *
 *  {
 *      where : {
 *          'lagerid =' : Number
 *          'barcode >': String
 *          'modified ': null
 *      },
 *      fields : ['lagerid', 'barcode', 'max(modified)'],
 *      limit : 1,
 *      offset :
 *  }
 *  {
 *      'lagerid =' : Number
 *      'barcode >': String
 *      'modified ': null
 *  }
 */

Dict.prototype.getOne = async function (obj = {}) {
  obj = (obj.fields || obj.where || obj.group || obj.having || obj.order || obj.limit || obj.offset || obj.json) ? obj : { where: obj }
  obj.limit = 1

  const items = await this.get(obj)

  return Array.isArray(items) && items.length > 0 ? items[0] : null
}

Dict.prototype.join = async function (rightTable, keys, obj = {}) {
  try {
    const { rightSQL } = await this.prepareSQLData(obj)

    const leftTable = this.table.slice(1)
    const leftSql = `
          SELECT ${ this.table }._data as ${ leftTable },
                _${ rightTable }._data as ${ rightTable },
                 ${ this.table }._id   as left_id,
                _${ rightTable }._id   as right_id
          FROM ${ this.table }
          JOIN _${ rightTable }
          ON _${ rightTable }.${ keys.rightKey } = ${ this.table }.${ keys.leftKey }`

    const sql = `${ leftSql } ${ rightSQL }`
    const response = await this.sql(sql)

    if(response.error) {
      return []
    } else {
      return [...response.result.rows].map(item => {
        return {
          [leftTable]: { _id: item.left_id, ...JSON.parse(item[leftTable]) },
          [rightTable]: { _id: item.right_id, ...JSON.parse(item[rightTable]) }
        }
      })
    }
  } catch (e) {
    return []
  }

  return []
}

Dict.prototype.count = async function (obj) {
  try {
    const { rightSQL } = await this.prepareSQLData(obj)
    const sql = `
        select COUNT(*) as count
        from ${ this.table } 
        ${ rightSQL }`

    const response = await this.sql(sql)

    if(response.error) {
      return 0
    } else {
      return Number(response.result.rows[0].count || 0)
    }
  } catch (e) {
    return 0
  }

  return 0
}

Dict.prototype.get = async function (obj) {
  this.error = ''
  const { query, rightSQL, fields } = await this.prepareSQLData(obj)
  const sql = `
        select ${ query.distinct ? 'distinct' : '' } ${ fields } 
        from ${ this.table } 
        ${ rightSQL }`

  let arr = []
  let res = await this.sql(sql)

  if(res.error) {
    this.error = res.error.toString()
    console.error(this.error, sql)
    return []
  }

  if(query.fields.length || !this.isData || query.json) {
    arr = res.result.rows
  } else {
    for (let i = 0; i < res.result.rows.length; ++i) {
      let _id = res.result.rows[i]._id
      let _data = res.result.rows[i]._data
      try {
        _data = JSON.parse(_data)
      } catch (e) {
        console.error(`error _data in ${ this.table } : ${ _data }`)
      }
      _data._id = _id
      if(this.isModified) {
        _data._modified = res.result.rows[i]._modified
      }
      arr.push(_data)
    }
  }

  return arr
}

Dict.prototype.prepareSQLData = async function (obj) {
  obj = obj || {}
  let query = (obj.fields || obj.where || obj.group || obj.having || obj.order || obj.limit || obj.offset || obj.json) ? obj : { where: obj }

  query.fields = query.fields || []
  query.fields = query.fields.unshift ? query.fields : [query.fields]

  // var fields = query.fields.length ? query.fields.join(', ') : ( this.isData ? `_id, _data ${this.isModified ? ', _modified' : ''}` : '*' )
  let fields = ''
  if(query.fields.length || query.json) {
    fields = query.fields.join(', ')
    if(query.json) {
      let json = query.json

      json = json.map(i => {
        let arr = i.split('.')
        let arrJson = arr.length - 1 ? "::json -> '" + arr.slice(1).join("' -> '") + "'" : ""
        return `${ arr[0] }${ arrJson } AS ${ arr[arr.length - 1] }`
      })

      fields += `${ query.fields.length ? ", " : "" }${ json.join(", ") }`
    }
  } else {
    if(this.isData) {
      fields = '_id, _data'
      if(this.isModified) {
        fields += ', _modified'
      }
    } else {
      fields = '*'
    }
  }

  const where = this.prepareWhere(query.where)
  const rightSQL = `
        ${ where ? 'where ' + where : '' } 
        ${ query.group ? 'group by ' + query.group : '' } 
        ${ query.having ? 'having ' + query.having : '' } 
        ${ query.order ? 'order by ' + query.order : '' } 
        ${ query.limit ? 'limit ' + query.limit : '' } 
        ${ query.offset ? 'offset ' + query.offset : '' }`

  return { rightSQL, query, fields }
}

//----------------------------------------------------------------
Dict.prototype.prepareWhere = function (obj, delimiter = "AND") {
  obj = obj || {}
  var sql = []
  for (var key in obj) {
    if(!obj.hasOwnProperty(key)) {
      continue
    }

    if(key === "or" && typeof obj[key] === "object" && !Array.isArray(obj[key]) && !(obj[key] instanceof Date)) {
      sql.push("(" + this.prepareWhere(obj[key], "OR") + ")")
      continue
    }

    if(key === "and" && typeof obj[key] === "object" && !Array.isArray(obj[key]) && !(obj[key] instanceof Date)) {
      sql.push("(" + this.prepareWhere(obj[key], "AND") + ")")
      continue
    }

    var params = key.split(' ')
    var field = params[0]
    var oper = (params[1] ? params.slice(1).join(' ') : '=').toUpperCase()

    if(obj[key] === null) {
      sql.push(`${ field } IS NULL`)

      continue
    }

    var arr = (obj[key] && obj[key].unshift) ? obj[key] : [obj[key]]

    var values = []
    for (var i = 0, l = arr.length; i < l; ++i) {
      var value = arr[i]

      var valueSql = '' + value
      //console.log(this.table, field, this.fields[field])
      var type = (this.fields[field] || {}).type
      switch (type) {
        case Number:
        case 'Number':
          valueSql = '' + value
          break
        case Date:
        case 'Date':
          valueSql = '' + (value ? (value.getTime ? value.getTime() : value) : '')
          break
        case Boolean:
        case 'Boolean':
          valueSql = (!!value) ? '"true"' : '"false"'
          break
        case String:
        case 'String':
        default:
          valueSql = `'${ "" + value }'`
          break
      }
      values[i] = valueSql
    }

    let s = `${ field } ${ oper } ( ${ values.join(', ') } )`

    sql.push(s)
  }

  return sql.join(` ${ delimiter } `)
}
/**
 * удалить элементы справочника
 * @param {object} args объект Запит наа
 * @return {boolean} статус выполнения
 * @example
 * dict.del({
 *      lagerid; 25,
 *      quality: 0
 * })
 */
Dict.prototype.del = async function (obj) {
  var ok = false

  if(obj) {
    var where = this.prepareWhere(obj)
    var sql = `delete from ${ this.table } ${ where ? 'where ' + where : '' }`
    await this.sql(sql)
    ok = true
  } else {
    await this.sql(`delete from ${ this.table }`)
    ok = true
  }
  return ok
}
/*
* Очистить таблицу
* */
Dict.prototype.truncate = async function () {
  try {
    await this.sql(`delete from ${ this.table }`)
    return true
  } catch(e) {
    return false
  }
}
//----------------------------------------------------------------
Dict.prototype.error = ''
//----------------------------------------------------------------

Dict.prototype.sql = function (query, args = []) {
  // console.log('SQL', query, this.tx)
  args.forEach((item, i) => {
    if(typeof args[i] === 'undefined') args[i] = null
  })
  return new Promise((resolve, reject) => {
    if(this.tx) {
      this.tx.executeSql(query, args, (tx, result) => {
        // console.log('SQL result', result); 
        resolve({ result: result })
      }, (tx, error) => {
        // console.log('SQL error', error); 
        resolve({ error: error })
      })
    } else {
      this.db.transaction(async (tx) => {
        tx.executeSql(query, args, (tx, result) => {
          // console.log('SQL result', result); 
          resolve({ result: result })
        }, (tx, error) => {
          // console.log('SQL error', error); 
          resolve({ error: error })
        })
      })
    }
  })
}
