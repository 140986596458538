<template>
  <div ref="content" class="page-content" @scroll="onScroll">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    infinite: { type: Boolean, default: false },
    infiniteDistance: { type: Number, default: 50 }
  },
  emits: ['infinite'],
  methods: {
    onScroll() {
      if (!this.infinite) return;

      if (this.$refs.content.scrollTop > this.$refs.content.scrollHeight - this.$refs.content.offsetHeight - this.infiniteDistance) {
        this.$emit('infinite')
      }
    }
  }
}
</script>