<template>
  <Page schema="transparent" id="cash-in-out-page">
    <Header>
      <div class="left">
        <f7-link id="cash-in-out-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t(cashInOut.type === 'cashin' ? 'shifts.cashInTitle' : 'shifts.cashOutTitle') }}</div>
    </Header>

    <Inner>
      <div class="new-payment-container payment cashier">
        <div class="new-payment-inner">
          <div class="new-payment-item cash-field">
            <div class="new-payment-item-label">{{ $t(cashInOut.type === 'cashin' ? 'shifts.cashInLabel' : 'shifts.cashOutLabel') }}</div>
            <PriceField id="cash-in-out-sum" ref="sumRef" v-model="cashInOut.sum" input-mode="none" />
          </div>
          <div class="new-payment-item cash-field">
            <div class="new-payment-item-label">{{ $t("shifts.cashDescription") }} <span>({{ $t("shifts.cashDescriptionOptional") }})</span></div>
            <input class="input-item" @keyup.enter="blurDescription" id="cash-in-out-description" type="text" v-model="cashInOut.description" />
          </div>
          <div class="new-payment-item-confirm">
            <f7-button @tap="confirm" :disabled="!isValidSum" id="cash-in-out-button" round fill large>{{ $t("ok") }}</f7-button>
          </div>
        </div>
        <div class="new-payment-keyboard">
          <Keyboard id="cin-out-keyboard" @confirm="confirm" :disabled="!isValidSum" ref="keyboardRef" />
        </div>
      </div>
    </Inner>
  </Page>
</template>

<script>
  import { ref, computed, onMounted } from "vue"

  import Keyboard from "../../components/Keyboard"
  import PriceField from "../../components/PriceField"

  export default {
    components: { Keyboard, PriceField },
    setup() {
      const cashInOut = ref(gui.cashInOut)
      const keyboardRef = ref(null)
      const sumRef = ref(null)

      const isValidSum = computed(() => {
        const sum = Number(cashInOut.value.sum)
        return !isNaN(sum) && sum > 0.001
      })

      onMounted(() => {
        keyboardRef.value?.setRelation(sumRef.value)
      })

      return {
        sumRef,
        keyboardRef,
        cashInOut,
        isValidSum,
        blurDescription: () => {
          document.activeElement?.blur()
        },
        confirm: () => {
          if(isValidSum) {
            gui.dispatch("shift.addCash", cashInOut.value)
          }
        }
      }
    }
  }
</script>

<style lang="sass">
  @import "@/css/pages/checkout"
</style>
