<template>
  <component v-tap="onClick" @touchstart="addRipple" :is="tag" ref="container" :disabled="disabled" class="ripple-item" :class="{ 'ripple-hidden': hidden }">
    <slot />
  </component>
</template>

<script>
  import { tap } from "@/js/touches"
  import { click } from "@/js/audio"
  import { delay } from "@/js/utils"

  export default {
    emits: ["tap"],
    directives: { tap },
    props: {
      mediaClick: { type: Boolean, default: false },
      maxRipples: { type: Number, default: 3 },
      duration: { type: Number, default: 380 },
      disabled: { type: Boolean, default: false },
      pointer: { type: Boolean, default: true },
      hidden: { type: Boolean, default: false },
      tag: { type: String, default: "div" }
    },
    data() {
      return {
        ripples: 0
      }
    },
    methods: {
      onClick(event) {
        if(!this.disabled) {
          if(this.mediaClick) {
            click()
          }

          this.$emit("tap", event)
        }
      },
      getCoords(event) {
        let x = 0, y = 0

        if(event.touches && event.touches[0]) {
          x = event.touches[0].clientX
          y = event.touches[0].clientY
        } else if(event.changedTouches && event.changedTouches[0]) {
          x = event.changedTouches[0].clientX
          y = event.changedTouches[0].clientY
        } else {
          x = event.clientX
          y = event.clientY
        }

        return { x, y }
      },
      async addRipple(event) {
        if(gui.settingsData.value.disableAnimation) {
          return false
        }

        if(this.disabled) {
          return false
        }

        if(this.ripples > this.maxRipples) {
          return false
        }

        const { x, y } = this.getCoords(event)
        const ICON_ONLY_SIZE = 50
        const container = this.$refs.container

        if(container) {
          if(container.classList.contains("disabled")) {
            return false
          }

          let rippleSize = container.clientWidth * 2

          const isIconOnly = container.classList.contains("icon-only")
          const rippleItem = document.createElement("div")

          if(isIconOnly && rippleSize < ICON_ONLY_SIZE) {
            rippleSize = ICON_ONLY_SIZE
          }

          rippleItem.classList.add("ripple-wave")

          if(this.pointer) {
            const rect = container.getBoundingClientRect()

            const top = y - rect.top
            const left = x - rect.left

            rippleItem.style.top = top + "px"
            rippleItem.style.left = left + "px"

            rippleSize = container.offsetWidth * 2 + 15
          }

          rippleItem.style.width = rippleSize + "px"
          rippleItem.style.height = rippleSize + "px"

          rippleItem.style.marginTop = rippleSize / 2 * -1 + "px"
          rippleItem.style.marginLeft = rippleSize / 2 * -1 + "px"

          rippleItem.style.transition = `${this.duration}ms`

          container.appendChild(rippleItem)

          this.ripples++

          await delay(5)
          rippleItem.classList.add("start")

          await delay(this.duration)
          container.removeChild(rippleItem)
          this.ripples--
        }
      }
    }
  }
</script>
