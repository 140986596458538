import gui from "../gui"
import user from "../user"
import state from "../state"
import dicts from "../dicts"
import shiftCalculating from "../config/shift-calculating"
import * as settings from "../settings"

import { v4 as uuid } from "uuid"
import { push } from "../sync"
import { round2 } from "../libs/util"
import { navigate } from "./router"
import { openDocs } from "./docs"
import { printBill } from "../libs/bills"
import { saveDocument } from "../doc"
import { deleteReceivedDocs, deleteHoldShifts } from "../sync/cleaner"

export async function openPage() {
  if(state.shift.state) {
    gui.preloader(true)
    await calculate(state.shift)
  }

  gui.preloader(false)

  gui.shift = state.shift
  await navigate("/shift")
}

export async function viewTheClosedShift(shift) {
  gui.viewedShift = state.viewedShift = shift;
  await navigate("/shifts/view")
}

export async function openCashIn() {
  if(!await openShiftProcess()) {
    return false
  }

  if(await user.verifyPermissionRequest("all.cashier.createDeposit", {
    description: gui.translate("login.cashInRequest"),
    title: gui.translate("shifts.cashInTitle")
  })) {
    state.cashInOut = gui.cashInOut = { type: "cashin", description: "", sum: "" }
    await navigate("/shift/cash-in-out")
  }
}

export async function openCashOut() {
  if(!await openShiftProcess()) {
    return false
  }

  if(await user.verifyPermissionRequest("all.cashier.createWithdrawal", {
    description: gui.translate("login.cashOutRequest"),
    title: gui.translate("shifts.cashOutTitle")
  })) {
    state.cashInOut = gui.cashInOut = { type: "cashout", description: "", sum: "" }
    await navigate("/shift/cash-in-out")
  }
}

export async function toggle() {
  if(await isShiftOpened()) {
    if(await gui.questionUI({
      message: gui.translate("shifts.wantCloseShiftTitle"),
      cancelButton: gui.translate("shifts.wantOpenShiftCancel"),
      confirmButton: gui.translate("shifts.wantOpenShiftConfirm")
    })) {
      await close()
    }
  } else {
    if(await gui.questionUI({
      message: gui.translate("shifts.wantOpenShiftTitle"),
      cancelButton: gui.translate("shifts.wantOpenShiftCancel"),
      confirmButton: gui.translate("shifts.wantOpenShiftConfirm")
    })) {
      await open()
    }
  }
}

export async function open() {
  if(!await isShiftOpened()) {
    if(await user.verifyPermissionRequest("all.cashier.openShift", {
      description: gui.translate("login.openShiftRequest"),
      title: gui.translate("shifts.openShiftButton")
    })) {
      const startCashIn = await getCashIn()

      gui.preloader(true)

      state.shift = await getShiftSchema()
      state.shift.openingSum = startCashIn

      await save(state.shift)

      if(startCashIn > 0) {
        await createCashDocument({
          type: "cashin",
          sum: startCashIn,
          description: gui.translate("shifts.startCashInDescription")
        })
      }

      await calculate(state.shift)

      gui.shift = state.shift
      gui.preloader(false)

      if(await gui.questionUI({
        message: gui.translate("shifts.shiftSuccessfullyOpened"),
        cancelButton: gui.translate("ok"),
        confirmButton: gui.translate("shifts.printZeroReceipt")
      })) {
        await printZeroReceipt(state.shift)
      }

      return true
    }
  } else {
    gui.toast(gui.translate("shifts.shiftAlreadyOpened"))
  }

  return false
}

export async function close() {
  if(await isShiftOpened()) {
    if(await user.verifyPermissionRequest("all.cashier.closeShift", {
      description: gui.translate("login.closeShiftRequest"),
      title: gui.translate("shifts.closeShiftButton")
    })) {
      gui.preloader(true)

      const activeReceipts = await dicts.docs.count({ type: "receipt", state: "active", shiftUuid: state.shift.uuid })
      if(activeReceipts > 0) {
        gui.preloader(false)
        if(await gui.questionUI({
          message: gui.translate(await settings.isCafe() ? "shifts.existsActiveOrders" : "shifts.existsActiveReceipts", { count: activeReceipts }),
          confirmButton: gui.translate(await settings.isCafe() ? "shifts.viewOrders" : "shifts.viewReceipts"),
          cancelButton: gui.translate("cancel"),
        })) {
          await openDocs({ activeTab: "postponed", page: "receipts" })
        }

        return false
      }

      const closingSum = state.shift.availableCash
      if(closingSum > 0) {
        await createCashDocument({
          type: "cashout",
          sum: state.shift.availableCash,
          description: gui.translate("shifts.closingCashOutDescription")
        })
      }

      await calculate(state.shift)

      state.shift.state = "completed"
      state.shift.closingSum = closingSum
      state.shift.closeUserId = state.user._id
      state.shift.userName = state.user.fullName
      state.shift.closedDateTime = Date.now()
      state.shift.completedOn = Date.now()

      await save(state.shift)

      // Synchronization
      push().then(async () => {
        console.log("Current shift received")

        await deleteReceivedDocs()
        console.log("Received docs deleted")

        await deleteHoldShifts()
        console.log("Hold shifts deleted")
      })

      gui.preloader(false)
      if(await gui.questionUI({
        message: gui.translate("shifts.shiftSuccessfullyClosed"),
        cancelButton: gui.translate("ok"),
        confirmButton: gui.translate("shifts.printZReport")
      })) {
        await printZReport(state.shift)
      }

      return true
    }
  } else {
    gui.toast(gui.translate("shifts.shiftIsNotOpened"))
  }

  return false
}

export async function addCash({ sum, type, description }) {
  gui.preloader(true)

  if(await isShiftOpened()) {
    if(sum > 0 && ["cashin", "cashout"].includes(type)) {
      if(type === "cashout" && sum > state.shift.availableCash) {
        gui.toast(gui.translate("shifts.maxWithdrawalSum", { sum: (+state.shift.availableCash).toFixed(2) }))
      } else {
        await createCashDocument({ type, description, sum })
        await openPage()
      }
    }
  } else {
    gui.toast(gui.translate("shifts.shiftIsNotOpened"))
  }

  gui.preloader(false)
}

export async function isShiftOpened() {
  return state.shift.state === "active"
}

export async function getCurrentShift() {
  const shift = await dicts.docs.getOne({
    where: { type: "shift", state: "active", deviceId: state.device._id, storeId: state.user.storeId },
    order: "createdOn DESC"
  })

  if(shift) {
    await calculate(shift)
  }

  return shift
}

export async function openShiftProcess() {
  if(!await isShiftOpened()) {
    if(!await gui.questionUI({
      message: gui.translate("receipts.shift.notOpenedTitle"),
      description: gui.translate("receipts.shift.notOpenedDescription"),
      confirmButton: gui.translate("receipts.shift.openButton")
    })) {
      return false
    }

    if(!await open()) {
      return false
    }
  } else {
    calculate(state.shift).then(() => {
      gui.shift = state.shift
    })
  }

  return true
}

export async function printXReport(shift = state.shift) {
  if(!await openShiftProcess()) {
    return false
  }

  if(!Object.keys(shift).length) {
    shift = state.shift
  }

  return await printBill("x-report", shift, {
    queue: [settings.PRINTERS.MASTER],
    processMessage: gui.translate("checkout.printingXReport")
  })
}

export async function printZeroReceipt(shift = state.shift) {
  if(!await openShiftProcess()) {
    return false
  }

  return await printBill("0-receipt", shift, {
    queue: [settings.PRINTERS.MASTER],
    processMessage: gui.translate("checkout.printingZeroReceipt")
  })
}

export async function printZReport(shift = state.shift, copy = false) {
  return await printBill("z-report", shift, {
    copy,
    queue: [settings.PRINTERS.MASTER],
    processMessage: gui.translate(copy ? "checkout.printingZReportCopy" : "checkout.printingZReport")
  })
}

export async function printFullReport(shift = state.fullReport) {
  return await printBill("full-report", shift, {
    queue: [settings.PRINTERS.MASTER],
    processMessage: gui.translate("checkout.printingFullReport")
  })
}

export async function addTaxes(doc) {
  if(!doc.taxExemptionEnabled) {
    const destination = doc.type === "refund" ? state.shift.taxesRefund : state.shift.taxes

    for(let [taxId, tax] of Object.entries(doc.taxes)) {
      if(destination[taxId]) {
        destination[taxId].sum = round2(destination[taxId].sum + tax.sum)
      } else {
        destination[taxId] = { ...tax, sum: tax.sum }
      }
    }

    await dicts.docs.put(state.shift)
    await calculate(state.shift)
  }
}

async function calculate(shift) {
  for await (let [key, config] of Object.entries(shiftCalculating)) {
    if(typeof config.calc === "function") {
      shift[key] = await config.calc(shift)
    } else if(config.calc?.type === "aggregate") {
      shift[key] = round2(await aggregateField(config.calc.field, shift, config.calc.query))
    }
  }
}

export async function mergeShifts(left, right) {
  for (let [key, config] of Object.entries(shiftCalculating)) {
    if(typeof config.merge === "function") {
      right[key] = await config.merge(left, right)
    }
  }

  return right
}

async function getCashIn() {
  const shiftOpenType = await settings.shiftOpenAmountControl()

  if(shiftOpenType === "zero") {
    return 0
  }

  gui.preloader(true)

  let sum = 0

  const previousShift = await dicts.docs.getOne({
    where: {
      "type": "shift",
      "deviceId": state.device._id,
      "storeId": state.user.storeId,
      "state in": ["completed", "received"]
    },
    order: "createdOn DESC"
  })

  if(previousShift) {
    await calculate(previousShift)
    sum = round2(+previousShift.closingSum > 0 ? +previousShift.closingSum : 0)
  }

  gui.preloader(false)

  if(shiftOpenType === "previously") {
    return sum
  }

  if(await gui.questionUI({
    message: gui.translate("shifts.changeStartSumTitle", { sum }),
    description: gui.translate("shifts.changeStartSumDescription"),
    cancelButton: gui.translate("shifts.changeStartSumCancel"),
    confirmButton: gui.translate("shifts.changeStartSumConfirm")
  })) {
    const startSum = await gui.getSumUI({
      defaultSum: sum,
      title: gui.translate("shifts.changeStartSumShortTitle"),
    })

    if(startSum !== null) {
      sum = round2(startSum)
    }
  }

  return sum
}

async function createCashDocument({ type, description, sum }) {
  await dicts.docs.put({
    type,
    description: description || "",
    state: "completed",
    sumTotal: sum,
    shiftUuid: state.shift.uuid,
    uuid: uuid(),
    createdOn: Date.now(),
    userId: state.user._id,
    storeId: state.user.storeId,
    deviceId: state.device._id,
    companyId: state.user.companyId
  })

  push().then(() => {
    console.log("Cash document received")
  })
}

async function save(shift) {
  await saveDocument(shift)
  state.shift = gui.shift = shift
}

async function getShiftSchema() {
  return {
    type: "shift",
    state: "active", // completed, received
    taxes: {},
    taxesRefund: {},
    closingSum: 0,
    openingSum: 0,
    availableCash: 0,
    sumTotal: 0,
    sumPayments: 0,
    sumRefunds: 0,
    sumCash: 0,
    sumCard: 0,
    sumTaxes: 0,
    sumTaxesRefund: 0,
    cashRefund: 0,
    cardRefund: 0,
    cashIn: 0,
    cashOut: 0,
    uuid: uuid(),
    userId: state.user._id,
    userName: state.user.fullName,
    store: state.user.store,
    company: state.user.company,
    storeId: state.user.storeId,
    deviceId: state.device._id,
    companyId: state.user.companyId,
    openUserId: state.user._id,
    closeUserId: null,
    openedDateTime: Date.now(),
    closedDateTime: null,
    createdOn: Date.now(),
    completedOn: null
  }
}

async function aggregateField(fieldName, shift, where = {}) {
  const response = await dicts.docs.get({
    where: { ...where, shiftUuid: shift.uuid }, fields: [`sum(${ fieldName }) as sum`]
  })

  if(response.length === 1) {
    return Number(response[0].sum)
  }

  return 0
}
