import { isActiveTimeRange } from "../../libs/util"
import { applyPromoPercent, splitAndHandleGoods, superForPromo } from "./helpers"

export default async function (promotion, receipt) {
  if(!await superForPromo(promotion, receipt)) {
    return false
  }

  if(!isActiveTimeRange(promotion.options.beginTime, promotion.options.endTime)) {
    return false
  }

  const discountPercent = Number(promotion.options.discountPercent)

  if(!isNaN(discountPercent) && discountPercent > 0) {
    await splitAndHandleGoods(receipt, promotion.options.actionGoodsIds, promotion.options.maxQty, async good => {
      await applyPromoPercent(promotion, good, discountPercent)
    })
  }
}
