<template>
  <Page>
    <Inner>
      <div class="welcome-page">
        <div class="welcome-page-brand fade-in"></div>
        <div class="welcome-page-title-container">
          <div class="welcome-page-title fade-in" v-html="$t('welcome.deviceDisabled')" />
        </div>
      </div>
    </Inner>
  </Page>
</template>

<style lang="sass">
  @import "../css/pages/welcome"
</style>
