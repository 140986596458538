<template>
  <Page name="welcome">
    <Inner>
      <div class="welcome-page new">
        <div class="big-icon-container" :class="{'loading': step > 1, 'fade-in': step > 0}">
          <div class="big-icon-loader"></div>
          <div class="big-icon-inner"></div>
        </div>
        <div class="welcome-pos" :class="{'fade-in': step > 0}"></div>
        <div class="welcome-pos-logo" :class="{'fade-in': step > 0}">
          <img src="@/static/imgs/pos-logo.svg" alt="POS Logo" />
        </div>
        <div class="welcome-mplus-logo" :class="{ 'fade-in': step > 0 }">
          <img src="@/static/imgs/logo.svg" alt="MPlus solution">
        </div>
      </div>
    </Inner>
  </Page>
</template>

<script>
  import { ref, onMounted, nextTick } from "vue"

  export default {
    setup() {
      const step = ref(0)
      const welcomePageShown = ref(false)

      const loadAnimation = () => {
        return new Promise(resolve => {
          setTimeout(() => {
            step.value = 1
            setTimeout(() => {
              step.value = 2
              setTimeout(() => {
                resolve(true)
              }, 1300)
            }, 2000)
          }, 0)
        })
      }

      onMounted(async () => {
        if(!welcomePageShown.value) {
          welcomePageShown.value = true

          await nextTick(() => {
            gui.disableSwipePanel()
          })

          await Promise.all([await loadAnimation()])
          gui.welcomeData.value.action()
        }
      })

      return { step }
    }
  }
</script>

<style lang="sass">
  @import "../css/pages/welcome"
</style>
