<template>
  <f7-ripple @tap="click" tag="button" :pointer="true" :hidden="true" :disabled="disabled"
    :class="{ 'fill': fill, 'button-round': round, 'tab-link-active': tabLinkActive, 'disabled': disabled }"
    href="#" class="button">
    <slot />
  </f7-ripple>
</template>

<script>
  import { click } from "@/js/audio"

  export default {
    emits: ["tap"],
    props: {
      round: { type: Boolean, default: false },
      fill: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      tabLinkActive: { type: Boolean, default: false }
    },
    methods: {
      click(event) {
        if(this.disabled) {
          event.preventDefault()
          return false
        }

        click()

        this.$emit("tap", event)
      }
    }
  }
</script>
