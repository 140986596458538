<template>
  <Page schema="transparent" class="login-page add-certificate-page" id="add-certificate-page">
    <Header>
      <div class="left">
        <f7-link id="certificates-add-back" @tap="$gui.navigateBack()" class="icon-only">
          <i class="icon mpi-back color-black"></i>
        </f7-link>
      </div>
      <div class="title">{{ $t("certificates.addTitle") }}</div>
    </Header>

    <Inner>
      <div class="login-page-swiper">
        <div class="login-page-inside">
          <div class="login-slide with-keyboard">
            <div class="login-page-content">
              <div class="login-page-form">
                <div class="login-page-input">
                  <label>{{ $t("certificates.label") }}</label>
                  <CertificateField ref="certificate" v-model="certificate" />
                </div>
              </div>
            </div>
            <div class="login-page-keyboard">
              <div class="login-page-footer">
                <div class="login-page-footer-inside">
                  <div class="login-page-keyboard-button">
                    <div class="login-page-footer-buttons">
                      <div class="login-page-footer-button">
                        <f7-button @click="apply" :disabled="!isValidCertificate" id="certificate-add-button" round fill large>{{ $t("certificates.next") }}</f7-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Keyboard @confirm="apply" :disabled="!isValidCertificate" id="add-certificate-keyboard" ref="keyboard" />
            </div>
          </div>
        </div>
      </div>
    </Inner>
  </Page>
</template>

<script>
  import CertificateField from "@/components/Receipt/Cartificates/CertificateField"
  import Keyboard from "@/components/Keyboard"

  export default {
    components: { CertificateField, Keyboard },
    data() {
      return {
        certificateLength: 13,
        certificate: ""
      }
    },
    methods: {
      async apply() {
        await gui.dispatch("certificates.applyCertificate", this.certificate)
        this.certificate = ""
      }
    },
    computed: {
      isValidCertificate() {
        return this.certificate.length > 0
      }
    },
    mounted() {
      // Barcode scanner
      gui.onScanned.value = barcode => gui.dispatch("receipt.scannedBarcode", { barcode })

      this.$refs.keyboard.setRelation(this.$refs.certificate)
    }
  }
</script>

<style lang="scss">
  @import "@/css/pages/login";

  .add-certificate-page {
    .pin-circle {
      width: 9px !important;
      height: 9px !important;
      border-width: 2px !important;
    }
  }
</style>
