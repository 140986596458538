<template>
  <template v-if="halls.length > 0">
  <div v-for="(hall, index) in halls" :key="hall" class="search-group">
    <div class="title">
      {{ hall.hallName }}
    </div>
    <f7-list
      no-hairlines
      no-hairlines-between
      class="tables-search-list-wrap"
      no-chevron
    >
      <ul class="tables-search-list">
        <TableListItem
          v-for="(table, i) in hall.tables"
          :key="i"
          :id="`hall-${index}-table-${i}`"
          :table="table"
          @swipe:click="$emit('table-click', hall.hallName, table)"
        />
      </ul>
    </f7-list>
  </div>
  </template>
  <template v-else>
    <div class="category-empty">
      <div class="category-empty-text">
        {{ $t("tables.tablesIsMissing") }}
      </div>
    </div>
  </template>
</template>
<script>
import TableListItem from "./TableListItem";

export default {
  components: { TableListItem },
  props: {
    halls: {
      type: Object,
      required: true,
    },
  },
  emits: ['table-click'],
};
</script>
<style lang="scss">
@import "@/css/variables";
.search-group {
  margin-top: 20px;
  .title {
    margin-left: 10px;
  }
  .tables-search-list-wrap {
    margin-top: 0;
    .tables-search-list {
      background: none;
      padding: 0 $minimal-padding;
      .tables-list-item {
        margin: 0;
        border-radius: 5px;
        overflow: hidden;
      }
    }
  }
}
</style>