<template>
  <div class="input" :class="{ 'input-focused': focused }">
    <template v-if="resizable">
      <textarea :value="text" ref="field" @input="onInput" @keyup="onKeyUp" @focus="onFocus" @blur="onBlur" :class="{ 'resizable': resizable }" :placeholder="placeholder" />
    </template>
    <template v-else>
      <input :value="text" ref="field" @input="onInput" @keyup="onKeyUp" @focus="onFocus" @blur="onBlur" :placeholder="placeholder" />
    </template>
  </div>
</template>

<script>
  export default {
    emits: ["update:value", "keyup", "focus", "blur"],
    props: {
      value: { type: String, default: "" },
      placeholder: { type: String, default: "" },
      inputId: { type: String, default: "" },
      resizable: { type: Boolean, default: false },
    },
    data() {
      return {
        text: this.value,
        startHeight: 0,
        focused: false
      }
    },
    methods: {
      onInput(event) {
        this.text = event.target.value

        this.update()
        this.$emit("update:value", this.text)
      },
      onKeyUp(event) {
        this.$emit("keyup", event)
      },
      onBlur(event) {
        this.focused = false
        this.$emit("blur", event)
      },
      onFocus(event) {
        this.focused = true
        this.$emit("focus", event)
      },
      update() {
        if(this.resizable) {
          const field = this.$refs.field

          if(field) {
            field.style.height = this.startHeight + "px"
            field.style.height = field.scrollHeight + "px"
          }
        }
      }
    },
    mounted() {
      const field = this.$refs.field

      if(field) {
        this.startHeight = field.offsetHeight
      }
    },
    watch: {
      value(value) {
        this.text = value
        this.$nextTick(() => {
          this.update()
        })
      }
    }
  }
</script>
