<template>
  <SwipeItem class="tables-list-swipe-item" :disabled="table._id === null || $gui.tables.value.change" :id="id">
    <template #right>
      <f7-button
        class="tables-swipe-button"
        fill
        @tap="setReservedTable(table)"
        :id="`${id}-reserve-button`"
      >
        {{ $t("tables.reserve") }}
      </f7-button>
    </template>

    <f7-ripple tag="div" class="tables-list-item" :hidden="true">
      <f7-list-item :clickable="false">
        <div class="bg-wrap">
          <div class="tables-list-item-content" :class="{'reserved': false, 'filled': table.tableReceipts.remote || table.tableReceipts.local}">
            <div v-if="table.reserved" class="reserved-mark" />
            <div class="wrap">
              <div class="form-group">
                <div class="tables-list-item-title">
                  {{ table.tableName ?? $t("filters.null") }}
                </div>
                <div v-if="table.capacity ?? false">
                  <i class="icon mpi-person" :class="{'color-black': false}"></i>
                  {{ table.capacity }}
                </div>
              </div>
              <div v-if="table.userName" class="form-group">
                <div class="user-name">
                  {{ table.userName }}
                </div>
                <div v-if="table.tableReceipts" class="orders">
                  {{ $t("tables.orders") }}
                  {{ table.tableReceipts.remote ? `${table.tableReceipts.local ?? 0}/${table.tableReceipts.remote}` : `${table.tableReceipts.local ?? 0}`}}
                </div>
                <div v-if="table.tableReceipts">
                  {{ $t("tables.items") }}
                  {{ table.tableItems.remote ? `${table.tableItems.local ?? 0}/${table.tableItems.remote}` : `${table.tableItems.local ?? 0}`}}
                </div>
              </div>
              <template v-if="table.reserved">
                <div  class="form-group">
                  <div class="reserved">
                    {{ $t('tables.reserved') }}
                    {{ $filters.time(table.reserved.reserveDate) }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </f7-list-item>
    </f7-ripple>
  </SwipeItem>
</template>

<script>
  import { inject } from 'vue'
  import SwipeItem from "@/components/f7/SwipeItem";

export default {
  components: { SwipeItem },
  setup() {
    const setReservedTable = inject('setReservedTable');
    const unreserveTable = inject('unreserveTable');
    const offline = inject('offline');

    return { setReservedTable, unreserveTable, offline }
  },
  props: {
    table: { type: Object, required: true },
    id: { type: String, required: true },
  }
}
</script>

<style lang="scss">
  @import "@/css/variables";
  .tables-list-swipe-item {
    .swipe-item-right {
      padding: $border-radius 0;
      box-sizing: border-box;
      .tables-swipe-button {
        height: 100%;
        border-radius: 0 $border-radius $border-radius 0;
        font-size: 15px;
        padding: 0 18px;
        font-weight: bold;
      }
    }
  }

  .bg-wrap {
    background-color: $second-bg-color;
  }

  .tables-list-item {

    border-radius: $border-radius;
    margin-top: 10px !important;
    filter: drop-shadow(1px 1px 2px rgba(0, 71, 119, 0.4));

    .item-link .item-content {
      padding: 0;
      .item-inner {
        height: auto;
        padding: 0;
      }
      .item-inner::before {
        content: none;
      }
    }

    .tables-list-item-content {
      padding: $minimal-padding 18px;
      font-weight: normal;
      font-size: $text-size-secondary;
      line-height: 118.75%;
      color: $text-secondary;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-height: 73px;
      box-sizing: border-box;
      justify-content: center;
      background-color: #FFFFFF;
      &.reserved {
        color: $text-color;
      }

      .reserved {
        color: $text-color;
      }

      &.filled {
        background-color: rgba(255,255,255,0.8);
      }

      .tables-list-item-title {
        font-weight: 600;
        font-size: $text-size;
        line-height: 118.75%;
        color: $text-color;
      }

      .icon {
        font-size: 14px;
      }
      .form-group {
        display: flex;
        justify-content: space-between;
        height: 20px;
        align-items: center;
        .user-name {
          flex-grow: 1;
          margin-right: $minimal-padding;
        }
        .orders {
          margin-right: $minimal-padding;
        }
      }
    }
  }

  .reserved-mark {
    background-image: repeating-linear-gradient(45deg, #a6d1f5 0px, #a6d1f5 5px, transparent 5px, transparent 10px);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 12px;
    border-radius: $border-radius;
  }
</style>
