<template>
  <div class="dates-input-wrap" :class="{'error': error}" @click="open">
    <div class="dates-input" >
      <div v-if="dateDisplay !== ''" :id="`picker-date-value-${id}`">{{ dateDisplay }}</div>
      <div v-else class="dates-input-placeholder">{{ placeholder }}</div>
    </div>
  </div>
  <input :id="`picker-date-input-${id}`" v-show="false"/>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    value: { type: Number},
    displayValue: { type: String, default: ''},
    placeholder: { type: String, default: '' },
    // containerEl: { type: String, required: true },
    id: { type: String, required: true },
    error: { type: Boolean, default: false },
  },
  emits: ["update:value", "update:displayValue"],
  mounted() {
    this.createPicker();
    document.addEventListener('click', this.submit);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.submit);
  },
  data() {
    return {
      dateDisplay: this.displayValue || '',
      dateValue: this.value,
    }
  },
  watch: {
    value(value) {
        this.dateValue = value;
    },
    displayValue(value) {
        this.dateDisplay = value;
    },
  },
  methods: {
    submit(event) {
      if (event.target.id === `date-picker-confirm-${this.id}`) {
        this.dateDisplay = moment(Date.parse(this.picker.displayValue)).format("MMM DD, YYYY");
        this.dateValue = Date.parse(this.picker.displayValue);
        this.$emit('update:displayValue', this.dateDisplay);
        this.$emit('update:value', this.dateValue);
      }
    },
    open() {
      this.picker.open();
    },
    createPicker() {
      const self = this;
      const today = new Date();
      if (!self.picker) {
        self.picker = f7.picker.create({
          // containerEl: `#picker-date-container-${self.id}`,
          inputEl: `#picker-date-input-${self.id}`,
          renderToolbar: function () {
            return  `<div class="toolbar date-picker-toolbar">
                      <div class="toolbar-inner">
                      <div class="left"></div>
                        <div class="right">
                          <a onclick="self.submit" href="#" id="date-picker-confirm-${self.id}" class="link sheet-close popover-close ripple-color-black">${gui.translate('datePicker.confirmPick')}</a>
                        </div>
                      </div>
                    </div>`;
          },
          rotateEffect: true,
          backdrop: true,
          value: [today.getMonth(), today.getDate(), today.getFullYear()],
          formatValue: function (values, displayValues) {
            return displayValues[0] + " " + values[1] + ", " + values[2];
          },
          cols: [
            // Months
            {
              values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
              displayValues: gui.translate('datePicker.mounthes').split(" "),
              textAlign: "left",
            },
            // Days
            {
              values: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31 ],
            },
            // Years
            {
              values: (function () {
                const arr = [];
                for (let i = 2000; i <= today.getFullYear()+5; i++) {
                  arr.push(i);
                }
                return arr;
              })(),
            },
          ],
          on: {
            change: function (picker, values, displayValues) {
              const daysInMonth = new Date(picker.value[2], picker.value[0] * 1 + 1, 0).getDate();
              if (values[1] > daysInMonth) {
                picker.cols[1].setValue(daysInMonth);
              }
            },
          },
        });
      }
    },
  }
};
</script>
<style lang="scss">
@import "@/css/variables";

.dates-input-wrap {
  background: $input-color;
  border-radius: 10px;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
  height: 45px;
  width: 224px;
  display: flex;
  align-items: center;
    &.error {
    border: 1px solid $error-color;
    color: $error-color;
    box-sizing: border-box;
  }
  .dates-input {
    padding-left: 21px;
    font-size: 18px;
    line-height: 118.75%;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .dates-input-placeholder {
    font-weight: normal;
    color: #a7adbf;
  }
}
.date-picker-toolbar {
  background: #FFFFFF;
  border-bottom: 2px solid #C7C7C7;
  box-sizing: border-box;
  .toolbar-inner .right .link {
    padding: 0 20px;
    font-size: 18px;
    line-height: 118.75%;
    overflow: hidden;
    border-radius: 5px;
  }
}
</style>