<template>
  <div class="sync-dialog" id="sync-dialog" :class="{ 'fade-in': syncShown, 'fade-out': !syncShown }">
    <div class="common-dialog-container">
      <div class="common-dialog-header">
        <div class="common-dialog-icon">
          <BigIcon class="animate__bounceIn" :loading="true" icon="sync" />
        </div>

        <div class="common-dialog-title">{{ title }}</div>
        <div class="common-dialog-description" v-html="loadedTitle"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, watch, computed } from "vue"
  import BigIcon from "../BigIcon"

  export default {
    components: { BigIcon },
    setup() {
      const syncShown = ref(gui.syncShown)
      const syncDetails = ref(gui.syncDetails)

      watch(gui.syncShown, value => {
        syncShown.value = value
      })

      watch(gui.syncDetails, value => {
        syncDetails.value = value
      })

      return {
        syncShown,
        syncDetails,
        loadedTitle: computed(() => {
          const count = new Intl.NumberFormat("en-US").format(syncDetails.value.loaded).replace(/,/g, " ")
          const dict = syncDetails.value.dict

          if(syncDetails.value.state === "pull") {
            return gui.translate("synchronization.loadedFrom", { count, dict })
          } else if(syncDetails.value.state === "push") {
            return gui.translate("synchronization.loadedTo", { count, dict })
          }

          return gui.translate("synchronization.loaded", { count })
        }),
        title: computed(() => {
          if(syncDetails.value.state === "pull") {
            return gui.translate("synchronization.pullTitle")
          } else if(syncDetails.value.state === "push") {
            return gui.translate("synchronization.pushTitle")
          }

          return gui.translate("synchronization.title")
        })
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .sync-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: translateY(100vh);
    background-color: $main-bg-color;
    z-index: 13499;
    transition: .5s;

    .common-dialog-title {
      font-size: 29px;
    }

    &.fade-out {
      .animate__bounceIn {
        animation-name: none !important;
      }
    }

    &.fade-in {
      visibility: visible;
      transform: translateY(0);
    }
  }
</style>
