<template>
  <div id="preloader-dialog" class="preloader-dialog" :class="{ 'fade-in': preloaderShown, 'fade-out': !preloaderShown }">
    <div class="preloader-dialog-inner">
      <Preloader color="white" />
    </div>
  </div>
</template>
<script>
  import { ref, watch } from "vue"

  import Preloader from "@/components/f7/Preloader"

  export default {
    components: { Preloader },
    setup() {
      const preloaderShown = ref(gui.preloaderShown.value)

      watch(gui.preloaderShown, value => {
        preloaderShown.value = value
      })

      return { preloaderShown }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .preloader-dialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 13501;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;

    &.fade-out {
      .preloader-dialog-inner {
        transform: scale(1.5);
      }
    }

    &.fade-in {
      visibility: visible;
      opacity: 1;
      .preloader-dialog-inner {
        transform: scale(1);
      }
    }

    .preloader-dialog-inner {
      transform: scale(0);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: $border-radius;
      background-color: rgba(#000, .9);
      box-sizing: border-box;
      transition: .3s;
    }
  }
</style>