<template>
  <CustomBottomSheet class="printer-settings-sheet" ref="sheet" :mount-root="true" :default-close-all="true">
    <div class="printer-settings-sheet-body">
      <div class="settings-item-container">
        <div class="settings-item-body">
          <PrinterTemplate v-for="category of options.printCategories" :key="category.key" :printer="printer" :category="category" />
        </div>
      </div>
    </div>
  </CustomBottomSheet>
</template>
<script>
  import CustomBottomSheet from "../CustomBottomSheet"
  import PrinterTemplate from "./PrinterTemplate"

  export default {
    components: { CustomBottomSheet, PrinterTemplate },
    props: {
      options: { type: Object, default: () => ({}) },
      printer: { type: Object, default: () => ({}) }
    },
    methods: {
      open() {
        this.$refs.sheet.showAll()
      },
      close() {
        this.$refs.sheet.closeAll()
      }
    }
  }
</script>

<style lang="scss">
@import "@/css/variables";

.printer-settings-sheet {
  .bottom-sheet-container {
    background-color: transparent;
    box-shadow: none;
    padding: $minimal-padding;
    box-sizing: border-box;

    .printer-settings-sheet-body {
      background-color: $main-bg-color;
      border-radius: $border-radius;
      box-shadow: $item-shadow;

      .settings-title {
        min-width: 95px;
        max-width: 95px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .settings-item {
        align-items: center;
      }

      .settings-value {
        flex: 1;
        overflow: hidden;

        .settings-value-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .settings-value-text {
            flex: 1;
            // padding: 0 10px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .settings-value-arrow {
          min-width: 40px;
          max-width: 40px;
          height: 40px;
          border-radius: 50%;
          padding: 0;
          box-sizing: border-box;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $primary-color;
          font-size: 16px;

          i {
            transform-origin: center;
          }

          &.left i {
            transform: rotate(90deg)
          }

          &.right i {
            transform: rotate(-90deg)
          }
        }
      }
    }
  }
}
</style>