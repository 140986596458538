<template>
  <div class="settings-item">
    <div class="settings-title">{{ $te(`printers.categories.${category.key}`) ? $t(`printers.categories.${category.key}`) : category.key }}</div>
    <div class="settings-value">
      <div class="settings-value-inner">
        <f7-button :disabled="!hasPrev" @tap="prev" class="settings-value-arrow ripple-color-black left">
          <i class="mpi-expand2"></i>
        </f7-button>
        <div class="settings-value-text">{{ $te(`printers.templates.${current.key}`) ? $t(`printers.templates.${current.key}`) : current.key }}</div>
        <f7-button :disabled="!hasNext" @tap="next" class="settings-value-arrow ripple-color-black right">
          <i class="mpi-expand2"></i>
        </f7-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      printer: { type: Object, default: () => ({}) },
      category: { type: Object, default: () => ({}) }
    },
    data() {
      return {
        index: this.getTemplateIndex()
      }
    },
    methods: {
      prev() {
        const prevItem = this.category.list[this.index - 1]
        if(prevItem) {
          this.index--

          if(!this.printer.templates) {
            this.printer.templates = {}
          }

          this.printer.templates[this.category.key] = prevItem.key
        }
      },
      next() {
        const nextItem = this.category.list[this.index + 1]
        if(nextItem) {
          this.index++

          if(!this.printer.templates) {
            this.printer.templates = {}
          }

          this.printer.templates[this.category.key] = nextItem.key
        }
      },
      getTemplateIndex() {
        for(let index = 0; index < this.category.list.length; index++) {
          const template = this.category.list[index]
          const categoryKey = this.category.key

          if(this.printer.templates && this.printer.templates[categoryKey]) {
            if(template.key === this.printer.templates[categoryKey]) {
              return index
            }
          } else if(template.default) {
            return index
          }
        }

        return 0
      }
    },
    computed: {
      hasPrev() {
        return this.index > 0
      },
      hasNext() {
        return (this.index + 1) < this.category.list.length
      },
      current() {
        return this.category.list[this.index] || { key: "undefined" }
      }
    }
  }
</script>
