import user from '../user';
import state from '../state';
import gui from '../gui';

const counterDefault = 5;

let idleTimer = null;
let timeout;
let counter = counterDefault;
let logoutAt = 0;

function detectidle() {
  if (logoutAt < Date.now()) {
    user.logout(true);
    gui.idleСountdown = state.idleСountdown = null;
    return
  }

  gui.idleСountdown = state.idleСountdown = counter;

  idleTimer = setTimeout(() => {
    gui.idleСountdown = state.idleСountdown = --counter;

    detectidle();

    if (counter < 0) {
      user.logout(true);
      counter = counterDefault;
      gui.idleСountdown = state.idleСountdown = null;
    }
  }, 1000);
}

export function reset() {
  if (idleTimer !== null) {
    logoutAt = Date.now() + timeout + counterDefault * 1000 + 500;
    gui.idleСountdown = state.idleСountdown = null;
    clearTimeout(idleTimer);
    counter = counterDefault;
    idleTimer = setTimeout(detectidle, timeout);
  }
}

export function register(timeoutMins) {
  const newTimeoutMins = Number(timeoutMins);

  if (Number.isNaN(newTimeoutMins) || newTimeoutMins <= 0) return;
  if (idleTimer !== null) return;

  timeout = newTimeoutMins * 60 * 1000 - counterDefault * 1000;
  idleTimer = setTimeout(detectidle, timeout);
  logoutAt = Date.now() + newTimeoutMins * 60 * 1000 + 500;
}

export function unregister() {
  clearTimeout(idleTimer);
  idleTimer = null;
}

export default {
  register,
  unregister,
  reset
}
