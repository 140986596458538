<template>
  <div class="category-component-container">
    <template v-if="category.children.length === 0">
      <template v-if="category.goods.length > 0">
        <div class="category-goods-container">
          <Favourites @good-click="addGood" :favourites="category.goods" />
          <Uploading :loading="category.uploading" />
        </div>
      </template>
      <div v-else class="category-empty">
        <div class="category-empty-text">{{ $t('receipts.filteredGoodsEmpty', { filter: category.filter.slice(0, 20) } )}}</div>
      </div>
    </template>
    <div v-else-if="category.children.length > 0" class="categories-container">
      <f7-button @tap="$emit('category-click', children)" v-for="children of category.children" :key="children._id" class="category-item ripple-color-black">
        <div class="category-item-inner">{{ children.name }}</div>
      </f7-button>
    </div>
    <div v-else class="category-empty">
      <div class="category-empty-text">{{ $t('receipts.categoryIsEmpty', { name: category.name } )}}</div>
    </div>
  </div>
</template>

<script>
  import { watch, ref } from "vue"

  import Favourites from "./Favourites"
  import Uploading from "../Uploading"

  export default {
    components: { Uploading, Favourites },
    props: {
      category: { type: Object, default: () => ({}) }
    },
    setup(props) {
      const category = ref(props.category)

      watch(() => props.category, value => {
        category.value = value
      })

      return {
        category,
        addGood: good => gui.dispatch("receipt.addGood", { id: good._id })
      }
    }
  }
</script>

<style lang="scss">
  @import "@/css/variables";

  .category-component-container {
    min-height: 100%;
    position: relative;
    .favourites-list {
      padding-bottom: $total-height + $minimal-padding;
    }
  }

  .categories-container {
    .category-item {
      height: 55px;
      padding: 0 12px;
      box-sizing: border-box;
      border-radius: 0;

      &:last-child .category-item-inner {
        border-bottom: none;
      }

      .category-item-inner {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $list-border-color;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        color: $text-color;
        text-transform: none;
        font-size: 18px;
        font-weight: normal;
        padding: 0 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
</style>
