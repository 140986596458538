import Handler from './handler'
import state from "../state"

import { isNewReceipt, isSelection, calculateGood } from "../actions/receipt"
import { round2 } from "../libs/util"

class Taxes extends Handler {
  constructor() {
    super()
    this.enable = true
    this.options = {}
  }

  async calc(doc) {
    if(!isNewReceipt(doc)) {
      return false
    }

    await calculateDocTaxes(doc, state.taxes)
  }
}

export async function calculateDocTaxes(doc, taxes = {}) {
  try {
    const selection = await isSelection(doc)
    const taxExemptionEnabled = !selection && doc.taxExemptionEnabled

    doc.taxes = {}
    doc.sumTaxes = 0
    doc.sumTaxesExempt = 0

    for (const good of doc.goods) {
      good.taxes = {}
      good.sumTaxes = 0
      good.sumTaxesExempt = 0

      await calculateGood(doc, good)

      if(good.count > 0 && good.sum.total > 0.001) {
        const goodTaxIds = (good.taxIds || []).map(id => String(id))
        if(Array.isArray(goodTaxIds)) {
          for(let goodTaxId of goodTaxIds) {
            const goodTax = taxes[goodTaxId] || { error: `Error find tax data by _id ${goodTaxId}. Good ${ good._id } ${ good.name }` }

            if(!goodTax.error) {
              if(!goodTax.enabled) {
                continue
              }

              if(goodTax.holiday) {
                const now = Date.now()
                if(now > goodTax.holiday.start && now < goodTax.holiday.end) {
                  continue
                }
              }

              const docTax = { _id: String(goodTax._id), percent: goodTax.percent, name: goodTax.name, enabled: true }

              const percent = docTax.percent || 0
              const pack = good.pack || 1
              const count = selection ? good.selected : good.count
              const goodTaxSum = round2(good.price * percent / 100.0 * count * pack)

              good.taxes[goodTaxId] = { ...docTax, sum: goodTaxSum }

              if(taxExemptionEnabled) {
                good.sumTaxesExempt = round2(good.sumTaxesExempt + goodTaxSum)
                doc.sumTaxesExempt = round2(doc.sumTaxesExempt + goodTaxSum)
              } else {
                good.sumTaxes = round2(good.sumTaxes + goodTaxSum)
                doc.sumTaxes = round2(doc.sumTaxes + goodTaxSum)
              }

              if(doc.taxes[goodTaxId]) {
                doc.taxes[goodTaxId].sum = round2(doc.taxes[goodTaxId].sum + goodTaxSum)
              } else {
                doc.taxes[goodTaxId] = { ...docTax, sum: goodTaxSum }
              }
            }
          }
        }
      }
    }
  } catch (e) {
    console.log(e)
    alert('error')
  }
}

export default new Taxes()
