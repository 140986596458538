<template>
  <f7-ripple @tap="click" tag="a" :pointer="false" @click.prevent :href="href" :target="target" class="link" :class="{ 'disabled': disabled }">
    <slot />
  </f7-ripple>
</template>

<script>
  import { click } from "@/js/audio"

  export default {
    emits: ["tap"],
    props: {
      disabled: { type: Boolean, default: false },
      target: { type: String, default: "plaint" },
      href: { type: String, default: "#" }
    },
    methods: {
      click(event) {
        if(this.href === "#" || this.href === "") {
          event.preventDefault()
        }

        if(this.disabled) {
          return false
        }

        click()

        this.$emit("tap", event)
      }
    }
  }
</script>
